import {useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {getChannelGroupsDropDownContents} from "../utils/zenGroupSessionStorageManager";
import NotificationManager from "react-notifications/lib/NotificationManager";
import Modal from "react-modal";
import {
    MuiAutocompleteForZenGroupsWithoutCreateGroupOption,
    MuiAutocompleteNonGroupOptions,
    MuiAutocompleteNonGroupOptionsFreeSoloTrueForTextInput,
    MuiCloseIconButton,
    MuiIconWithTooltip
} from "./muiComponents";
import {Button, ThemeProvider} from "@mui/material";
import {roundButtonTheme} from "../utils/muiStyling";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PhoneInput, {isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber} from "react-phone-number-input";
import * as EmailValidator from "email-validator";
import {getUsersInGroupListReactive} from "../pages/api/groupsApi";
import {createNewPartnerInMotionReactive, editPartnerInMotionReactive} from "../pages/api/partnersInMotionApi";

export const CreateAndEditPartnerInMotionModal=({
                                                    isOpen=false, setIsOpen, user, activePartnerInMotion, creatingNewPartnerInMotion=true, setIsLoading, resetGrid, userDistributorGroupsDropdownList,
                                                    ccUserAllPartnerGroupsList, ccAllUsernamesList}) => {
    const { register, reset, handleSubmit } = useForm();
    const [phoneNumber, setPhoneNumber] = useState();
    const [distributorGroupForPartnerInMotion, setDistributorGroupForPartnerInMotion] = useState();
    const [partnerLeadOwnerUserOptions, setPartnerLeadOwnerUserOptions] = useState([]);
    const [partnerLeadOwnerValue, setPartnerLeadOwnerValue] = useState(null);
    const [dealCompletedOrRequestedToSign, setDealCompletedOrRequestedToSign] = useState(false); //controls if certain fields are disabled or not


    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            if(isOpen){
                if(!creatingNewPartnerInMotion){
                    //Editing the partner in motion, populate the fields
                    setDealCompletedOrRequestedToSign(false)
                    if(activePartnerInMotion?.closedDate){
                        setDealCompletedOrRequestedToSign(true)
                    }
                    reset({
                        prospectName: activePartnerInMotion?.prospectName,
                        linkedInCompanyUrl: activePartnerInMotion?.linkedInCompanyUrl,
                        prospectEmail: activePartnerInMotion?.prospectEmail,
                        prospectPhone: "", //is set below
                        prospectAddress: activePartnerInMotion?.prospectAddress,
                        prospectCity: activePartnerInMotion?.prospectCity,
                        prospectOrganization: activePartnerInMotion?.prospectOrganization,
                        prospectZipCode: activePartnerInMotion?.prospectZipCode,
                        prospectDescription: activePartnerInMotion?.prospectDescription,
                        userSetFriendlyName: activePartnerInMotion?.partnerInMotionDisplayName,
                    })
                    setPhoneNumber(activePartnerInMotion?.prospectPhone)
                    setDistributorGroupForPartnerInMotion(activePartnerInMotion?.zenGroupId)
                    setPartnerLeadOwnerValue(activePartnerInMotion?.createdByUsername)
                    setPartnerLeadOwnerUserOptionsForGroup(activePartnerInMotion?.zenGroupId)

                }
                else{
                    //else creating new
                    resetPartnerInMotionFormData()
                }
            }
        })()
        return () => controller?.abort();
    }, [isOpen]);

    function setPartnerLeadOwnerUserOptionsForGroup(channelGroupSelected){
        if(channelGroupSelected === null || channelGroupSelected === undefined){
            clearPartnerLeadOwnerOptionsAndSetCurrentUserToValue()
        }
        else{
            if(user && user.trim().toLowerCase().endsWith("@cybercrucible.com")){
                //is cc user
                setPartnerLeadOwnerUserOptions(ccAllUsernamesList)
            }
            else{
                //not cc user
                getUsersInGroupListReactive(channelGroupSelected).then(response => {
                    if(response !== null && response !== undefined){
                        let usernamesList = []
                        response.forEach(responseUser => {
                            if(responseUser.username){
                                if(user?.trim()?.toLowerCase()?.endsWith("@cybercrucible.com")){ //current user is a cc user, push all usernames to list
                                    usernamesList.push(responseUser.username)
                                }
                                else if(!responseUser.username.trim().toLowerCase().endsWith("@cybercrucible.com")){ //user is not cc user, do not push a cc username to list
                                    usernamesList.push(responseUser.username)
                                }
                            }
                        })
                        //should always have current user in the list, check the list here
                        if((user !== null && user !== undefined) && !usernamesList.includes(user)){
                            usernamesList.push(user)
                        }
                        //If there is a current partnerLeadOwnerValue selected and the new usernamesList does not contain partnerLeadOwnerValue, reset it to current user
                        if((partnerLeadOwnerValue !== null && partnerLeadOwnerValue !== undefined) && !usernamesList.includes(partnerLeadOwnerValue)){
                            if(creatingNewPartnerInMotion){ //if creating a new partner in motion then reset partner lead owner value to current user.
                                setPartnerLeadOwnerValue(user)
                            }
                            //else they are editing, leave partnerLeadOwnerValue alone
                        }
                        setPartnerLeadOwnerUserOptions(usernamesList)
                    }
                    else{
                        clearPartnerLeadOwnerOptionsAndSetCurrentUserToValue()
                    }
                }).catch(function (error) {
                    clearPartnerLeadOwnerOptionsAndSetCurrentUserToValue()
                })
            }
        }
    }

    function clearPartnerLeadOwnerOptionsAndSetCurrentUserToValue(){
        if(user && user.trim().toLowerCase().endsWith("@cybercrucible.com")){
            setPartnerLeadOwnerUserOptions(ccAllUsernamesList)
            setPartnerLeadOwnerValue(null)
        }
        else{
            //reset options list to only have the current user and set the owner value to be the current user
            if(creatingNewPartnerInMotion){
                setPartnerLeadOwnerUserOptions([user])
                setPartnerLeadOwnerValue(user)
            }
            else{
                //else when editing reset options but leave owner value alone
                setPartnerLeadOwnerUserOptions([])
            }
        }
    }

    function resetPartnerInMotionFormData(){
        reset({
            salespersonEmail: user,
            linkedInCompanyUrl: "",
            prospectName: "",
            prospectEmail: "",
            prospectPhone: "",
            prospectAddress: "",
            prospectCity: "",
            prospectOrganization: "",
            prospectZipCode: "",
            prospectDescription: "",
            userSetFriendlyName: ""
        })
        setPhoneNumber()
        setDistributorGroupForPartnerInMotion()
        setDealCompletedOrRequestedToSign(false)
        clearPartnerLeadOwnerOptionsAndSetCurrentUserToValue()
    }

    function getGroupOptionsList(){
        if(user && user.trim().toLowerCase().endsWith("@cybercrucible.com") && ccUserAllPartnerGroupsList !== null && ccUserAllPartnerGroupsList !== undefined){
            return ccUserAllPartnerGroupsList.filter(group => group.isDistributor).map(({ id, organizationName, userSetFriendlyName, friendlyName }) => ({
                value: id,
                label: userSetFriendlyName|| friendlyName || id,
            })).sort(function (a, b) {
                return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
            });
        }
        else{
            return userDistributorGroupsDropdownList
        }

    }


    const submitModal = async (data) => {
        //verify information
        if(!distributorGroupForPartnerInMotion){
            return;
        }
        if(!data.prospectEmail || !partnerLeadOwnerValue){
            return;
        }
        if(data.prospectEmail.trim().length > 0){
            if(!EmailValidator.validate(data.prospectEmail.trim())){
                NotificationManager.error("Please enter a valid prospect email address")
                return;
            }
        }
        else{
            NotificationManager.error("Please enter a valid prospect email address");
            return;
        }

        if(isValidPhoneNumber(phoneNumber) && isPossiblePhoneNumber(phoneNumber)){
            try{
                const phoneNumberParsed = parsePhoneNumber(phoneNumber)
                data.channelPartnerZenGroupId = distributorGroupForPartnerInMotion
                data.phoneCountry = phoneNumberParsed.country
                data.prospectPhone = phoneNumber
                data.prospectEmail = data.prospectEmail.trim().toLowerCase()
                data.partnerLeadOwnerUsername = partnerLeadOwnerValue.trim().toLowerCase()

                setIsLoading && setIsLoading(true)
                if(creatingNewPartnerInMotion){
                    await createNewPartnerInMotionReactive(data);
                    NotificationManager.success(`Successfully registered this partner in motion`);
                }
                else{
                    data.partnerInMotionId = activePartnerInMotion?.id
                    await editPartnerInMotionReactive(data);
                    NotificationManager.success(`Successfully edited this partner in motion`);
                }

                setIsOpen && setIsOpen(false)
                resetGrid && resetGrid()
                resetPartnerInMotionFormData()
            }
            catch(error){
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Unexpected error completing this request");
                }
            }
        }
        else{
            NotificationManager.error("Please enter a valid phone number")
        }
        setIsLoading && setIsLoading(false)
    }

    return (
        <Modal contentLabel="New Partner in Motion" isOpen={isOpen}
               onRequestClose={() => {
                   setIsOpen && setIsOpen(false)
                   resetPartnerInMotionFormData()
               }} shouldCloseOnOverlayClick={false} shouldCloseOnEsc={true}
               className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-4xl max-w-4xl min-w-4xl inset-y-10 mx-auto rounded-2xl`}
               overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
        >
            <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(submitModal)}>
                <div className="flex flex-1 flex-col gap-y-5 ml-1">
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">{creatingNewPartnerInMotion ? "New Partner in Motion" : "Edit Partner in Motion"}</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                setIsOpen && setIsOpen(false)
                                resetPartnerInMotionFormData()
                            }}
                        />
                    </div>
                    <hr className="h-0.5"/>
                    <div className="flex flex-row flex-wrap justify-between gap-x-2 gap-y-2">
                        <div className={"w-full xl:w-[46%] lg:w-[46%] md:w-[46%]"}>
                            <label>Select Parent Distributor Partner Group</label>
                            <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                                disabled={dealCompletedOrRequestedToSign}
                                zenGroupDropdownOptionsList={getGroupOptionsList()}
                                value={distributorGroupForPartnerInMotion}
                                onChange={(event, value) => {
                                    setDistributorGroupForPartnerInMotion(value?.value)
                                    setPartnerLeadOwnerUserOptionsForGroup(value?.value)
                                }}
                            />
                        </div>
                        <div className={"w-full xl:w-[46%] lg:w-[46%] md:w-[46%]"}>
                            {((user && user.trim().toLowerCase().endsWith("@cybercrucible.com") ? (
                                <div>
                                    <label>Partner Lead Owner</label>
                                    <MuiAutocompleteNonGroupOptions
                                        disabled={dealCompletedOrRequestedToSign}
                                        label={"Select Lead Owner"}
                                        options={partnerLeadOwnerUserOptions}
                                        value={partnerLeadOwnerValue}
                                        onChange={(event, value) => {
                                            setPartnerLeadOwnerValue(value)
                                        }}
                                    />
                                </div>
                            ) : (
                                <div>
                                    <label>Partner Lead Owner</label>
                                    <MuiAutocompleteNonGroupOptions
                                        disabled={dealCompletedOrRequestedToSign}
                                        label={"Select Lead Owner"}
                                        options={partnerLeadOwnerUserOptions}
                                        value={partnerLeadOwnerValue}
                                        onChange={(event, value) => {
                                            setPartnerLeadOwnerValue(value)
                                        }}
                                    />
                                </div>
                            )))}
                        </div>
                    </div>
                    <div className="flex flex-row flex-wrap justify-between gap-x-2 gap-y-2">
                        <div className="w-full xl:w-[46%] lg:w-[46%] md:w-[46%]">
                            <label>Prospect Organization</label>
                            <input
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                                disabled={dealCompletedOrRequestedToSign}
                                type="text"
                                name="prospectOrganization"
                                {...register("prospectOrganization")}
                                required
                                placeholder="Required"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className={"w-full xl:w-[46%] lg:w-[46%] md:w-[46%]"}>
                            <label>Prospect Friendly Name</label>
                            <input
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                                type="text"
                                name="userSetFriendlyName"
                                {...register("userSetFriendlyName")}
                                placeholder="Optional"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                    </div>
                    <div className="flex flex-row flex-wrap justify-between gap-y-5">
                        <div className="w-full xl:w-[46%] lg:w-[46%] md:w-[46%]">
                            <label>Prospect Point of Contact</label>
                            <input
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                                type="text"
                                name="prospectName"
                                {...register("prospectName")}
                                required
                                placeholder="Required"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="w-full xl:w-[46%] lg:w-[46%] md:w-[46%]">
                            <label>LinkedIn Company URL</label>
                            <input
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                                disabled={dealCompletedOrRequestedToSign}
                                type="text"
                                name="linkedInCompanyUrl"
                                required
                                {...register("linkedInCompanyUrl")}
                                placeholder="Required"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                    </div>
                    <div className="flex flex-row flex-wrap justify-between gap-y-5">
                        <div className="w-full xl:w-[46%] lg:w-[46%] md:w-[46%]">
                            <label>Prospect Email</label>
                            <input
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                                type="text"
                                required
                                name="prospectEmail"
                                {...register("prospectEmail")}
                                placeholder="Required"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="w-full xl:w-[46%] lg:w-[46%] md:w-[46%]">
                            <label>Prospect Phone</label>
                            <PhoneInput
                                required
                                name="prospectPhone"
                                maxLength="16"
                                className="mt-3 w-full bg-white rounded border border-gray-300 focus:border-theme text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                defaultCountry="US"
                                placeholder="Enter phone number"
                                value={phoneNumber}
                                onChange={setPhoneNumber}
                            />
                        </div>
                    </div>
                    <div className="">
                        <label>Prospect Address</label>
                        <input
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                            type="text"
                            name="prospectAddress"
                            {...register("prospectAddress")}
                            placeholder="Optional"
                            className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                        />
                    </div>
                    <div className="flex flex-row flex-wrap justify-between gap-y-5">
                        <div className="w-full xl:w-[46%] lg:w-[46%] md:w-[46%]">
                            <label>City</label>
                            <input
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                                type="text"
                                name="prospectCity"
                                {...register("prospectCity")}
                                placeholder="Optional"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="w-full xl:w-[46%] lg:w-[46%] md:w-[46%]">
                            <label>Zip Code</label>
                            <input
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                                type="text"
                                name="prospectZipCode"
                                {...register("prospectZipCode")}
                                placeholder="Optional"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                    </div>

                    <div className="">
                        <label>Description</label>
                        <textarea
                            name="prospectDescription"
                            {...register("prospectDescription")}
                            className={"w-full p-2 mt-3 rounded-lg border border-black border-opacity-25 border-solid"}
                            disabled={dealCompletedOrRequestedToSign}
                            rows={5}
                            defaultValue={""}
                            placeholder={"Optional"}
                        />
                    </div>
                    <div className={"flex flex-row flex-wrap justify-between gap-y-3 gap-x-3"}>
                        <ThemeProvider theme={roundButtonTheme}>
                            <Button variant={"contained"} className={"flex-1"}
                                    color={"primary"}
                                    type={"submit"}
                                    style={{minWidth: "200px"}}>
                                {creatingNewPartnerInMotion ? "Register Partner in Motion" : "Edit Partner in Motion"}
                            </Button>
                        </ThemeProvider>
                    </div>
                </div>
            </form>
        </Modal>
    )
}