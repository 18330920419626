import axios from "../../utils/axiosInstance";

export const createNewPartnerInMotionReactive = async (req) => {
    return await axios.post("/createNewPartnerInMotionReactive", req)
};

export const editPartnerInMotionReactive = async (req) => {
    return await axios.post("/editPartnerInMotionReactive", req)
};

export const ccApprovePartnerInMotionReactive = async (partnerInMotionId) => {
    return await axios.post("/ccApprovePartnerInMotionReactive", {
        partnerInMotionId: partnerInMotionId
    });
};
export const ccPartnerInMotionClosedStatusReactive = async (partnerInMotionId, closedWon) => {
    return await axios.post("/ccPartnerInMotionClosedStatusReactive", {
        partnerInMotionId: partnerInMotionId,
        closedWon: closedWon
    });
};

export const ccRejectPartnerInMotionReactive = async (partnerInMotionId) => {
    return await axios.post("/ccRejectPartnerInMotionReactive", {
        partnerInMotionId: partnerInMotionId
    });
};

export const ccRenewPartnerInMotionReactive = async (partnerInMotionId) => {
    return await axios.post("/ccRenewPartnerInMotionReactive", {
        partnerInMotionId: partnerInMotionId
    });
};

export const changePartnerInMotionNameReactive = async (newName, zenGroupId, id) => {
    return await axios.post("/changePartnerInMotionNameReactive", {
        "newName": newName,
        "zenGroupId": zenGroupId,
        "id": id
    });
};

export const getPartnersInMotionGridFilterStateReactive = async () => {
    const response =  await axios.post("/getPartnersInMotionGridFilterStateReactive", {});
    return response.data;
};

export const updatePartnersInMotionGridFilterModelReactive = async (newFilterModel) => {
    return await axios.post("/updatePartnersInMotionGridFilterModelReactive", {
        newFilterModel: newFilterModel
    });
};

export const updatePartnersInMotionGridUseFilterStateReactive = async (newUseFilterState) => {
    return await axios.post("/updatePartnersInMotionGridUseFilterStateReactive", {
        newUseFilterState: newUseFilterState
    });
};

export const getPartnersInMotionGridColumnStateReactive = async () => {
    const response =  await axios.post("/getPartnersInMotionGridColumnStateReactive", {});
    return response.data;
};
export const updatePartnersInMotionGridColumnStateReactive = async (columnStatesList) => {
    return await axios.post("/updatePartnersInMotionGridColumnStateReactive", {
        columnStatesList: columnStatesList
    });
};

export const updatePartnersInMotionGridUseColumnStateReactive = async (newUseColumnState) => {
    return await axios.post("/updatePartnersInMotionGridUseColumnStateReactive", {
        newUseColumnState: newUseColumnState
    });
};

export const updatePartnersInMotionGridColumnModeReactive = async (columnMode) => {
    return await axios.post("/updatePartnersInMotionGridColumnModeReactive", {
        columnMode: columnMode
    });
};