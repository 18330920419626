import React, {useEffect, useState} from 'react';
import SortedObjectArray from "sorted-object-array";
import {Accordion, AccordionBody, AccordionHeader} from "@material-tailwind/react";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import {Link, NavLink} from "react-router-dom";
import {
    storeGroupListForCCUsersOnDealsPageInSessionSSE,
    userGroupListWithChannelInfoReactive
} from "../pages/api/groupsApi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NotificationContainer} from "react-notifications";
import {StripeLoggedInModalSetup} from "./StripeLoggedInModalSetup";
import {useZenGroupSessionStorage} from "../utils/zenGroupSessionStorageManager";
import {Auth} from "aws-amplify";
import {checkRefreshToken, handleLogout} from "../utils/axiosInstance";
import ReactGA from "react-ga";
import {
    decryptAndGetSessionVariable,
    encryptAndStoreSessionVariable, getItemFromStorageWithoutDecrypting
} from "../utils/storageHelper";
import {getAndStoreAllRoleNamesOfCurrentUserInSession} from "../utils/roleNamesOfCurrentUserHelper";
import {Helmet} from "react-helmet";
import SessionTimer from "./sessionTimer";
import {
    getAndStoreAllClientSideDataInSession,
    getAndStoreDistinctAgentVersionsInSession
} from "../utils/clientSideDataSessionStorageHelper";
import {
    ccGenerateMonthlyReportForCustomerReactive,
    ccRetrieveUsernamesReactive,
    generateMonthlyReport
} from "../pages/api/notificationsApi";
import {InformationModal} from "./informationOnlyModal";
import {
    getAndStoreLicensesListWithAgentIdReactiveWithSSE,
    licensesListWithAgentIdSessionStorageChangeStreamListener
} from "../utils/licenseNameGridHelper";
import {getParentGroupsWithTrainingData} from "../pages/api/settingsApi";
import {
    Box,
    Button,
    CssBaseline,
    Divider,
    FormControlLabel,
    IconButton,
    Switch,
    ThemeProvider,
    Tooltip
} from "@mui/material";
import {buttonTheme, roundButtonTheme, switchTheme} from "../utils/muiStyling";
import Modal from "react-modal";
import {checkUserLastPasswordResetDateReactive} from "../pages/api/loginApi";
import Drawer from '@mui/material/Drawer';
import {
    MuiAutocompleteForZenGroupsWithoutCreateGroupOption,
    MuiAutocompleteNonGroupOptions,
    MuiCloseIconButton
} from "./muiComponents";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {getAndStoreAgentNamesListInSession} from "../utils/agentNameGridHelper";
import {
    addSelfToGroupsCCOnly,
    createDefaultDeleteIncidentProgramRoleCCOnly,
    createLicensesCCOnly,
    createTrainingLicensesCCOnly,
    deleteDefaultDeleteIncidentProgramRoleCCOnly,
    getGroupsWithDefaultDeleteProgramRoleCCOnly,
    listAllGroupsCCOnly,
    listAllUserGroupsCCOnly
} from "../pages/api/ccOnlyAddToGroupApi";
import {useForm} from "react-hook-form";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MenuIcon from '@mui/icons-material/Menu';
import { styled, useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

export default function SidebarMenu({setIsLoading}){
    const { register, reset, handleSubmit } = useForm();
    const { register: registerRoleModal, handleSubmit: handleSubmitRoleModal, reset: resetRoleModal } = useForm();
    const [accordionOpen,setAccordionOpen] = useState(0);
    const [isMenuOpen, setIsMenuOpen] = useState(getSidebarSessionState("isMenuOpen"));
    const [operationsOpen, setOperationsOpen] = useState(getSidebarSessionState("operationsOpen"));
    const [ccInternalOperationsOpen, setCCInternalOperationsOpen] = useState(getSidebarSessionState("ccInternalOperationsOpen"));
    const [partnersOpen, setPartnersOpen] = useState(getSidebarSessionState("partnersOpen"));
    const [administrationOpen, setAdministrationOpen] = useState(getSidebarSessionState("administrationOpen"));
    const [threatHuntingOpen, setThreatHuntingOpen] = useState(getSidebarSessionState("threatHuntingOpen"));
    const [isPartner, setIsPartner] = useState(JSON.parse(decryptAndGetSessionVariable("isPartner")));
    const [identityBlocksPageVisible, setIdentityBlocksPageVisible] = useState(JSON.parse(decryptAndGetSessionVariable("identityBlocksPageVisible")));
    const [identityHidesPageVisible, setIdentityHidesPageVisible] = useState(JSON.parse(decryptAndGetSessionVariable("identityHidesPageVisible")));
    const [showStripeLoggedInModal, setShowStripeLoggedInModal] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [userIsMemberOfPartnerDistributorGroup, setUserIsMemberOfPartnerDistributorGroup] = useState(JSON.parse(decryptAndGetSessionVariable("userIsMemberOfPartnerDistributorGroup")));
    const [showPurchaseLicenses, setShowPurchaseLicenses] = useState(false);
    const [showCollectipede, setShowCollectipede] = useState(false);
    const [generateReportInProgress, setGenerateReportInProgress] = useState(false);
    const [showGenerateReportTextModal, setShowGenerateReportTextModal] = useState(false);
    const [usernameEndsWithCyberCrucible, setUsernameEndsWithCyberCrucible] = useState(JSON.parse(decryptAndGetSessionVariable("usernameEndsWithCyberCrucible")));
    const [showResetPasswordNeededModal, setShowResetPasswordNeededModal] = useState(false);
    const [allUsernamesList, setAllUsernamesList] = useState([]);
    const [ccGenerateCustomerReportModalOpen, setCCGenerateCustomerReportModalOpen] = useState(false);
    const [customerReportUsernameSelected, setCustomerReportUsernameSelected] = useState();
    const [createLicensesCCModalOpen, setCreateLicensesCCModalOpen] = useState(false);
    const [usernameSelected, setUsernameSelected] = useState(null);
    const [groupSelected, setGroupSelected] = useState(null);
    const [userGroupOptions, setUserGroupOptions] = useState([]);
    const [endDateValue, setEndDateValue] = React.useState(null);
    const [startDateValue, setStartDateValue] = React.useState(null);
    const [pocLicense, setPOCLicense] = useState(false);
    const [agentLicenses, setAgentLicenses] = useState(true); //Create licenses modal agent licenses vs training licenses
    const [createDeleteIncidentRoleModalOpen, setCreateDeleteIncidentRoleModalOpen] = useState(false);
    const [createDefaultDeleteRole, setCreateDefaultDeleteRole] = useState(true);
    const [deleteRoleGroupOptions, setDeleteRoleGroupOptions] = useState([]);
    const [deleteRoleGroupsSelected, setDeleteRoleGroupsSelected] = useState([]);

    //  transferred from header
    const [loggedUser, setLoggedUser] = useState();
    // eslint-disable-next-line no-unused-vars
    const [showLoggedInOptions, setShowLoggedInOptions] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [zenGroupSessionStorage,setZenGroupSessionStorage] = useZenGroupSessionStorage()
    const trackingCode = process.env.REACT_APP_GA_ID

    const handleAccordionChange = (setHookFunction, oldValue, sessionVariableName) => {
        let newValue = !oldValue
        setSidebarSessionState(sessionVariableName, newValue)
        setHookFunction(newValue)
    }

    const logOut = async () => {
        await Auth.signOut();
        handleLogout()
    };

    const customAccordionAnimation = {mount: { opacity: 1}, unmount: { opacity: .3 }}

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    }));

    useEffect(() =>{
        if(generateReportInProgress){
            //console.debug("Setting generateReportInProgress animation text");
            let generateReportObjectString = "An executive summary report sent to your user's email address detailing information on your licenses, agents, incidents, and process behaviors"

            generateReportObject = <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                            title={<div className={"text-sm"}>{generateReportObjectString}</div>} placement={"right"}>
                <div  className={`text-blue-gray-700 antialiased font-sans text-xl font-semibold leading-snug select-none hover:text-blue-gray-900 transition-colors p-0 disabled:opacity-25 flex shrink-0 flex-nowrap`}
            >Generate Activity Report</div>
            </Tooltip>
        }else{
            let generateReportObjectString = "An executive summary report sent to your user's email address detailing information on your licenses, agents, incidents, and process behaviors"
            //console.debug("Setting generateReport ***NOT*** in progress text");
            generateReportObject = <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                            title={<div className={"text-sm"}>{generateReportObjectString}</div>} placement={"right"}>
                                    <div className={`cursor-pointer text-blue-gray-700 antialiased font-sans text-xl font-semibold leading-snug select-none hover:text-blue-gray-900 transition-colors p-0 flex shrink-0 flex-nowrap`}
                                        onClick={() => {
                                            //to avoid them double clicking before endpoint returns and receiving multiple emails, disable click until response comes back.
                                            //TODO: always set to disabled after first click?
                                            setGenerateReportInProgress(true)
                                            generateMonthlyReport().then(r => {
                                                setShowGenerateReportTextModal(true)
                                                setGenerateReportInProgress(false)
                                            }).catch(error => {
                                                setGenerateReportInProgress(false)
                                            })
                                        }}>Generate Activity Report</div>
                                    </Tooltip>
        }

    },[generateReportInProgress])

    function generateNavigationTabs(forDrawerNavigation){
        let stripeCustomerPortalLink = process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK
        let isDemoUser = false
        let isCCUser = false
        let userName = getItemFromStorageWithoutDecrypting("username")
        let minidumpsTabVisible = false
        if(userName !== null && userName !== undefined){
            stripeCustomerPortalLink = stripeCustomerPortalLink + "?prefilled_email=" + userName.trim().toLowerCase()
        }
        if(userName && userName.toLowerCase().trim() === "demonstration@cybercrucible.com"){
            isDemoUser = true
        }
        if(userName && userName.endsWith("@cybercrucible.com") && userName.toLowerCase() !== "demonstration@cybercrucible.com"){
            isCCUser = true
        }
        let ccInternalOperationsAccordionObject = ""
        if(usernameEndsWithCyberCrucible && !isDemoUser){
            if(userName && (userName.toLowerCase().trim() === "noah.greenberg@cybercrucible.com" ||userName.toLowerCase().trim() === "kyle.nehman@cybercrucible.com" ||
                userName.toLowerCase().trim() === "dennis.underwood@cybercrucible.com" || userName.toLowerCase().trim() === "mark.weideman@cybercrucible.com")){
                minidumpsTabVisible = true
            }
            ccInternalOperationsAccordionObject = <Accordion id="ccInternalAccordion" open={ccInternalOperationsOpen} icon={ccInternalOperationsOpen ? <RemoveRoundedIcon /> : <AddRoundedIcon />} animate={customAccordionAnimation}>
                <AccordionHeader className="flex shrink-0 p-0" onClick={() => handleAccordionChange(setCCInternalOperationsOpen, ccInternalOperationsOpen, "ccInternalOperationsOpen")}>
                    <div className="flex shrink-0">CC Internal Operations</div>
                </AccordionHeader>
                <AccordionBody className="flex flex-col ml-5 gap-y-2 p-0">
                    <NavLink className={`tracking-wider text-dark-grey`} to="/private/ccAddToGroup">
                        <div className="flex shrink-0">Add Self to Group</div>
                    </NavLink>
                    {/*<NavLink className={`tracking-wider text-dark-grey`} to="/private/agentReleaseEmailSubmissions">
                        <div className="flex shrink-0">Agent Release Emails</div>
                    </NavLink>*/}
                    <div className={`cursor-pointer tracking-wider text-dark-grey`} onClick={() => {
                        setCreateDeleteIncidentRoleModalOpen(true)
                        getGroupsWithDefaultDeleteProgramRoleCCOnly().then(response => {
                            let groupsFormattedForOptions = []
                            response.forEach(group => {
                                let objToPush = {}
                                objToPush.value = group.id
                                objToPush.label = group.userSetFriendlyName || group.friendlyName || group.id
                                groupsFormattedForOptions.push(objToPush)
                            })
                            groupsFormattedForOptions.sort(function (a, b) {
                                return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
                            });
                            setDeleteRoleGroupOptions(groupsFormattedForOptions)
                        }).catch(error => {})
                    }}>Default Delete Incident Role
                    </div>
                    <div className={`cursor-pointer tracking-wider text-dark-grey`} onClick={() => {
                        setCreateLicensesCCModalOpen(true)
                    }}>Create Licenses
                    </div>
                    <div className={`cursor-pointer tracking-wider text-dark-grey`} onClick={() => {
                        setCCGenerateCustomerReportModalOpen(true)
                    }}>Generate Customer Report
                    </div>
                    <NavLink className={`tracking-wider text-dark-grey`} to="/private/ccGroupIdentityAccessVisibility">
                        <div className="flex shrink-0">Group Identity Access Visibility</div>
                    </NavLink>
                    <NavLink className={`tracking-wider text-dark-grey ${minidumpsTabVisible ? "block" : "hidden"}`}
                             to="/private/ccMinidumps">
                        <div className="flex shrink-0">Minidumps</div>
                    </NavLink>
                    <NavLink className={`tracking-wider text-dark-grey`} to="/private/partnerGroupManagement">
                        <div className="flex shrink-0">Partner Group Management</div>
                    </NavLink>
                    <NavLink className={`tracking-wider text-dark-grey`} to="/private/ccPartnerOrganizationLeads">
                        <div className="flex shrink-0">Partner Organization Leads</div>
                    </NavLink>
                    <NavLink className={`tracking-wider text-dark-grey`} to="/private/ccLinkedInCompanyProfiles">
                        <div className="flex shrink-0">PB LinkedIn Company Profiles</div>
                    </NavLink>
                    <NavLink className={`tracking-wider text-dark-grey`} to="/private/ccLinkedInEmployeeProfiles">
                        <div className="flex shrink-0">PB LinkedIn Employee Profiles</div>
                    </NavLink>
                    <NavLink className={`tracking-wider text-dark-grey`} to="/private/ccLicenseTrueUps">
                        <div className="flex shrink-0">View Possible License True Ups</div>
                    </NavLink>
                </AccordionBody>
            </Accordion>
        }
        let partnerAccordionObject = ""
        if (isPartner) {
            partnerAccordionObject = <Accordion id="partnersAccordion" open={partnersOpen}
                                                icon={partnersOpen ? <RemoveRoundedIcon/> : <AddRoundedIcon />} animate={customAccordionAnimation}>
                <AccordionHeader className="flex shrink-0 p-0" onClick={() => handleAccordionChange(setPartnersOpen, partnersOpen, "partnersOpen")}>
                    <div className="flex shrink-0">Partners</div>
                </AccordionHeader>
                <AccordionBody className="flex flex-col ml-5 gap-y-2 p-0">
                    <NavLink className="tracking-wider text-dark-grey" to="/private/partnerRegisterDeal">
                        <div className="flex shrink-0">Deal Registration</div>
                    </NavLink>
                    <NavLink className="tracking-wider text-dark-grey" to="/private/salesNotifications">
                        <div className="flex shrink-0">Sales Notifications</div>
                    </NavLink>
                    <NavLink className="tracking-wider text-dark-grey" to="/private/partnerRegisterDeal"
                             state={{openNewDealForm: true}}
                    >
                        <div className="flex shrink-0">Purchase Agent Licenses</div>
                    </NavLink>
                    <NavLink className="tracking-wider text-dark-grey" to="/private/demoVideos">
                        <div className="flex shrink-0">Demo Videos</div>
                    </NavLink>
                    <NavLink className="tracking-wider text-dark-grey" to="/private/paymentManagement">
                        <div className="flex shrink-0">Payment Management</div>
                    </NavLink>
                    <NavLink className={`tracking-wider text-dark-grey ${userIsMemberOfPartnerDistributorGroup ? "block" : "hidden"}`} to="/private/distributorGroupManagement">
                        <div className="flex shrink-0">Distributor Group Management</div>
                    </NavLink>
                    <NavLink className={`tracking-wider text-dark-grey ${(userIsMemberOfPartnerDistributorGroup || isCCUser) ? "block" : "hidden"}`} to="/private/partnersInMotion">
                        <div className="flex shrink-0">Partners in Motion</div>
                    </NavLink>
                </AccordionBody>
            </Accordion>
        }
        let collectipedeObject = "";
        if(showCollectipede){
            collectipedeObject =
                <NavLink className={`tracking-wider text-dark-grey`} to="/private/collectipede">
                    <div className="flex shrink-0">Collectipede</div>
                </NavLink>
        }

        let masterAccordionObject = "";
        if(isMenuOpen || forDrawerNavigation){
            masterAccordionObject = <div className={`flex flex-col w-full basis-full gap-y-1`}>
                {partnerAccordionObject}
                <Accordion id="operationsAccordion" open={operationsOpen} icon={operationsOpen ? <RemoveRoundedIcon /> : <AddRoundedIcon />} animate={customAccordionAnimation}>
                    <AccordionHeader className="flex flex-row flw-nowrap shrink-0 p-0" onClick={() => handleAccordionChange(setOperationsOpen, operationsOpen, "operationsOpen")}>
                        <div className="flex shrink-0">Operations</div>
                    </AccordionHeader>
                    <AccordionBody className="flex flex-col ml-5 gap-y-2 p-0">
                        <NavLink className="tracking-wider text-dark-grey" to="/private/agents">
                            <div className="flex shrink-0">Agents</div>
                        </NavLink>
                        <NavLink className="tracking-wider text-dark-grey" to="/private/customerDeploymentHealth">
                            <div className="flex shrink-0">Customer Deployment Health</div>
                        </NavLink>
                        <NavLink className="tracking-wider text-dark-grey" to="/private/tailoredBehaviors">
                            <div className="flex shrink-0">Tailored Behaviors</div>
                        </NavLink>
                        <NavLink className="tracking-wider text-dark-grey" to="/private/silentResponses">
                            <div className="flex shrink-0">Silent Response Rules</div>
                        </NavLink>
                        <NavLink className="tracking-wider text-dark-grey" to="/private/incidents">
                            <div className="flex shrink-0">Extortion Responses</div>
                        </NavLink>
                        {collectipedeObject}
                    </AccordionBody>
                </Accordion>
                <Accordion id="threatHuntingAccordion" open={threatHuntingOpen} icon={threatHuntingOpen ? <RemoveRoundedIcon /> : <AddRoundedIcon />} animate={customAccordionAnimation}>
                    <AccordionHeader className="flex flex-row flw-nowrap shrink-0 p-0" onClick={() => handleAccordionChange(setThreatHuntingOpen, threatHuntingOpen, "threatHuntingOpen")}>
                        <div className="flex shrink-0">Threat Hunting</div>
                    </AccordionHeader>
                    <AccordionBody className="flex flex-col ml-5 gap-y-2 p-0">
                        <NavLink className="tracking-wider text-dark-grey" to="/private/processInjections">
                            Process Injections
                        </NavLink>
                        <NavLink className="tracking-wider text-dark-grey" to="/private/processCreations">
                            Process Creations
                        </NavLink>
                        <NavLink className="tracking-wider text-dark-grey" to="/private/browserDataIncidents">
                            Identity Access
                        </NavLink>
                        <NavLink className={`tracking-wider text-dark-grey ${identityBlocksPageVisible ? "block" : "hidden"}`} to="/private/browserDataIncidentBlocks">
                            Identity Access Blocks
                        </NavLink>
                        <NavLink className={`tracking-wider text-dark-grey ${identityHidesPageVisible ? "block" : "hidden"}`} to="/private/browserDataIncidentHides">
                            Identity Access Hides
                        </NavLink>
                    </AccordionBody>
                </Accordion>
                <Accordion id="administrationAccordion" open={administrationOpen} icon={administrationOpen ? <RemoveRoundedIcon /> : <AddRoundedIcon />} animate={customAccordionAnimation}>
                    <AccordionHeader className="flex flex-row flw-nowrap shrink-0 p-0" onClick={() => handleAccordionChange(setAdministrationOpen, administrationOpen, "administrationOpen")}>
                        <div className="flex shrink-0">Administration</div>
                    </AccordionHeader>
                    <AccordionBody className="flex flex-col ml-5 gap-y-2 p-0">
                        <NavLink className="tracking-wider text-dark-grey" to="/private/groups">
                            <div className="flex shrink-0">Groups</div>
                        </NavLink>
                        <NavLink className="tracking-wider text-dark-grey" to="/private/roles">
                            <div className="flex shrink-0">Roles</div>
                        </NavLink>
                        <NavLink className="tracking-wider text-dark-grey" to="/private/sessionDurations">
                            <div className="flex shrink-0">Session Timeout Rules</div>
                        </NavLink>
                        <NavLink className="tracking-wider text-dark-grey" to="/private/notifications">
                            <div className="flex shrink-0">Security Notifications</div>
                        </NavLink>
                        <NavLink className="tracking-wider text-dark-grey" to="/private/trainingLicenses">
                            <div className="flex shrink-0">Training Licenses</div>
                        </NavLink>
                        <NavLink className="tracking-wider text-dark-grey" to="/private/settings">
                            <div className="flex shrink-0">Account Settings</div>
                        </NavLink>
                        <NavLink className="tracking-wider text-dark-grey" to="/private/restIntegration">
                            <div className="flex shrink-0">Rest Integration</div>
                        </NavLink>
                    </AccordionBody>
                </Accordion>
                {ccInternalOperationsAccordionObject}

                {/*<NavLink className="flex shrink-0 text-blue-gray-700 antialiased font-sans text-xl font-semibold leading-snug select-none hover:text-blue-gray-900 transition-colors p-0" to="/private/settings">
                <div className="flex shrink-0">Account Settings</div>
            </NavLink>*/}
                <NavLink className="text-blue-gray-700 antialiased font-sans text-xl font-semibold leading-snug select-none hover:text-blue-gray-900 transition-colors p-0" to="/private/licenses">
                    <div className="flex shrink-0">Agent Licenses</div>
                </NavLink>
                <div className={`text-blue-gray-700 antialiased font-sans text-xl font-semibold leading-snug select-none hover:text-blue-gray-900 transition-colors p-0 ${(showPurchaseLicenses && !isPartner) ? 'block' : 'hidden'}`} onClick={() => {
                    setShowStripeLoggedInModal(true)
                }}>Purchase Agent Licenses</div>
                <a className="text-blue-gray-700 antialiased font-sans text-xl font-semibold leading-snug select-none hover:text-blue-gray-900 transition-colors p-0" href={stripeCustomerPortalLink} rel="noreferrer" target="_blank">
                    Manage Payment Methods
                </a>
                <div className={`${(usernameEndsWithCyberCrucible && !isDemoUser) ? 'hidden' : 'block'}`}>
                    {generateReportObject}
                </div>
                <NavLink className="text-blue-gray-700 antialiased font-sans text-xl font-semibold leading-snug select-none hover:text-blue-gray-900 transition-colors p-0" onClick={logOut} to="">
                    <div className="flex shrink-0">Logout</div>
                </NavLink>

                <NotificationContainer />
            </div>
        }
        return masterAccordionObject
    }

    let generateReportObject = ""
    let generateReportObjectString = "An executive summary report sent to your user's email address detailing information on your licenses, agents, incidents, and process behaviors"

    if(generateReportInProgress){
        generateReportObject = <Tooltip slotProps={{tooltip: {sx: {maxWidth: 700}}}} arrow enterDelay={750} title={<div className={"text-sm"}>{generateReportObjectString}</div>} placement={"right"}>
            <div className={`text-blue-gray-700 antialiased font-sans text-xl font-semibold leading-snug select-none hover:text-blue-gray-900 transition-colors p-0 disabled:opacity-25 flex shrink-0 flex-nowrap`}
             >Generate Activity Report</div>
        </Tooltip>
    }else{
        generateReportObject = <Tooltip slotProps={{tooltip: {sx: {maxWidth: 700}}}} arrow enterDelay={750} title={<div className={"text-sm"}>{generateReportObjectString}</div>} placement={"right"}>
            <div className={`cursor-pointer text-blue-gray-700 antialiased font-sans text-xl font-semibold leading-snug select-none hover:text-blue-gray-900 transition-colors p-0 flex shrink-0 flex-nowrap`}
             onClick={() => {
                 //to avoid them double clicking before endpoint returns and receiving multiple emails, disable click until response comes back.
                 //TODO: always set to disabled after first click?
                 setGenerateReportInProgress(true)
                 generateMonthlyReport().then(r => {
                     setShowGenerateReportTextModal(true)
                     setGenerateReportInProgress(false)
                 }).catch(error => {
                     setGenerateReportInProgress(false)
                 })
             }}>Generate Activity Report</div>
        </Tooltip>
    }


    //initialize Google Analytics and set pageview, and checkRefreshToken()
    useEffect(() => {
        //ReactGA initialized in App.js
        try{
            ReactGA.initialize(trackingCode);
            ReactGA.set({ page: window.location.pathname});
            ReactGA.pageview(window.location.pathname);
        }
        catch(error){
            console.log(error)
        }
        checkRefreshToken()
    }, [trackingCode])

    //user logged in check
    useEffect(() => {
        async function auth() {
            let path = window.location.pathname
            let privatePage = false;
            if(/^\/private\//.test(path)){ //check if we are on a private page
                privatePage = true
            }
            setShowLoggedInOptions(!privatePage)
            try {
                let refreshToken = decryptAndGetSessionVariable("refresh_token")
                if(refreshToken !== null){
                    //user logged in
                    loadJiraScript() //loads jira script if it's not loaded already on page
                    //console.log("refresh token found, user logged in")
                    if(!loggedUser)
                        setLoggedUser(true)
                    if(/^\/login/.test(path)){ //check if we are on the login page
                        window.location.href='./private/agents'; //take user to agents page
                    }
                }
                else{
                    //user not logged in
                    unloadJiraScript() //hide jira widget if it does exist when a user is not logged in
                    //console.log("refresh token not found, user not logged in")
                    setLoggedUser(false)
                    //TODO: is there a better way to redirect users to another page?
                    if(privatePage){
                        window.location.href='.././'; //take user back to home page
                    }

                }
            } catch (error) {
                if(privatePage){
                    window.location.href='.././'; //take user back to home page
                }
            }
        }
        auth()
        //!collapsed && window.addEventListener("scroll", handleScroll);
    }, [loggedUser])

    //isPartner check
    useEffect(() => {
        if(loggedUser){
            Auth.currentSession().then(response => {
                let accessToken = response.getAccessToken().getJwtToken()
                checkUserLastPasswordResetDateReactive(false, accessToken).then(response => {
                    if(response && response.passwordResetNeeded === true){
                        //user needs to reset password
                        setShowResetPasswordNeededModal(true)
                        try{
                            //log user out since they need to reset their password and login again after
                            sessionStorage.clear();
                            localStorage.clear()
                            Auth.signOut().then().catch(e => {})
                        } catch (e) {}
                    }
                }).catch(e => {})
            }).catch(error => {
                console.error(error)
            })
            licensesListWithAgentIdSessionStorageChangeStreamListener()
            //getAndStoreLicensesListWithAgentIdReactive()
            storeGroupListForCCUsersOnDealsPageInSessionSSE()
            getAndStoreAllRoleNamesOfCurrentUserInSession()

            //Updating licenses list and agent names list in session since if they come back to our site after a while with remember me enabled, we want to make sure these lists are updated.
            getAndStoreLicensesListWithAgentIdReactiveWithSSE(false)
            getAndStoreAgentNamesListInSession()

            let userName = getItemFromStorageWithoutDecrypting("username")
            if(userName && userName.endsWith("@cybercrucible.com")){
                ccRetrieveUsernamesReactive().then(response => {
                    setAllUsernamesList(response)
                }).catch(function (error) {
                    setAllUsernamesList([])
                })
            }
            userGroupListWithChannelInfoReactive().then(zenGroupsFromRest => {
                //console.log("starting to process getGroupsWithChannelPartnerInformationForUser");
                let zenGroupSortedObjectArray = new SortedObjectArray("friendlyName");
                let zenGroupBareObjects = []
                let isMemberOfChannelPartnerCustomerGroup = false
                let isMemberOfChannelPartnerGroup = false
                let isMemberOfPartnerDistributorGroup = false
                let identityBlocksVisible = false
                let identityHidesVisible = false
                zenGroupsFromRest.forEach(groupFromRest => {
                    //username and the member fields here should be the same for each result
                    //userName = groupFromRest.username
                    isMemberOfChannelPartnerCustomerGroup = groupFromRest.isMemberOfChannelPartnerCustomerGroup
                    isMemberOfChannelPartnerGroup = groupFromRest.isMemberOfChannelPartnerGroup
                    isMemberOfPartnerDistributorGroup = groupFromRest.isMemberOfPartnerDistributorGroup
                    identityBlocksVisible = groupFromRest.identityBlocksVisible
                    identityHidesVisible = groupFromRest.identityHidesVisible
                    zenGroupSortedObjectArray.insert({"friendlyName": groupFromRest.zenGroup.friendlyName?.toLowerCase(), zenGroup: groupFromRest.zenGroup})
                    //zenGroups.push(group.zenGroup)
                })
                //console.log(zenGroupSortedObjectArray);
                //we can potentially really amke this more efficient:
                //https://www.npmjs.com/package/sorted-object-array
                //instead, we'll prune it

                zenGroupSortedObjectArray.array.forEach(sortedObject => {

                    zenGroupBareObjects.push(sortedObject.zenGroup)
                });
                //console.log(zenGroupBareObjects);
                setZenGroupSessionStorage(zenGroupBareObjects)

                let isPartner = false;
                for (let idx in zenGroupBareObjects) {
                    let group = zenGroupBareObjects[idx]
                    if (group.channelPartner) {
                        isPartner = true;
                        break;
                    }
                }
                let endsWithCyberCrucible = false
                if(userName){
                    //stored in session already from login
                    //If username ends with @cybercrucible and not equal to demonstration@cybercrucible.com, then we should allow cc users to see partner portal
                    if(userName.endsWith("@cybercrucible.com") && userName.toLowerCase() !== "demonstration@cybercrucible.com"){
                        endsWithCyberCrucible = true
                        isPartner = true
                    }
                }
                if(userName === "mark.weideman@cybercrucible.com"){
                    //setShowCollectipede(true) //commenting out until needed again
                }
                else{
                    setShowCollectipede(false)

                    let path = window.location.pathname
                    //check if user is on this collectipede page and is not allowed to be
                    if(/^\/private\/collectipede$/.test(path)){ //check if we are on collectipede page
                        //take them to agents page
                        window.location.href='./agents';
                    }
                }
                let isPartnerSessionValue = JSON.parse(decryptAndGetSessionVariable("isPartner"))
                if(isPartnerSessionValue !== isPartner){
                    encryptAndStoreSessionVariable("isPartner",JSON.stringify(isPartner))
                    setIsPartner(isPartner);
                }

                let endsWithCyberCrucibleSessionVariable = JSON.parse(decryptAndGetSessionVariable("usernameEndsWithCyberCrucible"))
                if(endsWithCyberCrucibleSessionVariable !== endsWithCyberCrucible){
                    encryptAndStoreSessionVariable("usernameEndsWithCyberCrucible",JSON.stringify(endsWithCyberCrucible))
                    setUsernameEndsWithCyberCrucible(endsWithCyberCrucible);
                }

                let identityBlocksPageVisibleSessionValue = JSON.parse(decryptAndGetSessionVariable("identityBlocksPageVisible"))
                if(identityBlocksPageVisibleSessionValue !== identityBlocksVisible){
                    encryptAndStoreSessionVariable("identityBlocksPageVisible",JSON.stringify(identityBlocksVisible))
                    setIdentityBlocksPageVisible(identityBlocksVisible);
                }

                let identityHidesPageVisibleSessionValue = JSON.parse(decryptAndGetSessionVariable("identityHidesPageVisible"))
                if(identityHidesPageVisibleSessionValue !== identityHidesVisible){
                    encryptAndStoreSessionVariable("identityHidesPageVisible",JSON.stringify(identityHidesVisible))
                    setIdentityHidesPageVisible(identityHidesVisible);
                }

                if(isMemberOfChannelPartnerCustomerGroup){
                    //we need to check if they are also a part of a channelPartner group
                    if(isMemberOfChannelPartnerGroup){
                        //good to show
                        setShowPurchaseLicenses(true)
                    }
                    else{
                        //don't allow tabs to show
                        setShowPurchaseLicenses(false)
                    }
                }
                else{
                    //good to show
                    setShowPurchaseLicenses(true)
                }
                let isMemberOfPartnerDistributorGroupSessionVariable = JSON.parse(decryptAndGetSessionVariable("userIsMemberOfPartnerDistributorGroup"))
                if(isMemberOfPartnerDistributorGroupSessionVariable !== isMemberOfPartnerDistributorGroup){
                    encryptAndStoreSessionVariable("userIsMemberOfPartnerDistributorGroup",JSON.stringify(isMemberOfPartnerDistributorGroup))
                    setUserIsMemberOfPartnerDistributorGroup(isMemberOfPartnerDistributorGroup)
                }
                if(!isPartner){
                    let path = window.location.pathname
                    let onDealsPage = false;
                    let onDemoVideosPage = false;
                    if(/^\/private\/partnerRegisterDeal$/.test(path)){ //check if we are on partnerRegisterDeal page
                        onDealsPage = true
                    }
                    //demoVideos
                    if(/^\/private\/demoVideos$/.test(path)){ //check if we are on partnerRegisterDeal page
                        onDemoVideosPage = true
                    }
                    if(onDealsPage || onDemoVideosPage){
                        window.location.href='./agents'; //take user to agents page since they should not be on the deals page
                    }
                }
                //if username does not end with @cybercrucible.com, check if they are on the agentReleaseEmailSubmissions or partnerGroupManagement page. If so, redirect them to agents page
                if(!endsWithCyberCrucible){
                    let path = window.location.pathname
                    let onAgentReleaseEmailsPage = false;
                    let onPartnerGroupManagement = false;
                    let onPartnerLeads = false;
                    let onCCAddToGroup = false;
                    let onCCMinidumps = false;
                    let onCCLicenseTrueUps = false;
                    let onCCGroupIdentityAccessVisibility = false;
                    let onCCLinkedInCompanyProfiles = false
                    let onCCLinkedInEmployeeProfiles = false
                    if(/^\/private\/agentReleaseEmailSubmissions$/.test(path)){ //check if we are on agentReleaseEmailSubmissions page
                        onAgentReleaseEmailsPage = true
                    }
                    if(/^\/private\/partnerGroupManagement$/.test(path)){ //check if we are on partnerGroupManagement page
                        onPartnerGroupManagement = true
                    }
                    if(/^\/private\/ccPartnerOrganizationLeads$/.test(path)){ //check if we are on ccPartnerOrganizationLeads page
                        onPartnerLeads = true
                    }
                    if(/^\/private\/ccLinkedInCompanyProfiles$/.test(path)){ //check if we are on ccLinkedInCompanyProfiles page
                        onCCLinkedInCompanyProfiles = true
                    }
                    if(/^\/private\/ccLinkedInEmployeeProfiles$/.test(path)){ //check if we are on ccLinkedInEmployeeProfiles page
                        onCCLinkedInEmployeeProfiles = true
                    }
                    if(/^\/private\/ccLicenseTrueUps$/.test(path)){ //check if we are on ccLicenseTrueUps page
                        onCCLicenseTrueUps = true
                    }
                    if(/^\/private\/ccAddToGroup$/.test(path)){ //check if we are on ccAddToGroup page
                        onCCAddToGroup = true
                    }
                    if(/^\/private\/ccMinidumps$/.test(path)){ //check if we are on ccMinidumps page
                        onCCMinidumps = true
                    }
                    if(/^\/private\/ccGroupIdentityAccessVisibility$/.test(path)){ //check if we are on ccGroupIdentityAccessVisibility page
                        onCCGroupIdentityAccessVisibility = true
                    }
                    if(onAgentReleaseEmailsPage || onPartnerGroupManagement || onCCAddToGroup || onCCMinidumps || onPartnerLeads || onCCGroupIdentityAccessVisibility
                        || onCCLicenseTrueUps || onCCLinkedInCompanyProfiles || onCCLinkedInEmployeeProfiles){
                        window.location.href='./agents'; //take user to agents page since they should not be on these CC only pages
                    }
                }
                if(!identityHidesVisible){
                    let path = window.location.pathname
                    if(/^\/private\/browserDataIncidentHides$/.test(path)){
                        window.location.href='./agents'; //take user to agents page
                    }
                }
                if(!identityBlocksVisible){
                    let path = window.location.pathname
                    if(/^\/private\/browserDataIncidentBlocks$/.test(path)){
                        window.location.href='./agents'; //take user to agents page
                    }
                }
                if(!isMemberOfPartnerDistributorGroup){
                    let path = window.location.pathname
                    let onDistributorGroupManagementPage = false;
                    let onPartnersInMotionPage = false;
                    if(/^\/private\/distributorGroupManagement$/.test(path)){ //check if we are on distributorGroupManagement page
                        onDistributorGroupManagementPage = true
                    }
                    if(!endsWithCyberCrucible && /^\/private\/partnersInMotion$/.test(path)){ //check if we are on partnersInMotion page
                        onPartnersInMotionPage = true
                    }
                    if(onDistributorGroupManagementPage || onPartnersInMotionPage){
                        window.location.href='./agents'; //take user to agents page since they should not be on the distributorGroupManagement or partnersInMotion page
                    }
                }
            }).catch(function (error) {
                //console.log("getGroupsWithChannelPartnerInformationForUser error received: ");
                //console.log(error);
                setIsPartner(false);
                setShowPurchaseLicenses(false)
                setZenGroupSessionStorage(null)
                setIdentityBlocksPageVisible(false)
                setIdentityHidesPageVisible(false)
                encryptAndStoreSessionVariable("isPartner","false")
                encryptAndStoreSessionVariable("usernameEndsWithCyberCrucible", "false")
                encryptAndStoreSessionVariable("userIsMemberOfPartnerDistributorGroup", "false")

            })
            getAndStoreAllClientSideDataInSession() //update all client side grid data
            getAndStoreDistinctAgentVersionsInSession()
            getParentGroupsWithTrainingData().then(zenGroupIds => {
                if(zenGroupIds === undefined || zenGroupIds === null){
                    encryptAndStoreSessionVariable("groupIdsWithTrainingData", JSON.stringify([]))
                }
                else{
                    encryptAndStoreSessionVariable("groupIdsWithTrainingData", JSON.stringify(zenGroupIds))
                }
            }).catch(function (error) {
                encryptAndStoreSessionVariable("groupIdsWithTrainingData", JSON.stringify([]))
            })
        }
        else{

        }
    }, [loggedUser,setZenGroupSessionStorage]);

    const submitCreateLicenses = (data) => {
        if(data && groupSelected && endDateValue && startDateValue){
            let serverLicenseCount = parseInt(data.serverLicenseCount)
            let desktopLicenseCount = parseInt(data.desktopLicenseCount)
            let trainingLicenseCount = parseInt(data.trainingLicenseCount)
            if(isNaN(serverLicenseCount)){
                serverLicenseCount = 0
            }
            if(isNaN(desktopLicenseCount)){
                desktopLicenseCount = 0
            }
            if(isNaN(trainingLicenseCount)){
                trainingLicenseCount = 0
            }
            let startDayOfMonth = startDateValue.date()
            let startMonthOfYear = startDateValue.month() + 1 //month() is 0 based, so we need + 1
            let startYear = startDateValue.year()
            if(!startDateValue.isValid() || isNaN(startDayOfMonth) || isNaN(startMonthOfYear) || isNaN(startYear)){
                NotificationManager.info("Invalid Start Date Entered")
                return
            }

            let dayOfMonth = endDateValue.date()
            let monthOfYear = endDateValue.month() + 1 //month() is 0 based, so we need + 1
            let year = endDateValue.year()
            if(!endDateValue.isValid() || isNaN(dayOfMonth) || isNaN(monthOfYear) || isNaN(year)){
                NotificationManager.info("Invalid Expiration Date Entered")
                return
            }
            //Check if these are agent licenses or training licenses
            if(agentLicenses){
                //these are agent licenses
                if(serverLicenseCount < 1 && desktopLicenseCount < 1){
                    NotificationManager.info("You must enter at least one desktop or server license")
                    return
                }
                setIsLoading && setIsLoading(true)
                createLicensesCCOnly(groupSelected, pocLicense, desktopLicenseCount, serverLicenseCount, year, monthOfYear, dayOfMonth, startYear, startMonthOfYear, startDayOfMonth).then(response => {
                    setIsLoading && setIsLoading(false)
                    NotificationManager.success("Successfully created licenses")
                    resetCreateLicensesModal()
                }).catch(function(error){
                    setIsLoading && setIsLoading(false)
                    if(error.message){
                        NotificationManager.error(error.message)
                    }
                    else{
                        NotificationManager.error("Unexpected error creating licenses")
                    }
                })
            }
            else{
                //these are training licenses
                if(trainingLicenseCount < 1){
                    NotificationManager.info("You must enter at least one training license")
                    return
                }
                setIsLoading && setIsLoading(true)
                createTrainingLicensesCCOnly(groupSelected, pocLicense, trainingLicenseCount, year, monthOfYear, dayOfMonth, startYear, startMonthOfYear, startDayOfMonth).then(response => {
                    setIsLoading && setIsLoading(false)
                    NotificationManager.success("Successfully created licenses")
                    resetCreateLicensesModal()
                }).catch(function(error){
                    setIsLoading && setIsLoading(false)
                    if(error.message){
                        NotificationManager.error(error.message)
                    }
                    else{
                        NotificationManager.error("Unexpected error creating licenses")
                    }
                })
            }


        }


    }

    const submitCreateDeleteIncidentRole = () => {
        if(createDefaultDeleteRole){
            if(groupSelected){
                setIsLoading && setIsLoading(true)
                createDefaultDeleteIncidentProgramRoleCCOnly(groupSelected).then(response => {
                    setIsLoading && setIsLoading(false)
                    NotificationManager.success("Successfully Created the Role")
                    resetDefaultDeleteIncidentRoleModal()
                }).catch(function(error){
                    setIsLoading && setIsLoading(false)
                    if(error.message){
                        NotificationManager.error(error.message)
                    }
                    else{
                        NotificationManager.error("Unexpected error creating the role")
                    }
                })
            }
        }
        else{
            if(deleteRoleGroupsSelected && deleteRoleGroupsSelected.length > 0){
                let groupIdsToSend = []
                deleteRoleGroupsSelected.forEach(groupToDeleteRole => {
                    if(groupToDeleteRole.value !== null && groupToDeleteRole.value !== undefined){
                        groupIdsToSend.push(groupToDeleteRole.value)
                    }
                })
                setIsLoading && setIsLoading(true)
                deleteDefaultDeleteIncidentProgramRoleCCOnly(groupIdsToSend).then(response => {
                    setIsLoading && setIsLoading(false)
                    NotificationManager.success("Successfully Deleted the Role(s)")
                    resetDefaultDeleteIncidentRoleModal()
                }).catch(function(error){
                    setIsLoading && setIsLoading(false)
                    if(error.message){
                        NotificationManager.error(error.message)
                    }
                    else{
                        NotificationManager.error("Unexpected error deleting the role")
                    }
                })
            }
        }
    }

    function loadJiraScript(){
        //jira script for widget
        try{
            let jiraWidgetScript = document.createElement('script');
            jiraWidgetScript.type = 'text/javascript';
            jiraWidgetScript.id = "jiraHelpDeskWidget";
            jiraWidgetScript.setAttribute('data-jsd-embedded', null);
            jiraWidgetScript.setAttribute('data-key', '93b9f178-ca0f-47e9-bb55-4a681006da70');
            jiraWidgetScript.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
            jiraWidgetScript.src='https://jsd-widget.atlassian.com/assets/embed.js';
            if(jiraWidgetScript.readyState) { // old IE support
                jiraWidgetScript.onreadystatechange = function() {
                    if ( jiraWidgetScript.readyState === 'loaded' || jiraWidgetScript.readyState === 'complete' ) {
                        jiraWidgetScript.onreadystatechange = null;
                        jiraHelpdesk();
                    }
                };
            } else { //modern browsers
                jiraWidgetScript.onload = function() {
                    jiraHelpdesk();
                };
            }
            if (!document.getElementById('jiraHelpDeskWidget')) {
                document.getElementsByTagName('head')[0].appendChild(jiraWidgetScript);
                window.document.dispatchEvent(new Event("DOMContentLoaded", {
                    bubbles: true,
                    cancelable: true
                }));
            }

            let jiraHelpdesk =(function() {
                window.document.dispatchEvent(new Event("DOMContentLoaded", {
                    bubbles: true,
                    cancelable: true
                }));
            });
        }
        catch(error){
            //console.log(error)
        }
    }

    function unloadJiraScript(){
        let iframe = document.querySelector("#jsd-widget");
        //hide widget if it exists
        if(iframe){
            iframe.style.display = 'none';
        }
    }

    function resetCreateLicensesModal(){
        setCreateLicensesCCModalOpen(false)
        setUsernameSelected(null)
        setGroupSelected(null)
        setUserGroupOptions([])
        setEndDateValue(null)
        setStartDateValue(null)
        setPOCLicense(false)
        setAgentLicenses(true)
        reset({
            serverLicenseCount: "",
            desktopLicenseCount: "",
            trainingLicenseCount: "",
        })
    }
    function resetDefaultDeleteIncidentRoleModal(){
        setCreateDeleteIncidentRoleModalOpen(false)
        setCreateDefaultDeleteRole(true)
        setUsernameSelected(null)
        setGroupSelected(null)
        setUserGroupOptions([])
        setDeleteRoleGroupsSelected([])
        setDeleteRoleGroupOptions([])
    }

    function getGroupsForUser(username){
        //Need to reset group options and selected group since new username is selected
        setGroupSelected(null)
        setUserGroupOptions([])
        if(username !== null && username !== undefined){
            listAllUserGroupsCCOnly(username).then(results => {
                let groupsFormattedForOptions = []
                results.forEach(group => {
                    let objToPush = {}
                    objToPush.value = group.id
                    objToPush.label = group.userSetFriendlyName || group.friendlyName || group.id
                    groupsFormattedForOptions.push(objToPush)
                })
                groupsFormattedForOptions.sort(function (a, b) {
                    return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
                });
                setUserGroupOptions(groupsFormattedForOptions)
            }).catch(function (error) {})
        }
    }

    return (
        <div className={`ml-2`}>
            <div id="sideBarComponent" className={`ml-2 flex flex-col mt-8`}>
                <Helmet>
                    <script>{`var DID=260241;`}</script>
                    <script type="text/javascript">{`
                            linkedin_partner_id = "1395570";
                            window.linkedin_data_partner_ids = window.linkedin_data_partner_ids || [];
                            window.linkedin_data_partner_ids.push(linkedin_partner_id);
                    `}</script>
                    <noscript>{`<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=1395570&fmt=gif" />`}
                    </noscript>
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-YD4ZG9H383"></script>
                    <script type="text/javascript">{`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-YD4ZG9H383');
                    `}</script>
                </Helmet>
                <SessionTimer loggedUser={loggedUser}/>
                <StripeLoggedInModalSetup
                    onClose={() => {
                        setShowStripeLoggedInModal(false)
                    }}
                    setIsLoading={setIsLoading}
                    opened={showStripeLoggedInModal}
                    setIsOpened={setShowStripeLoggedInModal}
                />
                <Modal contentLabel={"User password reset needed modal"} isOpen={showResetPasswordNeededModal}
                       onRequestClose={async () => {
                           setShowResetPasswordNeededModal(false)
                       }}
                       shouldCloseOnOverlayClick={false}
                       shouldCloseOnEsc={false}
                       className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white max-w-xl inset-y-10 mx-auto rounded-2xl overflow-auto`}
                       overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
                >
                    <div className="flex flex-col p-5 pl-8 pr-8">
                        <div className="flex-1 mb-6 text-xl">
                            <small>
                                The password for your account needs reset. A verification code to reset your password was sent to your email address.
                                You need to immediately login within the same session after resetting your password to complete the process.
                            </small>
                        </div>
                        <div className="flex flex-row flex-wrap">
                            <Link to={{pathname:"../resetPassword"}} state={{showFullForm: true}} className="">
                                <ThemeProvider theme={roundButtonTheme}>
                                    <Button variant={"contained"} color={"primary"}>
                                        Reset Password
                                    </Button>
                                </ThemeProvider>
                            </Link>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={ccGenerateCustomerReportModalOpen}
                    onRequestClose={() => {
                        setCCGenerateCustomerReportModalOpen(false)
                        setCustomerReportUsernameSelected()
                    }}
                    contentLabel={"Generate Customer Activity Report"}
                    className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white xl:w-lg lg:w-lg md:w-lg sm:w-lg xs:w-11/12 max-w-lg inset-y-10 mx-auto rounded-2xl`}
                    overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
                    shouldCloseOnOverlayClick={true}
                >
                    <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-3">
                        {/*Title with exit button*/}
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Generate Customer Activity Report</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    setCCGenerateCustomerReportModalOpen(false)
                                    setCustomerReportUsernameSelected()
                                }}
                            />
                        </div>
                        <hr className="h-0.5" />
                        {/*Form content*/}
                        <div>
                            <label>Select which user to generate the report content for</label>
                            <MuiAutocompleteNonGroupOptions
                                options={allUsernamesList}
                                value={customerReportUsernameSelected}
                                onChange={(event, value) => {
                                    setCustomerReportUsernameSelected(value)
                                }}
                            />
                        </div>
                        <div className={"flex flex-row flex-wrap gap-y-3 gap-x-3 justify-between"}>
                            <ThemeProvider theme = {buttonTheme}>
                                <Button variant={"contained"} className={"flex-1"} color={"primary"} type={"submit"}
                                        onClick={() => {
                                            if(customerReportUsernameSelected){
                                                setIsLoading(true)
                                                ccGenerateMonthlyReportForCustomerReactive(customerReportUsernameSelected).then(response => {
                                                    setShowGenerateReportTextModal(true)
                                                    setIsLoading(false)
                                                }).catch(error => {
                                                    if(error.message){
                                                        NotificationManager.error(error.message)
                                                    }
                                                    else{
                                                        NotificationManager.error("Unexpected error generating report.")
                                                    }
                                                    setIsLoading(false)
                                                })
                                            }
                                        }}>
                                    Send Report for this User to Myself
                                </Button>
                            </ThemeProvider>
                        </div>

                    </div>
                </Modal>
                <Modal contentLabel="Create Licenses" isOpen={createLicensesCCModalOpen}
                       onRequestClose={() => {
                           resetCreateLicensesModal()
                       }} shouldCloseOnOverlayClick={true}
                       className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white max-w-2xl inset-y-10 mx-auto rounded-2xl`}
                       overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
                >
                    <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(submitCreateLicenses)}>
                        <div className="flex flex-1 flex-col gap-y-4 ml-1">
                            <div className="flex flex-row justify-between">
                                <h1 className="font-bold text-3xl">Create Licenses</h1>
                                <MuiCloseIconButton
                                    onClick={() => {
                                        resetCreateLicensesModal()
                                    }}
                                />
                            </div>
                            <hr className="mt-3 h-0.5"/>
                            <div>
                                <label>Select User to get Group Options</label>
                                <MuiAutocompleteNonGroupOptions
                                    options={allUsernamesList}
                                    value={usernameSelected}
                                    onChange={(event, value) => {
                                        setUsernameSelected(value)
                                        //call to get group options
                                        getGroupsForUser(value)
                                    }}
                                />
                            </div>
                            <div>
                                <label>Select Group for Licenses to be Placed in</label>
                                <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                                    zenGroupDropdownOptionsList={userGroupOptions}
                                    value={groupSelected}
                                    onChange={(event, value) => {
                                        setGroupSelected(value?.value)
                                    }}
                                />
                            </div>
                            <div className={`flex flex-row items-center`}>
                                <ThemeProvider theme={switchTheme}>
                                    <FormControlLabel control={
                                        <Switch
                                            checked={pocLicense}
                                            name="isPOCLicenseToggle"
                                            onChange={e => setPOCLicense(e.target.checked)}
                                        />
                                    } label={pocLicense ? "These are POC Licenses" : "These are Not POC Licenses"}/>
                                </ThemeProvider>
                            </div>
                            <div className={`flex flex-row items-center`}>
                                <ThemeProvider theme={switchTheme}>
                                    <FormControlLabel control={
                                        <Switch
                                            checked={agentLicenses}
                                            name="isAgentLicenseToggle"
                                            onChange={e => setAgentLicenses(e.target.checked)}
                                        />
                                    } label={agentLicenses ? "These are Agent Licenses" : "These are Training Licenses"}/>
                                </ThemeProvider>
                            </div>
                            {agentLicenses ? (
                                <div className="flex flex-1 flex-col gap-y-5">
                                    <div className={"flex flex-col gap-y-3"}>
                                        <label>Select the Start Date for These Agent Licenses. Licenses will not be served to
                                            agents until the start date has happened</label>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                label={"Start Date. Time of day will be start of day (12am UTC)"}
                                                disablePast={false} views={['year', 'month', 'day']}
                                                slotProps={{field: {clearable: true}}}
                                                value={startDateValue}
                                                onChange={(newValue) => {
                                                    setStartDateValue(newValue)
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div className={"flex flex-col gap-y-3"}>
                                        <label>Select the Expiration Date for These Agent Licenses</label>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                label={"Expiration Date. Time of day will be start of day (12am UTC)"}
                                                disablePast={true} views={['year', 'month', 'day']}
                                                slotProps={{field: {clearable: true}}}
                                                value={endDateValue}
                                                onChange={(newValue) => {
                                                    setEndDateValue(newValue)
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div className={"flex sm:flex-row flex-col gap-x-5 gap-y-5 flex-wrap sm:justify-around"}>
                                        <div className={"flex flex-col"}>
                                            <label>Num Desktop Licenses</label>
                                            <input
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter' || e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                type="number" name="desktopLicenseCount" min={0}
                                                {...register("desktopLicenseCount")}
                                                className="focus:outline-none h-10 p-2 mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                                            />
                                        </div>
                                        <div className={"flex flex-col"}>
                                            <label>Num Server Licenses</label>
                                            <input
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter' || e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                type="number" name="serverLicenseCount" min={0}
                                                {...register("serverLicenseCount")}
                                                className="focus:outline-none h-10 p-2 mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                            : (
                                    <div className="flex flex-1 flex-col gap-y-5">
                                        <div className={"flex flex-col gap-y-3"}>
                                            <label>Select the Purchase Date for These Training Licenses</label>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker
                                                    label={"Start Date. Time of day will be start of day (12am UTC)"}
                                                    disablePast={false} views={['year', 'month', 'day']}
                                                    slotProps={{field: {clearable: true}}}
                                                    value={startDateValue}
                                                    onChange={(newValue) => {
                                                        setStartDateValue(newValue)
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                        <div className={"flex flex-col gap-y-3"}>
                                            <label>Select the Expiration Date for These Training Licenses</label>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker
                                                    label={"Expiration Date. Time of day will be start of day (12am UTC)"}
                                                    disablePast={true} views={['year', 'month', 'day']}
                                                    slotProps={{field: {clearable: true}}}
                                                    value={endDateValue}
                                                    onChange={(newValue) => {
                                                        setEndDateValue(newValue)
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                        <div className={"flex flex-col self-center"}>
                                            <label>Num Training Licenses</label>
                                            <input
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter' || e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                type="number" name="trainingLicenseCount" min={0}
                                                {...register("trainingLicenseCount")}
                                                className="focus:outline-none h-10 p-2 mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                                            />
                                        </div>
                                    </div>

                                )
                            }

                            <div className="flex flex-col">
                                <ThemeProvider theme={buttonTheme}>
                                    <Button variant={"contained"} type={"submit"} color={"primary"}>
                                        Create Licenses
                                    </Button>
                                </ThemeProvider>
                            </div>
                        </div>
                    </form>
                </Modal>
                <Modal contentLabel="Create Default Delete Incident Role" isOpen={createDeleteIncidentRoleModalOpen}
                       onRequestClose={() => {
                           resetDefaultDeleteIncidentRoleModal()
                       }} shouldCloseOnOverlayClick={true}
                       className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white max-w-2xl inset-y-10 mx-auto rounded-2xl`}
                       overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
                >
                    <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmitRoleModal(submitCreateDeleteIncidentRole)}>
                        <div className="flex flex-1 flex-col gap-y-4 ml-1">
                            <div className="flex flex-row justify-between">
                                <h1 className="font-bold text-3xl">{createDefaultDeleteRole ? "Create" : "Delete"} Default Delete Extortion Response Program Role</h1>
                                <MuiCloseIconButton
                                    onClick={() => {
                                        resetDefaultDeleteIncidentRoleModal()
                                    }}
                                />
                            </div>
                            <hr className="mt-3 h-0.5"/>
                            <div className={`flex flex-row items-center`}>
                                <ThemeProvider theme={switchTheme}>
                                    <FormControlLabel control={
                                        <Switch
                                            checked={createDefaultDeleteRole}
                                            name="createDefaultDeleteRoleToggle"
                                            onChange={e => setCreateDefaultDeleteRole(e.target.checked)}
                                        />
                                    } label={createDefaultDeleteRole ? "Creating the Default Delete Program Role" : "Deleting the Default Delete Program Role"}/>
                                </ThemeProvider>
                            </div>
                            <hr className="h-0.5"/>
                            <div className={`flex flex-col gap-y-5 ${createDefaultDeleteRole ? "block" : "hidden"}`}>
                                <label>Creating this default role will give users in this group who are assigned this
                                    role the ability to manually
                                    delete extortion response programs and change the group auto-delete extortion
                                    response program setting.</label>
                                <label>Creating the role will not assign the role, you will have to add yourself to the
                                    group and assign the role if needed.</label>
                            </div>
                            <div className={`flex flex-col gap-y-5 ${!createDefaultDeleteRole ? "block" : "hidden"}`}>
                                <label>Only groups who have had this default role created for them are shown in the options below</label>
                            </div>
                            <hr className={`h-0.5`}/>
                            <div className={`${createDefaultDeleteRole ? "block" : "hidden"}`}>
                                <label>Select User to get Group Options</label>
                                <MuiAutocompleteNonGroupOptions
                                    options={allUsernamesList}
                                    value={usernameSelected}
                                    onChange={(event, value) => {
                                        setUsernameSelected(value)
                                        //call to get group options
                                        getGroupsForUser(value)
                                    }}
                                />
                            </div>
                            <div className={`${createDefaultDeleteRole ? "block" : "hidden"}`}>
                                <label>Select Group for Role to be Placed in</label>
                                <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                                    zenGroupDropdownOptionsList={userGroupOptions}
                                    value={groupSelected}
                                    onChange={(event, value) => {
                                        setGroupSelected(value?.value)
                                    }}
                                />
                            </div>
                            <div className={`${!createDefaultDeleteRole ? "block" : "hidden"}`}>
                                <label>Select Group(s) for Role to be Removed from</label>
                                <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                                    zenGroupDropdownOptionsList={deleteRoleGroupOptions}
                                    value={deleteRoleGroupsSelected} multiple={true}
                                    onChange={( event, valueList ) => {
                                        setDeleteRoleGroupsSelected(valueList)
                                    }}
                                />
                            </div>
                            <div className="flex flex-col">
                                <ThemeProvider theme={buttonTheme}>
                                    <Button variant={"contained"} type={"submit"} color={"primary"}>
                                        {createDefaultDeleteRole ? "Create Role" : "Delete Role"}
                                    </Button>
                                </ThemeProvider>
                            </div>
                        </div>
                    </form>
                </Modal>
                <InformationModal
                    opened={showGenerateReportTextModal}
                    onClose={() => {
                        setShowGenerateReportTextModal(false)
                        setCCGenerateCustomerReportModalOpen(false)
                        setCustomerReportUsernameSelected()
                    }}
                    text="Please allow a few minutes for the report to generate and be sent to your user's email address"
                    contentLabel={"Activity Report Modal"}
                />
                {/*Larger screens sidebar content*/}
                <div className={`lg:flex md:hidden sm:hidden xs:hidden`}>
                    <Box sx={{display: 'flex'}}>
                        <CssBaseline/>
                        {isMenuOpen ?
                            (
                                <Drawer
                                    sx={{
                                        width: 290,
                                        flexShrink: 0,
                                        '& .MuiDrawer-paper': {
                                            width: 290,
                                            boxSizing: 'border-box',
                                            scrollbarWidth: "none"
                                        },
                                    }}
                                    variant="persistent"
                                    anchor="left"
                                    open={true}
                                >
                                    <DrawerHeader>
                                    <IconButton
                                            onClick={() => {
                                                handleAccordionChange(setIsMenuOpen, isMenuOpen, "isMenuOpen")
                                            }}
                                        >
                                            <ChevronLeftIcon className="text-black"/>
                                        </IconButton>
                                    </DrawerHeader>
                                    <Divider/>
                                    <div className={"mx-3 my-2"}>
                                        {generateNavigationTabs(false)}
                                    </div>
                                </Drawer>
                            )
                            :
                            (
                                <Drawer
                                    sx={{
                                        width: 50,
                                        flexShrink: 0,
                                        '& .MuiDrawer-paper': {
                                            width: 50,
                                            boxSizing: 'border-box',
                                            scrollbarWidth: "none"
                                        },
                                    }}
                                    variant="persistent"
                                    anchor="left"
                                    open={true}
                                >
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={() => {
                                            handleAccordionChange(setIsMenuOpen, isMenuOpen, "isMenuOpen")
                                        }}
                                        edge="start"
                                        sx={{mx: "auto", mt: 2}}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </Drawer>
                            )
                        }
                    </Box>
                </div>
                {/*Smaller screens sidebar content*/}
                <div className={`lg:hidden md:block`}>
                    <Box sx={{ display: 'flex' }}>
                        <Drawer
                            sx={{
                                width: 50,
                                flexShrink: 0,
                                '& .MuiDrawer-paper': {
                                    width: 50,
                                    boxSizing: 'border-box',
                                    scrollbarWidth: "none"
                                },
                            }}
                            variant="persistent"
                            anchor="left"
                            open={true}
                        >
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={() => {
                                    setDrawerOpen(true)
                                }}
                                edge="start"
                                sx={{mx: "auto",  mt: 2}}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Drawer>
                    </Box>
                </div>
                {/*Drawer for smaller screens*/}
                <Drawer
                    sx={{
                        '& .MuiDrawer-paper': {
                            scrollbarWidth: "none"
                        },
                    }}
                    anchor={"left"}
                    open={drawerOpen}
                    onClose={() => {
                        setDrawerOpen(false)
                    }}
                >
                    <div className={"mx-3 my-2"}>
                        {generateNavigationTabs(true)}
                    </div>
                </Drawer>
            </div>
        </div>
    )

}

function getSidebarSessionState(variable){
    try{
        let value = JSON.parse(decryptAndGetSessionVariable(variable))
        if(value === null || value === undefined){
            return false
        }
        else{
            return value
        }
    } catch (e){}
    return false //return false by default
}

function setSidebarSessionState(variable, value){
    try{
        encryptAndStoreSessionVariable(variable, JSON.stringify(value))
    } catch (e) {}
}
