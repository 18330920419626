import React, {useState} from "react";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer, {NonGridPageFooter} from "../../components/footer";
import "../../css/system-styles.css";
import "../../css/colors.css";
import "../../css/typography.css";
import "../../css/buttons.css";
import "../../css/web-pages.css";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";

function PrivacyPolicy() {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div className="application">
            <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="description" content="Cyber Crucible Dashboard Privacy Policy"/>
                <meta name="keywords" content=""/>
                <meta name="author" content=""/>
                <title>Cyber Crucible Privacy Policy</title>
                <script src="https://js.stripe.com/v3/"/>
                <link
                    href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap"
                    rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>

            {/*Title Banner*/}
            <div className="banner-contact">
                <div className="py-20">
                    <div className="container text-center mx-auto px-6">
                        <h2 className="text-4xl font-bold mb-2 text-white">
                            Privacy Policy
                        </h2>
                        <h3 className="text-2xl mb-8 text-gray-200">
                            Version: 01.2025
                        </h3>
                    </div>
                </div>
            </div>

            <section className="textContentSectionWrapper mx-auto">
                <div className="textContentHeaderAndParagraphFlex">
                    <h2 className="textContentH2">Introduction</h2>
                    <p className="textContentParagraph">At CyberCrucible, whose website is accessible from <a
                        href="https://www.cybercrucible.com" className="linkColor" target="_blank">https://www.cybercrucible.com</a>, a main priority is
                        the privacy of their website visitors. This Privacy Policy contains types of information
                        collected and recorded by CyberCrucible on this website and how it is used.<br/>‍<br/>If you
                        have additional questions or require more information about this Privacy Policy, do not hesitate
                        to contact us at support@cybercrucible.com.<br/><br/>This Privacy Policy
                        applies only to the company&#x27;s online activities and is valid for visitors to this website
                        with regards to the information they share to and/or collect from on said website. This policy
                        is not applicable to any information collected offline or via channels other than this website.
                    </p>
                </div>
                <div className="textContentHeaderAndParagraphFlex">
                    <h2 className="textContentH2">Consent</h2>
                    <p className="textContentParagraph">By using this website, you hereby consent to
                        CyberCrucible&#x27;s Privacy Policy and agree to its terms.</p>
                </div>
                <div className="textContentHeaderAndParagraphFlex">
                    <h2 className="textContentH2">Information we collect</h2>
                    <p className="textContentParagraph">The personal information you are asked to provide, and the
                        reasons why you are asked to provide it, will be made clear to you at the point we ask you to
                        provide your personal information.<br/><br/>If you contact us directly, we may receive
                        additional information about you such as your name, email address, phone number, the contents of
                        the message, and/or attachments you may send us, as well as any other information you may choose
                        to provide.<br/><br/>When you register for an account, we may ask for your contact information
                        such as name, company name, address, email address, and telephone number.</p>
                </div>
                <div className="textContentHeaderAndParagraphFlex">
                    <h2 className="textContentH2">How we use your information</h2>
                    <p className="textContentParagraph">We use the information we collect in various ways, including
                        to:‍</p>
                    <ul className={"list-disc list-inside pl-7"}>
                        <li>Provide, operate, and maintain our website</li>
                        <li>Improve, personalize, and expand our website</li>
                        <li>Understand and analyze how you use our website<br/></li>
                        <li>Develop new products, services, features, and functionality<br/></li>
                        <li>Communicate with you, either directly or through one of our partners, including for customer
                            service, to provide you with updates and other information relating to the website, and for
                            marketing and promotional purposes<br/></li>
                        <li>Send you emails<br/></li>
                        <li>Find and prevent fraud<br/></li>
                    </ul>
                </div>
                <div className="textContentHeaderAndParagraphFlex">
                    <h2 className="textContentH2">Log files</h2>
                    <p className="textContentParagraph">This website follows a standard procedure of using log files.
                        These files log visitors when they visit websites. All hosting companies do this, and this data
                        is a part of hosting services&#x27; analytics. The information collected by log files include
                        internet protocol (IP) address, browser type, Internet Service Provider (ISP), date and time,
                        referring/exit pages, and, potentially, the number of clicks. These are not linked to any
                        information that is personally identifiable. The purpose of this information is for analyzing
                        trends, site administration, tracking users&#x27; movement on the website, and gathering
                        demographic information.</p>
                </div>
                <div className="textContentHeaderAndParagraphFlex">
                    <h2 className="textContentH2">Cookies and web beacons</h2>
                    <p className="textContentParagraph">Like any other website, this website uses &quot;cookies&quot;.
                        These cookies are used to store information such as visitor preferences and the web pages the
                        visitor accessed or visited. This information is used to optimize users&#x27; experience by
                        customizing web page content based on visitors&#x27; browser type and/or other information.</p>
                </div>
                <div className="textContentHeaderAndParagraphFlex">
                    <h2 className="textContentH2">Advertising partners&#x27; privacy policies</h2>
                    <p className="textContentParagraph">You may consult this list to find the Privacy Policy for each of
                        the advertising partners for the CyberCrucible website.<br/><br/>Third-party ad servers or ad
                        networks uses technologies like cookies, JavaScript, or web beacons in their advertisements and
                        links that appear on this CyberCrucible website, which are sent directly to a
                        users&#x27; browser. When this occurs, the advertising partner automatically receives your IP
                        address. These technologies are used to measure the effectiveness of their advertising campaigns
                        and/or to personalize the advertising content you see on websites that you visit.<br/><br/>Note
                        that CyberCrucible, Inc. has no access to or control over the cookies used by third-party
                        advertisers.</p>
                </div>
                <div className="textContentHeaderAndParagraphFlex">
                    <h2 className="textContentH2">Third party privacy policies</h2>
                    <p className="textContentParagraph">This Privacy Policy does not apply to other advertisers or
                        websites. Thus, we advise you to consult the Privacy Policies of these third-party ad servers
                        for more detailed information. They may include their practices and instructions about how to
                        opt-out of certain options.<br/><br/>You may choose to disable cookies through your individual
                        browser options. For more detailed information about cookie management using specific web
                        browsers, visit those browsers&#x27; respective websites.</p>
                </div>
                <div className="textContentHeaderAndParagraphFlex">
                    <h2 className="textContentH2">CCPA privacy rights (Do Not Sell My Personal Information)</h2>
                    <p className="textContentParagraph">Under the CCPA, among other rights, California consumers have
                        the right to:</p>
                    <ul className={"list-disc list-inside pl-7"}>
                        <li>Request that a business that collects a consumer&#x27;s personal data disclose the
                            categories and specific pieces of personal data that a business has collected about
                            consumers
                        </li>
                        <li>Request that a business delete any personal data about the consumer that a business has
                            collected
                        </li>
                        <li>Request that a business that sells a consumer&#x27;s personal data, not sell the
                            consumer&#x27;s personal data
                        </li>
                    </ul>
                    <p className="textContentParagraph">If you make a request, we have one month to respond to you. If
                        you would like to exercise any of these rights, please contact us at support@cybercrucible.com.
                    </p>
                </div>
                <div className="textContentHeaderAndParagraphFlex">
                    <h2 className="textContentH2">GDPR Data Protection Rights</h2>
                    <p className="textContentParagraph">We would like to make sure you are fully aware of all of your
                        data protection rights. Every user is entitled to the following:</p>
                    <ul className={"list-disc list-inside pl-7"}>
                        <li>The right to access: You have the right to request copies of your personal data. We may
                            charge you a small fee for this service.
                        </li>
                        <li>The right to rectification: You have the right to request that we correct any information
                            you believe is inaccurate. You also have the right to request that we complete the
                            information you believe is incomplete.
                        </li>
                        <li>The right to erasure: You have the right to request that we erase your personal data, under
                            certain conditions
                        </li>
                        <li>The right to restrict processing: You have the right to request that we restrict the
                            processing of your personal data, under certain conditions
                        </li>
                        <li>The right to object to processing: You have the right to object to our processing of your
                            personal data, under certain conditions
                        </li>
                        <li>The right to data portability: You have the right to request that we transfer the data that
                            we have collected to another organization, or directly to you, under certain conditions
                        </li>
                    </ul>
                    <p className="textContentParagraph">If you make a request, we have one month to respond to you. If
                        you would like to exercise any of these rights, please contact us at support@cybercrucible.com.</p>
                </div>
                <div className="textContentHeaderAndParagraphFlex">
                    <h2 className="textContentH2">Children&#x27;s information</h2>
                    <p className="textContentParagraph">We also prioritize protection for children while using the
                        Internet. We encourage parents and guardians to observe, participate in, and/or monitor and
                        guide their child&#x27;s online activity.<br/><br/>CyberCrucible&#x27;s website does not
                        knowingly collect any personal identifiable information from children under the age of 13. If
                        you think your child provided this kind of information on our website, we strongly encourage you
                        to contact us at support@cybercrucible.com immediately, and we will do
                        our best to promptly remove such information from our records.</p>
                </div>
            </section>

            <NonGridPageFooter/>

        </div>
    )
}

export default PrivacyPolicy
