import React, {Component, useEffect, useMemo, useState} from "react";
import {AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import SidebarMenu from "../../components/sideBarComponent";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import CustomNameCellEditor from "../../utils/customCellEditor";
import DTPicker, {dateFilterParametersInHeaderClientSideGrid} from "../../utils/DTPicker";
import {standardHandlePopulateGrid} from "../../utils/sseAndChangeStreamHelper";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {dateValueFormatter} from "../../utils/gridDateFormatter";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {buttonTheme} from "../../utils/muiStyling";
import {ThemeProvider} from "@mui/material";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {downloadMinidumpCCOnly} from "../api/ccOnlyMinidumpsApi";
import {Auth} from "aws-amplify";
import {fetchEventSource} from "@microsoft/fetch-event-source";
import {getDemoModeSetting, getTrainingModeSetting} from "../../utils/trainingAndDemoModeHelper";
import LoadingButton from '@mui/lab/LoadingButton';
import {MuiIconButtonWithTooltip} from "../../components/muiComponents";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {base64ToArrayBuffer} from "./incidents";
import {getDefaultAgGridSidebarProps} from "../../utils/gridFilterStateAndColumnStateHelper";


export default function CcOnlyMinidumps() {
    const [isLoading, setIsLoading] = useState(false);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [startDateValue, setStartDateValue] = React.useState(null);
    const [endDateValue, setEndDateValue] = React.useState(null);
    const [gridRowData, setGridRowData] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(200); //200 to start for the initial sse data pull, will change when sse data pull is done for change streams
    const [columnDefs, setColumnDefs] = useState([
        {
            field: "currentAgentVersion", initialWidth: 260, headerName: "Current Agent Version",
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: true,
                suppressSorting: false
            },
            sortable: true
        },
        {
            field: "dumpAgentVersion", initialWidth: 250, headerName: "Dump Agent Version",
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: true,
                suppressSorting: false
            },
            sortable: true
        },
        { field: "dateCollected", headerName: "Date Collected",  initialWidth: 330,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        { field: "dateCreated", headerName: "Date Created",  initialWidth: 330,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        { field: "dateFileCreated", headerName: "Date File Created",  initialWidth: 330,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        {
            field: "threadName", initialWidth: 225, headerName: "Thread Name",
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: true,
                suppressSorting: false
            },
            sortable: true
        },
        {
            field: "download", initialWidth: 150, headerName: "Download", sortable: false,
            cellRenderer: function (params) {
                return (
                    <div className={`flex flex-row items-center`}>
                        <MuiIconButtonWithTooltip
                            icon={ <FontAwesomeIcon
                                className="object-contain"
                                icon="fa-duotone fa-download"
                                size="xs"/>}
                            tooltipTitle={"Click to download this Minidump"}
                            tooltipPlacement={"bottom-start"}
                            onClick={() => {
                                if(params.node.data.id){
                                    setIsLoading(true)
                                    downloadMinidumpCCOnly(params.node.data.id).then(response => {
                                        setIsLoading(false)
                                        try{
                                            let minidumpId = params.node.data.id
                                            let bytes = base64ToArrayBuffer(response.data);
                                            let blob = new Blob([bytes], {type: "application/octet-stream"});
                                            let link=document.createElement('a');
                                            link.href=window.URL.createObjectURL(blob);
                                            link.download=`${minidumpId}.dmp`;
                                            link.click();
                                        }
                                        catch(error){
                                            NotificationManager.error("Unexpected error making this request");
                                        }
                                    }).catch(function (error) {
                                        setIsLoading(false)
                                        if(error.message){
                                            NotificationManager.error(error.message);
                                        }
                                        else{
                                            NotificationManager.error("Unexpected error making this request");
                                        }
                                    })
                                }
                                else{
                                    NotificationManager.error("Unexpected error making this request");
                                }
                            }}
                        />
                    </div>
                )
            },
        },
        {
            field: "agentId", initialWidth: 225, headerName: "Agent Id",
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: true,
                suppressSorting: false
            },
            sortable: true
        }
    ])
    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            filterParams: null,
            floatingFilter: true,
            headerClass: "border-0 border-b-0",
            cellClass: "outline:none",
            enableCellChangeFlash: true,
            autoHeight: false,
            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
        }
    )
    const sideBar = useMemo(() => {
        //Inside useMemo to help prevent the sidebar from re-rendering
        return getDefaultAgGridSidebarProps(260)
    }, []);
    const rowSelection = useMemo(() => {
        return {
            mode: 'singleRow',
            enableClickSelection: true,
            checkboxes: false,
            headerCheckbox: false
        };
    }, []);
    const [ abortController, setAbortController ] = useState(new AbortController());


    useEffect(() => {
        //This code is here to stop the sse query that retrieves minidumps if leaving the page so that it does not keep consuming resources
        if(submitButtonDisabled){
            return () => {
                stopMinidumpQuery()
            }
        }
    }, [submitButtonDisabled]);

    function stopMinidumpQuery() {
        abortController.abort();
        setAbortController(new AbortController());
    }

    return (
        <div className="flex flex-col h-full">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>CC Only Minidumps</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <div className="flex flex-1 flex-row h-full overflow-y-auto">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col flex-nowrap gap-y-3 mt-8 ml-5 mr-10 h-full">
                    {privatePageHeaderHelper("Minidumps")}
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row justify-between flex-wrap gap-x-0 gap-y-3">
                        <div className="flex flex-row justify-start gap-x-3 flex-wrap gap-y-2 items-center">
                            <div className={"flex flex-row gap-x-3 flex-wrap gap-y-2 items-center"}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label={"Start Date for Query"} views={['year', 'month', 'day']}
                                        slotProps={{field: {clearable: true}}}
                                        sx={{ maxWidth: 260 }}
                                        value={startDateValue}
                                        onChange={(newValue) => {
                                            setStartDateValue(newValue)
                                        }}
                                    />
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label={"End Date for Query"} views={['year', 'month', 'day']}
                                        slotProps={{field: {clearable: true}}}
                                        sx={{ maxWidth: 260 }}
                                        value={endDateValue}
                                        onChange={(newValue) => {
                                            setEndDateValue(newValue)
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>
                            <ThemeProvider theme={buttonTheme}>
                                <LoadingButton variant={"contained"} color={"primary"} type={"submit"} loading={submitButtonDisabled}
                                        onClick={() => {
                                            if(startDateValue && endDateValue) {
                                                let startDateDayOfMonth = startDateValue.date()
                                                let startDateMonthOfYear = startDateValue.month() + 1 //month() is 0 based, so we need + 1
                                                let startDateYear = startDateValue.year()
                                                if(!startDateValue.isValid() || isNaN(startDateDayOfMonth) || isNaN(startDateMonthOfYear) || isNaN(startDateYear)){
                                                    NotificationManager.info("Invalid Start Date Entered")
                                                    return
                                                }
                                                let endDateDayOfMonth = endDateValue.date()
                                                let endDateMonthOfYear = endDateValue.month() + 1 //month() is 0 based, so we need + 1
                                                let endDateYear = endDateValue.year()
                                                if(!endDateValue.isValid() || isNaN(endDateDayOfMonth) || isNaN(endDateMonthOfYear) || isNaN(endDateYear)){
                                                    NotificationManager.info("Invalid End Date Entered")
                                                    return
                                                }
                                                //startDateValue.toString() === endDateValue.toString()
                                                if(endDateValue.isBefore(startDateValue)){
                                                    NotificationManager.info("End Date Cannot be Before Start Date")
                                                    return
                                                }
                                                setGridRowData([])
                                                setSubmitButtonDisabled(true)
                                                Auth.currentSession().then(response => {
                                                    let accessToken = response.getAccessToken().getJwtToken()
                                                    fetchEventSource(process.env.REACT_APP_AXIOS_BASE_URL + "/getMinidumpsCCOnly", {
                                                        method: "POST",
                                                        headers: {
                                                            "Accept": "text/event-stream",
                                                            "Content-Type": "application/json",
                                                            "Authorization": "Bearer " + accessToken
                                                        },
                                                        signal: abortController.signal,
                                                        //Get trainingMode and demoMode settings dynamically from session
                                                        body: JSON.stringify({
                                                            trainingMode: getTrainingModeSetting(),
                                                            demoMode: getDemoModeSetting(),
                                                            startYear: startDateYear,
                                                            startMonth: startDateMonthOfYear,
                                                            startDayOfMonth: startDateDayOfMonth,
                                                            endYear: endDateYear,
                                                            endMonth: endDateMonthOfYear,
                                                            endDayOfMonth: endDateDayOfMonth
                                                        }),
                                                        openWhenHidden: true, //initial sse data pull should be open when hidden so if they hide then view the same tab the initial sse data pull does not start again. This causes
                                                        // problems with the populateGridFunction since it may try and add the same object/row with the same rowNodeId to the grid, which causes problems for ag grid.
                                                        onmessage(event) {
                                                            let rowData = JSON.parse(event.data)
                                                            if (gridApi) {
                                                                standardHandlePopulateGrid(rowData, gridApi)
                                                            }
                                                        },
                                                        onclose() {
                                                            setSubmitButtonDisabled(false)
                                                        },
                                                        onerror(err) {
                                                            console.error("There was an error from server", err);
                                                            setSubmitButtonDisabled(false)
                                                            throw err //stops operations
                                                        },
                                                    }).then()
                                                })
                                            }
                                        }}
                                >
                                    Submit
                                </LoadingButton>
                            </ThemeProvider>
                        </div>
                        <ClearRefresh gridApi = {gridApi} showRefreshIcon={false} />
                    </div>
                    <div className="h-full flex flex-col gap-y-5" id="gridRoot">
                        <Grid
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            sideBar={sideBar}
                            rowSelection={rowSelection}
                            setGridApi={setGridApi}
                            sseDataPullActive={sseDataPullActive}
                            setSSEDataPullActive={setSSEDataPullActive}
                            asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                            setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                            gridRowData={gridRowData}
                        />
                        <Footer />
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );
}

class Grid extends Component {
    updateTransactionsToApply = []
    abortController = new AbortController()

    constructor(props, onClickRow, filterVals) {
        super(props);
    }

    onCellEditingStopped = (event) => {
        let gridApi = event.api
        //Nothing to edit on this grid
    }

    componentWillUnmount(){
        this.abortController.abort()
    }

    populateGrid = async (rowData) => {
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    getRowId = (params) => {
        return params.data.id
    }

    getContextMenuItems = (params) => {
        return [
            "resetColumns",
            "autoSizeAll"
        ];
    };

    onGridReady = async (gridReadyParams) => {
        this.gridApi = gridReadyParams.api;
        this.props.setGridApi(gridReadyParams.api);

        // Disable text selection on the page while holding shift or control (to allow grid selections to be done easily without selecting all text)
        ["keyup","keydown"].forEach((event) => {
            window.addEventListener(event, (e) => {
                document.onselectstart = function() {
                    return !(e.shiftKey || e.ctrlKey);
                }
            });
        });
    };
    render() {
        return (
            <div className={"w-full h-full"} style={{minHeight: "400px"}}>
                <div id="myGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                    <AgGridReact
                        columnMenu={"legacy"}
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule]}
                        defaultColDef={this.props.defaultColDef}
                        columnDefs={this.props.columnDefs}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        multiSortKey={"ctrl"}
                        rowData={this.props.gridRowData}
                        onGridReady={this.onGridReady}
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        maintainColumnOrder={true} //fixes issue where if you re-order/move column then click anywhere on the grid it reverts this change
                        suppressModelUpdateAfterUpdateTransaction={true}
                        getRowId={this.getRowId}
                        onCellEditingStopped={this.onCellEditingStopped}
                        rowSelection={this.props.rowSelection}
                        onSelectionChanged={() => {}}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        getContextMenuItems={this.getContextMenuItems}
                        sideBar={this.props.sideBar}
                    />
                </div>
            </div>
        );
    }
}
