import React, {Component, useMemo, useState} from "react";
import {AgGridReact} from "@ag-grid-community/react";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {ExcelExportModule} from "@ag-grid-enterprise/excel-export";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import SidebarMenu from "../../components/sideBarComponent";
import {
    defaultZenGroupColumnInitWithOptionsWithValueGetter
} from "../../utils/zenGroupDisplayNameGridHelper";
import {dateValueFormatter} from "../../utils/gridDateFormatter";

import {
    getColumnModeInSession, getDefaultAgGridSidebarProps,
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnState, updateColumnModeInSessionHelper,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import CustomNameCellEditor, {editNameIconOnlyCellRenderer} from "../../utils/customCellEditor";
import DTPicker, {dateFilterParametersInHeaderClientSideGrid} from "../../utils/DTPicker";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import {
    loadDataWithSSEAndStartChangeStreamListener,
    standardHandleInsertEvent,
    standardHandlePopulateGrid,
    standardHandleUpdateAndReplaceEvent
} from "../../utils/sseAndChangeStreamHelper";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {standardExcelExportHelper, standardExcelExportObjectInContextMenu} from "../../utils/excelExportHelper";
import {
    assignUserTrainingLicenseReactive,
    changeTrainingLicenseNameReactive,
    releaseTrainingLicenseReactive, updateTrainingLicensesGridColumnModeReactive,
    updateTrainingLicensesGridColumnStateReactive,
    updateTrainingLicensesGridFilterModelReactive,
    updateTrainingLicensesGridUseColumnStateReactive,
    updateTrainingLicensesGridUseFilterStateReactive
} from "../api/trainingLicensesApi";
import {handleGroupColumnChangeNameOnly} from "../../utils/gridCellEditing";
import {checkPermission} from "../../utils/permissionCheckHelper";
import {StripeTrainingLicensesSetupModal} from "../../components/StripeTrainingLicensesSetupModal";
import {ConfirmationModalWithPermissionsShown} from "../../components/confirmationModal";
import Modal from "react-modal";
import {getUsersInGroupListReactive} from "../api/groupsApi";
import {useForm} from "react-hook-form";
import {buttonTheme, cellButtonTheme} from "../../utils/muiStyling";
import {Button, ThemeProvider} from "@mui/material";

import {
    MuiAutocompleteNonGroupOptions,
    MuiCloseIconButton,
    MuiIconButtonWithTooltipAndBox
} from "../../components/muiComponents";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import {
    ClickToShowColumnOptionsWithToggleButtonGroup,
    customColumnModeText
} from "../../components/clickToShowButtons";
import DeleteIcon from "@mui/icons-material/Delete";
import {getItemFromStorageWithoutDecrypting} from "../../utils/storageHelper";
import {defaultClientSideTextFilterParams} from "../../utils/filterHelper";

let gridColumnStateSessionVariableName = "trainingLicensesGridColumnState"
let gridFilterStateSessionVariableName = "trainingLicensesGridFilterState"

export default function TrainingLicenses() {
    const [isLoading, setIsLoading] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const { handleSubmit } = useForm();
    const [showStripePurchaseModal, setShowStripePurchaseModal] = useState(false);
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession(gridFilterStateSessionVariableName));
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    const [showReleaseLicensesConfirmation, setShowReleaseLicensesConfirmation] = useState(false);
    const [zenGroupNamesWithPermission, setZenGroupNamesWithPermission] = useState(new Set());
    const [zenGroupIdsWithoutPermission, setZenGroupIdsWithoutPermission] = useState(new Set());
    const [zenGroupNamesWithoutPermission, setZenGroupNamesWithoutPermission] = useState(new Set());
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [trainingLicenseToAssignToUser, setTrainingLicenseToAssignToUser] = useState();
    const [usersInGroupForAssignLicenseModal, setUsersInGroupForAssignLicenseModal] = useState([]);
    const [selectedUserToAssignLicense, setSelectedUserToAssignLicense] = useState([]);
    const [showAssignUserLicenseModal, setShowAssignUserLicenseModal] = useState(false);
    const [enableButtons, setEnableButtons] = useState(false);
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(200); //200 to start for the initial sse data pull, will change when sse data pull is done for change streams
    const [columnMode, setColumnMode] = useState(getColumnModeInSession(gridColumnStateSessionVariableName));
    // eslint-disable-next-line no-unused-vars
    const [columnDefs, setColumnDefs] = useState([
        defaultZenGroupColumnInitWithOptionsWithValueGetter(true,true, true),
        { field: "zenGroupId", hide: true, suppressColumnsToolPanel: true, lockVisible: true},
        { field: "licenseDisplayName", headerName: "License Name", initialWidth: 380,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true,
            editable: true,
            cellEditor: "customNameCellEditor",
            cellRenderer: function (params) {
                return editNameIconOnlyCellRenderer(params, "Click to Edit this License's Name", "licenseDisplayName")
            }
        },
        { field: "trainingUserUsername", headerName: "User", initialWidth: 380,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true,
            editable: false,
            cellRenderer: function (params) {
                let assignLicenseIconDiv = ""
                if(params.node.data.trainingUserUsername === null || params.node.data.trainingUserUsername === undefined){
                    assignLicenseIconDiv =
                        <ThemeProvider theme = {cellButtonTheme}>
                            <Button variant={"contained"}
                                    color={"primary"}
                                    onClick={() => {
                                        setTrainingLicenseToAssignToUser(params.node.data)
                                        setShowAssignUserLicenseModal(true)
                                        setUsersInGroupForAssignLicenseModal([])
                                        setSelectedUserToAssignLicense()
                                        //need to get users in group to populate usersInGroupForAssignLicenseModal with
                                        getUsersInGroupListReactive(params.node.data.zenGroupId).then(users => {
                                            let usernamesOnlyList = []
                                            if(users && users.length > 0){
                                                users.forEach(user => {
                                                    if(user.username){
                                                        usernamesOnlyList.push(user.username)
                                                    }
                                                })
                                            }
                                            //Allow user to assign themsevles a license
                                            let userName = getItemFromStorageWithoutDecrypting("username")
                                            if(userName && !usernamesOnlyList.includes(userName)){
                                                usernamesOnlyList.push(userName)
                                            }
                                            setUsersInGroupForAssignLicenseModal(usernamesOnlyList)
                                        }).catch(function(error){

                                        })
                                    }}
                            >Assign to User
                            </Button>
                        </ThemeProvider>
                }
                return(
                    <div className={"flex flex-nowrap items-center justify-start gap-x-2"}>
                        {assignLicenseIconDiv}
                        {params.node.data.trainingUserUsername}
                    </div>
                )
            }
        },
        { field: "purchasedDate", headerName: "Purchased", initialWidth: 280,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        { field: "expirationDate", headerName: "Renews", initialWidth: 280,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
    ])
    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            filterParams: null,
            floatingFilter: true,
            headerClass: "border-0 border-b-0",
            cellClass: "outline:none",
            enableCellChangeFlash: true,
            autoHeight: false,
            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
        }
    )
    const onSubmitAssignLicenseModal = () => {
        if(trainingLicenseToAssignToUser && trainingLicenseToAssignToUser.id && selectedUserToAssignLicense){
            setIsLoading(true)
            assignUserTrainingLicenseReactive(trainingLicenseToAssignToUser.id, selectedUserToAssignLicense).then(response => {
                NotificationManager.success("Successfully assigned license to this user")
                setShowAssignUserLicenseModal(false)
                setUsersInGroupForAssignLicenseModal([])
                setSelectedUserToAssignLicense()
                setTrainingLicenseToAssignToUser()
                setIsLoading(false)
            }).catch(function(error){
                if(error.message){
                    NotificationManager.error(error.message)
                }
                else{
                    NotificationManager.error("Error assigning license to user")
                }
                setIsLoading(false)
            })
        }
    }

    const sideBar = useMemo(() => {
        //Inside useMemo to help prevent the sidebar from re-rendering
        return getDefaultAgGridSidebarProps()
    }, []);
    const rowSelection = useMemo(() => {
        return {
            mode: 'multiRow',
            enableClickSelection: true,
            checkboxes: false,
            headerCheckbox: false
        };
    }, []);
    return (
        <div className="flex flex-col h-full">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Training Licenses</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <div className="flex flex-1 flex-row h-full overflow-y-auto">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col flex-nowrap gap-y-2 mt-8 ml-5 mr-10 h-full">
                    {privatePageHeaderHelper("Training Licenses")}
                    <hr className="bg-black h-0.5" />
                    <StripeTrainingLicensesSetupModal
                        onClose={() => {
                            setShowStripePurchaseModal(false)
                        }}
                        setIsLoading={setIsLoading}
                        opened={showStripePurchaseModal}
                        setIsOpened={setShowStripePurchaseModal}
                    />
                    <ConfirmationModalWithPermissionsShown
                        text="WARNING: You are about to release the selected training licenses. The grid below shows your permission level to do this action for each of the selected training licenses' group:"
                        onConfirm={() => {
                            releaseTrainingLicenses()
                        }}
                        onClose={() => {
                            setShowReleaseLicensesConfirmation(false)
                            setZenGroupNamesWithPermission(new Set())
                            setZenGroupNamesWithoutPermission(new Set())
                            setZenGroupIdsWithoutPermission(new Set())
                        }}
                        opened={showReleaseLicensesConfirmation}
                        groupNamesWithPermission={zenGroupNamesWithPermission}
                        groupNamesWithoutPermission={zenGroupNamesWithoutPermission}
                    />
                    <Modal contentLabel="Assign Training License to User" isOpen={showAssignUserLicenseModal}
                           onRequestClose={() => {
                               setShowAssignUserLicenseModal(false)
                               setUsersInGroupForAssignLicenseModal([])
                               setSelectedUserToAssignLicense()
                           }} shouldCloseOnOverlayClick={true}
                           className={`border-2 flex relative z-50 bg-white max-w-lg inset-y-10 mx-auto rounded-2xl focus:outline-none focus:shadow-sm border-2`}
                           overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
                    >
                        <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(onSubmitAssignLicenseModal)}>
                            <div className="flex flex-1 flex-col gap-y-4">
                                <div className="flex flex-row justify-between">
                                    <h1 className="font-bold text-3xl">Assign Training License to User</h1>
                                    <MuiCloseIconButton
                                        onClick={() => {
                                            setShowAssignUserLicenseModal(false)
                                            setUsersInGroupForAssignLicenseModal([])
                                            setSelectedUserToAssignLicense()
                                        }}
                                    />
                                </div>
                                <hr className="h-0.5" />
                                <div className="">
                                    <label>Select User:</label>
                                    <MuiAutocompleteNonGroupOptions
                                        options={usersInGroupForAssignLicenseModal}
                                        value={selectedUserToAssignLicense}
                                        onChange={(event, value) => {
                                            setSelectedUserToAssignLicense(value)
                                        }}
                                    />
                                </div>
                                <ThemeProvider theme = {buttonTheme}>
                                    <Button variant={"contained"} color={"primary"} type={"submit"}>
                                        Submit
                                    </Button>
                                </ThemeProvider>
                            </div>
                        </form>
                    </Modal>
                    <div className="flex flex-row justify-between gap-x-1 gap-y-3">
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <GridColumnFilterStateSaving
                                useFilterStateSettingToggled = {useFilterStateSettingToggled}
                                setUseFilterStateSettingToggled = {setUseFilterStateSettingToggled}
                                toggleUpdateUseFilterState = {toggleUpdateUseFilterState}
                                useColumnStateSettingToggled = {useColumnStateSettingToggled}
                                setUseColumnStateSettingToggled = {setUseColumnStateSettingToggled}
                                toggleUpdateUseColumnState = {toggleUpdateUseColumnState}/>
                            <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2">
                                <MuiIconButtonWithTooltipAndBox
                                    icon={<AddShoppingCartIcon className={"cursor-pointer"}/>} tooltipTitle={"Purchase Training Licenses"}
                                    tooltipPlacement={"top"}
                                    onClick={() => {
                                        setShowStripePurchaseModal(true)
                                    }}/>
                                <MuiIconButtonWithTooltipAndBox
                                    icon={<DeleteIcon className={"cursor-pointer"}/>} tooltipTitle={"Release Training Licenses"}
                                    tooltipPlacement={"top"} disabled={!enableButtons}
                                    onClick={() => {
                                        let zenGroupIdSet = new Set();
                                        let permissionCheckResult = true
                                        for (const rowNode of gridApi.getSelectedNodes()) {
                                            if(rowNode.data.zenGroupId && !zenGroupIdSet.has(rowNode.data.zenGroupId)){
                                                zenGroupIdSet.add(rowNode.data.zenGroupId)
                                                if (!checkPermission(rowNode.data.zenGroupId, "groupManager", "removeUserFromGroup")) {
                                                    permissionCheckResult = false
                                                    zenGroupIdsWithoutPermission.add(rowNode.data.zenGroupId)
                                                    zenGroupNamesWithoutPermission.add(rowNode.data.zenGroupDisplayName)
                                                    setZenGroupNamesWithoutPermission(zenGroupNamesWithoutPermission)
                                                    setZenGroupIdsWithoutPermission(zenGroupIdsWithoutPermission)
                                                } else {
                                                    zenGroupNamesWithPermission.add(rowNode.data.zenGroupDisplayName)
                                                    setZenGroupNamesWithPermission(zenGroupNamesWithPermission)
                                                }
                                            }
                                        }

                                        if (!permissionCheckResult) {   //  permission check failed, show modal
                                            setShowReleaseLicensesConfirmation(true)
                                        }
                                        else{
                                            releaseTrainingLicenses()
                                        }
                                    }}/>
                            </div>
                        </div>
                        <div className={"flex flex-row flex-wrap gap-y-3 gap-x-8 self-end justify-end"}>
                            <ClickToShowColumnOptionsWithToggleButtonGroup
                                columnMode={columnMode} setColumnMode={setColumnMode} gridColumnStateSessionVariableName={gridColumnStateSessionVariableName} gridApi={gridApi}
                                disableMediumOption={true} disableMinOption={true} updateGridColumnModeFunction={updateTrainingLicensesGridColumnModeReactive} />
                            <ClearRefresh gridApi = {gridApi} showRefreshIcon={false} sseDataPullActive={sseDataPullActive}
                                          showExcelExportIcon={true}
                                          excelExportFunction = {excelExport} />
                        </div>
                    </div>
                    <div className="h-full flex flex-col gap-y-5" id="gridRoot">
                        {getGrid()}
                        <Footer />
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );

    function releaseTrainingLicenses(){
        if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length > 0){
            let validLicenseIdList = []
            gridApi.getSelectedNodes().forEach(currentLicense => {
                if(currentLicense.data.id && currentLicense.data.trainingUserId && !zenGroupIdsWithoutPermission.has(currentLicense.data.zenGroupId)) { //make sure license has an id (probably unnecessary check)
                    validLicenseIdList.push(currentLicense.data.id)
                    releaseTrainingLicenseReactive(currentLicense.data.id)
                        .then(() => {
                            currentLicense.setDataValue("trainingUserId",null)
                            currentLicense.setDataValue("trainingUserUsername",null)
                        }).catch(function(error){})
                }
            })

            if(validLicenseIdList.length < 1){
                NotificationManager.info(`None of the selected training licenses in groups you have permission in were eligible to be released`);
            }
            else{
                NotificationManager.success("Queued the release of eligible training licenses for groups you have permission to do so in");
                setShowReleaseLicensesConfirmation(false)
                setZenGroupNamesWithPermission(new Set())
                setZenGroupNamesWithoutPermission(new Set())
                setZenGroupIdsWithoutPermission(new Set())
            }
        }

    }

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, gridFilterStateSessionVariableName, updateTrainingLicensesGridUseFilterStateReactive);
    }
    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, gridColumnStateSessionVariableName, updateTrainingLicensesGridUseColumnStateReactive);
    }

    function getGrid(){
        return (
            <Grid
                columnDefs={columnDefs}
                sideBar={sideBar}
                rowSelection={rowSelection}
                defaultColDef={defaultColDef}
                setGridApi={setGridApi}
                sseDataPullActive={sseDataPullActive}
                setSSEDataPullActive={setSSEDataPullActive}
                setEnableButtons={setEnableButtons}
                asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                excelExport={excelExport}
                columnMode={columnMode}
                setColumnMode={setColumnMode}
            />
        );
    }

    function excelExport(){
        standardExcelExportHelper(gridApi, sseDataPullActive, "trainingLicensesGridExport")
    }
}

class Grid extends Component {
    rowData = []
    updateTransactionsToApply = []
    abortController = new AbortController()

    constructor(props, filterVals) {
        super(props);
    }
    onFirstDataRendered = (params) => {

    };
    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        if(params.source !== "api" && params.source !== "gridOptionsChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateTrainingLicensesGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateTrainingLicensesGridColumnModeReactive, customColumnModeText)
        }
        else if(params.source === "api" && params.type === "sortChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateTrainingLicensesGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateTrainingLicensesGridColumnModeReactive, customColumnModeText)
        }
    }
    onCellEditingStopped = (event) => {
        let gridApi = event.api
        if(event.column.colId === "licenseDisplayName"){
            if(event.newValue && event.newValue.trim().length > 0){
                if(event.newValue === event.oldValue){
                    event.data.licenseDisplayName = event.oldValue
                    gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
                    return;
                }
                if(!event.data.id){
                    NotificationManager.error("Error updating name")
                    event.data.licenseDisplayName = event.oldValue
                    gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
                    return;
                }
                changeTrainingLicenseNameReactive(event.newValue.trim(), event.data.zenGroupId, event.data.id).then(function(response){
                    NotificationManager.success("Successfully changed the name")
                    event.node.setDataValue("licenseDisplayName", event.newValue.trim());
                }).catch(function(error){
                    if(error.message){
                        NotificationManager.error(error.message)
                    }
                    else{
                        NotificationManager.error("Error updating name")
                    }
                    event.data.licenseDisplayName = event.oldValue
                    gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
                })
            }
            else{
                event.data.licenseDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
            }
        }
        else if(event.column.colId === "zenGroupDisplayName"){
            handleGroupColumnChangeNameOnly(event)
        }

    }

    componentWillUnmount(){
        this.abortController.abort()
    }

    populateGrid = async (rowData) => {
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    updateGridForChangeStream = async (changeStreamData) => {
        let operationType = changeStreamData.operationType
        let objectBody = changeStreamData.body
        objectBody["licenseDisplayName"] = objectBody["userSetFriendlyName"] ? objectBody["userSetFriendlyName"] : objectBody["friendlyName"]
        if(operationType === "UPDATE" || operationType === "REPLACE"){
            standardHandleUpdateAndReplaceEvent(objectBody, this.gridApi, this.props.sseDataPullActive, this.updateTransactionsToApply)
        }
        else if (operationType === "INSERT"){
            standardHandleInsertEvent(objectBody, this.gridApi, this.props.sseDataPullActive)
        }
    }

    getRowId = (params) => {
        return params.data.id
    }

    getContextMenuItems = (params) => {
        let excelExport = this.props.excelExport //don't have access to this.props below in the action function so define it here
        return [
            standardExcelExportObjectInContextMenu(excelExport),
            "resetColumns",
            "autoSizeAll"
        ];
    };

    onGridReady = async (gridReadyParams) => {
        this.gridApi = gridReadyParams.api;
        this.props.setGridApi(gridReadyParams.api);
        // Disable text selection on the page while holding shift or control (to allow grid selections to be done easily without selecting all text)
        ["keyup","keydown"].forEach((event) => {
            window.addEventListener(event, (e) => {
                document.onselectstart = function() {
                    return !(e.shiftKey || e.ctrlKey);
                }
            });
        });
        //Only max/custom are allowed for this grid
        let columnMode = this.props.columnMode
        //check which initial column mode to apply
        if(columnMode === customColumnModeText){
            onGridReadyHelperForColumnState(gridReadyParams, gridColumnStateSessionVariableName)
        }
        //else if columnMode is max then the default column state already shows the max amount of columns no need to update

        onGridReadyHelper(gridReadyParams, gridFilterStateSessionVariableName);

        await loadDataWithSSEAndStartChangeStreamListener("/sse/trainingLicenseListReactive", "/sse/listenToTrainingLicenseEvent",
            this.populateGrid, this.updateGridForChangeStream, gridReadyParams, this.props.setSSEDataPullActive, this.props.setAsyncTransactionWaitMillis, this.updateTransactionsToApply,
            this.abortController)
        //gridReadyParams.api.sizeColumnsToFit()
    };
    render() {
        return (
            <div className={"w-full h-full"} style={{minHeight: "400px"}}>
                <div id="myGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                    <AgGridReact
                        columnMenu={"legacy"}
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, ExcelExportModule]}
                        defaultColDef={this.props.defaultColDef}
                        columnDefs={this.props.columnDefs}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        multiSortKey={"ctrl"}
                        rowData={this.rowData}
                        onGridReady={this.onGridReady}
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        suppressModelUpdateAfterUpdateTransaction={true}
                        getRowId={this.getRowId}
                        onCellEditingStopped={this.onCellEditingStopped}
                        maintainColumnOrder={true} //fixes issue where if you re-order/move column then click anywhere on the grid it reverts this change
                        rowSelection={this.props.rowSelection}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            if(selectedRows && selectedRows.length > 0){
                                //checks if the setEnableButtons method is null or not
                                this.props.setEnableButtons && this.props.setEnableButtons(true);
                            }
                            else{
                                this.props.setEnableButtons && this.props.setEnableButtons(false);
                            }
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        onFilterChanged={(params)=> {
                            onFilterChangedHelper(params, gridFilterStateSessionVariableName, updateTrainingLicensesGridFilterModelReactive);
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                        getContextMenuItems={this.getContextMenuItems}
                        sideBar={this.props.sideBar}
                    />
                </div>
            </div>
        );
    }
}
