import React, {Component, useMemo, useState} from "react";
import {AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {ExcelExportModule} from "@ag-grid-enterprise/excel-export";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import SidebarMenu from "../../components/sideBarComponent";
import {defaultZenGroupColumnInitWithOptionsWithValueGetter} from "../../utils/zenGroupDisplayNameGridHelper";
import {dateValueFormatter} from "../../utils/gridDateFormatter";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import CustomNameCellEditor from "../../utils/customCellEditor";
import {handleGroupColumnChangeNameOnly} from "../../utils/gridCellEditing";
import DTPicker, {dateFilterParametersInHeaderClientSideGrid} from "../../utils/DTPicker";
import {
    loadDataWithSSEAndStartChangeStreamListener, standardHandleInsertEvent,
    standardHandlePopulateGrid, standardHandleUpdateAndReplaceEvent
} from "../../utils/sseAndChangeStreamHelper";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {standardExcelExportHelper, standardExcelExportObjectInContextMenu} from "../../utils/excelExportHelper";
import {
    getColumnModeInSession, getDefaultAgGridSidebarProps,
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnState,
    updateColumnModeInSessionHelper,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import {
    ClickToShowColumnOptionsWithToggleButtonGroup,
    customColumnModeText,
    minColumnModeText,
    standardApplyMinimumOrMediumColumnMode
} from "../../components/clickToShowButtons";
import {
    updateCustomerDeploymentHealthGridColumnModeReactive,
    updateCustomerDeploymentHealthGridColumnStateReactive,
    updateCustomerDeploymentHealthGridFilterModelReactive,
    updateCustomerDeploymentHealthGridUseColumnStateReactive,
    updateCustomerDeploymentHealthGridUseFilterStateReactive
} from "../api/customerDeploymentHealthApi";
import {defaultClientSideTextFilterParams} from "../../utils/filterHelper";

let gridColumnStateSessionVariableName = "customerDeploymentHealthGridColumnState"
let gridFilterStateSessionVariableName = "customerDeploymentHealthGridFilterState"
let minColumnIds = ["zenGroupDisplayName", "machineName", "humanReadableError", "dateCollected"]
export default function CustomerDeploymentHealth() {
    const [isLoading, setIsLoading] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession(gridFilterStateSessionVariableName));
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    const [columnMode, setColumnMode] = useState(getColumnModeInSession(gridColumnStateSessionVariableName));
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(200); //200 to start for the initial sse data pull, will change when sse data pull is done for change streams
    // eslint-disable-next-line no-unused-vars
    const [columnDefs, setColumnDefs] = useState([
        defaultZenGroupColumnInitWithOptionsWithValueGetter(true, true, true),
        { field: "zenGroupId", hide: true, suppressColumnsToolPanel: true, lockVisible: true},
        { field: "machineName", headerName: "Machine Name", initialWidth: 225,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true,
            editable: false,
        },
        { field: "humanReadableError", headerName: "Error", initialWidth: 400,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true,
            editable: false,
            valueGetter: (params) => {
                if(params.node.data.humanReadableError){
                    return params.node.data.humanReadableError
                }
                else{
                    return "Unknown Error"
                }
            },
        },
        { field: "dateCollected", headerName: "Date Collected", initialWidth: 350,
            filter: 'agDateColumnFilter',
            sortable: true,
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            valueFormatter: dateValueFormatter
        },
        { field: "installerVersion", headerName: "Installer Version", initialWidth: 250,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true,
            editable: false,
        },
        { field: "osVersion", headerName: "OS Version", initialWidth: 250,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true,
            editable: false,
        },
        { field: "fqdn", headerName: "FQDN", initialWidth: 250,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true,
            editable: false,
        }
    ])
    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            filterParams: null,
            floatingFilter: true,
            headerClass: "border-0 border-b-0",
            cellClass: "outline:none",
            enableCellChangeFlash: true,
            autoHeight: false,
            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
        }
    )
    const sideBar = useMemo(() => {
        //Inside useMemo to help prevent the sidebar from re-rendering
        return getDefaultAgGridSidebarProps()
    }, []);
    const rowSelection = useMemo(() => {
        return {
            mode: 'singleRow',
            enableClickSelection: true,
            checkboxes: false,
            headerCheckbox: false
        };
    }, []);

    return (
        <div className="flex flex-col h-full">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Customer Deployment Health</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <div className="flex flex-1 flex-row h-full overflow-y-auto">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col flex-nowrap gap-y-2 mt-8 ml-5 mr-10 h-full">
                    {privatePageHeaderHelper("Customer Deployment Health")}
                    <hr className="bg-black h-0.5"/>
                    <div className="flex flex-row justify-between gap-x-1 gap-y-3">
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <GridColumnFilterStateSaving
                                useFilterStateSettingToggled={useFilterStateSettingToggled}
                                setUseFilterStateSettingToggled={setUseFilterStateSettingToggled}
                                toggleUpdateUseFilterState={toggleUpdateUseFilterState}
                                useColumnStateSettingToggled={useColumnStateSettingToggled}
                                setUseColumnStateSettingToggled={setUseColumnStateSettingToggled}
                                toggleUpdateUseColumnState={toggleUpdateUseColumnState}/>
                        </div>
                        <div className={"flex flex-row flex-wrap gap-y-3 gap-x-8 self-end justify-end"}>
                            <ClickToShowColumnOptionsWithToggleButtonGroup
                                columnMode={columnMode} setColumnMode={setColumnMode}
                                gridColumnStateSessionVariableName={gridColumnStateSessionVariableName}
                                gridApi={gridApi} minColumnIds={minColumnIds} disableMediumOption={true}
                                updateGridColumnModeFunction={updateCustomerDeploymentHealthGridColumnModeReactive}/>
                            <ClearRefresh gridApi={gridApi} showRefreshIcon={false}
                                          sseDataPullActive={sseDataPullActive}
                                          showExcelExportIcon={true} excelExportFunction={excelExport}/>
                        </div>
                    </div>
                    <div className="h-full flex flex-col gap-y-5" id="gridRoot">
                        {getGrid()}
                        <Footer/>
                    </div>
                </div>
            </div>

            <NotificationContainer/>
        </div>
    );

    function getGrid() {
        return (
            <Grid
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                sideBar={sideBar}
                rowSelection={rowSelection}
                setGridApi={setGridApi}
                sseDataPullActive={sseDataPullActive}
                setSSEDataPullActive={setSSEDataPullActive}
                asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                excelExport={excelExport}
                columnMode={columnMode}
                setColumnMode={setColumnMode}
            />
        );
    }

    function excelExport() {
        standardExcelExportHelper(gridApi, sseDataPullActive, "customerDeploymentHealthGridExport")
    }

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, gridFilterStateSessionVariableName, updateCustomerDeploymentHealthGridUseFilterStateReactive);
    }
    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, gridColumnStateSessionVariableName, updateCustomerDeploymentHealthGridUseColumnStateReactive);
    }
}

class Grid extends Component {
    rowData = []
    updateTransactionsToApply = []
    abortController = new AbortController()

    constructor(props, onClickRow, filterVals) {
        super(props);
    }

    onFirstDataRendered = (params) => {

    };
    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        if(params.source !== "api" && params.source !== "gridOptionsChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateCustomerDeploymentHealthGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateCustomerDeploymentHealthGridColumnModeReactive, customColumnModeText)
        }
        else if(params.source === "api" && params.type === "sortChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateCustomerDeploymentHealthGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateCustomerDeploymentHealthGridColumnModeReactive, customColumnModeText)
        }

    }
    onCellEditingStopped = (event) => {
        let gridApi = event.api
        if (event.column.colId === "zenGroupDisplayName") {
            handleGroupColumnChangeNameOnly(event)
        }

    }

    componentWillUnmount() {
        this.abortController.abort()
    }

    populateGrid = async (rowData) => {
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    updateGridForChangeStream = async (changeStreamData) => {
        let operationType = changeStreamData.operationType
        let objectBody = changeStreamData.body
        if(operationType === "UPDATE" || operationType === "REPLACE"){
            standardHandleUpdateAndReplaceEvent(objectBody, this.gridApi, this.props.sseDataPullActive, this.updateTransactionsToApply)
        }
        else if (operationType === "INSERT"){
            standardHandleInsertEvent(objectBody, this.gridApi, this.props.sseDataPullActive)
        }
    }

    getRowId = (params) => {
        return params.data.id
    }

    getContextMenuItems = (params) => {
        let excelExport = this.props.excelExport //don't have access to this.props below in the action function so define it here
        return [
            standardExcelExportObjectInContextMenu(excelExport),
            "resetColumns",
            "autoSizeAll"
        ];
    };

    onGridReady = async (gridReadyParams) => {
        this.gridApi = gridReadyParams.api;
        this.props.setGridApi(gridReadyParams.api);

        let columnMode = this.props.columnMode
        //check which initial column mode to apply
        if(columnMode === customColumnModeText){
            onGridReadyHelperForColumnState(gridReadyParams, gridColumnStateSessionVariableName)
        }
        else if(columnMode === minColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, gridReadyParams.api, this.props.setColumnMode, minColumnModeText, minColumnIds, updateCustomerDeploymentHealthGridColumnModeReactive)
        }
        //Medium not allowed for this grid, only min/max/custom
        //else if columnMode is max then the default column state already shows the max amount of columns no need to update

        onGridReadyHelper(gridReadyParams, gridFilterStateSessionVariableName);

        await loadDataWithSSEAndStartChangeStreamListener("/sse/customerDeploymentHealthListReactive", "/sse/listenToAgentInstallationReportEvent",
            this.populateGrid, this.updateGridForChangeStream, gridReadyParams, this.props.setSSEDataPullActive, this.props.setAsyncTransactionWaitMillis, this.updateTransactionsToApply,
            this.abortController)
        //gridReadyParams.api.sizeColumnsToFit()
    };
    render() {
        return (
            <div className={"w-full h-full"} style={{minHeight: "400px"}}>
                <div id="myGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                    <AgGridReact
                        columnMenu={"legacy"}
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, ExcelExportModule]}
                        defaultColDef={this.props.defaultColDef}
                        columnDefs={this.props.columnDefs}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        multiSortKey={"ctrl"}
                        rowData={this.rowData}
                        onGridReady={this.onGridReady}
                        maintainColumnOrder={true} //fixes issue where if you re-order/move column then click anywhere on the grid it reverts this change
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        suppressModelUpdateAfterUpdateTransaction={true}
                        getRowId={this.getRowId}
                        onCellEditingStopped={this.onCellEditingStopped}
                        rowSelection={this.props.rowSelection}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        onFilterChanged={(params)=> {
                            onFilterChangedHelper(params, gridFilterStateSessionVariableName, updateCustomerDeploymentHealthGridFilterModelReactive);
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                        getContextMenuItems={this.getContextMenuItems}
                        sideBar={this.props.sideBar}
                    />
                </div>
            </div>
        );
    }
}
