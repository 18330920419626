import React, {Component, useEffect, useMemo, useState} from "react";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "../../css/system-styles.css";
import "../../css/colors.css";
import "../../css/typography.css";
import "../../css/buttons.css";
import "../../css/web-pages.css";
import "../../css/private-web-pages.css";
import {AgGridReact} from "@ag-grid-community/react";
import '@ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import '@ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {ExcelExportModule} from "@ag-grid-enterprise/excel-export";
import {
    bulkChangeLicensesGroupReactive,
    changeLicenseGroupReactive,
    licenseUsageReactive, nonExpiredLicenseUsageReactive,
    singleReleaseLicensesReactive,
    updateAgentLicenseChartVisibilityReactive,
    updateFilterExpiredLicensesByDefaultReactive,
    updateLicensesGridColumnModeReactive,
    updateLicensesGridColumnStateReactive,
    updateLicensesGridFilterModelReactive,
    updateLicensesGridUseColumnStateReactive,
    updateLicensesGridUseFilterStateReactive
} from "../api/licensesApi";
import {NotificationContainer} from 'react-notifications';
import Modal from "react-modal";
import {
    ConfirmationModalWithPermissionsShown,
    PermissionsGrid,
    processGroupPermissionData
} from "../../components/confirmationModal";
import NotificationManager from "react-notifications/lib/NotificationManager";
import 'react-notifications/lib/notifications.css';
import SidebarMenu from "../../components/sideBarComponent";
import {useForm} from "react-hook-form";
import {dateValueFormatter} from "../../utils/gridDateFormatter";
import {defaultZenGroupColumnInitWithOptionsWithValueGetter} from "../../utils/zenGroupDisplayNameGridHelper";
import {
    defaultAgentLastValidateColDefWithValueGetter,
    defaultAgentNameColumnForLicensesGrid,
    defaultMachineNameColDefWithFiltersWithValueGetter
} from "../../utils/agentNameGridHelper";
import {licensePageCellEditingStopped} from "../../utils/gridCellEditing";
import {AgChartsReact} from "ag-charts-react";
import {
    findZenGroupById,
    getZenGroupDropDownContents,
    useZenGroupSessionStorage
} from "../../utils/zenGroupSessionStorageManager";
import EjectRoundedIcon from '@mui/icons-material/EjectRounded';
import {
    agentLicenseChartVisibleSessionVariable,
    filterExpiredLicensesByDefaultVariable,
    getChartVisibilitySettingInSession,
    getColumnModeInSession,
    getDefaultAgGridSidebarProps,
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnState,
    updateChartVisibilitySettingInSession,
    updateColumnModeInSessionHelper,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import {useLocation} from "react-router-dom";
import CustomNameCellEditor, {
    CustomMuiAutocompleteGroupCellEditor,
    editNameIconOnlyCellRenderer
} from "../../utils/customCellEditor";
import {decryptAndGetSessionVariable, encryptAndStoreSessionVariable} from "../../utils/storageHelper";
import {checkPermission} from "../../utils/permissionCheckHelper";
import DTPicker, {dateFilterParametersInHeaderClientSideGrid} from "../../utils/DTPicker";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import {
    loadDataWithSSEAndStartChangeStreamListener,
    standardHandleInsertEvent,
    standardHandlePopulateGrid,
    standardHandleUpdateAndReplaceEvent
} from "../../utils/sseAndChangeStreamHelper";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropChartLoadingOverlay, BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {standardExcelExportHelper, standardExcelExportObjectInContextMenu} from "../../utils/excelExportHelper";
import {buttonTheme, switchTheme} from "../../utils/muiStyling";
import {Button, FormControlLabel, Switch, ThemeProvider} from "@mui/material";
import {
    MuiAutocompleteForZenGroupsWithoutCreateGroupOption,
    MuiAutocompleteNonGroupOptions,
    MuiCloseIconButton, MuiIconButtonWithTooltip,
    MuiIconButtonWithTooltipAndBox, MuiIconWithTooltip
} from "../../components/muiComponents";
import GroupIcon from '@mui/icons-material/Group';
import {
    ClickToShowColumnOptionsWithToggleButtonGroup,
    customColumnModeText,
    mediumColumnModeText,
    minColumnModeText,
    standardApplyMinimumOrMediumColumnMode
} from "../../components/clickToShowButtons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {defaultClientSideTextFilterParams} from "../../utils/filterHelper";

let gridColumnStateSessionVariableName = "licensesGridColumnState"
let minColumnIds = ["zenGroupDisplayName", "machineName", "licenseType", "expirationDate"]
let medColumnIds = ["zenGroupDisplayName", "licenseName", "machineName", "licenseType", "expirationDate"]
let chartSessionVariableName = agentLicenseChartVisibleSessionVariable
Modal.setAppElement('#root')
export default function Licenses() {
    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [zenGroups,setZenGroups] = useState([]);
    const [zenGroup, setZenGroup] = useState();
    const [showBulkMoveLicenses, setShowBulkMoveLicenses] = useState(false);
    const [groupToMoveFrom, setGroupToMoveFrom] = useState();
    const [groupToMoveTo, setGroupToMoveTo] = useState();
    const { register, handleSubmit, reset } = useForm();
    const [licenseType, setLicenseType] = useState();
    const [showReleaseLicensesConfirmation, setShowReleaseLicensesConfirmation] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [zenGroupNamesWithPermission, setZenGroupNamesWithPermission] = useState(new Set());
    const [zenGroupIdsWithoutPermission, setZenGroupIdsWithoutPermission] = useState(new Set());
    const [zenGroupNamesWithoutPermission, setZenGroupNamesWithoutPermission] = useState(new Set());
    const [showPermissionGrid, setShowPermissionGrid] = useState(false);
    const [showChangeSelectedLicenseGroupsModal, setShowChangeSelectedLicenseGroupsModal] = useState(false);
    const [newLicensesZenGroup, setNewLicensesZenGroup] = useState(null);
    const [enableButtons, setEnableButtons] = useState(false);
    const [allLicensesChartData, setAllLicensesChartData] = useState([]);
    const [nonExpiredLicensesChartData, setNonExpiredLicensesChartData] = useState([]);
    const [chartIsLoading, setChartIsLoading] = useState(false);
    const [chartToggled, setChartToggled] = useState(getChartVisibilitySettingInSession(chartSessionVariableName));
    // eslint-disable-next-line no-unused-vars
    const [zenGroupSessionStorage,setZenGroupSessionStorage] = useZenGroupSessionStorage()
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession("licensesGridFilterState"));
    const licenseLocation = useLocation();
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    const [filterOutExpiredLicensesToggled, setFilterOutExpiredLicensesToggled] = useState(getChartVisibilitySettingInSession(filterExpiredLicensesByDefaultVariable)) //same logic for expired licenses toggle and chart toggle
    const [columnMode, setColumnMode] = useState(getColumnModeInSession(gridColumnStateSessionVariableName));
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(1000);
    /*
        1000ms to start for the initial sse data pull, larger grids benefit from the second delay to give ag grid time to perform saved sorts to apply by default. If we have small number of
        ms then ag grid performance for certain column sorts is slower since it has to re-sort and re-render much more frequently.
     */
    // eslint-disable-next-line no-unused-vars
    const [columnDefs, setColumnDefs] = useState([
        defaultZenGroupColumnInitWithOptionsWithValueGetter(true,true, false),
        { field: "zenGroupId", hide: true, suppressColumnsToolPanel: true, lockVisible: true},
        { field: "agentId", hide: true, suppressColumnsToolPanel: true, lockVisible: true},
        { field: "licenseName", headerName: "License Name", initialWidth: 380,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true,
            editable: true,
            cellEditor: "customNameCellEditor",
            cellRenderer: function (params) {
                return editNameIconOnlyCellRenderer(params, "Click to Edit this License's Name", "licenseName")
            }
        },
        defaultAgentNameColumnForLicensesGrid,
        defaultMachineNameColDefWithFiltersWithValueGetter,
        { field: "licenseType", headerName: "License Type", initialWidth: 225,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Desktop', 'Server'],
                suppressSorting: false,
                suppressSelectAll: false,
            },
            sortable: true
        },
        { field: "expirationDate", headerName: "Renews", initialWidth: 280,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            sortable: true,
            valueFormatter: dateValueFormatter,
            cellRenderer: function (params) {
                let showExpiredIcon = false
                let expirationDateValue = params.node.data.expirationDate

                if(expirationDateValue !== null && expirationDateValue !== undefined) {
                    try{
                        let expirationDate = new Date(expirationDateValue).getTime()
                        let currentDate = new Date().getTime()
                        if(currentDate >= expirationDate){
                            //expired
                            showExpiredIcon = true
                        }
                    } catch(error){
                        //show expired icon
                        showExpiredIcon = true
                    }
                }

                return (
                    <div className={`flex flex-row items-center gap-x-0.5`}>
                        {showExpiredIcon && (
                            <MuiIconButtonWithTooltip
                                icon={
                                    <FontAwesomeIcon
                                        className="object-contain"
                                        icon={"fa-duotone fa-clock-rotate-left"}
                                        size="xs"
                                    />
                                }
                                tooltipTitle={"This license is expired, agents can no longer stop any extortion attack with an expired license!"}
                                tooltipPlacement={"bottom-start"}
                            />
                        )}
                        {params.valueFormatted}
                    </div>
                )

            }
        },
        { field: "purchasedDate", headerName: "Purchased", initialWidth: 280,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        defaultAgentLastValidateColDefWithValueGetter,
    ])
    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            filterParams: null,
            floatingFilter: true,
            headerClass: "border-0 border-b-0",
            cellClass: "outline:none",
            enableCellChangeFlash: true,
            autoHeight: false,
            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
        }
    )
    const sideBar = useMemo(() => {
        //Inside useMemo to help prevent the sidebar from re-rendering
        return getDefaultAgGridSidebarProps()
    }, []);
    const rowSelection = useMemo(() => {
        return {
            mode: 'multiRow',
            enableClickSelection: true,
            checkboxes: false,
            headerCheckbox: false
        };
    }, []);

    var _ = require('lodash');

    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            setZenGroups(zenGroupSessionStorage)
            if(zenGroupSessionStorage !== null && zenGroupSessionStorage.length > 0){
                setZenGroup(zenGroupSessionStorage[0].id);
            }

            //Grab chart data for licenseUsageReactive and nonExpiredLicenseUsageReactive
            setChartIsLoading(true)
            licenseUsageReactive().then(licenseUsagesList => {
                setChartIsLoading(false)
                handleLicenseChartData(licenseUsagesList, setAllLicensesChartData)
            }).catch(function (error) {
                setChartIsLoading(false)
            })
            nonExpiredLicenseUsageReactive().then(licenseUsagesList => {
                setChartIsLoading(false)
                handleLicenseChartData(licenseUsagesList, setNonExpiredLicensesChartData)
            }).catch(function (error) {
                setChartIsLoading(false)
            })

        })()
        return () => controller?.abort();
    }, [zenGroupSessionStorage])

    const onSubmit = async (data) => {
        if(data.count && groupToMoveFrom && groupToMoveTo && licenseType){
            if(groupToMoveFrom === groupToMoveTo){
                NotificationManager.info("You cannot select the same group for both fields");
                return;
            }
            try{
                let numLicenses = Number(data.count)
                if(numLicenses > 10000){
                    NotificationManager.info("The number of licenses cannot exceed 10,000");
                    return;
                }
                if(numLicenses < 1){
                    NotificationManager.info("The number of licenses must be greater than 0");
                    return;
                }
                bulkChangeLicensesGroupReactive(numLicenses, groupToMoveFrom, groupToMoveTo, licenseType).then(response => {
                    NotificationManager.success("Successfully queued update for licenses (you may need to refresh the grid periodically to see changes for larger requests)");
                    refreshGrid()
                    resetMoveLicenseForm()

                }).catch(function (error) {
                    if(error.message){
                        NotificationManager.error(error.message);
                    }
                    else{
                        NotificationManager.error(
                            `Error updating licenses.`
                        );
                    }
                })
            }
            catch(error){
                console.log(error)
            }
        }
        else{
            NotificationManager.error("Please fill out all fields")
        }
    }

    const resetMoveLicenseForm = () => {
        setShowBulkMoveLicenses(false)
        reset({count: ""})
        setGroupToMoveFrom()
        setGroupToMoveTo()
        setLicenseType()
    }

    function handleLicenseChartData(licenseUsagesList, setChartDataHook){
        if(licenseUsagesList){
            let data = []
            for (let i in licenseUsagesList){
                let group = findZenGroupById(licenseUsagesList[i].zenGroupId)
                if(group && group.friendlyName){
                    //found group in session
                    data.push({"zenGroupId":licenseUsagesList[i].zenGroupId, "zenGroupName":group.friendlyName,
                        "desktopUsed":licenseUsagesList[i].desktopUsed,
                        "desktopAvailable":licenseUsagesList[i].desktopAvailable,
                        "serverUsed":licenseUsagesList[i].serverUsed,
                        "serverAvailable":licenseUsagesList[i].serverAvailable
                    })
                }
                else{
                    //else did not find group in session
                    data.push({"zenGroupId":licenseUsagesList[i].zenGroupId,
                        "desktopUsed":licenseUsagesList[i].desktopUsed,
                        "desktopAvailable":licenseUsagesList[i].desktopAvailable,
                        "serverUsed":licenseUsagesList[i].serverUsed,
                        "serverAvailable":licenseUsagesList[i].serverAvailable
                    })
                }
            }

            data.sort((object1, object2) => (object1.zenGroupName?.toLowerCase() > object2.zenGroupName?.toLowerCase()) ? 1 : -1)
            setChartDataHook && setChartDataHook(data)
        }
        else{
            setChartDataHook && setChartDataHook([])
        }
    }

    return (
        <div className="flex flex-col h-full">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Agent Licenses</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <Modal contentLabel="Bulk Change License Groups" isOpen={showBulkMoveLicenses}
                   onRequestClose={() => {
                       resetMoveLicenseForm()
                   }} shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-2xl max-w-2xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"

            >
                <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-1 flex-col">
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Move Free Licenses Between Groups</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    resetMoveLicenseForm()
                                }}
                            />
                        </div>
                        <hr className="mt-3 h-0.5" />
                        <div className="ml-1 mt-5">
                            <label>How Many Licenses Would You Like to Move?</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                type="text"
                                autoFocus={true}
                                required
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/\D/g,'');
                                }}
                                name="count"
                                {...register("count")}
                                placeholder="Number of Licenses to Move"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="ml-1 mt-5">
                            <label>Move From Group:</label>
                            <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                                zenGroupDropdownOptionsList={getZenGroupDropDownContents()}
                                value={groupToMoveFrom}
                                onChange={( event, value ) => {
                                    setGroupToMoveFrom(value?.value)
                                }}
                            />
                        </div>
                        <div className="ml-1 mt-5">
                            <label>To Group:</label>
                            <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                                zenGroupDropdownOptionsList={getZenGroupDropDownContents()}
                                value={groupToMoveTo}
                                onChange={( event, value ) => {
                                    setGroupToMoveTo(value?.value)
                                }}
                            />
                        </div>
                        <div className="ml-1 mt-5">
                            <label>License Type to Move:</label>
                            <MuiAutocompleteNonGroupOptions
                                options={["Desktop", "Server"]}
                                value={licenseType}
                                onChange={(event, value) =>{
                                    setLicenseType(value)
                                }}
                            />
                        </div>
                        <div className="flex flex-col mt-5">
                            <ThemeProvider theme = {buttonTheme}>
                                <Button variant={"contained"}
                                        type={"submit"}
                                        color={"primary"}>
                                Submit
                                </Button>
                            </ThemeProvider>
                        </div>
                    </div>
                </form>
            </Modal>
            <ConfirmationModalWithPermissionsShown
                text="WARNING: You are about to release the selected licenses from their agents. The grid below shows your permission level to do this action for each of the selected licenses' group:"
                onConfirm={async () => {
                    if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length > 0){
                        let validLicenseIdList = []
                        gridApi.getSelectedNodes().forEach(currentLicense => {
                            if(currentLicense.data.licenseId && currentLicense.data.agentId && !zenGroupIdsWithoutPermission.has(currentLicense.data.zenGroupId)) { //make sure license has an id (probably unnecessary check)
                                validLicenseIdList.push(currentLicense.data.licenseId)
                                singleReleaseLicensesReactive(currentLicense.data.licenseId)
                                    .then(() => {
                                        currentLicense.setDataValue("agentId",null)
                                        currentLicense.setDataValue("agentDisplayName",null)
                                        currentLicense.setDataValue("machineName",null)
                                        currentLicense.setDataValue("lastValidateDateTime",null)
                                    })
                                    .catch(function(error){
                                        // if(error.message){
                                        //     NotificationManager.error(error.message);
                                        // }
                                        // else{
                                        //     NotificationManager.error(
                                        //         `Error requesting the release of these licenses.`
                                        //     );
                                        // }
                                        // setShowReleaseLicensesConfirmation(false)
                                    })
                            }
                        })

                        if(validLicenseIdList.length < 1){
                            NotificationManager.info(`None of the selected licenses in groups you have permission in were eligible to be released`);
                        }
                        else{
                            NotificationManager.success("Queued the release of eligible licenses for groups you have permission to do so in (you may need to refresh the grid periodically to see changes for larger requests)");
                            // refreshGrid()
                            setShowReleaseLicensesConfirmation(false)
                            setZenGroupNamesWithPermission(new Set())
                            setZenGroupNamesWithoutPermission(new Set())
                            setZenGroupIdsWithoutPermission(new Set())
                        }
                    }
                }}
                onClose={() => {
                    setShowReleaseLicensesConfirmation(false)
                    setZenGroupNamesWithPermission(new Set())
                    setZenGroupNamesWithoutPermission(new Set())
                    setZenGroupIdsWithoutPermission(new Set())
                }}
                opened={showReleaseLicensesConfirmation}
                groupNamesWithPermission={zenGroupNamesWithPermission}
                groupNamesWithoutPermission={zenGroupNamesWithoutPermission}
            />
            <Modal contentLabel="Change Selected License Group" isOpen={showChangeSelectedLicenseGroupsModal}
                   onRequestClose={() => {
                       setShowChangeSelectedLicenseGroupsModal(false)
                       setNewLicensesZenGroup(null)
                       setZenGroupNamesWithPermission(new Set())
                       setZenGroupNamesWithoutPermission(new Set())
                       setZenGroupIdsWithoutPermission(new Set())
                   }}
                   shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white inset-y-10 mx-auto rounded-2xl ${showPermissionGrid ? "max-w-3xl" : "max-w-xl"}`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">{(!gridApi || gridApi.getSelectedNodes().length < 2) ? "Change Selected License's Group" : "Change Selected Licenses' Group"}</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                setShowChangeSelectedLicenseGroupsModal(false)
                                setNewLicensesZenGroup(null)
                                setZenGroupNamesWithPermission(new Set())
                                setZenGroupNamesWithoutPermission(new Set())
                                setZenGroupIdsWithoutPermission(new Set())
                            }}
                        />
                    </div>
                    <hr className="mt-3 h-0.5" />
                    <div className="ml-1">
                        <label>New Group for Selected License(s)</label>
                        <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                            zenGroupDropdownOptionsList={getZenGroupDropDownContents()}
                            value={newLicensesZenGroup}
                            onChange={( event, value ) => {
                                setNewLicensesZenGroup(value?.value)
                            }}
                        />
                    </div>
                    <div className="ml-1" style={{display: showPermissionGrid ? "block" : "none"}}>
                        <div>The grid below shows your permission level to change a license's group for each of the selected licenses' group:</div>
                    </div>
                    <div className="ml-1" style={{display: showPermissionGrid ? "block" : "none"}}>
                        <PermissionsGrid
                            headers={[
                                { field: "zenGroupWithPermission", headerName: "Groups with permission", initialWidth: 300},
                                { field: "zenGroupWithoutPermission", headerName: "Groups without permission", initialWidth: 350}
                            ]}
                            data={processGroupPermissionData(zenGroupNamesWithPermission, zenGroupNamesWithoutPermission)}
                        />
                    </div>
                    <ThemeProvider theme = {buttonTheme}>
                        <Button
                            disabled={zenGroupNamesWithPermission?.size < 1}
                            variant={"contained"}
                            color={"primary"}
                            onClick={() => {
                                changeSelectedLicensesGroup()
                            }}>
                            Update
                        </Button>
                    </ThemeProvider>
                </div>
            </Modal>
            <div className="flex flex-1 flex-row h-full overflow-y-auto">
                <SidebarMenu setIsLoading={setIsLoading} />
                <div className="flex flex-1 flex-col ml-5 mr-10 mt-8 gap-y-3 h-full">
                    {privatePageHeaderHelper("Agent Licenses")}
                    <hr className="bg-black h-0.5" />
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme = {switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={chartToggled}
                                    name="licenseUsageToggle"
                                    onChange={e => {
                                        setChartToggled(e.target.checked)
                                        updateChartVisibilitySettingInSession(chartSessionVariableName, e.target.checked, updateAgentLicenseChartVisibilityReactive)
                                    }}
                                />
                            } label={chartToggled ? "Showing License Usage Chart" : "Hiding License Usage Chart"}/>
                        </ThemeProvider>
                    </div>
                    {/*License Usage Chart*/}
                    <div className={`chartContainer ${chartToggled ? `block` : `hidden`} relative`}>
                        <AgChartsReact options={
                            {
                                autoSize: true,
                                data: filterOutExpiredLicensesToggled ? nonExpiredLicensesChartData : allLicensesChartData,
                                title: {
                                    text: 'License Usage Per Group',
                                    fontSize: 18,
                                },
                                series: [
                                    {
                                        type: 'bar',
                                        stacked: true,
                                        stackGroup: 'Desktop',
                                        xKey: 'zenGroupId',
                                        yKey: 'desktopUsed',
                                        yName: 'Desktop Used',
                                        fill: '#4194a5',
                                        stroke: '#c2c2c2',
                                        strokeWidth: 1,
                                        highlightStyle: {
                                            item: {
                                                fill: "#E8E8E8",
                                                stroke: "#181818",
                                                strokeWidth: 1
                                            },
                                        },
                                        tooltip: { //for hovering over a column
                                            renderer: function (params) {
                                                let content = params.datum.zenGroupName + ": " + params.datum[params.yKey]
                                                return {
                                                    title: params.yName,
                                                    content: content
                                                };
                                            },
                                        },
                                    },
                                    {
                                        type: 'bar',
                                        stacked: true,
                                        stackGroup: 'Desktop',
                                        xKey: 'zenGroupId',
                                        yKey: 'desktopAvailable',
                                        yName: 'Desktop Free',
                                        fill: '#e76a24',
                                        stroke: '#c2c2c2',
                                        strokeWidth: 1,
                                        highlightStyle: {
                                            item: {
                                                fill: "#E8E8E8",
                                                stroke: "#181818",
                                                strokeWidth: 1
                                            },
                                        },
                                        tooltip: { //for hovering over a column
                                            renderer: function (params) {
                                                let content = params.datum.zenGroupName + ": " + params.datum[params.yKey]
                                                return {
                                                    title: params.yName,
                                                    content: content
                                                };
                                            },
                                        },
                                    },
                                    {
                                        type: 'bar',
                                        stacked: true,
                                        stackGroup: 'Server',
                                        xKey: 'zenGroupId',
                                        yKey: 'serverUsed',
                                        yName: 'Server Used',
                                        fill: '#57b757',
                                        stroke: '#c2c2c2',
                                        strokeWidth: 1,
                                        highlightStyle: {
                                            item: {
                                                fill: "#E8E8E8",
                                                stroke: "#181818",
                                                strokeWidth: 1
                                            },
                                        },
                                        tooltip: { //for hovering over a column
                                            renderer: function (params) {
                                                let content = params.datum.zenGroupName + ": " + params.datum[params.yKey]
                                                return {
                                                    title: params.yName,
                                                    content: content
                                                };
                                            },
                                        },
                                    },
                                    {
                                        type: 'bar',
                                        stacked: true,
                                        stackGroup: 'Server',
                                        xKey: 'zenGroupId',
                                        yKey: 'serverAvailable',
                                        yName: 'Server Free',
                                        fill: '#fba71b',
                                        stroke: '#c2c2c2',
                                        strokeWidth: 1,
                                        highlightStyle: {
                                            item: {
                                                fill: "#E8E8E8",
                                                stroke: "#181818",
                                                strokeWidth: 1
                                            },
                                        },
                                        tooltip: { //for hovering over a column
                                            renderer: function (params) {
                                                let content = params.datum.zenGroupName + ": " + params.datum[params.yKey]
                                                return {
                                                    title: params.yName,
                                                    content: content
                                                };
                                            },
                                        },
                                    },
                                ],
                                axes: [
                                    {
                                        type: 'category',
                                        position: 'bottom',
                                        keys: ["zenGroupId"],
                                        label: {
                                            rotation: 50,
                                            formatter: function(params){
                                                //The params include the zenGroupId (params.value) and the index of the label in the chart data array, so we can access the object and format return value
                                                let index = params.index //index of the chart data array
                                                let hookToUse = allLicensesChartData
                                                if(filterOutExpiredLicensesToggled){
                                                    hookToUse = nonExpiredLicensesChartData
                                                }
                                                if(hookToUse && index !== undefined && index !== null){
                                                    try{
                                                        let object = hookToUse[index]
                                                        if(object){
                                                            let name = params.value //initialize to zenGroupId (params.value) in case no zenGroupName found
                                                            if(object.zenGroupName){
                                                                name = object.zenGroupName
                                                            }
                                                            if(object.desktopUsed !== null && object.desktopUsed !== undefined && object.desktopAvailable !== null && object.desktopAvailable !== undefined
                                                                && object.serverUsed !== null && object.serverUsed !== undefined && object.serverAvailable !== null && object.serverAvailable !== undefined){
                                                                return `${name} (Total: ${object.desktopUsed + object.desktopAvailable + object.serverUsed + object.serverAvailable})`
                                                            }
                                                            else{
                                                                //should not get to here but default to return name
                                                                return name
                                                            }
                                                        }
                                                    } catch (e) {}
                                                }
                                                return params.value //if we get to here then something went wrong, return zenGroupId (params.value)

                                            },
                                            fontSize: 11
                                        },
                                    },
                                    {
                                        type: 'number',
                                        position: 'left'
                                    },
                                ],
                                legend: {
                                    spacing: 40,
                                    position: 'top',
                                    enabled: true
                                },
                            }
                        } />
                        <BackDropChartLoadingOverlay opened={chartIsLoading} />
                    </div>
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row justify-between gap-x-1 gap-y-3">
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <div className={"self-end flex flex-col gap-y-0"}>
                                <GridColumnFilterStateSaving
                                    useFilterStateSettingToggled={useFilterStateSettingToggled}
                                    setUseFilterStateSettingToggled={setUseFilterStateSettingToggled}
                                    toggleUpdateUseFilterState={toggleUpdateUseFilterState}
                                    useColumnStateSettingToggled={useColumnStateSettingToggled}
                                    setUseColumnStateSettingToggled={setUseColumnStateSettingToggled}
                                    toggleUpdateUseColumnState={toggleUpdateUseColumnState}/>
                                <div className={`flex flex-row items-center flex-nowrap gap-x-0`}>
                                    <ThemeProvider theme = {switchTheme}>
                                        <FormControlLabel control={
                                            <Switch checked={filterOutExpiredLicensesToggled} name="filterOutExpiredLicensesToggled" color={"primary"}
                                                    onChange={toggleSetting => {
                                                        if(gridApi !== null && gridApi !== undefined){
                                                            setFilterOutExpiredLicensesToggled(toggleSetting.target.checked) //flip toggle
                                                            //update current grid to reflect new toggle setting
                                                            if(toggleSetting.target.checked){ //If turning on, then apply filter for licenses that are not expired
                                                                let startTimeFilterString = getCurrentDateAgGridFilterString()

                                                                let nonExpiredFilter = {
                                                                    "dateFrom": startTimeFilterString,
                                                                    "dateTo": null,
                                                                    "filterType": "date",
                                                                    "type": "greaterThan"
                                                                }
                                                                let filterModel = gridApi.getFilterModel();
                                                                if(filterModel === null || filterModel === undefined){ //precaution, set to {} if null
                                                                    filterModel = {}
                                                                }
                                                                filterModel["expirationDate"] = nonExpiredFilter
                                                                gridApi.setFilterModel(filterModel)
                                                                //gridApi.onFilterChanged()

                                                            }
                                                            else { //If turning off, then clear filter for the Renews column
                                                                let filterModel = gridApi.getFilterModel();
                                                                if(filterModel === null || filterModel === undefined){ //precaution, set to {} if null
                                                                    filterModel = {}
                                                                }
                                                                filterModel["expirationDate"] = null
                                                                gridApi.setFilterModel(filterModel)
                                                                //gridApi.onFilterChanged()

                                                            }
                                                            encryptAndStoreSessionVariable(filterExpiredLicensesByDefaultVariable, JSON.stringify(toggleSetting.target.checked)) //update in session
                                                            updateFilterExpiredLicensesByDefaultReactive(toggleSetting.target.checked).then(response => {}) //call to api to save setting for user
                                                                .catch(error => {
                                                                    console.log(error);
                                                                })
                                                        }
                                                    }}
                                            />
                                        } label={filterOutExpiredLicensesToggled ? "Filter Expired Licenses from Grid and Chart by Default" : "Do Not Filter Expired Licenses from Grid and Chart by Default"}
                                        />
                                    </ThemeProvider>
                                    <MuiIconWithTooltip
                                        icon={<FontAwesomeIcon className="mt-1 mr-1 object-contain cursor-pointer" icon="fa-light fa-circle-info" size="lg"/>}
                                        tooltipTitle={"This setting is for automatically filtering out expired licenses from the grid and chart. Note that when this setting is turned on, it will override" +
                                            " any saved filter for the Renews column"}
                                        tooltipPlacement={"bottom-start"}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                                <MuiIconButtonWithTooltipAndBox
                                    icon={<GroupIcon className={"cursor-pointer"}/>}
                                    tooltipTitle={"Move Free Licenses Between Groups"}
                                    tooltipPlacement={"top"}
                                    onClick={() => {
                                        setShowBulkMoveLicenses(true)
                                    }}/>
                                <MuiIconButtonWithTooltipAndBox
                                    icon={<EjectRoundedIcon className={"cursor-pointer"}/>}
                                    tooltipTitle={"Bulk Release Selected Licenses"}
                                    tooltipPlacement={"top"} disabled={!enableButtons}
                                    onClick={async () => {
                                        let zenGroupIdSet = new Set();
                                        let permissionCheckResult = true
                                        for (const rowNode of gridApi.getSelectedNodes()) {
                                            if (rowNode.data.zenGroupId && !zenGroupIdSet.has(rowNode.data.zenGroupId)){
                                                zenGroupIdSet.add(rowNode.data.zenGroupId)
                                                if (!checkPermission(rowNode.data.zenGroupId, "agentManager", "releaseAgentLicense")) {
                                                    permissionCheckResult = false
                                                    zenGroupIdsWithoutPermission.add(rowNode.data.zenGroupId)
                                                    zenGroupNamesWithoutPermission.add(rowNode.data.zenGroupDisplayName)
                                                    setZenGroupNamesWithoutPermission(zenGroupNamesWithoutPermission)
                                                    setZenGroupIdsWithoutPermission(zenGroupIdsWithoutPermission)
                                                } else {
                                                    zenGroupNamesWithPermission.add(rowNode.data.zenGroupDisplayName)
                                                    setZenGroupNamesWithPermission(zenGroupNamesWithPermission)
                                                }
                                            }
                                        }

                                        if (!permissionCheckResult) {   //  permission check failed, show modal
                                            setShowReleaseLicensesConfirmation(true)
                                        } else {    //  permission check pass, do not show modal and directly call api
                                            if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length > 0){
                                                let validLicenseIdList = []
                                                gridApi.getSelectedNodes().forEach(currentLicense => {
                                                    if(currentLicense.data.licenseId && currentLicense.data.agentId && !zenGroupIdsWithoutPermission.has(currentLicense.data.zenGroupId)) { //make sure license has an id (probably unnecessary check)
                                                        validLicenseIdList.push(currentLicense.data.licenseId)
                                                        singleReleaseLicensesReactive(currentLicense.data.licenseId)
                                                            .then(() => {
                                                                //let change stream take care of updating grid
                                                                /*currentLicense.setDataValue("agentId",null)
                                                                currentLicense.setDataValue("agentDisplayName",null)
                                                                currentLicense.setDataValue("machineName",null)*/
                                                            })
                                                            .catch(function(error){
                                                            })
                                                    }
                                                })
                                                if(validLicenseIdList.length < 1){
                                                    NotificationManager.info(`None of the selected licenses in groups you have permission in were eligible to be released`);
                                                }
                                                else{
                                                    NotificationManager.success("Queued the release of eligible licenses for groups you have permission to do so in (you may need to refresh the grid periodically to see changes for larger requests)");
                                                    setZenGroupNamesWithPermission(new Set())
                                                    setZenGroupNamesWithoutPermission(new Set())
                                                    setZenGroupIdsWithoutPermission(new Set())
                                                }
                                            }
                                        }
                                    }} />
                                <MuiIconButtonWithTooltipAndBox
                                    icon={<FontAwesomeIcon className="object-contain" icon="fa-duotone fa-right-left" size="1x"/>}
                                    tooltipTitle={"Move Selected Licenses to Another Group"} disabled={!enableButtons}
                                    tooltipPlacement={"top"}
                                    onClick={() => {
                                        let zenGroupIdSet = new Set();
                                        let permissionCheckResult = true
                                        if(gridApi && gridApi.getSelectedNodes().length > 0){
                                            gridApi.getSelectedNodes().forEach(rowNode => {
                                                if(rowNode.data.zenGroupId && !zenGroupIdSet.has(rowNode.data.zenGroupId)){
                                                    zenGroupIdSet.add(rowNode.data.zenGroupId)
                                                    if (!checkPermission(rowNode.data.zenGroupId, "agentManager", "changeAgentGroup")) {
                                                        permissionCheckResult = false;
                                                        zenGroupIdsWithoutPermission.add(rowNode.data.zenGroupId)
                                                        zenGroupNamesWithoutPermission.add(rowNode.data.zenGroupDisplayName)
                                                        setZenGroupNamesWithoutPermission(zenGroupNamesWithoutPermission)
                                                        setZenGroupIdsWithoutPermission(zenGroupIdsWithoutPermission)
                                                    } else {
                                                        zenGroupNamesWithPermission.add(rowNode.data.zenGroupDisplayName)
                                                        setZenGroupNamesWithPermission(zenGroupNamesWithPermission)
                                                    }
                                                }
                                            })
                                            setShowPermissionGrid(!permissionCheckResult)
                                            setShowChangeSelectedLicenseGroupsModal(true)
                                        }
                                    }}/>
                            </div>
                        </div>
                        <div className={"flex flex-row flex-wrap gap-y-3 gap-x-8 self-end justify-end"}>
                            <ClickToShowColumnOptionsWithToggleButtonGroup
                                columnMode={columnMode} setColumnMode={setColumnMode} gridColumnStateSessionVariableName={gridColumnStateSessionVariableName} gridApi={gridApi}
                                minColumnIds={minColumnIds} medColumnIds={medColumnIds} updateGridColumnModeFunction={updateLicensesGridColumnModeReactive} />
                            <ClearRefresh gridApi = {gridApi} showRefreshIcon={false}
                                          refreshGridFunction = {refreshGrid} showExcelExportIcon={true} sseDataPullActive={sseDataPullActive}
                                          excelExportFunction={excelExport}/>
                        </div>
                    </div>
                    <div className="h-full flex flex-col gap-y-5" id="gridRoot">
                        {getGrid()}
                        <Footer />
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, 'licensesGridFilterState', updateLicensesGridUseFilterStateReactive);
    }
    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, gridColumnStateSessionVariableName, updateLicensesGridUseColumnStateReactive);
    }

    function changeSelectedLicensesGroup() {
        if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length > 0 && newLicensesZenGroup) {
            let licenseIdsList = []; //store license ids of selected licenses
            let newZenGroup = findZenGroupById(newLicensesZenGroup)
            gridApi.getSelectedNodes().forEach(selectedNode => {
                if (selectedNode.data.licenseId) {
                    if(!zenGroupIdsWithoutPermission.has(selectedNode.data.zenGroupId)){
                        licenseIdsList.push(selectedNode.data.licenseId);
                        changeLicenseGroupReactive(newLicensesZenGroup, selectedNode.data.licenseId)
                            .then(() => {
                                selectedNode.setDataValue("zenGroupId", newLicensesZenGroup)
                                if(newZenGroup && newZenGroup.friendlyName){
                                    selectedNode.setDataValue("zenGroupDisplayName", newZenGroup.friendlyName)
                                }
                            })
                            .catch((error) => {
                                //console.log("Unexpected error making this request")
                            })
                    }
                }
            })
            if(licenseIdsList.length === 0){
                NotificationManager.error(`You don't have permission to change any selected license's group`);
                return
            }

            NotificationManager.success(`Successfully updated the selected ${licenseIdsList.length < 2 ? "license's" : "licenses'"} group that you have permission to do so in.`);
            setShowChangeSelectedLicenseGroupsModal(false)
            setNewLicensesZenGroup(null)
            setZenGroupNamesWithPermission(new Set())
            setZenGroupNamesWithoutPermission(new Set())
            setZenGroupIdsWithoutPermission(new Set())
        } else {
            console.log("changeSelectedLicensesGroup called with no selected row(s)");
            NotificationManager.error("Please select a license to change groups");
        }
    }

    function getGrid(){
        return (
            <Grid
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                sideBar={sideBar}
                rowSelection={rowSelection}
                setGridApi={setGridApi}
                setEnableButtons={setEnableButtons}
                licenseLocation={licenseLocation}
                sseDataPullActive={sseDataPullActive}
                setSSEDataPullActive={setSSEDataPullActive}
                asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                excelExport={excelExport}
                columnMode={columnMode}
                setColumnMode={setColumnMode}
                filterOutExpiredLicensesToggled={filterOutExpiredLicensesToggled}
            />
        );
    }

    function excelExport(){
        standardExcelExportHelper(gridApi, sseDataPullActive, "licensesGridExport")
    }

    //This should mostly be in the renderer
    async function refreshGrid(){
        //We are going to have change streams in the future, and with high quantities of data now in the grid, this code slows down the grid and becomes laggy, so disabling this for now by
        // just having a return line to stop the code from running
        return
    }

}

 let saveFilterChanges = true //used for if user clicks link to come to licenses page and we auto filter grid to show that specific license, in that case we don't want to save
                                // the filters because that would mess with their previous filters they still may want.
class Grid extends Component {
    rowData = []
    agentNamesListSessionStorage = JSON.parse(decryptAndGetSessionVariable("agentNamesList"))
    updateTransactionsToApply = []
    abortController = new AbortController()

    constructor(props, setEnableButtons, onClickRow, filterVals) {
        super(props);
    }
    onFirstDataRendered = (params) => {
        params.api.getColumnFilterInstance("zenGroupDisplayName").then(columnFilterInstance => {
            columnFilterInstance.refreshFilterValues()
        }).catch(function (error) {})
    };
    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        if(params.source !== "api" && params.source !== "gridOptionsChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateLicensesGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateLicensesGridColumnModeReactive, customColumnModeText)
        }
        else if(params.source === "api" && params.type === "sortChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateLicensesGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateLicensesGridColumnModeReactive, customColumnModeText)
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        this.abortController.abort()
    }

    populateGrid = (rowData) => {
        if(!this.agentNamesListSessionStorage){
            this.agentNamesListSessionStorage = JSON.parse(decryptAndGetSessionVariable("agentNamesList"))
        }
        this.checkForAgentNameInSessionStorage(rowData)
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    checkForAgentNameInSessionStorage = (rowData) => {
        if(rowData.agentId && this.agentNamesListSessionStorage){
            let agentFound = null
            for(let i = 0; i < this.agentNamesListSessionStorage.length; i++){
                if(this.agentNamesListSessionStorage[i].agentId === rowData.agentId){
                    agentFound = this.agentNamesListSessionStorage[i]
                }
            }
            if(agentFound){
                //need to implement some checks to make sure spinner stops loading in some rare cases
                if(agentFound.name){
                    rowData.agentDisplayName = agentFound.name
                }
                else{
                    rowData.agentDisplayName = " "
                }
                //machineName
                if(agentFound.machine){
                    rowData.machineName = agentFound.machine
                }
                else{
                    rowData.machineName = " "
                }
                if(agentFound.validate){
                    rowData.lastValidateDateTime = agentFound.validate
                }
                else{
                    rowData.lastValidateDateTime = " "
                }
            }
        }
    }

    updateGridForChangeStream = async (changeStreamData) => {
        let operationType = changeStreamData.operationType
        let objectBody = changeStreamData.body
        objectBody["licenseId"] = objectBody["id"]
        objectBody["licenseName"] = objectBody["userSetFriendlyName"] ? objectBody["userSetFriendlyName"] : objectBody["friendlyName"]
        if(operationType === "UPDATE" || operationType === "REPLACE"){
            //gridApi.getRowNode throws an error if the grid was destroyed (user goes to another page), so add in the destroyCalled check
            if(!this.gridApi.destroyCalled){
                let rowNode = this.gridApi.getRowNode(objectBody["licenseId"])
                if(rowNode && rowNode.data){
                    let rowNodeData = rowNode.data
                    //check if objectBody from change stream has agentId, if it does then see if licenseIds changed with the update that came in, if not then don't update objectBody with a value
                    if(rowNodeData.agentId && rowNodeData.agentId === objectBody.agentId){
                        if(rowNodeData.agentDisplayName === rowNodeData.agentId){
                            //if agentDisplayName is the same as agentId (set in agentNameGridHelper value getter), don't set the agentDisplayName  or else the cell value will be stuck as the agentId
                            /*console.log("agentDisplayName was the same as agentId")
                            console.log(rowNodeData)*/
                        }
                        else{
                            objectBody["agentDisplayName"] = rowNodeData.agentDisplayName
                            objectBody["machineName"] = rowNodeData.machineName
                            objectBody["lastValidateDateTime"] = rowNodeData.lastValidateDateTime
                        }
                    }
                }
                else{
                    //else license row now was not found in grid yet, need to prep the agent name column before applying transaction
                    this.checkForAgentNameInSessionStorage(objectBody)
                }
                standardHandleUpdateAndReplaceEvent(objectBody, this.gridApi, this.props.sseDataPullActive, this.updateTransactionsToApply)
            }
        }
        else if (operationType === "INSERT"){
            standardHandleInsertEvent(objectBody, this.gridApi, this.props.sseDataPullActive)
        }
    }

    getRowId = (params) => {
        return params.data.licenseId
    }

    getContextMenuItems = (params) => {
        let excelExport = this.props.excelExport //don't have access to this.props below in the action function so define it here
        return [
            standardExcelExportObjectInContextMenu(excelExport),
            "resetColumns",
            "autoSizeAll"
        ];
    };

    onGridReady = async (params) => {
        this.gridApi = params.api;
        this.props.setGridApi(params.api);

        // Disable text selection on the page while holding shift or control (to allow grid selections to be done easily without selecting all text)
        ["keyup","keydown"].forEach((event) => {
            window.addEventListener(event, (e) => {
                document.onselectstart = function() {
                    return !(e.shiftKey || e.ctrlKey);
                }
            });
        });
        let filterOutExpiredLicensesToggled = this.props.filterOutExpiredLicensesToggled

        let columnMode = this.props.columnMode
        //check which initial column mode to apply
        if(columnMode === customColumnModeText){
            onGridReadyHelperForColumnState(params, gridColumnStateSessionVariableName)
        }
        else if(columnMode === minColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, params.api, this.props.setColumnMode, minColumnModeText, minColumnIds, updateLicensesGridColumnModeReactive)
        }
        else if(columnMode === mediumColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, params.api, this.props.setColumnMode, mediumColumnModeText, medColumnIds, updateLicensesGridColumnModeReactive)
        }
        //else if columnMode is max then the default column state already shows the max amount of columns no need to update


        if(this.props.licenseLocation && this.props.licenseLocation.state && this.props.licenseLocation.state.licenseDisplayNameClicked){
            let locationFilterModel = {"licenseName": {filterType: "text", type: "equals", filter: this.props.licenseLocation.state.licenseDisplayNameClicked}}
            //we should have the agentDisplayNameClicked passed to us, but double-checking it is present
            if(this.props.licenseLocation.state.agentDisplayNameClicked) {
                locationFilterModel["agentDisplayName"] = {
                    filterType: "text",
                    type: "equals",
                    filter: this.props.licenseLocation.state.agentDisplayNameClicked
                }
            }

            //we don't want to save filter changes for the user if we are coming from a page where they clicked the license link
            saveFilterChanges = false
            params.api.setFilterModel(locationFilterModel)
            //scroll to top of page or else it is very likely the user will be at the bottom of the grid and see no data (since they should only see one row) when being redirected
            window.scroll({behavior: "smooth", top: 0, left: 0})
            //remove this state or else when the user refreshes the page or clicks the back tab then the forward tab, they will keep seeing this licenseLocation filter present.
            // Although if they have the use saved filters toggled, this licenseLocation filter will be updated for their saved grid filter
            //window.history.replaceState(this.props.licenseLocation.state, '')
        }
        else{
            saveFilterChanges = true
            let defaultFilterObject = null
            if(filterOutExpiredLicensesToggled){
                /*
                    If filterOutExpiredLicensesToggled is true, then we want to add to default filter for non-expired licenses.
                    If they have a filter saved beforehand for Renews column, it will be overwritten for non-expired filter
                 */
                //Get current date
                let startTimeFilterString = getCurrentDateAgGridFilterString()

                defaultFilterObject = {
                    "expirationDate": {
                        "dateFrom": startTimeFilterString,
                        "dateTo": null,
                        "filterType": "date",
                        "type": "greaterThan"
                    }
                }
            }
            onGridReadyHelper(params, "licensesGridFilterState", defaultFilterObject);
        }

        let agentNamesList = JSON.parse(decryptAndGetSessionVariable("agentNamesList"))
        if(!agentNamesList){
            //if this is null then setInterval to check for it in session
            const interval = setInterval(() => {
                agentNamesList = JSON.parse(decryptAndGetSessionVariable("agentNamesList"))
                if(agentNamesList){
                    params.api && params.api.onSortChanged()
                    params.api && params.api.onFilterChanged()
                    clearInterval(interval)
                }
            }, 2000);
            this.interval = interval
        }
        //await getLicensesReactiveSSE(this.populateGrid)
        await loadDataWithSSEAndStartChangeStreamListener("/sse/licenseListReactive", "/sse/listenToLicenseEvent",
            this.populateGrid, this.updateGridForChangeStream, params, this.props.setSSEDataPullActive, this.props.setAsyncTransactionWaitMillis, this.updateTransactionsToApply,
            this.abortController)
        //params.api.sizeColumnsToFit()
    };

    render() {
        return (
            <div className={"w-full h-full"} style={{minHeight: "400px"}}>
                <div id="licenseGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                    <AgGridReact
                        columnMenu={"legacy"}
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, ExcelExportModule]}
                        defaultColDef={this.props.defaultColDef}
                        columnDefs={this.props.columnDefs}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor, customMuiAutocompleteGroupCellEditor: CustomMuiAutocompleteGroupCellEditor}}
                        multiSortKey={"ctrl"}
                        rowData={this.rowData}
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        suppressModelUpdateAfterUpdateTransaction={true}
                        getRowId={this.getRowId}
                        onGridReady={this.onGridReady}
                        onCellEditingStopped={licensePageCellEditingStopped}
                        valueCache={true}
                        maintainColumnOrder={true} //fixes issue where if you re-order/move column then click anywhere on the grid it reverts this change
                        rowSelection={this.props.rowSelection}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            if(selectedRows && selectedRows.length > 0){
                                //checks if the setEnableButtons method is null or not
                                this.props.setEnableButtons && this.props.setEnableButtons(true);
                            }
                            else{
                                this.props.setEnableButtons && this.props.setEnableButtons(false);
                            }
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        onFilterChanged={(params)=> {
                            if(saveFilterChanges){
                                onFilterChangedHelper(params, 'licensesGridFilterState', updateLicensesGridFilterModelReactive);
                            }
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                        getContextMenuItems={this.getContextMenuItems}
                        sideBar={this.props.sideBar}
                    />
                </div>
            </div>
        );
    }
}

function getCurrentDateAgGridFilterString(){
    let timezone = decryptAndGetSessionVariable("timezone")
    if(timezone === null || timezone === undefined){
        timezone = "Europe/London" //default to london gmt time if timezone is null
    }
    let currentDate = new Date(new Date().toLocaleString('en-US', {timeZone: timezone}))
    let startYear = currentDate.getFullYear()
    let startMonth = currentDate.getMonth() + 1 //month starts at 0
    let startDay = currentDate.getDate()
    let startDateString = `${startYear}-${startMonth}-${startDay}`
    let startHour = currentDate.getHours()
    let startMins = currentDate.getMinutes()
    let startSeconds = currentDate.getSeconds()
    let startTimeString = `${startHour}:${startMins}:${startSeconds}`

    return `${startDateString} ${startTimeString}`
}









