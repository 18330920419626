import React, {useEffect, useState} from "react";
import {
    getDistributionGroups,
    getZenGroupSessionStorageOrDefault,
    useZenGroupSessionStorage
} from "../utils/zenGroupSessionStorageManager";
import {decryptAndGetSessionVariable} from "../utils/storageHelper";
import Modal from "react-modal";
import {
    MuiAutocompleteForZenGroupsWithoutCreateGroupOption,
    MuiAutocompleteNonGroupOptions,
    MuiCloseIconButton,
    MuiIconWithTooltip
} from "./muiComponents";
import {Button, FormControlLabel, Switch, ThemeProvider} from "@mui/material";
import {buttonTheme, switchTheme} from "../utils/muiStyling";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {useForm} from "react-hook-form";
import {
    dmzModeTooltipText, getAgentInactiveDaysForCell, getPasswordExpirationToDisplayInCell,
    inactiveAgentDays30Text, inactiveAgentDays60Text, inactiveAgentDays90Text,
    inactiveAgentDaysOffText,
    inactiveAgentDaysOptions,
    passwordPolicyFilterAndEditableOptionsMap,
    passwordPolicyLabelList, sslPinningModeOptions,
    sslPinningModeOptionsLabelList
} from "../pages/private/groups";
import {bulkEditGroupSettingsReactive, createNewGroupReactive} from "../pages/api/groupsApi";
import SortedObjectArray from "sorted-object-array";
import {getZenGroupFriendlyNamesReactive} from "../pages/api/agentsApi";

export const CreateGroupModal=({isOpen=false, setIsOpen, shouldCloseOnOverlayClick=false, shouldCloseOnEsc=false, setIsLoading, setGroupListUnformattedHook,
                                                               defaultNewGroupName=null, resetGrid, setSelectedGroup, creatingNewGroup=true, activeGroup,
                                                               setZenGroupIdToUpdateDistributionGroup, setDistributionGroupsForReassignModal, setGroupsToReassign, setShowReassignGroupsModal}) => {
    const { register, watch, reset } = useForm();
    const [distributionGroupChecked, setDistributionGroupChecked] = useState(true);
    const [showChannelOptions, setShowChannelOptions] = useState(false);
    const [internalPartnerUse, setInternalPartnerUse] = useState(true);
    const [newGroupCollectAgentTelemetryChecked, setNewGroupCollectAgentTelemetryChecked] = useState(true);
    const [runAgentsInSafeMode, setRunAgentsInSafeMode] = useState(false);
    const [hideAgentsAutomatically, setHideAgentsAutomatically] = useState(false);
    const [dmzModeEnabled, setDmzModeEnabled] = useState(false);
    const [enableSystemTray, setEnableSystemTray] = useState(false);
    const [autoAssignLicensesChecked, setAutoAssignLicensesChecked] = useState(false);
    const [distributionGroups, setDistributionGroups] = useState(getDistributionGroups(true));
    const [distributionGroupSelected, setDistributionGroupSelected] = useState();
    const [copyFromGroupToggled, setCopyFromGroupToggled] = useState(false);
    const [zenGroupSessionStorage,setZenGroupSessionStorage] = useZenGroupSessionStorage()
    const [copyFromGroupSelected, setCopyFromGroupSelected] = useState();
    const [copyWhitelistsFromGroupToggled, setCopyWhitelistsFromGroupToggled] = useState(false);
    const [copyWhitelistsFromGroupSelected, setCopyWhitelistsFromGroupSelected] = useState();
    const [newGroupAutoUpdateToggled, setNewGroupAutoUpdateToggled] = useState(true);
    const [distinctAgentVersionsList, setDistinctAgentVersionsList] = useState(JSON.parse(decryptAndGetSessionVariable("distinctAgentVersionsList")));
    const [newGroupLatestApprovedAgentVersionSelected, setNewGroupLatestApprovedAgentVersionSelected] = useState(null);
    const [whcpToggleChecked, setWhcpToggleChecked] = useState(true);
    const [newGroupSSLPinningModeSelected, setNewGroupSSLPinningModeSelected] = useState("Off"); //Off by default
    const [passwordExpirationPolicyForNewGroup, setPasswordExpirationPolicyForNewGroup] = useState("Never"); //Never by default
    const [agentInactiveDaysSettingForNewGroup, setAgentInactiveDaysSettingForNewGroup] = useState(inactiveAgentDaysOffText); //Off by default
    const whcpWikipediaLink =<p> <a href ="https://en.wikipedia.org/wiki/WHQL_Testing" rel="noreferrer" target="_blank">WHCP Wikipedia Page</a></p>
    const whcpMicrosoftLink = <p> <a href ="https://docs.microsoft.com/en-us/windows-hardware/drivers/install/whql-release-signature" rel="noreferrer" target="_blank">WHCP Microsoft Page</a></p>
    const newGroup = watch("newGroup");

    //Check if creating new group or editing group
    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            if(isOpen){
                setDistributionGroups(getDistributionGroups(true)) //reset distr group options
                if(!creatingNewGroup){
                    //editing group, populate group settings
                    reset({
                        newGroup: activeGroup?.friendlyName,
                    })
                    setDistributionGroupChecked(activeGroup?.distributionGroup)
                    setInternalPartnerUse(activeGroup?.channelPartnerCustomer)
                    setNewGroupCollectAgentTelemetryChecked(activeGroup?.collectAgentTelemetry)
                    if(activeGroup?.assignedDistributionGroupId !== null && activeGroup?.assignedDistributionGroupId !== undefined){
                        setAutoAssignLicensesChecked(true)
                        setDistributionGroupSelected(activeGroup?.assignedDistributionGroupId)
                    }
                    setNewGroupAutoUpdateToggled(activeGroup?.autoUpdate)
                    setNewGroupLatestApprovedAgentVersionSelected(activeGroup?.latestAgentVersionApproved)
                    setWhcpToggleChecked(activeGroup?.whcpOnly)
                    //have to format runAgentsInSafeMode for modal
                    if(activeGroup?.runAgentsInSafeMode === "RUN"){
                        setRunAgentsInSafeMode(true)
                    }
                    else{
                        setRunAgentsInSafeMode(false)
                    }
                    setHideAgentsAutomatically(activeGroup?.hideAgentsAutomatically)
                    setDmzModeEnabled(activeGroup?.dmzMode)
                    setEnableSystemTray(activeGroup?.enableSystemTray)
                    //Format the group sslPinningMode value for the modal options
                    let sslPinningModeForModal = "Off" //off by default
                    sslPinningModeOptionsLabelList.forEach(option => {
                        if(option.toLowerCase().trim() === activeGroup?.sslPinningMode?.toLowerCase()?.trim()){
                            sslPinningModeForModal = option
                        }
                    })
                    setNewGroupSSLPinningModeSelected(sslPinningModeForModal)
                    setPasswordExpirationPolicyForNewGroup(getPasswordExpirationToDisplayInCell(activeGroup?.passwordExpirationPolicyInDays))
                    setAgentInactiveDaysSettingForNewGroup(getAgentInactiveDaysForCell(activeGroup?.agentInactiveDaysSetting))
                    /*
                        User might not be a member of the assigned distribution group, we would need to call to get the group name rather
                        than show blank in the select since the options for assigned distr. group would not have the assigned ditsr. group name if
                        user is not a member of it
                     */
                    if(activeGroup && activeGroup.assignedDistributionGroupId !== null && activeGroup.assignedDistributionGroupId !== undefined){
                        let containsDistributionGroupId = false
                        if(distributionGroups){
                            try{
                                distributionGroups.forEach(zenGroup => {
                                    if(zenGroup.id === activeGroup.assignedDistributionGroupId){
                                        containsDistributionGroupId = true
                                    }
                                })
                            } catch (e) {}
                        }
                        if(!containsDistributionGroupId){
                            let idList = []
                            idList.push(activeGroup.assignedDistributionGroupId);
                            getZenGroupFriendlyNamesReactive(idList).then(function(nameObjects){
                                if(nameObjects && nameObjects.length>0) {
                                    if (nameObjects[0].name) {
                                        //Need to format the group to match getZenGroupSessionStorageOrDefault
                                        let objToAdd = {}
                                        objToAdd.friendlyName = nameObjects[0].name
                                        objToAdd.id = nameObjects[0].zenGroupId
                                        setDistributionGroups([...distributionGroups, objToAdd])
                                    }
                                }
                            }).catch(function(error){})
                        }
                    }
                }
            }
        })()
        return () => controller?.abort();
    }, [isOpen]);

    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            //If defaultNewGroupName is given then populate it by default
            if(defaultNewGroupName !== null && defaultNewGroupName !== undefined){
                reset({
                    newGroup: defaultNewGroupName,
                })
            }
        })()
        return () => controller?.abort();
    }, [defaultNewGroupName]);

    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            let isMemberOfChannelPartnerGroup = false;
            zenGroupSessionStorage.forEach(zenGroupFromSessionStorage => {
                if (zenGroupFromSessionStorage.channelPartner === true) {
                    isMemberOfChannelPartnerGroup = true;
                }
            })
            if(isMemberOfChannelPartnerGroup){
                setShowChannelOptions(true)
            }
            else{
                setShowChannelOptions(false)
            }
        })()
        return () => controller?.abort();
    }, []);

    function resetCreateGroupModalFields(){
        setIsOpen && setIsOpen(false)
        reset({
            newGroup: "",
        })
        setDistributionGroupChecked(true)
        setInternalPartnerUse(true)
        setNewGroupCollectAgentTelemetryChecked(true)
        setAutoAssignLicensesChecked(false)
        setDistributionGroupSelected()
        setCopyFromGroupToggled(false)
        setCopyWhitelistsFromGroupToggled(false)
        setNewGroupAutoUpdateToggled(true)
        setCopyFromGroupSelected()
        setNewGroupLatestApprovedAgentVersionSelected(null)
        setCopyWhitelistsFromGroupSelected()
        setWhcpToggleChecked(true)
        setNewGroupSSLPinningModeSelected("Off")
        setPasswordExpirationPolicyForNewGroup("Never")
        setAgentInactiveDaysSettingForNewGroup(inactiveAgentDaysOffText)
        setRunAgentsInSafeMode(false)
        setHideAgentsAutomatically(false)
        setDmzModeEnabled(false)
        setEnableSystemTray(false)
        setDistributionGroups(getDistributionGroups(true)) //reset distr group options
    }

    function updateNewGroupInSession(newGroup){
        //Add new group to session variable
        let zenGroupSortedObjectArray = new SortedObjectArray("friendlyName");
        let zenGroupBareObjects = []
        //let newDropdownContents = []
        let newDistributionGroups = []
        zenGroupSessionStorage.forEach(group => {
            zenGroupSortedObjectArray.insert({"friendlyName": group.friendlyName?.toLowerCase(), zenGroup: group})
        })
        zenGroupSortedObjectArray.insert({"friendlyName": newGroup.friendlyName?.toLowerCase(), zenGroup: newGroup})
        zenGroupSortedObjectArray.array.forEach(sortedObject => {
            zenGroupBareObjects.push(sortedObject.zenGroup)
            //newDropdownContents.push({value: sortedObject.zenGroup.id, label: sortedObject.zenGroup.friendlyName || sortedObject.zenGroup.id})
            if(sortedObject.zenGroup.distributionGroup){
                newDistributionGroups.push(sortedObject.zenGroup)
            }
        });
        setZenGroupSessionStorage(zenGroupBareObjects)
        //if setGroupListUnformattedHook is provided, add new group to list and also sort accounting for new group name
        setGroupListUnformattedHook && setGroupListUnformattedHook(current => {
            const copyOfCurrent = [...current]
            copyOfCurrent.push(newGroup)
            copyOfCurrent.sort((object1, object2) => (object1.friendlyName?.toLowerCase() > object2.friendlyName?.toLowerCase()) ? 1 : -1)
            return copyOfCurrent
        })
        setSelectedGroup && setSelectedGroup(newGroup.id)
        setDistributionGroups(newDistributionGroups)
    }

    const onCreateGroup = async () => {
        if(creatingNewGroup){
            //creating new group
            if (newGroup) {
                if(newGroup.trim().length > 0 && newGroupSSLPinningModeSelected){
                    let assignedDistributionGroupToSend = null
                    if(autoAssignLicensesChecked){
                        if(!distributionGroupSelected){
                            NotificationManager.info("Please select a distribution group before continuing.");
                            return;
                        }
                        assignedDistributionGroupToSend = distributionGroupSelected
                    }
                    if(copyFromGroupToggled && !copyFromGroupSelected){
                        NotificationManager.info("Please select a group to add all users and permissions from before continuing.");
                        return;
                    }
                    if(copyWhitelistsFromGroupToggled && !copyWhitelistsFromGroupSelected){
                        NotificationManager.info("Please select a group to add all tailored behaviors from before continuing.");
                        return;
                    }
                    if(!newGroupAutoUpdateToggled && !newGroupLatestApprovedAgentVersionSelected){
                        NotificationManager.info("Please select the latest agent version approved for agents in this group to update to automatically.");
                        return;
                    }
                    let latestApprovedAgentVersionToSend = null
                    if(!newGroupAutoUpdateToggled){
                        latestApprovedAgentVersionToSend = newGroupLatestApprovedAgentVersionSelected
                    }
                    if(!passwordExpirationPolicyForNewGroup){
                        NotificationManager.info("Please select the password expiration policy for users in this group.");
                        return;
                    }
                    let valueToSend = passwordPolicyFilterAndEditableOptionsMap.find(({value, label}) => label === passwordExpirationPolicyForNewGroup)
                    let sslPinningModeValueToSend = sslPinningModeOptions.find(({value, label}) => label === newGroupSSLPinningModeSelected)

                    if(!agentInactiveDaysSettingForNewGroup){
                        NotificationManager.info("Please select the automatically release inactive agent licenses policy for this group");
                        return;
                    }
                    let agentInactiveDaysToSend = "Off"
                    if(agentInactiveDaysSettingForNewGroup === inactiveAgentDays30Text){agentInactiveDaysToSend = "Thirty"}
                    else if(agentInactiveDaysSettingForNewGroup === inactiveAgentDays60Text){agentInactiveDaysToSend = "Sixty"}
                    else if(agentInactiveDaysSettingForNewGroup === inactiveAgentDays90Text){agentInactiveDaysToSend = "Ninety"}

                    setIsLoading && setIsLoading(true)
                    try {
                        let response = await createNewGroupReactive(newGroup.trim(), internalPartnerUse, distributionGroupChecked, assignedDistributionGroupToSend, copyFromGroupToggled ? copyFromGroupSelected : null,
                            copyWhitelistsFromGroupToggled ? copyWhitelistsFromGroupSelected : null, newGroupCollectAgentTelemetryChecked,whcpToggleChecked, sslPinningModeValueToSend.value, latestApprovedAgentVersionToSend,
                            valueToSend.value, agentInactiveDaysToSend, runAgentsInSafeMode, hideAgentsAutomatically, dmzModeEnabled, enableSystemTray)
                        //update in session
                        updateNewGroupInSession(response.data)
                        NotificationManager.success("Group created successfully!");
                        resetGrid && resetGrid()
                        resetCreateGroupModalFields()
                    } catch (error) {
                        if(error.message){
                            NotificationManager.error(error.message);
                        }
                        else{
                            NotificationManager.error("Error creating this group, please try again.");
                        }
                    }
                }
            }
            setIsLoading && setIsLoading(false);
        }
        else{
            //update existing group
            if(newGroup && newGroup.trim().length > 0 && newGroupSSLPinningModeSelected && activeGroup && activeGroup.zenGroupId){
                let assignedDistributionGroupToSend = null
                if(autoAssignLicensesChecked){
                    if(!distributionGroupSelected){
                        NotificationManager.info("Please select a distribution group before continuing.");
                        return;
                    }
                    assignedDistributionGroupToSend = distributionGroupSelected
                }
                if(!newGroupAutoUpdateToggled && !newGroupLatestApprovedAgentVersionSelected){
                    NotificationManager.info("Please select the latest agent version approved for agents in this group to update to automatically.");
                    return;
                }
                let latestApprovedAgentVersionToSend = null
                if(!newGroupAutoUpdateToggled){
                    latestApprovedAgentVersionToSend = newGroupLatestApprovedAgentVersionSelected
                }
                if(!passwordExpirationPolicyForNewGroup){
                    NotificationManager.info("Please select the password expiration policy for users in this group.");
                    return;
                }
                let valueToSend = passwordPolicyFilterAndEditableOptionsMap.find(({value, label}) => label === passwordExpirationPolicyForNewGroup)
                let sslPinningModeValueToSend = sslPinningModeOptions.find(({value, label}) => label === newGroupSSLPinningModeSelected)

                if(!agentInactiveDaysSettingForNewGroup){
                    NotificationManager.info("Please select the automatically release inactive agent licenses policy for this group");
                    return;
                }
                let agentInactiveDaysToSend = "Off"
                if(agentInactiveDaysSettingForNewGroup === inactiveAgentDays30Text){agentInactiveDaysToSend = "Thirty"}
                else if(agentInactiveDaysSettingForNewGroup === inactiveAgentDays60Text){agentInactiveDaysToSend = "Sixty"}
                else if(agentInactiveDaysSettingForNewGroup === inactiveAgentDays90Text){agentInactiveDaysToSend = "Ninety"}

                setIsLoading && setIsLoading(true)
                try{
                    let response = await bulkEditGroupSettingsReactive(activeGroup.zenGroupId, newGroup.trim(), internalPartnerUse, distributionGroupChecked, assignedDistributionGroupToSend, null,
                        null, newGroupCollectAgentTelemetryChecked,whcpToggleChecked, sslPinningModeValueToSend.value, latestApprovedAgentVersionToSend,
                        valueToSend.value, agentInactiveDaysToSend, runAgentsInSafeMode, hideAgentsAutomatically, dmzModeEnabled, enableSystemTray)
                    if(!response.needToReassignGroups) {
                        NotificationManager.success("Group updated successfully!");
                        resetGrid && resetGrid()
                        resetCreateGroupModalFields()
                    }
                    else{
                        //Else the group switched from a distribution to not one and there are groups that are still assigned to this group as their distribution group that need re-assigned
                        if (response.zenGroupsThatNeedReassigned) {
                            let distributionGroupsLocal = getDistributionGroups(true)
                            if (distributionGroupsLocal) {
                                setZenGroupIdToUpdateDistributionGroup && setZenGroupIdToUpdateDistributionGroup(activeGroup.zenGroupId)
                                //good to go, we need to remove the value.data.zenGroupId group from the distributionGroups list
                                let distributionGroupsList = []
                                distributionGroupsLocal.forEach(e => {
                                    if(e.id && e.id !== activeGroup.zenGroupId){
                                        distributionGroupsList.push(e)
                                    }
                                })
                                let noGroupOption = {"id":"none", "friendlyName":"Do Not Reassign a Value"}
                                distributionGroupsList.push(noGroupOption)
                                setDistributionGroupsForReassignModal && setDistributionGroupsForReassignModal(distributionGroupsList)
                                setGroupsToReassign && setGroupsToReassign(response.zenGroupsThatNeedReassigned)
                                setShowReassignGroupsModal && setShowReassignGroupsModal(true)
                            } else {
                                NotificationManager.error("Unexpected error updating group");
                            }
                        } else {
                            NotificationManager.error("Unexpected error updating group");
                        }
                    }
                } catch (error) {
                    if(error.message){
                        NotificationManager.error(error.message);
                    }
                    else{
                        NotificationManager.error("Error updating this group, please try again.");
                    }
                }
                setIsLoading && setIsLoading(false)
            }

        }
    };


    return (
        <Modal contentLabel="Create New Group Modal"
               isOpen={isOpen}
               onRequestClose={() => {
                   resetCreateGroupModalFields()
               }}
               shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
               shouldCloseOnEsc={shouldCloseOnEsc}
               className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-3xl max-w-3xl inset-y-10 mx-auto rounded-2xl`}
               overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
        >
            <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-3">
                {/*Title with exit button*/}
                <div className="flex flex-row justify-between">
                    <h1 className="font-bold text-3xl">{creatingNewGroup ? "Create New Group" : "Edit Group Information"}</h1>
                    <MuiCloseIconButton
                        onClick={() => {
                            resetCreateGroupModalFields()
                        }}
                    />
                </div>
                <hr className="mt-3 h-0.5" />
                {/*Form content*/}
                <div className="flex flex-1 flex-col gap-y-3">
                    <label>Group Name</label>
                    <input
                        type="text"
                        name="newGroup"
                        {...register("newGroup")}
                        className="focus:outline-none h-10 p-2 w-full rounded-lg border border-black border-opacity-25 border-solid"
                        placeholder={'New Group Name'}
                    />
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme={switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={distributionGroupChecked}
                                    name="toggle3"
                                    onChange={e => setDistributionGroupChecked(e.target.checked)}
                                />
                            } label={distributionGroupChecked ? "Distribution Group" : "No Distribution"}/>
                        </ThemeProvider>
                    </div>
                    <div className={`${showChannelOptions ? 'block' : 'hidden'} flex flex-row items-center`}>
                        <ThemeProvider theme={switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={internalPartnerUse}
                                    name="toggle4"
                                    onChange={e => setInternalPartnerUse(e.target.checked)}
                                />
                            } label={internalPartnerUse ? "Internal Partner Use" : "Customer Use"}/>
                        </ThemeProvider>
                    </div>
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme={switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={newGroupCollectAgentTelemetryChecked}
                                    name="agentTelemetryToggleNewGroup"
                                    onChange={e => setNewGroupCollectAgentTelemetryChecked(e.target.checked)}
                                />
                            }
                                              label={newGroupCollectAgentTelemetryChecked ? "Collect Telemetry For Agents in This Group" : "Do Not Collect Telemetry For Agents in This Group"}/>
                        </ThemeProvider>
                    </div>
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme={switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={autoAssignLicensesChecked}
                                    name="toggle5"
                                    onChange={e => setAutoAssignLicensesChecked(e.target.checked)}
                                />
                            }
                                              label={autoAssignLicensesChecked ? "Auto-Allocate Licenses" : "Do Not Auto-Allocate Licenses"}/>
                        </ThemeProvider>
                    </div>
                    <div className={`${autoAssignLicensesChecked ? 'block' : 'hidden'}`}>
                        <label>Select distribution group to allocate from</label>
                        <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                            zenGroupDropdownOptionsList={distributionGroups.map(({id, friendlyName}) => ({
                                    value: id,
                                    label: friendlyName || id,
                                })
                            )}
                            value={distributionGroupSelected}
                            onChange={(event, value) => {
                                setDistributionGroupSelected(value?.value)
                            }}
                        />
                    </div>
                    <div className={`flex flex-row items-center ${creatingNewGroup ? "block" : "hidden"}`}>
                        <ThemeProvider theme={switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={copyFromGroupToggled}
                                    name="toggleCopyFromGroup"
                                    onChange={e => setCopyFromGroupToggled(e.target.checked)}
                                />
                            }
                                              label={copyFromGroupToggled ? "Add All Users and Permissions from Another Group" : "Do Not Add All Users and Permissions from Another Group"}/>
                        </ThemeProvider>
                    </div>
                    <div className={`${copyFromGroupToggled ? 'block' : 'hidden'}`}>
                        <label>Select which group to add users and permissions from</label>
                        <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                            zenGroupDropdownOptionsList={zenGroupSessionStorage.map(({id, friendlyName}) => ({
                                value: id,
                                label: friendlyName || id,
                            }))}
                            value={copyFromGroupSelected}
                            onChange={(event, value) => {
                                setCopyFromGroupSelected(value?.value)
                            }}
                        />
                    </div>
                    <div className={`flex flex-row items-center ${creatingNewGroup ? "block" : "hidden"}`}>
                        <ThemeProvider theme={switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={copyWhitelistsFromGroupToggled}
                                    name="toggleCopyWhitelistsFromGroup"
                                    onChange={e => setCopyWhitelistsFromGroupToggled(e.target.checked)}
                                />
                            }
                                              label={copyWhitelistsFromGroupToggled ? "Add All Tailored Behaviors from Another Group" : "Do Not Add All Tailored Behaviors from Another Group"}/>
                        </ThemeProvider>
                    </div>
                    <div className={`${copyWhitelistsFromGroupToggled ? 'block' : 'hidden'}`}>
                        <label>Select which group to add tailored behaviors from</label>
                        <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                            zenGroupDropdownOptionsList={zenGroupSessionStorage.map(({id, friendlyName}) => ({
                                value: id,
                                label: friendlyName || id,
                            }))}
                            value={copyWhitelistsFromGroupSelected}
                            onChange={(event, value) => {
                                setCopyWhitelistsFromGroupSelected(value?.value)
                            }}
                        />
                    </div>
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme={switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={newGroupAutoUpdateToggled}
                                    name="toggleAutoUpdateAgents"
                                    onChange={e => setNewGroupAutoUpdateToggled(e.target.checked)}
                                />
                            }
                                              label={newGroupAutoUpdateToggled ? "Always Update Agents in Group to Newest Version" : "Do Not Update Agents in Group to Newest Version Automatically"}/>
                        </ThemeProvider>
                    </div>
                    <div className={`${newGroupAutoUpdateToggled ? 'hidden' : 'block'}`}>
                        <label>Select the latest agent version approved for agents in this group to update to
                            automatically</label>
                        <MuiAutocompleteNonGroupOptions
                            options={distinctAgentVersionsList}
                            value={newGroupLatestApprovedAgentVersionSelected}
                            onChange={(event, value) => {
                                setNewGroupLatestApprovedAgentVersionSelected(value)
                            }}
                        />
                    </div>
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme={switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={runAgentsInSafeMode}
                                    name="runAgentsInSafeModeToggle"
                                    onChange={e => setRunAgentsInSafeMode(e.target.checked)}
                                />
                            }
                                              label={runAgentsInSafeMode ? "Run Agents In Safe Mode" : "Do Not Run Agents In Safe Mode"}/>
                        </ThemeProvider>
                    </div>
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme={switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={hideAgentsAutomatically}
                                    name="hideAgentsAutomaticallyNewGroupToggle"
                                    onChange={e => setHideAgentsAutomatically(e.target.checked)}
                                />
                            }
                                              label={hideAgentsAutomatically ? `Automatically Hide Agents` : "Do Not Automatically Hide Agents"}/>
                        </ThemeProvider>
                        <div className="mt-1">
                            <MuiIconWithTooltip
                                icon={
                                    <FontAwesomeIcon
                                        className="object-contain"
                                        icon="fa-light fa-circle-info"
                                        size="lg"
                                    />
                                }
                                tooltipTitle={"This setting is for automatically hiding agents in this group when a license is released from an agent, an agent is marked as inactive, or an agent completes an uninstall"}
                                tooltipPlacement={"bottom-start"}
                            />
                        </div>
                    </div>
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme={switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={dmzModeEnabled}
                                    name="dmzModeEnabledNewGroupToggle"
                                    onChange={e => setDmzModeEnabled(e.target.checked)}
                                />
                            }
                                              label={dmzModeEnabled ? "DMZ Mode Enabled" : "DMZ Mode Disabled"}/>
                        </ThemeProvider>
                        <div className="mt-1">
                            <MuiIconWithTooltip
                                icon={
                                    <FontAwesomeIcon
                                        className="object-contain"
                                        icon="fa-light fa-circle-info"
                                        size="lg"
                                    />
                                }
                                tooltipTitle={dmzModeTooltipText}
                                tooltipPlacement={"bottom-start"}
                            />
                        </div>
                    </div>
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme={switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={enableSystemTray}
                                    name="enableSystemTrayNewGroupToggle"
                                    onChange={e => setEnableSystemTray(e.target.checked)}
                                />
                            } label={enableSystemTray ? "Show Agent in System Tray" : "Hide Agent in System Tray"}/>
                        </ThemeProvider>
                        <div className="mt-1">
                            <MuiIconWithTooltip
                                icon={
                                    <FontAwesomeIcon
                                        className="object-contain"
                                        icon="fa-light fa-circle-info"
                                        size="lg"
                                    />
                                }
                                tooltipTitle={"This setting is for showing the agent icon in the system tray for agents in this group. Note that only agents on version 4.4.8.2 and higher have this capability"}
                                tooltipPlacement={"bottom-start"}
                            />
                        </div>
                    </div>
                    <div className={``}>
                        <div className={`flex flex-row items-center`}>
                            <label>Select SSL Pinning Mode</label>
                            <div className="ml-1 mt-2">
                                <MuiIconWithTooltip
                                    icon={
                                        <FontAwesomeIcon
                                            className="ml-1 object-contain"
                                            icon="fa-light fa-circle-info"
                                            size="lg"
                                        />
                                    }
                                    tooltipTitle={`This option is for environments with SSL proxying`}
                                    tooltipPlacement={"bottom-start"}
                                />
                            </div>
                        </div>
                        <MuiAutocompleteNonGroupOptions
                            options={sslPinningModeOptionsLabelList}
                            value={newGroupSSLPinningModeSelected}
                            onChange={(event, value) => {
                                setNewGroupSSLPinningModeSelected(value)
                            }}
                        />
                    </div>
                    <div className={``}>
                        <label>User Password Expiration Policy</label>
                        <MuiAutocompleteNonGroupOptions
                            options={passwordPolicyLabelList}
                            value={passwordExpirationPolicyForNewGroup}
                            onChange={(event, value) => {
                                setPasswordExpirationPolicyForNewGroup(value)
                            }}
                        />
                    </div>
                    <div className={``}>
                        <div className={`flex flex-row items-center`}>
                            <label>Automatically Release Inactive Agent Licenses Policy</label>
                            <div className="ml-1 mt-2">
                                <MuiIconWithTooltip
                                    icon={
                                        <FontAwesomeIcon
                                            className="ml-1 object-contain"
                                            icon="fa-light fa-circle-info"
                                            size="lg"
                                        />
                                    }
                                    tooltipTitle={`This setting will automatically release licenses from agents in this group that have not been active in the specified amount of time. To turn off this functionality, select the Off option`}
                                    tooltipPlacement={"bottom-start"}
                                />
                            </div>
                        </div>
                        <MuiAutocompleteNonGroupOptions
                            options={inactiveAgentDaysOptions}
                            value={agentInactiveDaysSettingForNewGroup}
                            onChange={(event, value) => {
                                setAgentInactiveDaysSettingForNewGroup(value)
                            }}
                        />
                    </div>
                    <ThemeProvider theme={buttonTheme}>
                        <Button variant={"contained"}
                                color={"primary"}
                                type={"submit"}
                                onClick={() => {
                                    if (newGroup) {
                                        if (newGroup.trim().length > 0) {
                                            if (newGroupSSLPinningModeSelected && newGroupSSLPinningModeSelected.trim().length > 0) {
                                                onCreateGroup()
                                            } else {
                                                NotificationManager.info("Please select the SSL Pinning Mode for new group");
                                            }
                                        } else {
                                            NotificationManager.info("Invalid Group Name");
                                        }
                                    }
                                }}>
                            {creatingNewGroup ? "Create" : "Update"}
                        </Button>
                    </ThemeProvider>
                </div>
            </div>
        </Modal>
    )
}