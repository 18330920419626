import React, {Component, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {ExcelExportModule} from "@ag-grid-enterprise/excel-export";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Modal from "react-modal";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import SidebarMenu from "../../components/sideBarComponent";
import * as EmailValidator from "email-validator";
import {
    createSalesEmailNotificationObjectReactive,
    deleteSingleSalesNotificationReactive,
    findBySalesNotificationIdListReactive,
    updateSalesNotificationNameReactive, updateSalesNotificationsGridColumnModeReactive,
    updateSalesNotificationsGridColumnStateReactive,
    updateSalesNotificationsGridFilterModelReactive,
    updateSalesNotificationsGridUseColumnStateReactive,
    updateSalesNotificationsGridUseFilterStateReactive,
    updateSalesNotificationTimeIntervalReactive,
    updateSalesNotificationTypeReactive
} from "../api/salesNotificationsApi";
import {
    defaultZenGroupColumnInitWithOptionsWithValueGetter
} from "../../utils/zenGroupDisplayNameGridHelper";
import {
    getChannelGroupsDropDownContents
} from "../../utils/zenGroupSessionStorageManager";
import {
    getColumnModeInSession, getDefaultAgGridSidebarProps,
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnState, updateColumnModeInSessionHelper,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import CustomNameCellEditor, {editNameIconOnlyCellRenderer} from "../../utils/customCellEditor";
import {handleGroupColumnChangeNameOnly} from "../../utils/gridCellEditing";

import {refreshGridZenGroupOnlyWithSetDataValue} from "../../utils/refreshGridHelper";
import {checkPermissionAndDeleteRowNodeFromGrid,} from "../../utils/clientSideDataSessionStorageHelper";
import DTPicker, {dateFilterParametersInHeaderClientSideGrid} from "../../utils/DTPicker";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import {
    loadDataWithSSEAndStartChangeStreamListener,
    standardHandleInsertEvent,
    standardHandlePopulateGrid,
    standardHandleUpdateAndReplaceEvent
} from "../../utils/sseAndChangeStreamHelper";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {dateValueFormatter} from "../../utils/gridDateFormatter";
import {standardExcelExportHelper, standardExcelExportObjectInContextMenu} from "../../utils/excelExportHelper";
import {Button, FormControlLabel, Switch, ThemeProvider} from "@mui/material";
import {switchTheme} from "../../utils/muiStyling";
import {
    ClickToShowColumnOptionsWithToggleButtonGroup,
    customColumnModeText, mediumColumnModeText,
    minColumnModeText,
    standardApplyMinimumOrMediumColumnMode
} from "../../components/clickToShowButtons";
import {
    MuiAutocompleteForZenGroupsWithoutCreateGroupOption,
    MuiAutocompleteNonGroupOptions,
    MuiIconButtonWithTooltipAndBox
} from "../../components/muiComponents";
import DeleteIcon from "@mui/icons-material/Delete";
import {defaultClientSideTextFilterParams} from "../../utils/filterHelper";

const INTERVAL_OPTIONS = [
    //{
    //  value: "300",
    //  label: "5 Minutes",
    //},
    {
        value: 900,
        label: "15 Minutes",
    },
    {
        value: 1800,
        label: "30 Minutes",
    },
    {
        value: 3600,
        label: "1 Hour",
    },
    {
        value: 43200,
        label: "12 Hours",
    },
    {
        value: 86400,
        label: "24 Hours",
    },
    {
        value: 604800,
        label: "1 Week",
    },
    {
        value: 2419200,
        label: "1 Month",
    }
];

const NOTIFICATION_TYPES = [
    //values line up with SalesEmailNotification model. Will need to update if model is changed
    {
        value: "dealExpired",
        label: "Deal Expired",
    },
    {
        value: "dealCreated",
        label: "New Partner Deal Registration (one time alert)",
    },
    {
        value: "dealDisabled",
        label: "Deal Disabled (one time alert)",
    },
    {
        value: "upcomingDealExpiration",
        label: "Upcoming Deal Expiration (one time alert)",
    },
    {
        value: "dealPOCActivity",
        label: "Deal POC Activity for Agent Installs/Uninstalls and POC Finished",
    },
    {
        value: "invoiceCreated",
        label: "Invoice Sent (one time alert)",
    }
]

//only displayed in new sales notification form, users can't select or deselect any of these options though
const UPCOMING_DEAL_EXPIRATION_OPTIONS = [
    {
        label: "14 Days",
    },
    {
        label: "7 Days",
    },
    {
        label: "3 Days",
    },
    {
        label: "1 Day",
    },
    {
        label: "Final Notice",
    }
]

let gridColumnStateSessionVariableName = "salesNotificationsGridColumnState"
let minColumnIds = ["zenGroupDisplayName", "invoiceCreated", "upcomingDealExpiration", "dealExpired", "dealDisabled", "dealCreated", "dealPOCActivity"]
let medColumnIds = ["zenGroupDisplayName", "emailAddress", "timeIntervalInSeconds", "invoiceCreated", "upcomingDealExpiration", "dealExpired", "dealDisabled", "dealCreated", "lastMessageSent", "lastOneTimeAlertMessageSent"]
export default function SalesNotifications() {
    const { register, handleSubmit, reset } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [zenGroup,setZenGroup] = useState(); // eslint-disable-line no-unused-vars
    const [newNotificationObj, setNewNotificationObj] = useState({});
    const [newNotificationObjTypesChecked, setNewNotificationObjTypesChecked] = useState([]);
    const [enableButtons, setEnableButtons] = useState(false);
    const [createNotificationModalOpen, setCreateNotificationModalOpen] = useState(false);
    const [notifyAllGroupMembersToggled, setNotifyAllGroupMembersToggled] = useState(true);
    const [gridApi, setGridApi] = useState();
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession("salesNotificationsGridFilterState"));
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    const [columnMode, setColumnMode] = useState(getColumnModeInSession(gridColumnStateSessionVariableName));
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(200); //200 to start for the initial sse data pull, will change when sse data pull is done for change streams
    // eslint-disable-next-line no-unused-vars
    const [columnDefs, setColumnDefs] = useState([
        defaultZenGroupColumnInitWithOptionsWithValueGetter(true, true, true),
        { field: "notificationName", headerName: "Notification Name",  filter: 'agTextColumnFilter', initialWidth: 400,
            filterParams: defaultClientSideTextFilterParams,
            sortable: true,
            editable: true,
            cellEditor: "customNameCellEditor",
            cellRenderer: function (params) {
                return editNameIconOnlyCellRenderer(params, "Click to Edit this Notification's Name", "notificationName")
            }
        },
        { field: "emailAddress", headerName: "Email Address to Notify", initialWidth: 300,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
        { field: "timeIntervalInSeconds", headerName: "Time Interval", initialWidth: 280,
            valueGetter: function (params){
                return convertTimeIntervalToFormattedString(params.node.data.timeIntervalInSeconds)
            },
            comparator: function(valueA, valueB, nodeA, nodeB, isDescending){
                return (nodeA.data.timeIntervalInSeconds === nodeB.data.timeIntervalInSeconds) ? 0 : (nodeA.data.timeIntervalInSeconds > nodeB.data.timeIntervalInSeconds) ? 1 : -1;
            },
            cellEditor: "agSelectCellEditor",
            editable: true,
            cellEditorParams: function (params) {
                return { cellHeight: 50, values: INTERVAL_OPTIONS.map(({ value, label}) => label)}
            },
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ["15 Minutes", "30 Minutes", "1 Hour", "12 Hours", "24 Hours", "1 Week", "1 Month"],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            sortable: true
        },
        { field: "dealCreated", headerName: "Deal Created", initialWidth: 200,
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                            <Switch
                                checked={params.node.data.dealCreated}
                                name={`cellToggleDealCreated${params.node.data.notificationId}`}
                                onChange={(changeEvent) => {
                                    if(params.node.data.notificationId){
                                        updateSalesNotificationTypeReactive(params.node.data.notificationId, "dealCreated").then(response => {
                                            params.node.setDataValue("dealCreated", !params.node.data.dealCreated);
                                        }).catch(function (error) {
                                            if (error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error("Error updating this notification");
                                            }
                                        })
                                    }
                                    else{
                                        NotificationManager.error("Error updating this notification");
                                    }
                                }}
                            />
                            </ThemeProvider>
                            {/*{params.node.data.hiddenFromUI === true ? "Hidden" : "Visible"}*/}
                        </div>
                    )
                },
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ["Send Alerts", "Do Not Send Alerts"],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            sortable: true,
            valueGetter: (params) => {
                if(params.node.data.dealCreated){
                    //console.log("Deal Created");
                    return "Send Alerts";
                }else{
                    //console.log("Deal Not Created");
                    return "Do Not Send Alerts";
                }
            },
        },
        { field: "dealDisabled", headerName: "Deal Disabled",initialWidth: 250,
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                                <Switch
                                checked={params.node.data.dealDisabled}
                                name={`cellToggleDealDisabled${params.node.data.notificationId}`}
                                onChange={(changeEvent) => {
                                    if(params.node.data.notificationId){
                                        updateSalesNotificationTypeReactive(params.node.data.notificationId, "dealDisabled").then(response => {
                                            params.node.setDataValue("dealDisabled", !params.node.data.dealDisabled);
                                        }).catch(function (error) {
                                            if (error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error("Error updating this notification");
                                            }
                                        })
                                    }
                                    else{
                                        NotificationManager.error("Error updating this notification");
                                    }
                                }}
                            />
                            </ThemeProvider>
                            {/*{params.node.data.hiddenFromUI === true ? "Hidden" : "Visible"}*/}
                        </div>
                    )
                },
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ["Send Alerts", "Do Not Send Alerts"],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            valueGetter: (params) => {
                if(params.node.data.dealDisabled){
                    //console.log("Disabled");
                    return "Send Alerts";
                }else{
                    //console.log("Not Disabled");
                    return "Do Not Send Alerts";
                }
            },
            sortable: true
        },
        { field: "dealExpired", headerName: "Deal Expired",initialWidth: 250,
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                            <Switch
                                checked={params.node.data.dealExpired}
                                name={`cellToggleDealExpired${params.node.data.notificationId}`}
                                onChange={(changeEvent) => {
                                    if(params.node.data.notificationId){
                                        updateSalesNotificationTypeReactive(params.node.data.notificationId, "dealExpired").then(response => {
                                            params.node.setDataValue("dealExpired", !params.node.data.dealExpired);
                                        }).catch(function (error) {
                                            if (error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error("Error updating this notification");
                                            }
                                        })
                                    }
                                    else{
                                        NotificationManager.error("Error updating this notification");
                                    }
                                }}
                            />
                            </ThemeProvider>
                            {/*{params.node.data.hiddenFromUI === true ? "Hidden" : "Visible"}*/}
                        </div>
                    )
                },
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ["Send Alerts", "Do Not Send Alerts"],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            valueGetter: (params) => {
                if(params.node.data.dealExpired){
                    //console.log("Expired");
                    return "Send Alerts";
                }else{
                    //console.log("Not Expired");
                    return "Do Not Send Alerts";
                }
            },
            sortable: true
        },
        { field: "upcomingDealExpiration", headerName: "Upcoming Deal Expiration",initialWidth: 290,
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                                <Switch
                                checked={params.node.data.upcomingDealExpiration}
                                name={`cellToggleUpcomingDealExpiration${params.node.data.notificationId}`}
                                onChange={(changeEvent) => {
                                    if(params.node.data.notificationId){
                                        updateSalesNotificationTypeReactive(params.node.data.notificationId, "upcomingDealExpiration").then(response => {
                                            params.node.setDataValue("upcomingDealExpiration", !params.node.data.upcomingDealExpiration);
                                        }).catch(function (error) {
                                            if (error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error("Error updating this notification");
                                            }
                                        })
                                    }
                                    else{
                                        NotificationManager.error("Error updating this notification");
                                    }
                                }}
                            />
                            </ThemeProvider>
                        </div>
                    )
                },
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ["Send Alerts", "Do Not Send Alerts"],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            valueGetter: (params) => {
                if(params.node.data.upcomingDealExpiration){
                    //console.log("Expired");
                    return "Send Alerts";
                }else{
                    //console.log("Not Expired");
                    return "Do Not Send Alerts";
                }
            },
            sortable: true
        },
        { field: "dealPOCActivity", headerName: "Deal POC Activity",initialWidth: 250,
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                                <Switch checked={params.node.data.dealPOCActivity}
                                        name={`cellToggleDealPOCActivity${params.node.data.notificationId}`}
                                        onChange={(changeEvent) => {
                                            if(params.node.data.notificationId){
                                                updateSalesNotificationTypeReactive(params.node.data.notificationId, "dealPOCActivity").then(response => {
                                                    params.node.setDataValue("dealPOCActivity", !params.node.data.dealPOCActivity);
                                                }).catch(function (error) {
                                                    if (error.message){
                                                        NotificationManager.error(error.message);
                                                    }
                                                    else{
                                                        NotificationManager.error("Error updating this notification");
                                                    }
                                                })
                                            }
                                            else{
                                                NotificationManager.error("Error updating this notification");
                                            }
                                        }}
                                />
                            </ThemeProvider>
                            {/*{params.node.data.hiddenFromUI === true ? "Hidden" : "Visible"}*/}
                        </div>
                    )
                },
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Send Alerts', 'Do Not Send Alerts'],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            valueGetter: (params) => {
                if(params.node.data.dealPOCActivity){
                    return "Send Alerts";
                }else{
                    return "Do Not Send Alerts";
                }
            },
            sortable: true
        },
        { field: "invoiceCreated", headerName: "Invoice Created",initialWidth: 250,
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                                <Switch checked={params.node.data.invoiceCreated}
                                name={`cellToggleInvoiceCreated${params.node.data.notificationId}`}
                                onChange={(changeEvent) => {
                                    if(params.node.data.notificationId){
                                        updateSalesNotificationTypeReactive(params.node.data.notificationId, "invoiceCreated").then(response => {
                                            params.node.setDataValue("invoiceCreated", !params.node.data.invoiceCreated);
                                        }).catch(function (error) {
                                            if (error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error("Error updating this notification");
                                            }
                                        })
                                    }
                                    else{
                                        NotificationManager.error("Error updating this notification");
                                    }
                                }}
                            />
                            </ThemeProvider>
                            {/*{params.node.data.hiddenFromUI === true ? "Hidden" : "Visible"}*/}
                        </div>
                    )
                },
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ["Send Alerts", "Do Not Send Alerts"],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            valueGetter: (params) => {
                if(params.node.data.invoiceCreated){
                    return "Send Alerts";
                }else{
                    return "Do Not Send Alerts";
                }
            },
            sortable: true
        },
        { field: "lastMessageSent", headerName: "Last Repeating Alert Sent",initialWidth: 330,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        { field: "lastOneTimeAlertMessageSent", headerName: "Last One Time Alert Sent",initialWidth: 330,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        { field: "userCreatedItUsername", headerName: "Created By", initialWidth: 275,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
        //TODO, come back when adding notifications to notificationGroups feature is in and add sorting, make sure to update API too
        //{ field: "notificationGroupName", headerName: "Notification Group Name", hide: true},
    ])
    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            filterParams: null,
            floatingFilter: true,
            headerClass: "border-0 border-b-0",
            cellClass: "outline:none",
            enableCellChangeFlash: true,
            autoHeight: false,
            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
        }
    )
    const sideBar = useMemo(() => {
        //Inside useMemo to help prevent the sidebar from re-rendering
        return getDefaultAgGridSidebarProps(300)
    }, []);
    const rowSelection = useMemo(() => {
        return {
            mode: 'singleRow',
            enableClickSelection: true,
            checkboxes: false,
            headerCheckbox: false
        };
    }, []);
    const createNewNotificationSubmit = async (data) => {
        //TODO, make confirmation modals
        if(data && newNotificationObj){
            if(newNotificationObj.group && newNotificationObj.timeInterval){
                let emailAddressValueToSend = null
                if(!notifyAllGroupMembersToggled){
                    //validate email if notifyAllGroupMembers is not toggled
                    if(!data.emailAddress || data.emailAddress.trim().length < 1){
                        NotificationManager.error("Please enter a valid email address")
                        return;
                    }
                    data.emailAddress = data.emailAddress.trim();
                    if(!EmailValidator.validate(data.emailAddress)){
                        NotificationManager.error("Please enter a valid email address")
                        return;
                    }
                    //valid email if we get to here
                    emailAddressValueToSend = data.emailAddress;
                }
                if(newNotificationObjTypesChecked && newNotificationObjTypesChecked.length > 0){
                    let dealCreated = newNotificationObjTypesChecked.includes("dealCreated")
                    let dealDisabled = newNotificationObjTypesChecked.includes("dealDisabled")
                    let dealExpired = newNotificationObjTypesChecked.includes("dealExpired")
                    let upcomingDealExpiration = newNotificationObjTypesChecked.includes("upcomingDealExpiration")
                    let invoiceCreated = newNotificationObjTypesChecked.includes("invoiceCreated")
                    let dealPOCActivity = newNotificationObjTypesChecked.includes("dealPOCActivity")

                    let nameToSend = null
                    if(data.name && data.name.trim().length > 0){
                        nameToSend = data.name.trim()
                    }
                    setIsLoading(true)
                    try{
                        await createSalesEmailNotificationObjectReactive(newNotificationObj.group, notifyAllGroupMembersToggled, emailAddressValueToSend, nameToSend, newNotificationObj.timeInterval,
                            dealCreated, dealDisabled, dealExpired, upcomingDealExpiration, invoiceCreated, dealPOCActivity)
                        NotificationManager.success("Successfully created email notification")
                        setNewNotificationObj({})
                        setNewNotificationObjTypesChecked([])
                        setCreateNotificationModalOpen(false)
                        setNotifyAllGroupMembersToggled(true)
                        resetGrid();
                        reset({})
                    }
                    catch(error){
                        if(error.message){
                            NotificationManager.error(error.message);
                        }
                        else{
                            NotificationManager.error("Error creating this email notification");
                        }
                    }
                }
                else{
                    NotificationManager.error("Please initially select at least one notification type, you will be able to edit this in the grid below later")
                }
            }
            else{
                NotificationManager.error("Please fill out all of the fields")
            }
        }
        setIsLoading(false)
    };

    return (
        <div className="flex flex-col h-full">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Sales Notifications</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            {/*Creat Notification Modal*/}
            <Modal contentLabel="Create New Sales Notification" isOpen={createNotificationModalOpen}
                   onRequestClose={() => {
                       setCreateNotificationModalOpen(false)
                       reset({})
                       setNewNotificationObj({})
                       setNewNotificationObjTypesChecked([])
                       setNotifyAllGroupMembersToggled(true)
                   }} shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-2xl max-w-2xl min-w-2xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"

            >
                <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(createNewNotificationSubmit)}>
                    <div className="flex flex-1 flex-col">
                        <div className="flex flex-row justify-center">
                            <h1 className="font-bold text-3xl">Create New Sales Notification</h1>
                        </div>
                        <hr className="mt-3 h-0.5" />
                        <div className="ml-1 mt-5">
                            <label>Channel Partner Group</label>
                            <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                                zenGroupDropdownOptionsList={getChannelGroupsDropDownContents()}
                                value={newNotificationObj.group}
                                onChange={( event, value ) => {
                                    setNewNotificationObj({ ...newNotificationObj, group: value?.value })
                                }}
                            />
                        </div>
                        <div className={`flex flex-row items-center ml-1 mt-5`}>
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={notifyAllGroupMembersToggled}
                                        name="toggle3"
                                        onChange={e => setNotifyAllGroupMembersToggled(e.target.checked)}
                                    />
                                } label={notifyAllGroupMembersToggled ? "Notify All Group Members" : "Notify Single Email Address"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`${notifyAllGroupMembersToggled ? "hidden" : "block"} ml-1 mt-5`}>
                            <label>Email Address to Notify</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                type="text"
                                required={!notifyAllGroupMembersToggled}
                                name="emailAddress"
                                {...register("emailAddress")}
                                placeholder="Required"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="ml-1 mt-5">
                            <label>Name for Notification</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                type="text"
                                name="name"
                                {...register("name")}
                                placeholder="Optional"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>

                        <div className="ml-1 mt-5">
                            <label>Notification type:</label>
                            {NOTIFICATION_TYPES.map(({value, label}) => {
                                return (
                                    <div key={value}>
                                        {value === "upcomingDealExpiration" ? (
                                            <div className="flex flex-col">
                                                <div className="ml-1 mt-5 flex flex-row">
                                                    <input
                                                        className="mr-2"
                                                        type="checkbox"
                                                        defaultValue={newNotificationObjTypesChecked.includes(value)}
                                                        onChange={(val) => {
                                                            let selectedTypesArray = newNotificationObjTypesChecked.slice();
                                                            if(selectedTypesArray.includes(value)){
                                                                let index = selectedTypesArray.indexOf(value);
                                                                selectedTypesArray.splice(index, 1);
                                                                setNewNotificationObjTypesChecked(selectedTypesArray);
                                                            }
                                                            else{
                                                                selectedTypesArray.push(value);
                                                                setNewNotificationObjTypesChecked(selectedTypesArray);
                                                            }
                                                        }}

                                                    />
                                                    <label>{label}</label>
                                                </div>
                                                {UPCOMING_DEAL_EXPIRATION_OPTIONS.map(({label}) => {
                                                    return (
                                                        <div key={label} className="ml-5 mt-1 flex flex-row">
                                                            <input
                                                                disabled={true}
                                                                className="mr-2"
                                                                type="checkbox"
                                                                checked={newNotificationObjTypesChecked.includes(value)}
                                                                defaultValue={newNotificationObjTypesChecked.includes(value)}
                                                            />
                                                            <label>{label}</label>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        ) : (
                                            <div className="ml-1 mt-5 flex flex-row">
                                                <input
                                                    className="mr-2"
                                                    type="checkbox"
                                                    defaultValue={newNotificationObjTypesChecked.includes(value)}
                                                    onChange={(val) => {
                                                        let selectedTypesArray = newNotificationObjTypesChecked.slice();
                                                        if(selectedTypesArray.includes(value)){
                                                            let index = selectedTypesArray.indexOf(value);
                                                            selectedTypesArray.splice(index, 1);
                                                            setNewNotificationObjTypesChecked(selectedTypesArray);
                                                        }
                                                        else{
                                                            selectedTypesArray.push(value);
                                                            setNewNotificationObjTypesChecked(selectedTypesArray);
                                                        }
                                                    }}

                                                />
                                                <label>{label}</label>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>

                        <div className="ml-1 mt-5">
                            <label>Time Interval</label>
                            <MuiAutocompleteNonGroupOptions
                                options={INTERVAL_OPTIONS}
                                value={newNotificationObj.timeInterval}
                                onChange={(event, value) => {
                                    setNewNotificationObj({ ...newNotificationObj, timeInterval: value?.value })
                                }}
                            />
                        </div>
                        <div className="flex flex-col mt-3">
                        <ThemeProvider theme = {switchTheme}>
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                type={"submit"}
                            >
                                Create
                            </Button>
                        </ThemeProvider>
                        </div>
                    </div>
                </form>
            </Modal>
            <div className="flex flex-1 flex-row h-full overflow-y-auto">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col flex-nowrap gap-y-2 ml-5 mr-10 mt-8 h-full">
                    {privatePageHeaderHelper("Sales Notifications")}
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row justify-between gap-x-1 gap-y-3">
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <GridColumnFilterStateSaving
                                useFilterStateSettingToggled = {useFilterStateSettingToggled}
                                setUseFilterStateSettingToggled = {setUseFilterStateSettingToggled}
                                toggleUpdateUseFilterState = {toggleUpdateUseFilterState}
                                useColumnStateSettingToggled = {useColumnStateSettingToggled}
                                setUseColumnStateSettingToggled = {setUseColumnStateSettingToggled}
                                toggleUpdateUseColumnState = {toggleUpdateUseColumnState}/>
                            <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                                <MuiIconButtonWithTooltipAndBox
                                    icon={<PersonAddAlt1Icon className={"cursor-pointer"}/>} tooltipTitle={"Create New Sales Notification"}
                                    tooltipPlacement={"top"}
                                    onClick={() => {
                                        setNewNotificationObj({ ...newNotificationObj, group: zenGroup });
                                        setCreateNotificationModalOpen(!createNotificationModalOpen);
                                    }}
                                />
                                <MuiIconButtonWithTooltipAndBox
                                    icon={<DeleteIcon className={"cursor-pointer"}/>} tooltipTitle={"Delete Sales Notification"}
                                    tooltipPlacement={"top"} disabled={!enableButtons}
                                    onClick={() => deleteSalesNotifications()}>
                                </MuiIconButtonWithTooltipAndBox>
                            </div>
                        </div>
                        <div className={"flex flex-row flex-wrap gap-y-3 gap-x-8 self-end justify-end"}>
                            <ClickToShowColumnOptionsWithToggleButtonGroup
                                columnMode={columnMode} setColumnMode={setColumnMode} gridColumnStateSessionVariableName={gridColumnStateSessionVariableName} gridApi={gridApi}
                                minColumnIds={minColumnIds} medColumnIds={medColumnIds} updateGridColumnModeFunction={updateSalesNotificationsGridColumnModeReactive} />
                            <ClearRefresh gridApi = {gridApi} showRefreshIcon={false}
                                          refreshGridFunction = {refreshGrid} showExcelExportIcon={true} sseDataPullActive={sseDataPullActive}
                                          excelExportFunction = {excelExport} />
                        </div>
                    </div>

                    <div className="h-full flex flex-col gap-y-5" id="gridRoot">
                        {getGrid()}
                        <Footer />
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, 'salesNotificationsGridFilterState', updateSalesNotificationsGridUseFilterStateReactive);
    }
    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, gridColumnStateSessionVariableName, updateSalesNotificationsGridUseColumnStateReactive);
    }

    function getGrid(){
        return (
            <Grid
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                sideBar={sideBar}
                rowSelection={rowSelection}
                setEnableButtons={setEnableButtons}
                setGridApi={setGridApi}
                sseDataPullActive={sseDataPullActive}
                setSSEDataPullActive={setSSEDataPullActive}
                asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                excelExport={excelExport}
                columnMode={columnMode}
                setColumnMode={setColumnMode}
            />
        );
    }

    async function deleteSalesNotifications() {
        if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length > 0){
            let notificationRowNodeToDelete = gridApi.getSelectedNodes()[0]
            if(!notificationRowNodeToDelete){
                NotificationManager.error("Error, no rows are selected")
                return
            }
            if(!notificationRowNodeToDelete.data || !notificationRowNodeToDelete.data.notificationId){
                return
            }
            let notificationId = notificationRowNodeToDelete.data.notificationId
            deleteSingleSalesNotificationReactive(notificationId).then(response => {
                NotificationManager.success("Successfully deleted this notification");
                checkPermissionAndDeleteRowNodeFromGrid(gridApi, notificationId, 'notificationId', notificationRowNodeToDelete.data.zenGroupId,
                    "notificationManager", "deleteNotification")
                resetGrid()
            }).catch(function(error){
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Error deleting notification");
                }
            })
        }

    }
    /*
    async function updateNotificationTypeOnClick(params, event, specificType, inputElement){
        //setIsLoading(true);
        event.stopPropagation(); //don't select row in grid
        try{
            if(params.node.data.notificationId){
                await updateSalesNotificationTypeReactive(params.node.data.notificationId, specificType)
                //NotificationManager.success(`Successfully updated notification: ${params.node.data.notificationName}`) //could get annoying sending notifications each time, so only notify on error
                params.value = !params.value
                inputElement.checked = params.value
                params.node.setDataValue(specificType, params.value);
                //updateObjectInSessionStorage("salesNotificationsGridList", "notificationId",
                  //  params.node.data.notificationId, specificType, params.value)
            }
            else{
                NotificationManager.error(`Error updating notification: ${params.node.data.notificationName}`)
                inputElement.checked = params.value
            }
        }
        catch(error){
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error(`Error updating notification: ${params.node.data.notificationName}`)
            }
            inputElement.checked = params.value
        }
        setNewNotificationObj({})
        setNewNotificationObjTypesChecked([])
        setIsLoading(false)

    }
    */

    function resetGrid(){
        //gridApi && gridApi.onFilterChanged()
        gridApi && gridApi.deselectAll();
        setEnableButtons(false)
        setNotifyAllGroupMembersToggled(true)
    }

    async function refreshGrid(){
        await refreshGridZenGroupOnlyWithSetDataValue(gridApi, "notificationId", "notificationId", findBySalesNotificationIdListReactive, [
            "lastMessageSent", "lastOneTimeAlertMessageSent", "dealCreated", "dealDisabled", "dealExpired", "upcomingDealExpiration", "invoiceCreated", "timeIntervalInSeconds", "dealPOCActivity",
            "notificationName"
        ])
    }

    function excelExport() {
        standardExcelExportHelper(gridApi, sseDataPullActive, "salesNotificationsGridExport")
    }

    function convertTimeIntervalToFormattedString(interval){
        switch(interval) {
            case 300:
                return "5 Minutes"
            case 900:
                return "15 Minutes"
            case 1800:
                return "30 Minutes"
            case 3600:
                return "1 Hour"
            case 43200:
                return "12 Hours"
            case 86400:
                return "24 Hours"
            case 604800:
                return "1 Week"
            case 2419200 :
                return "1 Month"
            default:
                return interval
        }
    }
}

function updateNotificationTimeIntervalOnChange(params, newTimeInterval, gridApi){
    //setIsLoading(true);
    let oldValue = params.oldValue
    if(params.newValue === params.oldValue){
        params.node.data.timeIntervalInSeconds = oldValue
        gridApi.refreshCells({suppressFlash: true, rowNodes: [params.node]})
        return;
    }
    if(params.node.data.zenGroupId && params.node.data.notificationId && newTimeInterval){
        updateSalesNotificationTimeIntervalReactive(params.node.data.notificationId, newTimeInterval).then(() => {
            //params.node.setDataValue("timeIntervalInSeconds", newTimeInterval);
            NotificationManager.success(`Successfully updated notification: ${params.node.data.notificationName}`)
            /*updateObjectInSessionStorage("salesNotificationsGridList", "notificationId",
                params.node.data.notificationId, "timeIntervalInSeconds", newTimeInterval)*/
        }).catch(function(error){
            if(error.message){
                NotificationManager.error(error.message)
            }
            else{
                NotificationManager.error("Error updating notification")
            }
            params.node.data.timeIntervalInSeconds = oldValue
            gridApi.refreshCells({suppressFlash: true, rowNodes: [params.node]})
        })

    }
    else{
        params.node.data.timeIntervalInSeconds = oldValue
        gridApi.refreshCells({suppressFlash: true, rowNodes: [params.node]})
    }
}

class Grid extends Component {
    rowData = []
    updateTransactionsToApply = []
    abortController = new AbortController()

    constructor(props, onClickRow, filterVals) {
        super(props);
    }
    onFirstDataRendered = (params) => {

    };
    componentDidMount() {
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        if(params.source !== "api" && params.source !== "gridOptionsChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateSalesNotificationsGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateSalesNotificationsGridColumnModeReactive, customColumnModeText)
        }
        else if(params.source === "api" && params.type === "sortChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateSalesNotificationsGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateSalesNotificationsGridColumnModeReactive, customColumnModeText)
        }
    }

    onCellEditingStopped = (event) => {
        let gridApi = event.api
        if(event.column.colId === "zenGroupDisplayName"){
            handleGroupColumnChangeNameOnly(event)
        }
        else if(event.column.colId === "notificationName"){
            if(event.newValue && event.newValue.trim().length > 0){
                if(event.newValue === event.oldValue){
                    event.data.notificationName = event.oldValue
                    gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
                    return;
                }
                if(!event.data.notificationId){
                    NotificationManager.error("Error updating name")
                    event.data.notificationName = event.oldValue
                    gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
                    return;
                }
                updateSalesNotificationNameReactive(event.newValue.trim(), event.data.notificationId).then(function(response){
                    NotificationManager.success("Successfully changed the name")
                    event.node.setDataValue("notificationName", event.newValue.trim());
                    /*updateObjectInSessionStorage("salesNotificationsGridList", "notificationId",
                        event.data.notificationId, "notificationName", event.newValue.trim())*/
                }).catch(function(error){
                    if(error.message){
                        NotificationManager.error(error.message)
                    }
                    else{
                        NotificationManager.error("Error updating name")
                    }
                    event.data.notificationName = event.oldValue
                    gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
                })
            }
            else{
                event.data.notificationName = event.oldValue
                gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
            }
        }
        else if(event.column.colId === "timeIntervalInSeconds"){
            let newTimeInterval
            INTERVAL_OPTIONS.forEach(element => {
                if(element.label === event.newValue){
                    newTimeInterval = element.value
                }
            })
            updateNotificationTimeIntervalOnChange(event, newTimeInterval, gridApi)
        }
    }

    componentWillUnmount(){
        this.abortController.abort()
    }

    populateGrid = async (rowData) => {
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    updateGridForChangeStream = async (changeStreamData) => {
        let operationType = changeStreamData.operationType
        let objectBody = changeStreamData.body
        objectBody["notificationId"] = objectBody["id"]
        objectBody["emailAddress"] = objectBody["notifyAllGroupMembers"] ? "All Group Members" : objectBody["emailAddress"]
        objectBody["notificationName"] = objectBody["notificationUserSetName"] ? objectBody["notificationUserSetName"] : objectBody["notificationRandomName"]
        objectBody["notificationGroupName"] = objectBody["notificationGroupUserSetName"] ? objectBody["notificationGroupUserSetName"] : objectBody["notificationGroupRandomName"]
        if(operationType === "UPDATE" || operationType === "REPLACE"){
            standardHandleUpdateAndReplaceEvent(objectBody, this.gridApi, this.props.sseDataPullActive, this.updateTransactionsToApply)
        }
        else if (operationType === "INSERT"){
            standardHandleInsertEvent(objectBody, this.gridApi, this.props.sseDataPullActive)
        }
    }

    getRowId = (params) => {
        return params.data.notificationId
    }

    getContextMenuItems = (params) => {
        let excelExport = this.props.excelExport //don't have access to this.props below in the action function so define it here
        return [
            standardExcelExportObjectInContextMenu(excelExport),
            "resetColumns",
            "autoSizeAll"
        ];
    };

    onGridReady = async (params) => {
        this.gridApi = params.api;
        this.props.setGridApi(params.api);
        //check if we want to apply saved column state
        let columnMode = this.props.columnMode

        //check which initial column mode to apply
        if(columnMode === customColumnModeText){
            onGridReadyHelperForColumnState(params, gridColumnStateSessionVariableName)
        }
        else if(columnMode === minColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, params.api, this.props.setColumnMode, minColumnModeText, minColumnIds, updateSalesNotificationsGridColumnModeReactive)
        }
        else if(columnMode === mediumColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, params.api, this.props.setColumnMode, mediumColumnModeText, medColumnIds, updateSalesNotificationsGridColumnModeReactive)
        }
        //else if columnMode is max then the default column state already shows the max amount of columns no need to update

        onGridReadyHelper(params, "salesNotificationsGridFilterState");

        await loadDataWithSSEAndStartChangeStreamListener("/sse/salesNotificationsListReactive", "/sse/listenToSalesNotificationEvent",
            this.populateGrid, this.updateGridForChangeStream, params, this.props.setSSEDataPullActive, this.props.setAsyncTransactionWaitMillis, this.updateTransactionsToApply,
            this.abortController)

        //params.api.sizeColumnsToFit()
    };
    render() {
        return (
            <div className={"w-full h-full"} style={{minHeight: "400px"}}>
                <div id="myGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                    <AgGridReact
                        columnMenu={"legacy"}
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, ExcelExportModule]}
                        defaultColDef={this.props.defaultColDef}
                        columnDefs={this.props.columnDefs}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        rowData={this.rowData}
                        onGridReady={this.onGridReady}
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        suppressModelUpdateAfterUpdateTransaction={true}
                        getRowId={this.getRowId}
                        multiSortKey={"ctrl"}
                        onCellEditingStopped={this.onCellEditingStopped}
                        maintainColumnOrder={true} //fixes issue where if you re-order/move column then click anywhere on the grid it reverts this change
                        rowSelection={this.props.rowSelection}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            if(selectedRows && selectedRows.length > 0){
                                //checks if the setEnableButtons method is null or not
                                this.props.setEnableButtons && this.props.setEnableButtons(true);
                            }
                            else{
                                this.props.setEnableButtons && this.props.setEnableButtons(false);
                            }
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        onFilterChanged={(params)=> {
                            onFilterChangedHelper(params, 'salesNotificationsGridFilterState', updateSalesNotificationsGridFilterModelReactive);
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                        getContextMenuItems={this.getContextMenuItems}
                        sideBar={this.props.sideBar}
                    />
                </div>
            </div>
        );
    }
}
