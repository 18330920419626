import React, {Component, useEffect, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {
    changeWhitelistNameReactive,
    copyAndReplaceWhitelistsReactive,
    copySingleWhitelistReactive,
    editWhitelistExpirationReactive,
    findByWhitelistIdListReactive,
    removeFromWhitelistReactive,
    scanServerAVs,
    updateWhitelistChartVisibilityReactive,
    updateWhitelistsGridColumnModeReactive,
    updateWhitelistsGridColumnStateReactive,
    updateWhitelistsGridFilterModelReactive,
    updateWhitelistsGridUseColumnStateReactive,
    updateWhitelistsGridUseFilterStateReactive,
    userAddWhitelistReactive,
    whitelistsPerGroupCountReactive
} from "../api/tailoredBehaviorsApi";
import {AgGridReact} from "@ag-grid-community/react";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ExcelExportModule} from "@ag-grid-enterprise/excel-export";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Modal from "react-modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import SidebarMenu from "../../components/sideBarComponent";
import {refreshGridZenGroupOnlyWithSetDataValue} from "../../utils/refreshGridHelper";
import {
    defaultZenGroupColumnInitWithOptionsWithValueGetter
} from "../../utils/zenGroupDisplayNameGridHelper";
import {convertDateToDateWithSessionTimeZone, dateValueFormatter} from "../../utils/gridDateFormatter";
import {AgChartsReact} from "ag-charts-react";
import {handleGroupColumnChangeNameOnly, WhiteListCellEditingStopped} from "../../utils/gridCellEditing";
import {
    findZenGroupById,
    getZenGroupDropDownContents,
    useZenGroupSessionStorage
} from "../../utils/zenGroupSessionStorageManager";
import {
    getChartVisibilitySettingInSession,
    getColumnModeInSession, getDefaultAgGridSidebarProps,
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnState,
    updateChartVisibilitySettingInSession,
    updateColumnModeInSessionHelper,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper,
    whitelistChartVisibleSessionVariable
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import CustomNameCellEditor, {editNameIconOnlyCellRenderer, getEditIconComponent} from "../../utils/customCellEditor";
import DTPicker, {dateFilterParametersInHeaderClientSideGrid} from "../../utils/DTPicker";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import {
    loadDataWithSSEAndStartChangeStreamListener,
    standardHandleInsertEvent,
    standardHandlePopulateGrid,
    standardHandleUpdateAndReplaceEvent
} from "../../utils/sseAndChangeStreamHelper";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {LoadingButton} from '@mui/lab';
import {Box, Button, FormControlLabel, IconButton, Switch, ThemeProvider, Tooltip} from "@mui/material";
import {BackDropChartLoadingOverlay, BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {standardExcelExportHelper, standardExcelExportObjectInContextMenu} from "../../utils/excelExportHelper";
import {useLocation} from "react-router-dom";
import {buttonTheme, switchTheme} from "../../utils/muiStyling";
import {
    MuiAutocompleteForZenGroupsWithoutCreateGroupOption, MuiAutocompleteNonGroupOptions,
    MuiCloseIconButton, MuiIconButtonWithTooltip,
    MuiIconButtonWithTooltipAndBox, MuiIconWithTooltip
} from "../../components/muiComponents";
import {
    ClickToShowColumnOptionsWithToggleButtonGroup,
    customColumnModeText,
    mediumColumnModeText,
    minColumnModeText,
    standardApplyMinimumOrMediumColumnMode
} from "../../components/clickToShowButtons";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CachedIcon from '@mui/icons-material/Cached';
import DeleteIcon from "@mui/icons-material/Delete";
import {BrowserUtilityProcessTuningModal} from "../../components/responseTuningComponents";
import {findNotificationEventForAutogeneratedWhitelistAutoFilter} from "../api/notificationEventApi";
import {getDateStringForAgGridFilter} from "./incidents";
import {defaultClientSideTextFilterParams} from "../../utils/filterHelper";
import {monthlyBillingCycleText, serverLicensePriceMonthly, serverLicensePriceYearly} from "./deals";
import {getUsersInGroupListReactive} from "../api/groupsApi";

let gridColumnStateSessionVariableName = "whitelistsGridColumnState"
let minColumnIds = ["zenGroupDisplayName", "path", "programArguments", "expirationDate"]
let medColumnIds = ["zenGroupDisplayName", "path", "programArguments", "parentPath", "created", "expirationDate"]
let chartSessionVariableName = whitelistChartVisibleSessionVariable
export const fileTriggersToMatchAllOptions = ["Read", "Write", "Delete", "Rename", "File Attributes"]
export default function TailoredBehaviors() {
    const { register, handleSubmit, reset } = useForm();
    const { register: registerEditExpiration, handleSubmit: handleSubmitEditExpiration, reset: resetEditExpiration } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [zenGroups, setZenGroups] = useState([]); // eslint-disable-line no-unused-vars
    const [zenGroup, setZenGroup] = useState();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [copyModalIsOpen, setCopyModalIsOpen] = useState(false);
    const [tuningModalIsOpen, setTuningModalIsOpen] = useState(false);
    const [replaceWhitelistsToggled, setReplaceWhitelistsToggled] = useState(false);
    const [deleteIsOpen, setDeleteIsOpen] = useState(false);
    const [deleteDisabled, setDeleteDisabled] = useState(true);
    const [parentProgramToggled, setParentProgramToggled] = useState(false);
    const [additionalRestrictionsToggled, setAdditionalRestrictionsToggled] = useState(false);
    const [temporaryWhitelistToggled, setTemporaryWhitelistToggled] = useState(false);
    const [onlyMatchIfTrustedCert, setOnlyMatchIfTrustedCert] = useState(false);
    const [onlyMatchIfNoInjections, setOnlyMatchIfNoInjections] = useState(false);
    const [onlyMatchIfNoModifiedMemory, setOnlyMatchIfNoModifiedMemory] = useState(false);
    const [newWhitelistIncidentMatchTypeList, setNewWhitelistIncidentMatchTypeList] = useState(fileTriggersToMatchAllOptions); //default to all
    const [gridApi, setGridApi] = useState();
    const [enableButtons, setEnableButtons] = useState(false);
    const [chartData, setChartData] = useState([]);
    const [chartIsLoading, setChartIsLoading] = useState(false);
    const [chartToggled, setChartToggled] = useState(getChartVisibilitySettingInSession(chartSessionVariableName));
    const [zenGroupSessionStorage,setZenGroupSessionStorage] = useZenGroupSessionStorage() // eslint-disable-line no-unused-vars
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession("whitelistsGridFilterState"));
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(200); //200 to start for the initial sse data pull, will change when sse data pull is done for change streams
    const whitelistLocation = useLocation();
    const [createWhitelistDefaultPathValue, setCreateWhitelistDefaultPathValue] = useState();
    const [createWhitelistDefaultArgsValue, setCreateWhitelistDefaultArgsValue] = useState();
    const [createWhitelistDefaultParentPathValue, setCreateWhitelistDefaultParentPathValue] = useState();
    const [createWhitelistDefaultParentArgsValue, setCreateWhitelistDefaultParentArgsValue] = useState();
    const [editWhitelistExpirationModalIsOpen, setEditWhitelistExpirationModalIsOpen] = useState(false);
    const [editWhitelistExpirationModalNodeData, setEditWhitelistExpirationModalNodeData] = useState(null);
    const [editWhitelistExpirationModalTemporaryToggled, setEditWhitelistExpirationModalTemporaryToggled] = useState(false);

    const [columnMode, setColumnMode] = useState(getColumnModeInSession(gridColumnStateSessionVariableName));
    // eslint-disable-next-line no-unused-vars
    const [columnDefs, setColumnDefs] = useState([
        defaultZenGroupColumnInitWithOptionsWithValueGetter(true, true, true),
        { field: "whitelistDisplayName", headerName: "Name", initialWidth: 330,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true,
            editable: true,
            cellEditor: "customNameCellEditor",
            cellRenderer: function (params) {
                return editNameIconOnlyCellRenderer(params, "Click to Edit this Tailored Behavior's Name", "whitelistDisplayName")
            }
        },
        { field: "friendlyName", hide: true, suppressColumnsToolPanel: true, lockVisible: true,
            //This col is only added in so we can filter by friendlyName easily for auto filtering autogenerated whitelist notifications
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
        },
        { field: "zenGroupId", hide: true, suppressColumnsToolPanel: true, lockVisible: true},
        { field: "path", headerName: "Path", initialWidth: 600,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
        { field: "programArguments", headerName: "Program Arguments", initialWidth: 600,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
        { field: "parentPath", headerName: "Parent Path", initialWidth: 600,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
        { field: "parentArguments", headerName: "Parent Program Arguments", initialWidth: 600,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
        { field: "expirationDate", headerName: "Expiration Date", initialWidth: 280,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            sortable: true,
            valueFormatter: dateValueFormatter,
            cellRenderer: function (params) {
                let tooltipText = ""
                let iconType = ""
                let valueToShowInCell = "No Expiration"
                let expirationDateValue = params.node.data.expirationDate
                if(expirationDateValue !== null && expirationDateValue !== undefined) {
                    //temporary
                    valueToShowInCell = params.valueFormatted
                    let showExpiredIcon = false
                    try{
                        let expirationDate = new Date(expirationDateValue).getTime()
                        let currentDate = new Date().getTime()
                        if(currentDate >= expirationDate){
                            //expired
                            showExpiredIcon = true
                        }
                    } catch(error){
                        //show expired icon
                        showExpiredIcon = true
                    }
                    if(showExpiredIcon){
                        tooltipText = "This temporary tailored behavior is currently expired and not in use, click to make permanent or edit the expiration date"
                        iconType = "fa-duotone fa-clock-rotate-left"
                    }
                    else{
                        //else temporary but not expired
                        tooltipText = "This tailored behavior is temporary, click to make permanent or edit the expiration date"
                        iconType = "fa-duotone fa-pen-to-square"
                    }
                }
                else {
                    //else permanent whitelist
                    tooltipText = "This tailored behavior is permanent, click to make temporary"
                    iconType = "fa-duotone fa-pen-to-square"
                }
                return (
                    <div className={`flex flex-row items-center gap-x-0.5`}>
                        <MuiIconButtonWithTooltip
                            icon={
                                <FontAwesomeIcon
                                    className="object-contain"
                                    icon={iconType}
                                    size="xs"
                                />
                            }
                            onClick={() => {
                                resetEditExpiration({
                                    expirationHoursEditTailoredBehavior: ""
                                })
                                if(expirationDateValue !== null && expirationDateValue !== undefined){
                                    //whitelist is currently temporary, default to temp toggled true
                                    setEditWhitelistExpirationModalTemporaryToggled(true)
                                }
                                else{
                                    //whitelist is currently permanent, default to temp toggled false
                                    setEditWhitelistExpirationModalTemporaryToggled(false)
                                }
                                setEditWhitelistExpirationModalNodeData(params.node.data)
                                setEditWhitelistExpirationModalIsOpen(true)
                            }}
                            tooltipTitle={tooltipText}
                            tooltipPlacement={"bottom-start"}
                        />
                        {valueToShowInCell}
                    </div>
                )
            },
        },
        { field: "onlyMatchIfTrustedCert", headerName: "Only Match if Trusted Cert",
            initialWidth: 290,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ["Only Match if Trusted Cert", "Do Not Limit Tailored Behavior to Match Trusted Certs Only"],
                suppressSorting: true,
                convertValuesToStrings: true,
                showTooltips: true
            },
            valueGetter: (params) => { //need valueGetter or else true/false will show for this column in excel export because only valueGetters are used to get values for export
                if(params.node.data.onlyMatchIfTrustedCert){
                    return "Only Match if Trusted Cert";
                }else{
                    return "Do Not Limit Tailored Behavior to Match Trusted Certs Only";
                }
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                                <Switch
                                    checked={params.node.data.onlyMatchIfTrustedCert}
                                    name={`cellToggleOnlyMatchIfTrustedCert${params.node.data.zenGroupId}`}
                                    disabled={true}
                                    className={"cursor-pointer"}
                                />
                            </ThemeProvider>
                        </div>
                    )
                },
            sortable: true
        },
        { field: "onlyMatchIfNoInjections", headerName: "Only Match if No Related Injections",
            initialWidth: 330,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ["Only Match if No Related Injections", "Do Not Limit Tailored Behavior to Match No Related Injections Only"],
                suppressSorting: true,
                convertValuesToStrings: true,
                showTooltips: true
            },
            valueGetter: (params) => { //need valueGetter or else true/false will show for this column in excel export because only valueGetters are used to get values for export
                if(params.node.data.onlyMatchIfNoInjections){
                    return "Only Match if No Related Injections";
                }else{
                    return "Do Not Limit Tailored Behavior to Match No Related Injections Only";
                }
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                                <Switch
                                    checked={params.node.data.onlyMatchIfNoInjections}
                                    name={`cellToggleOnlyMatchIfNoInjections${params.node.data.zenGroupId}`}
                                    disabled={true}
                                    className={"cursor-pointer"}
                                />
                            </ThemeProvider>
                        </div>
                    )
                },
            sortable: true
        },
        { field: "onlyMatchIfNoModifiedMemory", headerName: "Only Match if No Modified Memory",
            initialWidth: 330,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ["Only Match if No Modified Memory", "Do Not Limit Tailored Behavior to Match No Modified Memory Only"],
                suppressSorting: true,
                convertValuesToStrings: true,
                showTooltips: true
            },
            valueGetter: (params) => { //need valueGetter or else true/false will show for this column in excel export because only valueGetters are used to get values for export
                if(params.node.data.onlyMatchIfNoModifiedMemory){
                    return "Only Match if No Modified Memory";
                }else{
                    return "Do Not Limit Tailored Behavior to Match No Modified Memory Only";
                }
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                                <Switch
                                    checked={params.node.data.onlyMatchIfNoModifiedMemory}
                                    name={`cellToggleOnlyMatchIfNoModifiedMemory${params.node.data.zenGroupId}`}
                                    disabled={true}
                                    className={"cursor-pointer"}
                                />
                            </ThemeProvider>
                        </div>
                    )
                },
            sortable: true
        },
        { field: "reportTypesToMatch", headerName: "File Triggers To Match", initialWidth: 330,
            filter: 'agSetColumnFilter',
            filterParams: {
                suppressSorting: false,
                buttons: ["reset", "apply"],
                maxNumConditions: 1,
                values: fileTriggersToMatchAllOptions,
                convertValuesToStrings: true,
            },
            sortable: true,
            valueGetter: function(params){
                return handleFileTriggerValueForCell(params)
            }
        },
        { field: "created", headerName: "Created", initialWidth: 300,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        { field: "userCreatedItUsername", headerName: "Created By", initialWidth: 300,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
        },

    ])
    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            filterParams: null,
            floatingFilter: true,
            headerClass: "border-0 border-b-0",
            cellClass: "outline:none",
            enableCellChangeFlash: true,
            autoHeight: false,
            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
        }
    )
    const sideBar = useMemo(() => {
        //Inside useMemo to help prevent the sidebar from re-rendering
        return getDefaultAgGridSidebarProps(370)
    }, []);
    const rowSelection = useMemo(() => {
        return {
            mode: 'multiRow',
            enableClickSelection: true,
            checkboxes: false,
            headerCheckbox: false
        };
    }, []);
    const [avScanButtonDisabled, setAVScanButtonDisabled] = useState(false);

    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            setZenGroups(zenGroupSessionStorage)
            /*if(zenGroupSessionStorage !== null && zenGroupSessionStorage.length > 0){
                setZenGroup(zenGroupSessionStorage[0].id);
            }*/
        })()
        return () => controller?.abort();
    }, [zenGroupSessionStorage]);

    useEffect(() => {
        //if they click button then set timer
        if(avScanButtonDisabled){
            const scanTimeout = window.setTimeout(() => {
                setAVScanButtonDisabled(false)
            }, 900000); //15 mins in milliseconds
            //clear timer on unmount
            return () => window.clearTimeout(scanTimeout);
        }
    }, [avScanButtonDisabled])

    //api call for chart
    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            setChartIsLoading(true)
            whitelistsPerGroupCountReactive().then(whitelistsPerGroupList => {
                setChartIsLoading(false)
                if (whitelistsPerGroupList) {
                    let data = []
                    for (let i in whitelistsPerGroupList){
                        let group = findZenGroupById(whitelistsPerGroupList[i].zenGroupId)
                        if(group && group.friendlyName){
                            //found group in session
                            data.push({"zenGroupId":whitelistsPerGroupList[i].zenGroupId, "zenGroupName":group.friendlyName,
                                "count":whitelistsPerGroupList[i].count
                            })
                        }
                        else{
                            //else did not find group in session
                            data.push({"zenGroupId":whitelistsPerGroupList[i].zenGroupId,
                                "count":whitelistsPerGroupList[i].count
                            })
                        }
                    }
                    data.sort((object1, object2) => (object1.zenGroupName?.toLowerCase() > object2.zenGroupName?.toLowerCase()) ? 1 : -1)

                    setChartData(data)
                } else {
                    setChartData([])
                }
            }).catch(function (error) {
                setChartIsLoading(false)
            })
        })()
        return () => controller?.abort();
    }, []);

    useEffect(() => {
        if(whitelistLocation && whitelistLocation.state && whitelistLocation.state.showCreateModal){ //need to check for showCreateModal since we also redirect with state for whitelist auto generate notification links
            window.scroll({behavior: "smooth", top: 0, left: 0})
            setModalIsOpen(true)
            if(whitelistLocation.state.zenGroupId){
                setZenGroup(findZenGroupById(whitelistLocation.state.zenGroupId).id)
            }
            if(whitelistLocation.state.path){
                setCreateWhitelistDefaultPathValue(whitelistLocation.state.path)
            }
            if(whitelistLocation.state.programArguments){
                setCreateWhitelistDefaultArgsValue(whitelistLocation.state.programArguments)
            }
            //Populate the default values for parent path and args, but don't toggle on by default, if the user toggles then these value will appear if they are present in the state
            if(whitelistLocation.state.parentPath){
                setCreateWhitelistDefaultParentPathValue(whitelistLocation.state.parentPath)
            }
            if(whitelistLocation.state.parentProgramArguments){
                setCreateWhitelistDefaultParentArgsValue(whitelistLocation.state.parentProgramArguments)
            }

            //so on refresh or clicking back then forward tabs this modal does not keep popping up
            window.history.replaceState(whitelistLocation.state, '')
        }
    }, [whitelistLocation]);

    const createWhitelist = (data) => {
        if (data.path && data.path.trim().length > 0 && zenGroup) {
            let programArguments = null;
            if(data.programArguments && data.programArguments.trim().length > 0){
                programArguments = data.programArguments.trim()
            }
            let parentPathToSend = null
            let parentProgramArgsToSend = null
            if(parentProgramToggled){
                //parentPath
                if(data.parentPath && data.parentPath.trim().length > 0){
                    parentPathToSend = data.parentPath.trim()
                }
                else{
                    NotificationManager.error("Please fill out the parent program path");
                    return;
                }
                //parent args
                if(data.parentProgramArguments && data.parentProgramArguments.trim().length > 0){
                    parentProgramArgsToSend = data.parentProgramArguments.trim()
                }
            }
            let tempHoursToSend = 0
            if(temporaryWhitelistToggled){
                if(data.expirationHoursNewTailoredBehavior < 1){
                    NotificationManager.error("Please Enter the Hours Until This Tailored Behavior Expires");
                    return;
                }
                else{
                    tempHoursToSend = data.expirationHoursNewTailoredBehavior
                }
            }
            let onlyMatchIfTrustedCertToSend = false
            let onlyMatchIfNoInjectionsToSend = false
            let onlyMatchIfNoModifiedMemoryToSend = false
            let fileTriggersSelected = null
            if(additionalRestrictionsToggled){
                onlyMatchIfTrustedCertToSend = onlyMatchIfTrustedCert
                onlyMatchIfNoInjectionsToSend = onlyMatchIfNoInjections
                onlyMatchIfNoModifiedMemoryToSend = onlyMatchIfNoModifiedMemory
                if(newWhitelistIncidentMatchTypeList && newWhitelistIncidentMatchTypeList.length > 0){
                    fileTriggersSelected = handleFileTriggerTypesSelectedForCreateWhitelist(newWhitelistIncidentMatchTypeList)
                }
            }

            let whitelistName = null
            if(data.whitelistName && data.whitelistName.trim().length > 0){
                whitelistName = data.whitelistName.trim()
            }
            setIsLoading(true);
            userAddWhitelistReactive(data.path.trim(), zenGroup, programArguments, whitelistName, parentPathToSend, parentProgramArgsToSend, onlyMatchIfTrustedCertToSend, onlyMatchIfNoInjectionsToSend, onlyMatchIfNoModifiedMemoryToSend, fileTriggersSelected, tempHoursToSend).then(response => {
                setIsLoading(false);
                if(response.whiteListExistedAlready === true){
                    NotificationManager.info("Tailored Behavior existed already");
                }
                else{
                    NotificationManager.success("Tailored Behavior added successfully!");
                    resetCreateWhitelistModal()
                }
            }).catch(function(error){
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Unexpected error, please try again.");
                }
                setIsLoading(false);
            })
        }
        else{
            NotificationManager.error("Please make sure all fields are filled out and try again.");
        }
    };

    const removeWhitelist = (data) => {
        if(!data.delete || data.delete.toLowerCase() !== "delete"){
            return;
        }
        if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length > 0){
            //verify we have all our data needed to send removeFromWhitelist request
            setIsLoading(true)
            //loop through all the nodes
            for (let i in gridApi.getSelectedNodes()){
                let currentWhitelist = gridApi.getSelectedNodes()[i]
                if(currentWhitelist && currentWhitelist.data && currentWhitelist.data.whitelistId){
                    removeFromWhitelistReactive(currentWhitelist.data.whitelistId).then(response => {
                        gridApi.applyTransactionAsync({
                            remove: [currentWhitelist.data]
                        })
                    }).catch(function(error){
//                        if(error.message){
//                            NotificationManager.error(error.message);
//                        }
//                        else{
//                            NotificationManager.error("Unexpected error, please try again.");
//                        }
//                        setIsLoading(false)
                    })
                }
            }

            // successfully removed all
            setIsLoading(false)
            NotificationManager.success("Path(s) removed successfully for groups you have permission in");
            resetGrid();
            setDeleteIsOpen(false);
            reset({
                delete: ""
            })
        }
    };

    function resetCreateWhitelistModal(){
        reset({
            path: "",
            whitelistName: "",
            programArguments: "",
            parentPath: "",
            parentProgramArguments: "",
            expirationHoursNewTailoredBehavior: ""
        })
        setModalIsOpen(false)
        setParentProgramToggled(false)
        setOnlyMatchIfTrustedCert(false)
        setOnlyMatchIfNoInjections(false)
        setOnlyMatchIfNoModifiedMemory(false)
        setAdditionalRestrictionsToggled(false)
        setTemporaryWhitelistToggled(false)
        setZenGroup()
        setNewWhitelistIncidentMatchTypeList(fileTriggersToMatchAllOptions)
    }

    function handleFileTriggerValueForCell(params){
        if(params.node.data.reportTypesToMatch === null || params.node.data.reportTypesToMatch === undefined || params.node.data.reportTypesToMatch.length === 0){
            //If reportTypesToMatch is null or size 0 that is treated as if all are selected
            return fileTriggersToMatchAllOptions.sort()
        }
        //if reportTypesToMatch contains "TIMESTAMP_OR_LEGAL_ATTRS" or "SET_INFO", replace with "FILE_ATTRIBUTES"
        let arr = params.node.data.reportTypesToMatch
        let returnSet = new Set()
        arr.forEach(type => {
            if(type === "READ"){
                returnSet.add("Read")
            } else if(type === "WRITE"){
                returnSet.add("Write")
            } else if(type === "DEL"){
                returnSet.add("Delete")
            } else if(type === "RENAME"){
                returnSet.add("Rename")
            } else if(type === "TIMESTAMP_OR_LEGAL_ATTRS" || type === "SET_INFO"){
                returnSet.add("File Attributes")
            }
        })
        return Array.from(returnSet).sort()
    }

    const submitEditExpirationDate = (data) => {
        if(editWhitelistExpirationModalNodeData && editWhitelistExpirationModalNodeData.whitelistId){
            let currentlyPermanent = true
            if(editWhitelistExpirationModalNodeData.expirationDate !== null && editWhitelistExpirationModalNodeData.expirationDate !== undefined){
                currentlyPermanent = false
            }
            if(currentlyPermanent && !editWhitelistExpirationModalTemporaryToggled){
                //already permanent, no change
                NotificationManager.info("This behavior is already permanent");
                return;
            }
            let tempHoursToSend = 0
            if(editWhitelistExpirationModalTemporaryToggled){
                //making whitelist temporary, make sure hours filled out
                if(data.expirationHoursEditTailoredBehavior < 1){
                    NotificationManager.error("Please Enter the Hours Until This Tailored Behavior Expires");
                    return;
                }
                else{
                    tempHoursToSend = data.expirationHoursEditTailoredBehavior
                }
            }

            setIsLoading(true)
            editWhitelistExpirationReactive(editWhitelistExpirationModalNodeData.whitelistId, tempHoursToSend).then(response => {
                setIsLoading(false)
                NotificationManager.success("Tailored behavior successfully updated");
                //reset and close modal
                resetEditExpirationModal()

            }).catch(function(error){
                if(error.message){
                    NotificationManager.error(error.message)
                }
                else{
                    NotificationManager.error("Unexpected error making this request");
                }
                setIsLoading(false)
            })
        }
    };
    function resetEditExpirationModal(){
        setEditWhitelistExpirationModalIsOpen(false)
        setEditWhitelistExpirationModalNodeData(null)
        setEditWhitelistExpirationModalTemporaryToggled(false)
        resetEditExpiration({
            expirationHoursEditTailoredBehavior: ""
        })
    }

    return (
        <div className="flex flex-col h-full">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Tailored Behaviors</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            {/*Create Whitelist Modal*/}
            <Modal contentLabel="Create Tailored Behavior"
                   isOpen={modalIsOpen}
                   onRequestClose={() => {
                       resetCreateWhitelistModal()
                   }}
                   shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-3xl max-w-3xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(createWhitelist)}>
                    <div className="flex flex-1 flex-col gap-y-5">
                        {/*Title with exit button*/}
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Add Path to Tailored Behaviors</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    resetCreateWhitelistModal()
                                }}
                            />
                        </div>
                        <hr className="h-0.5"/>
                        {/*Form content*/}
                        <div className="ml-1">
                            <label>Select which group to add to</label>
                            <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                                zenGroupDropdownOptionsList={getZenGroupDropDownContents()}
                                value={zenGroup}
                                onChange={(event, value) => {
                                    setZenGroup(value?.value)
                                }}
                            />
                        </div>
                        <div className="ml-1">
                            <label>Name for the tailored behavior</label>
                            <br/>
                            <input
                                name="whitelistName"
                                type="text"
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                                {...register("whitelistName")}
                                placeholder={"Optional"}
                                className="focus:outline-none h-10 p-1 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="ml-1">
                            <label>You can use '*' as a wild card. e.g. C:\Program Files\Office\word*.exe</label>
                        </div>
                        <div className="ml-1">
                            <label>Program Path</label>
                            <br/>
                            <input
                                name="path"
                                type="text"
                                defaultValue={createWhitelistDefaultPathValue}
                                required
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                                {...register("path")}
                                placeholder={"Required"}
                                className="focus:outline-none h-10 p-1 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        {/* <div className={`flex flex-row items-center ml-1 mt-5`}>
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={programArgumentsToggled}
                                        name="toggleWhitelistProgramArguments"
                                        onChange={e => setProgramArgumentsToggled(e.target.checked)}
                                    />
                                } label={programArgumentsToggled ? "Limit Exception to Program + Arguments" : "Do Not Limit Exception to Program + Arguments"}/>
                            </ThemeProvider>
                        </div>*/}
                        <div className={`ml-1`}>
                            <label>Program Arguments (Optional)</label>
                            <br/>
                            <input
                                name="programArguments" placeholder={"Optional"}
                                type="text"
                                defaultValue={createWhitelistDefaultArgsValue}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                                {...register("programArguments")}
                                className="focus:outline-none h-10 p-1 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className={`flex flex-row items-center ml-1`}>
                            <ThemeProvider theme={switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={parentProgramToggled}
                                        name="toggleWhitelistParentProgram"
                                        onChange={e => setParentProgramToggled(e.target.checked)}
                                    />
                                }
                                                  label={parentProgramToggled ? "Limit Exception to Program + Parent Program" : "Do Not Limit Exception to Program + Parent Program"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`${parentProgramToggled ? "block" : "hidden"} ml-1`}>
                            <label>Parent Program Path</label>
                            <br/>
                            <input
                                name="parentPath" type="text"
                                defaultValue={createWhitelistDefaultParentPathValue}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                                required={parentProgramToggled}
                                {...register("parentPath")}
                                placeholder={"Required"} //if this input is showing then they toggled parentProgramToggled, so should show required placeholder text
                                className="focus:outline-none h-10 p-1 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className={`${parentProgramToggled ? "block" : "hidden"} ml-1`}>
                            <label>Parent Program Arguments (Optional)</label>
                            <br/>
                            <input
                                name="parentProgramArguments" type="text"
                                defaultValue={createWhitelistDefaultParentArgsValue}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                                {...register("parentProgramArguments")}
                                placeholder={"Optional"}
                                className="focus:outline-none h-10 p-1 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className={`flex flex-row items-center ml-1`}>
                            <ThemeProvider theme={switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={additionalRestrictionsToggled}
                                        name="newWhitelistAdditionalRestrictionsToggled"
                                        onChange={e => setAdditionalRestrictionsToggled(e.target.checked)}
                                    />
                                }
                                                  label={additionalRestrictionsToggled ? "Enter Additional Tailored Behavior Restrictions" : "Do Not Enter Additional Tailored Behavior Restrictions"}/>
                            </ThemeProvider>
                        </div>
                        <div
                            className={`${additionalRestrictionsToggled ? "block ml-3" : "hidden"} flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={onlyMatchIfTrustedCert}
                                        name="newWhitelistOnlyMatchIfTrustedCertToggle"
                                        onChange={e => setOnlyMatchIfTrustedCert(e.target.checked)}
                                    />
                                }
                                                  label={onlyMatchIfTrustedCert ? "Only Match Tailored Behavior if Trusted Cert" : "Do Not Limit Tailored Behavior to Match Trusted Certs Only"}/>
                            </ThemeProvider>
                            <MuiIconWithTooltip
                                icon={
                                    <FontAwesomeIcon
                                        className="object-contain mt-1 cursor-pointer"
                                        icon="fa-light fa-circle-info"
                                        size="lg"
                                    />
                                }
                                tooltipTitle={`This setting controls whether the tailored behavior should be applied to an extortion response regardless of if the response has a trusted cert or not, or only apply the 
                                tailored behavior when the extortion response has a trusted cert (meaning the response will be suspended otherwise). The default setting is to not limit.`}
                                tooltipPlacement={"bottom-start"}
                            />
                        </div>
                        <div
                            className={`${additionalRestrictionsToggled ? "block ml-3" : "hidden"} flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={onlyMatchIfNoInjections}
                                        name="newWhitelistOnlyMatchIfNoInjectionsToggle"
                                        onChange={e => setOnlyMatchIfNoInjections(e.target.checked)}
                                    />
                                }
                                                  label={onlyMatchIfNoInjections ? "Only Match Tailored Behavior if No Related Injections" : "Do Not Limit Tailored Behavior to Match No Related Injections Only"}/>
                            </ThemeProvider>
                            <MuiIconWithTooltip
                                icon={
                                    <FontAwesomeIcon
                                        className="object-contain mt-1 cursor-pointer"
                                        icon="fa-light fa-circle-info"
                                        size="lg"
                                    />
                                }
                                tooltipTitle={`This setting controls if the tailored behavior should be applied to an extortion response regardless of if the response has related process injections or not, or only apply the
                                tailored behavior when the extortion response has no related process injections (meaning the response will be suspended otherwise). The default setting is to not limit.`}
                                tooltipPlacement={"bottom-start"}
                            />
                        </div>
                        <div
                            className={`${additionalRestrictionsToggled ? "block ml-3" : "hidden"} flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={onlyMatchIfNoModifiedMemory}
                                        name="newWhitelistOnlyMatchIfNoModifiedMemoryToggle"
                                        onChange={e => setOnlyMatchIfNoModifiedMemory(e.target.checked)}
                                    />
                                }
                                                  label={onlyMatchIfNoModifiedMemory ? "Only Match Tailored Behavior if No Modified Memory" : "Do Not Limit Tailored Behavior to Match No Modified Memory Only"}/>
                            </ThemeProvider>
                            <MuiIconWithTooltip
                                icon={
                                    <FontAwesomeIcon
                                        className="object-contain mt-1 cursor-pointer"
                                        icon="fa-light fa-circle-info"
                                        size="lg"
                                    />
                                }
                                tooltipTitle={`This setting controls if the tailored behavior should be applied to an extortion response regardless of if the response has modified memory or not, or only apply the
                                tailored behavior when the extortion response has no modified memory (meaning the response will be suspended otherwise). The default setting is to not limit.`}
                                tooltipPlacement={"bottom-start"}
                            />
                        </div>
                        <div className={`${additionalRestrictionsToggled ? "block ml-3" : "hidden"}`}>
                            <label>File Triggers To Match</label>
                            <MuiAutocompleteNonGroupOptions
                                options={fileTriggersToMatchAllOptions}
                                multiple={true}
                                label={"All Triggers Used by Default"}
                                value={newWhitelistIncidentMatchTypeList}
                                onChange={(event, valueList) => {
                                    setNewWhitelistIncidentMatchTypeList(valueList)
                                }}
                            />
                        </div>
                        <div className={`flex flex-row items-center ml-1`}>
                            <ThemeProvider theme={switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={temporaryWhitelistToggled}
                                        name="newWhitelistTemporaryWhitelistToggled"
                                        onChange={e => setTemporaryWhitelistToggled(e.target.checked)}
                                    />
                                }
                                                  label={temporaryWhitelistToggled ? "Make This Tailored Behavior Temporary" : "Make This Tailored Behavior Permanent"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`${temporaryWhitelistToggled ? "block ml-3" : "hidden"}`}>
                            <div className="flex flex-row items-center gap-x-3 gap-y-2 flex-wrap">
                                <label>Hours Until This Tailored Behavior Expires</label>
                                <input
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter' || e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") {
                                            e.preventDefault();
                                        }
                                    }}
                                    min={0} max={168} type="number"
                                    autoComplete="off"
                                    name="expirationHoursNewTailoredBehavior"
                                    {...register("expirationHoursNewTailoredBehavior")}
                                    className="text-center w-24 focus:outline-none h-10 p-1 rounded-lg border border-black border-opacity-25 border-solid"
                                />
                            </div>
                        </div>
                        <div className="flex flex-1 flex-col">
                            <ThemeProvider theme={buttonTheme}>
                                <Button variant={"contained"}
                                        color={"primary"}
                                        type={"submit"}
                                >
                                    Create
                                </Button>
                            </ThemeProvider>
                        </div>
                    </div>
                </form>
            </Modal>
            {/*Delete Whitelist Modal*/}
            <Modal contentLabel="Delete Tailored Behavior"
                   isOpen={deleteIsOpen}
                   onRequestClose={() => {
                       setDeleteIsOpen(false)
                       reset({
                           delete: ""
                       })
                   }}
                   shouldCloseOnOverlayClick={true}
                   style={{
                       overlay: {},
                       content: {
                           top: '30%',
                           left: '50%',
                           right: 'auto',
                           bottom: 'auto',
                           marginRight: '-50%',
                           transform: 'translate(-50%, -50%)',
                           borderRadius: '15px',
                           backgroundColor: 'white',
                       }
                   }}
            >
                <form onSubmit={handleSubmit(removeWhitelist)}>
                    <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4">
                        {/*Title with exit button*/}
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Remove Tailored Behavior</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    setDeleteIsOpen(false)
                                    reset({
                                        delete: ""
                                    })
                                }}
                            />
                        </div>
                        <hr className="mt-3 h-0.5" />
                        {/*Form content*/}
                        <div className="ml-1 mt-5">
                            <label>To confirm, type "delete" below</label>
                            <input
                                onInput={(e) => {
                                    if(e.target.value && e.target.value.toLowerCase() === "delete"){
                                        setDeleteDisabled(false)
                                    }
                                    else{
                                        setDeleteDisabled(true)
                                    }
                                }}
                                name="delete"
                                type="text"
                                required
                                {...register("delete")}
                                className="focus:outline-none h-10 p-1 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="flex flex-1 flex-col mt-3">
                            <ThemeProvider theme = {buttonTheme}>
                                <Button
                                    variant={"contained"}
                                    color={"primary"}
                                    type={"submit"}
                                    disabled={deleteDisabled}
                                >
                                    Delete
                                </Button>
                            </ThemeProvider>
                        </div>
                    </div>

                </form>
            </Modal>
            {/*Copy Whitelist Modal */}
            <Modal contentLabel="Copy Selected Tailored Behavior"
                   isOpen={copyModalIsOpen}
                   onRequestClose={() => {
                       setCopyModalIsOpen(false)
                       setReplaceWhitelistsToggled(false)
                       setZenGroup()
                   }}
                   shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-xl max-w-xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4">
                    {/*Title with exit button*/}
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">Copy Selected Tailored Behaviors</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                setCopyModalIsOpen(false)
                                setReplaceWhitelistsToggled(false)
                                setZenGroup()
                            }}
                        />
                    </div>
                    <hr className="mt-3 h-0.5" />
                    {/*Form content*/}
                    <div className="ml-1 mt-5">
                        <label>Select which group to copy the selected tailored behaviors to. Note that any selected tailored behaviors in this group will not be included in the copy.</label>
                        <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                            zenGroupDropdownOptionsList={getZenGroupDropDownContents()}
                            value={zenGroup}
                            onChange={( event, value ) => {
                                setZenGroup(value?.value)
                            }}
                        />
                    </div>
                    <div className={`flex flex-row items-center ml-1 mt-5`}>
                        <ThemeProvider theme = {switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={replaceWhitelistsToggled}
                                    name="toggleReplaceWhitelists"
                                    onChange={e => setReplaceWhitelistsToggled(e.target.checked)}
                                />
                            } label={replaceWhitelistsToggled ? (<Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                                          title={<div className={"text-sm"}>This group's existing tailored behaviors will be removed (except auto generated behaviors) and only have the copied tailored behaviors remaining after. Note
                                                                              that any selected tailored behaviors in the same group as the group to copy to will also be removed.
                            </div>} placement={"top"}>
                                <div>Replace Existing Tailored Behaviors in this Group</div>
                            </Tooltip>) : (<Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                    title={<div className={"text-sm"}>This group's existing tailored behaviors will not be removed</div>} placement={"top"}>
                                <div>Do Not Replace Existing Tailored Behaviors in this Group</div>
                            </Tooltip>)}/>
                        </ThemeProvider>
                    </div>
                    <div className="flex flex-1 flex-col mt-3">
                        <ThemeProvider theme = {buttonTheme}>
                            <Button variant={"contained"}
                                    color={"primary"}
                                    type={"submit"}
                                    onClick={() => {
                                        if(zenGroup){
                                            //Check if we are only copying or replacing + copying
                                            if(replaceWhitelistsToggled){
                                                if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length > 0){
                                                    //Replace whitelists was toggled, we need to call copyAndReplaceWhitelistsReactive which will handle deleting the whitelists and creating them
                                                    let idsToCopy = [] //get ids to copy
                                                    gridApi.getSelectedNodes().forEach((currentWhitelist) => {
                                                        let whitelist = currentWhitelist.data
                                                        if(whitelist && whitelist.whitelistId && whitelist.zenGroupId !== zenGroup){
                                                            idsToCopy.push(whitelist.whitelistId)
                                                        }
                                                    })
                                                    if(idsToCopy.length === 0){
                                                        NotificationManager.error("You must select other tailored behaviors that are not in the selected group to copy to");
                                                        return
                                                    }

                                                    //Grab the whitelists from the group to copy to that we will remove from grid after api call
                                                    let rowsToDeleteAfterSuccess = []
                                                    gridApi.forEachNode((currentWhitelist) => {
                                                        if(currentWhitelist.data && currentWhitelist.data.zenGroupId === zenGroup){
                                                            rowsToDeleteAfterSuccess.push(currentWhitelist.data)
                                                        }
                                                    })
                                                    setIsLoading(true)
                                                    copyAndReplaceWhitelistsReactive(zenGroup, idsToCopy).then(response => {
                                                        setIsLoading(false)
                                                        NotificationManager.success("Tailored Behaviors copied successfully");
                                                        resetGrid();
                                                        setCopyModalIsOpen(false)
                                                        setReplaceWhitelistsToggled(false)
                                                        setZenGroup()
                                                        //Remove whitelists from grid
                                                        gridApi.applyTransactionAsync({
                                                            remove: rowsToDeleteAfterSuccess
                                                        })
                                                    }).catch(function(error){
                                                        if(error.message){
                                                            NotificationManager.error(error.message)
                                                        }
                                                        else{
                                                            NotificationManager.error("Unexpected error making this request");
                                                        }
                                                        setIsLoading(false)
                                                    })
                                                }
                                            }
                                            else{
                                                //Replace whitelists was not selected, get selected rows and call to endpoint to create each whitelist in new group
                                                if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length > 0){
                                                    //loop through all the nodes
                                                    let validIdsCopied = []
                                                    gridApi.getSelectedNodes().forEach((currentWhitelist) => {
                                                        let whitelist = currentWhitelist.data
                                                        if(whitelist.path && whitelist.zenGroupId !== zenGroup){ //Double check whitelist has a path before calling userAddWhitelistReactive
                                                            //We want the copied whitelist to have the same name in the copy group so send whitelist.whitelistDisplayName in request
                                                            validIdsCopied.push(whitelist.whitelistId)
                                                            copySingleWhitelistReactive(whitelist.whitelistId, zenGroup).then(response => {}).catch(function(error){})
                                                        }
                                                    })
                                                    if(validIdsCopied.length === 0){
                                                        NotificationManager.error("You must select other tailored behaviors that are not in the selected group to copy to");
                                                        return
                                                    }
                                                    NotificationManager.success("Tailored Behaviors copied successfully");
                                                    resetGrid();
                                                    setCopyModalIsOpen(false)
                                                    setReplaceWhitelistsToggled(false)
                                                    setZenGroup()
                                                }
                                            }
                                        }
                                    }}
                            >
                                Submit
                            </Button>
                        </ThemeProvider>
                    </div>
                </div>
            </Modal>
            <Modal contentLabel="Edit Expiration Date"
                   isOpen={editWhitelistExpirationModalIsOpen}
                   onRequestClose={() => {
                       resetEditExpirationModal()
                   }}
                   shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-2xl max-w-2xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4">
                    {/*Title with exit button*/}
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">Edit Behavior Expiration</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                resetEditExpirationModal()
                            }}
                        />
                    </div>
                    <hr className="mt-3 h-0.5"/>
                    {/*Form content*/}
                    <div className={`flex flex-row flex-wrap items-center ml-1 mt-5`}>
                        <label className={"font-bold"}>Current Behavior
                            Name:&nbsp;</label>{`${editWhitelistExpirationModalNodeData?.whitelistDisplayName}`}
                    </div>
                    <div className={`flex flex-row flex-wrap items-center ml-1 mt-5`}>
                        <label className={"font-bold"}>Current Expiration
                            Date:&nbsp;</label>{`${(editWhitelistExpirationModalNodeData && editWhitelistExpirationModalNodeData.expirationDate) ? (convertDateToDateWithSessionTimeZone(editWhitelistExpirationModalNodeData.expirationDate)) : "No Expiration"}`}
                    </div>
                    <form className="flex flex-1 flex-col" onSubmit={handleSubmitEditExpiration(submitEditExpirationDate)}>
                        <div className={`flex flex-row items-center ml-1 mt-5`}>
                            <ThemeProvider theme={switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={editWhitelistExpirationModalTemporaryToggled}
                                        name="editWhitelistTemporaryWhitelistToggled"
                                        onChange={e => setEditWhitelistExpirationModalTemporaryToggled(e.target.checked)}
                                    />
                                } label={editWhitelistExpirationModalTemporaryToggled ? "Make This Tailored Behavior Temporary" : "Make This Tailored Behavior Permanent"}/>
                            </ThemeProvider>
                        </div>
                        <div
                            className={`${editWhitelistExpirationModalTemporaryToggled ? "block ml-3" : "hidden"} mt-5`}>
                            <div className="flex flex-row items-center gap-x-3 gap-y-2 flex-wrap">
                                <label>Hours Until This Tailored Behavior Expires</label>
                                <input
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter' || e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") {
                                            e.preventDefault();
                                        }
                                    }}
                                    min={0} max={168} type="number"
                                    autoComplete="off"
                                    name="expirationHoursEditTailoredBehavior"
                                    {...registerEditExpiration("expirationHoursEditTailoredBehavior")}
                                    className="text-center w-24 focus:outline-none h-10 p-1 rounded-lg border border-black border-opacity-25 border-solid"
                                />
                            </div>
                        </div>
                        <div className="flex flex-1 flex-col mt-3">
                            <ThemeProvider theme={buttonTheme}>
                                <Button variant={"contained"} color={"primary"} type={"submit"}>
                                    Submit
                                </Button>
                            </ThemeProvider>
                        </div>
                    </form>
                </div>
            </Modal>
            <BrowserUtilityProcessTuningModal tuningModalIsOpen={tuningModalIsOpen}
                                              setTuningModalIsOpen={setTuningModalIsOpen} setIsLoading={setIsLoading}/>
            <div className="flex flex-1 flex-row h-full overflow-y-auto">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col ml-5 mr-10 mt-8 gap-y-3 h-full">
                    {privatePageHeaderHelper("Tailored Behaviors")}
                    <hr className="bg-black h-0.5"/>
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme={switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={chartToggled}
                                    name="whitelistsPerGroupToggleToggle"
                                    onChange={e => {
                                        setChartToggled(e.target.checked)
                                        updateChartVisibilitySettingInSession(chartSessionVariableName, e.target.checked, updateWhitelistChartVisibilityReactive)
                                    }}
                                />
                            }
                                              label={chartToggled ? "Showing Tailored Behaviors Per Group Chart" : "Hiding Tailored Behaviors Per Group Chart"}/>
                        </ThemeProvider>
                    </div>
                    {/*License Usage Chart*/}
                    <div className={`chartContainer ${chartToggled ? `block` : `hidden`} relative`}>
                        <AgChartsReact options={
                            {
                                autoSize: true,
                                data: chartData,
                                title: {
                                    text: 'Tailored Behaviors Per Group',
                                    fontSize: 18,
                                },
                                series: [
                                    {
                                        type: 'bar',
                                        xKey: 'zenGroupId',
                                        yKey: 'count',
                                        yName: 'Number of Tailored Behaviors',
                                        fill: '#e76a24',
                                        stroke: '#c2c2c2',
                                        highlightStyle: {
                                            item: {
                                                fill: "#E8E8E8",
                                                stroke: "#181818",
                                                strokeWidth: 1
                                            },
                                        },
                                        tooltip: { //for hovering over a column
                                            renderer: function (params) {
                                                let content = params.datum.zenGroupName + ": " + params.datum[params.yKey]
                                                return {
                                                    title: params.yName,
                                                    content: content
                                                };
                                            },
                                        },
                                    },
                                ],
                                axes: [
                                    {
                                        type: 'category',
                                        position: 'bottom',
                                        keys: ["zenGroupId"],
                                        label: {
                                            rotation: 50,
                                            formatter: function(params){
                                                //The params include the zenGroupId (params.value) and the index of the label in the chart data array, so we can access the object and format return value
                                                let index = params.index //index of the chart data array
                                                if(chartData && index !== undefined && index !== null){
                                                    try{
                                                        let object = chartData[index]
                                                        if(object){
                                                            let name = params.value //initialize to zenGroupId (params.value) in case no zenGroupName found
                                                            if(object.zenGroupName){
                                                                name = object.zenGroupName
                                                            }
                                                            if(object.count !== null && object.count !== undefined){
                                                                return `${name} (${object.count})`
                                                            }
                                                            else{
                                                                //should not get to here but default to return name
                                                                return name
                                                            }
                                                        }
                                                    } catch (e) {}
                                                }
                                                return params.value //if we get to here then something went wrong, return zenGroupId (params.value)
                                            },
                                            fontSize: 11
                                        },
                                    },
                                    {
                                        type: 'number',
                                        position: 'left',
                                    },
                                ],
                                legend: {
                                    spacing: 40,
                                    position: 'top',
                                    enabled: true
                                },
                            }
                        } />
                        <BackDropChartLoadingOverlay opened={chartIsLoading} />
                    </div>
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row justify-between gap-x-1 gap-y-3">
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <GridColumnFilterStateSaving
                                useFilterStateSettingToggled = {useFilterStateSettingToggled}
                                setUseFilterStateSettingToggled = {setUseFilterStateSettingToggled}
                                toggleUpdateUseFilterState = {toggleUpdateUseFilterState}
                                useColumnStateSettingToggled = {useColumnStateSettingToggled}
                                setUseColumnStateSettingToggled = {setUseColumnStateSettingToggled}
                                toggleUpdateUseColumnState = {toggleUpdateUseColumnState}/>
                            <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                                <MuiIconButtonWithTooltipAndBox
                                    //icon={<PersonAddAlt1Icon className={"cursor-pointer"}/>} tooltipTitle={"Add a Process"}
                                    tooltipTitle={"Exclude Process and Notify the Cyber Crucible Behavioral Analysis Team"}
                                    icon={
                                        <FontAwesomeIcon
                                            className="object-contain"
                                            icon="fa-duotone fa-magnifying-glass-arrow-right"
                                        />
                                    }
                                    tooltipPlacement={"top"}
                                    onClick={() => {
                                        setModalIsOpen(!modalIsOpen)
                                    }}/>
                                <Tooltip title={<div className={"text-sm"}>{avScanButtonDisabled ? "AV Scan In Progress" : "Start scan for antivirus products for your agents that would otherwise automatically occur nightly"}</div>}
                                         placement={"top"} enterDelay={750} arrow slotProps={{tooltip: {sx: {maxWidth: 700}}}}>
                                    <Box sx={{ boxShadow: 7, borderRadius: '28px'}}>
                                        <LoadingButton
                                            loading={avScanButtonDisabled}
                                            disabled={avScanButtonDisabled}
                                            variant={"text"}
                                            classes={{loadingIndicatorCenter: "px-2"}}
                                            sx={{
                                                "&:hover": {
                                                    backgroundColor: "white",
                                                    cursor: "pointer",
                                                    color: "#505050",
                                                },
                                                "&:disabled": {
                                                    backgroundColor: "#Fbfbfb",
                                                    cursor: "default",
                                                    color: "#Fbfbfb"
                                                },
                                                "&:hover .loading-button": {
                                                    backgroundColor: "white",
                                                    cursor: "pointer"
                                                },
                                                "&:disabled .loading-button": {
                                                    backgroundColor: "#Fbfbfb",
                                                    cursor: "default",
                                                    color: "#Fbfbfb"
                                                },
                                                color: "#505050",
                                                background: "#F3f3f3",
                                                borderRadius: "28px",
                                                maxWidth: "40px", maxHeight: "40px", minWidth: "40px", minHeight: "40px"
                                            }}
                                            loadingPosition={"center"}
                                            onClick={() => {
                                                setAVScanButtonDisabled(true)
                                                scanServerAVs()
                                                    .then(response => {
                                                        NotificationManager.success("AV Scan for your agents successfully sent");
                                                    })
                                                    .catch(function (error) {
                                                        if(error.message){
                                                            NotificationManager.error(error.message);
                                                        }
                                                        else{
                                                            NotificationManager.error("Error sending request");
                                                        }
                                                    })

                                            }}
                                        >
                                            <CachedIcon className="text-black loading-button" sx={{borderRadius: "28px", color: "#505050"}}/>
                                        </LoadingButton>
                                    </Box>
                                </Tooltip>
                                <MuiIconButtonWithTooltipAndBox
                                    icon={<ContentCopyIcon className={"cursor-pointer"}/>} tooltipTitle={"Copy Selected Tailored Behaviors to Another Group"}
                                    tooltipPlacement={"top"} disabled={!enableButtons}
                                    onClick={() => {
                                        setCopyModalIsOpen(true)
                                    }}>
                                </MuiIconButtonWithTooltipAndBox>
                                <MuiIconButtonWithTooltipAndBox
                                    icon={<DeleteIcon className={"cursor-pointer"}/>} tooltipTitle={"Remove Tailored Behavior"}
                                    tooltipPlacement={"top"} disabled={!enableButtons}
                                    onClick={() => {
                                        setDeleteDisabled(true)
                                        setDeleteIsOpen(true)
                                    }}>
                                </MuiIconButtonWithTooltipAndBox>
                                <MuiIconButtonWithTooltipAndBox
                                    icon={
                                        <IconButton
                                            sx={{width: 25, height: 25}} className={`self-center object-contain`} disableRipple={true}
                                        >
                                            <FontAwesomeIcon className={`object-contain`} icon={"fa-duotone fa-music-note"} size="sm" color={`black`}/>
                                        </IconButton>
                                    }
                                    tooltipTitle={"Tuning Available"} tooltipPlacement={"top"}
                                    onClick={() => {
                                        setTuningModalIsOpen(true)
                                    }}
                                />
                            </div>
                        </div>
                        <div className={"flex flex-row flex-wrap gap-y-3 gap-x-8 self-end justify-end"}>
                            <ClickToShowColumnOptionsWithToggleButtonGroup
                                columnMode={columnMode} setColumnMode={setColumnMode} gridColumnStateSessionVariableName={gridColumnStateSessionVariableName} gridApi={gridApi}
                                minColumnIds={minColumnIds} medColumnIds={medColumnIds} updateGridColumnModeFunction={updateWhitelistsGridColumnModeReactive} />
                            <ClearRefresh gridApi = {gridApi} showRefreshIcon={false} sseDataPullActive={sseDataPullActive}
                                          refreshGridFunction = {refreshGrid} showExcelExportIcon={true} excelExportFunction={excelExport} />
                        </div>
                    </div>
                    <div className="h-full flex flex-col gap-y-5" id="gridRoot">
                        {getGrid()}
                        <Footer />
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, 'whitelistsGridFilterState', updateWhitelistsGridUseFilterStateReactive);
    }

    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, gridColumnStateSessionVariableName, updateWhitelistsGridUseColumnStateReactive);
    }

    function getGrid(){
        return (
            <Grid
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                sideBar={sideBar}
                rowSelection={rowSelection}
                setEnableButtons={setEnableButtons}
                setGridApi={setGridApi}
                sseDataPullActive={sseDataPullActive}
                setSSEDataPullActive={setSSEDataPullActive}
                asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                excelExport={excelExport}
                columnMode={columnMode}
                setColumnMode={setColumnMode}
                whitelistLocation={whitelistLocation}
            />
        );
    }
    async function refreshGrid(){
        await refreshGridZenGroupOnlyWithSetDataValue(gridApi, "whitelistId", "id", findByWhitelistIdListReactive, ["whitelistDisplayName"])
    }
    function resetGrid(){
        //  refresh the grid, only a workaround for now, will change when applying sse
        gridApi && gridApi.deselectAll();
    }
    function excelExport(){
        standardExcelExportHelper(gridApi, sseDataPullActive, "tailoredBehaviorsGridExport")
    }
}

export function handleFileTriggerTypesSelectedForCreateWhitelist(typesList){
    //returns array of formatted types matching with enum values to send for api
    let triggersFormattedSet = new Set()
    //format values to match enum
    typesList.forEach(type => {
        if(type === "Read"){
            triggersFormattedSet.add("READ")
        } else if(type === "Write"){
            triggersFormattedSet.add("WRITE")
        } else if(type === "Delete"){
            triggersFormattedSet.add("DEL")
        } else if(type === "Rename"){
            triggersFormattedSet.add("RENAME")
        } else if(type === "File Attributes"){
            //add both to set
            triggersFormattedSet.add("SET_INFO")
            triggersFormattedSet.add("TIMESTAMP_OR_LEGAL_ATTRS")
        }
    })
    return Array.from(triggersFormattedSet)
}

let saveFilterChanges = true //we don't want to save filters if user clicks link in autogenerated whitelist notification link

class Grid extends Component {
    rowData = []
    updateTransactionsToApply = []
    abortController = new AbortController()

    constructor(props, setEnableButtons, onClickRow, filterVals) {
        super(props);
    }
    onFirstDataRendered = (params) => {

    };

    cellEditingStoppedFunctionCaller = (event) => {
        //add to this function if we want to allow editing to a cell, as of now (October 11th, 2021) we do
        if(event.column.colId === "zenGroupDisplayName"){
            handleGroupColumnChangeNameOnly(event)
        }
        else{
            WhiteListCellEditingStopped(event, changeWhitelistNameReactive,"whitelistDisplayName")
        }
    }

    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        if(params.source !== "api" && params.source !== "gridOptionsChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateWhitelistsGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateWhitelistsGridColumnModeReactive, customColumnModeText)
        }
        else if(params.source === "api" && params.type === "sortChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateWhitelistsGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateWhitelistsGridColumnModeReactive, customColumnModeText)
        }
    }

    componentWillUnmount(){
        this.abortController.abort()
    }

    populateGrid = async (rowData) => {
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    updateGridForChangeStream = async (changeStreamData) => {
        let operationType = changeStreamData.operationType
        let objectBody = changeStreamData.body
        objectBody["whitelistId"] = objectBody["id"]
        objectBody["whitelistDisplayName"] = objectBody["userSetFriendlyName"] ? objectBody["userSetFriendlyName"] : objectBody["friendlyName"]
        if(operationType === "UPDATE" || operationType === "REPLACE"){
            standardHandleUpdateAndReplaceEvent(objectBody, this.gridApi, this.props.sseDataPullActive, this.updateTransactionsToApply)
        }
        else if (operationType === "INSERT"){
            standardHandleInsertEvent(objectBody, this.gridApi, this.props.sseDataPullActive)
        }
    }

    getRowId = (params) => {
        return params.data.whitelistId
    }

    getContextMenuItems = (params) => {
        let excelExport = this.props.excelExport //don't have access to this.props below in the action function so define it here
        return [
            standardExcelExportObjectInContextMenu(excelExport),
            "resetColumns",
            "autoSizeAll"
        ];
    };

    onGridReady = async (gridReadyParams) => {
        this.gridApi = gridReadyParams.api;
        this.props.setGridApi(gridReadyParams.api);

        // Disable text selection on the page while holding shift or control (to allow grid selections to be done easily without selecting all text)
        ["keyup","keydown"].forEach((event) => {
            window.addEventListener(event, (e) => {
                document.onselectstart = function() {
                    return !(e.shiftKey || e.ctrlKey);
                }
            });
        });

        let columnMode = this.props.columnMode
        //check which initial column mode to apply
        if(columnMode === customColumnModeText){
            onGridReadyHelperForColumnState(gridReadyParams, gridColumnStateSessionVariableName)
        }
        else if(columnMode === minColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, gridReadyParams.api, this.props.setColumnMode, minColumnModeText, minColumnIds, updateWhitelistsGridColumnModeReactive)
        }
        else if(columnMode === mediumColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, gridReadyParams.api, this.props.setColumnMode, mediumColumnModeText, medColumnIds, updateWhitelistsGridColumnModeReactive)
        }
        //else if columnMode is max then the default column state already shows the max amount of columns no need to update

        let filterForNotification = false
        saveFilterChanges = true //reset to true
        if(this.props.whitelistLocation && this.props.whitelistLocation.state){
            let loginURLSearchParams = this.props.whitelistLocation.state.loginURLSearchParams
            if(loginURLSearchParams !== null && loginURLSearchParams !== undefined && loginURLSearchParams.trim().length > 0){
                try{
                    let response = await findNotificationEventForAutogeneratedWhitelistAutoFilter(loginURLSearchParams)
                    let zenGroupId = response.zenGroupId
                    let zenGroup = findZenGroupById(zenGroupId)
                    let queryStartDateMillis = response.queryStartDateMillis
                    let queryEndDateMillis = response.queryEndDateMillis
                    if(zenGroupId && queryStartDateMillis && queryEndDateMillis && zenGroup && zenGroup.friendlyName){
                        let startFilterString = getDateStringForAgGridFilter(queryStartDateMillis)
                        let endFilterString = getDateStringForAgGridFilter(queryEndDateMillis)
                        if(startFilterString && endFilterString){
                            filterForNotification = true
                            saveFilterChanges = false //don't save filter changes for when we auto filter
                            let filter = {}
                            filter["zenGroupDisplayName"] = {
                                "values": [
                                    zenGroup.friendlyName
                                ],
                                "filterType": "set"
                            }
                            filter["created"] = {
                                "dateFrom": startFilterString,
                                "dateTo": endFilterString,
                                "filterType": "date",
                                "type": "inRange"
                            }
                            //filter for random friendlyName contains Auto
                            filter["friendlyName"] = {
                                filterType: "text",
                                type: "contains",
                                filter: "Autogenerated"
                            }
                            //filter for userCreatedItUsername blank so we filter out any copied, autogenerated whitelists
                            filter["userCreatedItUsername"] = {
                                filterType: "text",
                                type: "blank"
                            }
                            gridReadyParams.api.setFilterModel(filter);
                        }
                    }
                } catch (e) {}
            }

        }

        if(!filterForNotification){
            onGridReadyHelper(gridReadyParams, "whitelistsGridFilterState");
        }

        await loadDataWithSSEAndStartChangeStreamListener("/sse/getWhitelistsReactive", "/sse/listenToWhitelistEvent",
            this.populateGrid, this.updateGridForChangeStream, gridReadyParams, this.props.setSSEDataPullActive, this.props.setAsyncTransactionWaitMillis, this.updateTransactionsToApply,
            this.abortController)
        //gridReadyParams.api.sizeColumnsToFit()
    };
    render() {
        return (
            <div className={"w-full h-full"} style={{minHeight: "400px"}}>
                <div id="myGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                    <AgGridReact
                        columnMenu={"legacy"}
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, ExcelExportModule]}
                        defaultColDef={this.props.defaultColDef}
                        columnDefs={this.props.columnDefs}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        multiSortKey={"ctrl"}
                        rowData={this.rowData}
                        onGridReady={this.onGridReady}
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        suppressModelUpdateAfterUpdateTransaction={true}
                        getRowId={this.getRowId}
                        onCellEditingStopped={this.cellEditingStoppedFunctionCaller}
                        maintainColumnOrder={true} //fixes issue where if you re-order/move column then click anywhere on the grid it reverts this change
                        rowSelection={this.props.rowSelection}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            if(selectedRows && selectedRows.length > 0){
                                //checks if the setEnableButtons method is null or not
                                this.props.setEnableButtons && this.props.setEnableButtons(true);
                            }
                            else{
                                this.props.setEnableButtons && this.props.setEnableButtons(false);
                            }
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        onFilterChanged={(params)=> {
                            if(saveFilterChanges){
                                onFilterChangedHelper(params, 'whitelistsGridFilterState', updateWhitelistsGridFilterModelReactive);
                            }
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                        valueCache={true}
                        getContextMenuItems={this.getContextMenuItems}
                        sideBar={this.props.sideBar}
                    />
                </div>
            </div>
        );
    }
}
