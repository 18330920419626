import React, {Component, useEffect, useMemo, useState} from "react";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {ServerSideRowModelModule} from "@ag-grid-enterprise/server-side-row-model";
import {MultiFilterModule} from "@ag-grid-enterprise/multi-filter";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import {
    browserDataIncidentsPerGroupCountReactive,
    changeBrowserDataIncidentNameReactive,
    checkBrowserIncidentHasRelatedProcessInjectionsAndCreationsReactive,
    findByBrowserDataIncidentIdListReactive,
    getBrowserDataIncidentsLazy,
    getBrowserIncidentCCDiffFileReactive, submitBrowserIncidentForReviewReactive,
    updateBrowserDataIncidentsGridColumnModeReactive,
    updateBrowserDataIncidentsGridColumnStateReactive,
    updateBrowserDataIncidentsGridFilterModelReactive,
    updateBrowserDataIncidentsGridUseColumnStateReactive,
    updateBrowserDataIncidentsGridUseFilterStateReactive,
    updateIdentityIncidentChartVisibilityReactive,
    updateShowAllIdentityIncidentsTelemetryReactive
} from "../api/browserDataIncidentsApi";
import Modal from "react-modal";
import {ConfirmationModal} from "../../components/confirmationModal";
import {findBySingleWhitelistAppliedIdReactive} from "../api/tailoredBehaviorsApi";
import SidebarMenu from "../../components/sideBarComponent";
import {useForm} from "react-hook-form";
import {refreshGridZenGroupAndAgentInformation} from "../../utils/refreshGridHelper";
import {defaultZenGroupColumnInitWithOptionsWithValueGetter} from "../../utils/zenGroupDisplayNameGridHelper";
import {defaultAgentNameColumnInitWithOptions} from "../../utils/agentNameGridHelper";
import {dateValueFormatter} from "../../utils/gridDateFormatter";
import {handleGroupColumnChangeNameOnly} from "../../utils/gridCellEditing";
import {AgChartsReact} from "ag-charts-react";
import {agentVersionFormatter} from "../../utils/agentVersionFormatter";
import {Link, useLocation} from "react-router-dom"
import {findZenGroupById, useZenGroupSessionStorage} from "../../utils/zenGroupSessionStorageManager";
import {masterFilterHelper, serverSideFilterHelperHandleMultiConditionChecks} from "../../utils/filterHelper";
import {
    getChartVisibilitySettingInSession,
    getColumnModeInSession,
    getDefaultAgGridSidebarProps,
    getTelemetryDataVisibilitySettingInSession,
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    identityIncidentChartVisibleSessionVariable,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnState,
    onShowAllTelemetryDataChangedHelper,
    showAllIdentityIncidentsTelemetryVariable,
    updateChartVisibilitySettingInSession,
    updateColumnModeInSessionHelper,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import {
    defaultIncidentNameColumnInitWithOptions,
    removePathFromStartOfProgramArgumentsHelper
} from "../../utils/incidentNameGridHelper";
import CustomNameCellEditor from "../../utils/customCellEditor";
import {
    createSilentIncidentsFromIncidentsPageReactive,
    findSilentIncidentNamesForSingleIncidentIdReactive,
    findSuggestedPathsReactive,
    findSuggestedSilentIncidentPathsReactive,
    submitIncidentForReviewReactive,
    userAddWhitelistsFromIncidentsPageReactive,
    userSetIncidentAsNotSilentReactive
} from "../api/incidentsApi";
import DTPicker, {dateFilterParametersInHeaderSuppressSorting} from "../../utils/DTPicker";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropChartLoadingOverlay, BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {buttonTheme, roundButtonTheme, switchTheme} from "../../utils/muiStyling";
import {Button, FormControlLabel, Switch, ThemeProvider} from "@mui/material";
import {MuiCloseIconButton, MuiIconButtonWithTooltip, MuiIconWithTooltip} from "../../components/muiComponents";
import {
    ClickToShowColumnOptionsWithToggleButtonGroup,
    customColumnModeText,
    mediumColumnModeText,
    minColumnModeText,
    standardApplyMinimumOrMediumColumnMode
} from "../../components/clickToShowButtons";
import TimerOffOutlinedIcon from '@mui/icons-material/TimerOffOutlined';
import VpnKeyOffOutlinedIcon from '@mui/icons-material/VpnKeyOffOutlined';
import {findNotificationEventForIdentityIncidentAutoFilter} from "../api/notificationEventApi";
import {base64ToArrayBuffer, getDateStringForAgGridFilter} from "./incidents";
import {changeAgentNameReactive} from "../api/agentsApi";

let keysToRefresh = ["currentLatestAgentVersion", "browserDataIncidentDisplayName", "silent"]
let gridColumnStateSessionVariableName = "browserDataIncidentsGridColumnState"
let minColumnIds = ["zenGroupDisplayName", "machineName", "accessedFileApplication", "application", "parentApplication", "created"]
let medColumnIds = ["zenGroupDisplayName", "incidentDisplayName", "machineName", "accessedFileApplication", "application", "parentApplication", "hasUntrustedRemoteThreads",
    "hasModifiedMem", "hasNoTrustedCert", "created", "accessLength", "accessOffset"]
let chartSessionVariableName = identityIncidentChartVisibleSessionVariable
let applicationFieldFilterValues=["Private_Internet_Access_VPN", "Steam", "Proton_VPN", "FileZilla", "OpenVPN", "Telegram_Messenger", "NordVPN", "Chrome", "Vivaldi", "Signal",
    "Discord", "Opera", "Slack", "Firefox", "Thunderbird", "Active_Directory", "Sophos", "SentinelOne", "CrowdStrike", "Malwarebytes", "McAfee", "Cisco", "HP_Sure_Click", "HP_Sure_Sense", "Kaseya",
    "Syncro", "Dell_Support_Assist", "Windows_Defender", "Microsoft_Office", "Microsoft_Teams", "Microsoft_Edge", "Carbon_Black", "Palo_Alto", "CybX", "PC_Matic", "Cylance", "VIPRE",
    "Webroot", "Kaspersky", "Bitdefender", "Fortinet", "Spybot", "Avast", "AVG_AntiVirus", "Tanium", "CCleaner", "Rapid7", "RocketCyber", "Varonis", "Commvault", "Tenable",
    "Qualys", "Acronis", "SecPod", "Symantec", "IBM_Security_Trusteer_Rapport", "iboss", "HCL_BigFix", "Other", "Websense", "Carbonite", "Desktop_Central", "Windows_Explorer", "Windows_Services",
    "Trend_Micro", "Service_Host", "Search_Indexer"
]
const processData = (data) => {
    let memString = ""
    const memVal = data.memory
    memString = memVal;
    if (memVal > 1000) {
        memString = (memVal / 1000).toFixed(2) + " KB"
    }
    if (memVal > 1000000) {
        memString = (memVal / 1000000).toFixed(2) + " MB"
    }
    if (memVal > 1000000000) {
        memString = (memVal / 1000000000).toFixed(2) + " GB"
    }
    if(data.lastResponseTimestamp){
        data.lastResponseTimestamp = new Date(data.lastResponseTimestamp).toUTCString()
    }

    let programArgumentsString = ""

    if(data) {
        programArgumentsString = removePathFromStartOfProgramArgumentsHelper(data.path, data.programArguments)
    }

    data.programArgumentsEditedString = programArgumentsString

    let parentProgramArgumentsString = ""

    if(data) {
        parentProgramArgumentsString = removePathFromStartOfProgramArgumentsHelper(data.parentPath, data.parentProgramArguments)
    }


    let gridData =
        {
        "Access Name": data.incidentDisplayName,
        "Machine Name": data.machineName,
        "Accessed Program Path": data.accessedFilePath,
        "Accessing Program Path": data.path,
        "Accessing Program Arguments": programArgumentsString,
        "Accessing Program File Hash": data.fileHash,
        "Parent Program Path": data.parentPath,
        "Parent Program Arguments": parentProgramArgumentsString,
        "Ram usage": memString,
        "Pid": data.pid,
        "Username": data.username,
        "User SID": data.userSid,
    };
    return Object.keys(gridData).map((key) => ({
        description: key,
        value: gridData[key],
    }))
};

export const regularIdentityAccessPage = "regular"
export const blockedIdentityAccessPage = "blocked"
export const hiddenIdentityAccessPage = "hidden"

export default function BrowserDataIncidents(
    {
        identityAccessPage=regularIdentityAccessPage, pageTitle="Identity Access", gridQueryFunction=getBrowserDataIncidentsLazy,
        chartsApiFunction=browserDataIncidentsPerGroupCountReactive, findByIdApiFunction=findByBrowserDataIncidentIdListReactive
    }
) {
    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [activeIncident, setActiveIncident] = useState();
    const [clickedIconIncidentData, setClickedIconIncidentData] = useState();
    const [showNoSuggestedPathsFoundIcon, setShowNoSuggestedPathsFoundIcon] = useState(false);
    const [showNoSuggestedSilentIncidentPathsFoundIcon, setShowNoSuggestedSilentIncidentPathsFoundIcon] = useState(false);
    const [openResponseHistoryModal, setOpenResponseHistoryModal] = useState();
    // eslint-disable-next-line no-unused-vars
    const [ignoreButtonDisabled, setIgnoreButtonDisabled] = useState(true);
    const [showIgnoreModal, setShowIgnoreModal] = useState(false);
    const [showIgnoreConfirmModal, setShowIgnoreConfirmModal] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const { handleSubmit, reset } = useForm();
    const [gridApi, setGridApi] = useState(null);
    const [suggestedPaths, setSuggestedPaths] = useState([]);
    const [selectedPaths, setSelectedPaths] = useState([]);
    const [ignoreSubmitButtonDisabled, setIgnoreSubmitButtonDisabled] = useState(false);
    const [silentIncidentTurnOnModalButtonDisabled, setSilentIncidentTurnOnModalButtonDisabled] = useState(false);
    const [showSendReviewConfirmModal, setShowSendReviewConfirmModal] = useState(false);
    const [showCertificateModal, setShowCertificateModal] = useState(false);
    //const [chartData, setChartData] = useState([]);
    const [chartDataWithoutWhitelistedCount, setChartDataWithoutWhitelistedCount] = useState([]);
    const [chartDataWithWhitelistedCount, setChartDataWithWhitelistedCount] = useState([]);
    const [chartIsLoading, setChartIsLoading] = useState(false);
    const [chartToggled, setChartToggled] = useState(getChartVisibilitySettingInSession(chartSessionVariableName));
    // eslint-disable-next-line no-unused-vars
    const [includeWhitelistedToggled, setIncludeWhitelistedToggled] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [includeWhitelistedToggleDisabled, setIncludeWhitelistedToggleDisabled] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [zenGroupSessionStorage,setZenGroupSessionStorage] = useZenGroupSessionStorage()
    const [showTurnIncidentSilentModal, setShowTurnIncidentSilentModal] = useState(false);
    const [showTurnIncidentSilentConfirmModal, setShowTurnIncidentSilentConfirmModal] = useState(false);
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession("browserDataIncidentsGridFilterState"));
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    const [showAllTelemetryDataToggled, setShowAllTelemetryDataToggled] = useState(getTelemetryDataVisibilitySettingInSession(showAllIdentityIncidentsTelemetryVariable, "browserDataIncidentsGridFilterState"));
    const [columnMode, setColumnMode] = useState(getColumnModeInSession(gridColumnStateSessionVariableName));
    const incidentLocation = useLocation();

    const [columnDefs, setColumnDefs] = useState([
        defaultZenGroupColumnInitWithOptionsWithValueGetter(false, true, true),
        defaultIncidentNameColumnInitWithOptions(true, false, initializeWhitelistModalForIconClick,
            initializeDetailsModalForIconClick, initializeTurnSilentSettingToOnForIconClick, initializeTurnSilentSettingToOffForIconClick, "Access", true,
            2),
        {
            //moved to showing silent icon in incident name col, so hide this column but keep it defined so
            // .setDataValue() for this silent field does not throw error if this silent value is updated in refresh function
            field: "silent", headerName: "Silent", width : 150, sortable: true,
            hide: true, suppressColumnsToolPanel: true, lockVisible: true,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                valueFormatter: (params) => {
                    if(params.value!=="(Select All)"){
                        if(params.value === "true"){
                            return "On";
                        }else{
                            return "Off";
                        }
                    }
                    else{
                        return params.value;
                    }
                },
                values: ['true', 'false'],
                suppressSorting: true,
            },
            valueFormatter: function(params){
                if(params.value === true){
                    return "On"
                }
                else{
                    return "Off"
                }
            },
            cellRenderer: function(params){
                let silentIconDiv = ""
                if(params.valueFormatted === "On" && params.node.data.whitelistAppliedId == null){
                    silentIconDiv =
                        <MuiIconButtonWithTooltip
                            icon={
                                <FontAwesomeIcon
                                    className="ml-1 object-contain"
                                    icon="fa-duotone fa-volume-slash"
                                    size="lg"
                                />
                            }
                            tooltipTitle={`Click to change this setting to Off`}
                            tooltipPlacement={"bottom-start"}
                            onClick={(event) => {
                                setClickedIconIncidentData(params.node.data)
                                let incidentData = params.node.data
                                if(incidentData && incidentData.incidentId){
                                    userSetIncidentAsNotSilentReactive(incidentData.incidentId).then(response => {
                                        NotificationManager.success("Successfully queued update, you may need to refresh the grid periodically to see updates for any affected accesses within the same group")
                                        setClickedIconIncidentData()
                                        refreshGrid().then()
                                    }).catch(function (error) {
                                        if(error.message){
                                            NotificationManager.error(error.message);
                                        }
                                        else{
                                            NotificationManager.error("Unexpected error completing this request.");
                                        }
                                    })
                                }
                            }}
                        />
                }
                else if(params.valueFormatted === "Off" && params.node.data.whitelistAppliedId == null){
                    silentIconDiv =
                        <MuiIconButtonWithTooltip
                            icon={
                                <FontAwesomeIcon
                                    className="ml-1 object-contain"
                                    icon="fa-duotone fa-volume"
                                    size="lg"
                                />
                            }
                            tooltipPlacement={"bottom-start"}
                            tooltipTitle={"Click to change this setting to On"}
                            onClick={(event) => {
                                //mirroring this program arguments replacement, don't think we need to do this though
                                let data = params.node.data
                                let programArgumentsString = ""
                                if(data) {
                                    programArgumentsString = removePathFromStartOfProgramArgumentsHelper(data.path, data.programArguments)
                                }

                                data.programArgumentsEditedString = programArgumentsString
                                setClickedIconIncidentData(params.node.data)
                                setShowNoSuggestedSilentIncidentPathsFoundIcon(false)
                                setSilentIncidentTurnOnModalButtonDisabled(false)
                                setSelectedPaths([])
                                findSuggestedSilentIncidentPathsReactive(params.node.data.path, params.node.data.incidentId).then(suggestedPaths => {
                                    if(suggestedPaths && suggestedPaths.length > 0){
                                        setSuggestedPaths(suggestedPaths)
                                    }
                                    else{
                                        setSuggestedPaths([])
                                        setSilentIncidentTurnOnModalButtonDisabled(true)
                                        setShowNoSuggestedSilentIncidentPathsFoundIcon(true)
                                    }
                                }).catch(function (error) {
                                    setSuggestedPaths([])
                                    setSilentIncidentTurnOnModalButtonDisabled(true)
                                    setShowNoSuggestedSilentIncidentPathsFoundIcon(true)
                                })
                                setShowTurnIncidentSilentModal(true)
                            }}
                        />
                }
                return(
                    <div className={"flex flex-nowrap items-center justify-between gap-x-2"}>
                        {silentIconDiv}
                        {params.valueFormatted}
                    </div>
                )
            },
        },
        { field: "zenGroupId", hide: true, suppressColumnsToolPanel: true, lockVisible: true},
        defaultAgentNameColumnInitWithOptions(false, 5),
        { field: "machineName", headerName: "Machine Name", initialWidth: 225,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],

                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                maxNumConditions: 5,
            },
            cellRenderer:function(params){
                const machineNameOnRender= params.node.data.machineName
                let agentLinkDiv = ""
                if(params.data.agentId){
                    //only using agentId for cross-link filter now so only need to check agentId exists
                    agentLinkDiv =
                        <Link to={{pathname:"/private/agents"}} state={{agentDisplayNameClicked: params.node.data.agentDisplayName, zenGroupIdClicked: params.node.data.zenGroupId,
                            agentIdClicked : params.node.data.agentId, machineNameClicked: params.node.data.machineNameForLocationLink}} className="">
                            <MuiIconButtonWithTooltip
                                icon={
                                    <FontAwesomeIcon
                                        className="object-contain"
                                        icon="fa-duotone fa-user-gear"
                                        size="xs"
                                    />
                                }
                                tooltipTitle={"Click to Manage This Agent"}
                                tooltipPlacement={"bottom-start"}
                            />
                        </Link>
                }
                return(
                    <div className={"flex flex-nowrap items-center gap-x-2"}>
                        {agentLinkDiv}
                        {machineNameOnRender}
                    </div>
                )
            },

            sortable: true,
            tooltipField:"One",


        },
        /*{ field: "status", headerName: "Status", initialWidth: 275,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ['AUTOMATICALLY_BLOCKED', 'AUTOMATICALLY_HIDDEN', 'AUTOMATICALLY_SUSPENDED', 'SUSPENDED', 'WOULD_SUSPEND', 'WOULD_BLOCK', 'WOULD_HIDE', 'WHITELISTED'],
                valueFormatter: function(params){
                    if(params.value === "WHITELISTED"){
                        return "EXCLUDED"
                    }
                    else{
                        return params.value
                    }
                },
                suppressSorting: false,
                showTooltips: true
            },
            valueFormatter: function(params){
                if(params.value === "WHITELISTED"){
                    return "EXCLUDED"
                }
                else{
                    return params.value
                }
            },
            sortable: true
        },*/
        { field: "accessedFilePath", headerName: "Accessed Program Path", initialWidth: 500,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],

                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                maxNumConditions: 2,
            },
            sortable: true
        },
        { field: "accessedFileApplication", headerName: "Accessed Application", initialWidth: 280,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: applicationFieldFilterValues,
                valueFormatter: applicationFieldValueFormatter,
                suppressSorting: false,
                showTooltips: false
            },
            sortable: true,
            valueFormatter: applicationFieldValueFormatter,
        },
        { field: "path", headerName: "Accessing Program Path", initialWidth: 500,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],

                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                maxNumConditions: 2,
            },
            sortable: true
        },
        { field: "application", headerName: "Accessing Application", initialWidth: 280,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: applicationFieldFilterValues,
                valueFormatter: applicationFieldValueFormatter,
                suppressSorting: false,
                showTooltips: false
            },
            sortable: true,
            valueFormatter: applicationFieldValueFormatter,
            cellRenderer: function(params){
                let iconDiv = ""
                if(params.node.data.application === "Windows_Services"){ //Services tooltip
                    iconDiv = <MuiIconButtonWithTooltip
                        icon={<FontAwesomeIcon className="object-contain" icon="fa-light fa-circle-info" size="xs"/>}
                        tooltipTitle={`This application is the service control manager that is responsible for managing and interacting with system services`}
                        tooltipPlacement={"bottom-start"}
                    />
                }
                else if(params.node.data.application === "Windows_Explorer"){ //Explorer tooltip
                    iconDiv = <MuiIconButtonWithTooltip
                        icon={<FontAwesomeIcon className="object-contain" icon="fa-light fa-circle-info" size="xs"/>}
                        tooltipTitle={`This was likely user GUI interaction as this application manages the graphical shell component on windows`}
                        tooltipPlacement={"bottom-start"}
                    />
                }
                return(
                    <div className={"flex flex-nowrap items-center gap-x-2"}>
                        {iconDiv}
                        {params.valueFormatted}
                    </div>
                )
            },
        },
        { field: "programArguments", headerName: "Accessing Program Arguments", initialWidth: 700,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],

                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                maxNumConditions: 2,
            },
            valueFormatter:
                function(params){
                    if(params.node.data) {
                        return removePathFromStartOfProgramArgumentsHelper(params.node.data.path, params.node.data.programArguments)
                    }
                    else{
                        return ""
                    }
                },
            sortable: true
        },
        { field: "parentPath", headerName: "Parent Program Path", initialWidth: 500,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],

                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                maxNumConditions: 2,
            },
            sortable: true
        },
        { field: "parentApplication", headerName: "Parent Application", initialWidth: 280,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: applicationFieldFilterValues,
                valueFormatter: applicationFieldValueFormatter,
                suppressSorting: false,
                showTooltips: false
            },
            sortable: true,
            valueFormatter: applicationFieldValueFormatter,
            cellRenderer: function(params){
                let iconDiv = ""
                if(params.node.data.parentApplication === "Windows_Services"){ //Services tooltip
                    iconDiv = <MuiIconButtonWithTooltip
                        icon={<FontAwesomeIcon className="object-contain" icon="fa-light fa-circle-info" size="xs"/>}
                        tooltipTitle={`This application is the service control manager that is responsible for managing and interacting with system services`}
                        tooltipPlacement={"bottom-start"}
                    />
                }
                else if(params.node.data.parentApplication === "Windows_Explorer"){ //Explorer tooltip
                    iconDiv = <MuiIconButtonWithTooltip
                        icon={<FontAwesomeIcon className="object-contain" icon="fa-light fa-circle-info" size="xs"/>}
                        tooltipTitle={`This was likely user GUI interaction as this application manages the graphical shell component on windows`}
                        tooltipPlacement={"bottom-start"}
                    />
                }
                return(
                    <div className={"flex flex-nowrap items-center gap-x-2"}>
                        {iconDiv}
                        {params.valueFormatted}
                    </div>
                )
            },
        },
        { field: "parentProgramArguments", headerName: "Parent Program Arguments", initialWidth: 700,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],

                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                maxNumConditions: 2,
            },
            valueFormatter:
                function(params){
                    if(params.node.data) {
                        return removePathFromStartOfProgramArgumentsHelper(params.node.data.parentPath, params.node.data.parentProgramArguments)
                    }
                    else{
                        return ""
                    }
                },
            sortable: true
        },
        { field: "created", headerName: "Created", initialWidth: 280,
            filter: 'agDateColumnFilter',
            filterParams:dateFilterParametersInHeaderSuppressSorting(2),
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        {
            field: "rootCauseAnalysis", headerName: "Root Cause Analysis", width: 300,
            sortable: false,
            valueFormatter: function (params) {
                if (params.node.data.rootCauseAnalysis) {
                    return params.node.data.rootCauseAnalysis
                }
                let path = params.node.data.path
                if (path && params.node.data.incidentId && params.node.data.zenGroupId && params.node.data.uniqueProcessIdentifier !== null && params.node.data.uniqueProcessIdentifier !== undefined) {
                    let endpointPathToUse = "/checkBrowserIncidentHasRelatedProcessInjectionsAndCreationsReactive"
                    if(identityAccessPage === blockedIdentityAccessPage){
                        endpointPathToUse = "/checkBrowserIncidentBlocksHasRelatedProcessInjectionsAndCreationsReactive"
                    }
                    else if(identityAccessPage === hiddenIdentityAccessPage){
                        endpointPathToUse = "/checkBrowserIncidentHidesHasRelatedProcessInjectionsAndCreationsReactive"
                    }
                    checkBrowserIncidentHasRelatedProcessInjectionsAndCreationsReactive(params.node.data.incidentId, params.node.data.zenGroupId, params.node.data.uniqueProcessIdentifier, path, endpointPathToUse).then(response => {
                        params.node.data.relatedProcessCreationExists = response.relatedProcessCreationExists
                        params.node.data.relatedProcessInjectionExists = response.relatedProcessInjectionExists
                        params.node.data.memoryFileExists = response.memoryFileExists
                        //Show the Related Processes Found text over Memory Download Available if either fields are true
                        if (response.relatedProcessCreationExists || response.relatedProcessInjectionExists) {
                            params.node.setDataValue("rootCauseAnalysis", "Related Processes Found")
                        } else {
                            //If we have memoryFileExists true then we can show some text, else show nothing
                            if(response.memoryFileExists){
                                params.node.setDataValue("rootCauseAnalysis", "Memory Diff Download Available")
                            }
                            else{
                                params.node.setDataValue("rootCauseAnalysis", " ") //lets cell know not to call checkBrowserIncidentHasRelatedProcessInjectionsAndCreationsReactive again
                            }
                        }
                    }).catch(function (error) {
                        params.node.setDataValue("rootCauseAnalysis", " ") //lets cell know not to call checkBrowserIncidentHasRelatedProcessInjectionsAndCreationsReactive again
                    })
                    params.node.data.rootCauseAnalysis = params.node.data.zenGroupId
                    return params.node.data.zenGroupId
                }
            },
            cellRenderer: function (params) {
                let spinnerDiv = ""
                let memoryFileDownloadIcon = ""
                let processCreationsLinkDiv = ""
                let processInjectionsLinkDiv = ""
                let path = params.node.data.path
                if (path && params.node.data.zenGroupId && params.node.data.uniqueProcessIdentifier !== null && params.node.data.uniqueProcessIdentifier !== undefined) {
                    if (params.node.data.rootCauseAnalysis === params.node.data.zenGroupId) {
                        spinnerDiv = <FontAwesomeIcon
                            className="contain fa-pulse mr-1"
                            icon="fa-light fa-spinner"
                            size="lg"
                            name="relatedProcessesLoading"
                        />
                    } else {
                        spinnerDiv = ""
                    }
                    //Check if we should show Memory file download icon
                    if (params.node.data.memoryFileExists === true) {
                        memoryFileDownloadIcon =
                            <div className={"mb-1"}>
                                <MuiIconButtonWithTooltip
                                    icon={ <FontAwesomeIcon
                                        className="object-contain"
                                        icon="fa-duotone fa-download"
                                        size="xs"
                                    />}
                                    tooltipTitle={"Click to download the Memory Diff File for this Identity Access. Please contact support for more information about this file."}
                                    tooltipPlacement={"bottom-start"}
                                    onClick={() => {
                                        if(params.node.data.incidentId){
                                            let endpointPathToUse = "/getBrowserIncidentCCDiffFileReactive"
                                            if(identityAccessPage === blockedIdentityAccessPage){
                                                endpointPathToUse = "/getBrowserIncidentBlocksCCDiffFileReactive"
                                            }
                                            else if(identityAccessPage === hiddenIdentityAccessPage){
                                                endpointPathToUse = "/getBrowserIncidentHidesCCDiffFileReactive"
                                            }
                                            getBrowserIncidentCCDiffFileReactive(params.node.data.incidentId, endpointPathToUse).then(response => {
                                                try{
                                                    let incidentName = params.node.data.incidentDisplayName
                                                    let bytes = base64ToArrayBuffer(response.data); // pass your byte response to this constructor
                                                    let blob = new Blob([bytes], {type: "application/octet-stream"});// change resultByte to bytes
                                                    //Add in local datetime string to filename down to the minutes, and this is in 24-hour time format
                                                    let today = new Date();
                                                    let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                                                    let time = today.getHours() + "-" + today.getMinutes();
                                                    let dateTime = date+'T'+time;
                                                    let link=document.createElement('a');
                                                    link.href=window.URL.createObjectURL(blob);
                                                    link.download=`${incidentName}-${dateTime}.ccdiff`;
                                                    link.click();
                                                }
                                                catch(error){
                                                    NotificationManager.error("Unexpected error making this request");
                                                }
                                            }).catch(function (error) {
                                                if(error.message){
                                                    NotificationManager.error(error.message);
                                                }
                                                else{
                                                    NotificationManager.error("Unexpected error making this request");
                                                }
                                            })
                                        }
                                        else{
                                            NotificationManager.error("Unexpected error making this request");
                                        }
                                    }}
                                />
                            </div>
                    }

                    //Links
                    if (params.node.data.relatedProcessCreationExists === true) {
                        processCreationsLinkDiv =
                            <Link to={{
                                pathname: "/private/processCreations"
                            }} state={{
                                uniqueProcessIdentifier: params.node.data.uniqueProcessIdentifier,
                                zenGroupIdClicked: params.node.data.zenGroupId,
                                childPath: path,
                                agentIdClicked: params.node.data.agentId,
                                browserAccessRootCause: true
                            }} className="">
                                <div className={"mb-1"}>
                                    <MuiIconButtonWithTooltip
                                        icon={
                                            <FontAwesomeIcon
                                                className="object-contain"
                                                icon="fa-duotone fa-magnifying-glass-arrow-right"
                                                size="xs"
                                            />
                                        }
                                        tooltipTitle={"Click to view related process creations"}
                                        tooltipPlacement={"bottom-start"}
                                    />
                                </div>
                            </Link>
                    }
                    if (params.node.data.relatedProcessInjectionExists === true) {
                        processInjectionsLinkDiv =
                            <Link to={{
                                pathname: "/private/processInjections"
                            }} state={{
                                uniqueProcessIdentifier: params.node.data.uniqueProcessIdentifier,
                                zenGroupIdClicked: params.node.data.zenGroupId,
                                agentIdClicked: params.node.data.agentId,
                                browserAccessRootCause: true
                            }} className="">
                                <div className={"mb-1"}>
                                    <MuiIconButtonWithTooltip
                                        icon={
                                            <FontAwesomeIcon
                                                className="object-contain"
                                                icon="fa-duotone fa-syringe"
                                                size="xs"
                                            />
                                        }
                                        tooltipTitle={"Click to view related process injections"}
                                        tooltipPlacement={"bottom-start"}
                                    />
                                </div>
                            </Link>
                    }
                }

                return (
                    <div className={"flex flex-nowrap items-center justify-start gap-x-0"}>
                        {spinnerDiv}
                        {memoryFileDownloadIcon}
                        {processCreationsLinkDiv}
                        {processInjectionsLinkDiv}
                        {params.node.data.rootCauseAnalysis}
                    </div>
                )
            }
        },
        { field: "hasUntrustedRemoteThreads", headerName: "Untrusted Remote Threads", initialWidth: 325,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                valueFormatter: processTrustStatusValueFormatter,
                values: ['FALSE', 'TRUE', 'UNKNOWN'],
                suppressSorting: false,
            },
            valueFormatter: processTrustStatusValueFormatter,
            sortable: true
        },
        { field: "hasModifiedMem", headerName: "Modified Memory", initialWidth: 230,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                valueFormatter: processTrustStatusValueFormatter,
                values: ['FALSE', 'TRUE', 'UNKNOWN'],
                suppressSorting: false,
            },
            valueFormatter: processTrustStatusValueFormatter,
            sortable: true
        },
        { field: "hasNoTrustedCert", headerName: "No Trusted Cert", initialWidth: 230,
            /*filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                valueFormatter: processTrustStatusValueFormatter,
                values: ['FALSE', 'TRUE', 'UNKNOWN'],
                suppressSorting: false,
            },*/
            filter: 'agMultiColumnFilter',
            filterParams: {
                //allow for two filters to show in filter menu, one for regular hasNoTrustedCert, and one for windows code
                filters: [
                    {
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            buttons: ["reset", "apply", "cancel"],
                            valueFormatter: processTrustStatusValueFormatter,
                            values: ['FALSE', 'TRUE', 'UNKNOWN'],
                            suppressSorting: false,
                        },
                    },
                    {
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            buttons: ["reset", "apply", "cancel"],
                            values: ['No Error', 'Disconnected Retrieving Cert Info', 'Timed Out Retrieving Cert Info'],
                            suppressSorting: true //don't sort because No Error should appear first
                        },
                    },
                ],
            },
            valueFormatter: processTrustStatusValueFormatter,
            cellRenderer: function(params){
                let iconDiv = ""
                if(params.node.data.windowsCode === 258){ //Timeout
                    iconDiv = <MuiIconButtonWithTooltip
                        icon={<TimerOffOutlinedIcon className={"cursor-pointer"}/>}
                        tooltipTitle={`Timeout occurred retrieving cert information from Windows`}
                        tooltipPlacement={"bottom-start"}
                    />
                }
                else if(params.node.data.windowsCode === 3221225527){ //Disconnect
                    iconDiv = <MuiIconButtonWithTooltip
                        icon={<VpnKeyOffOutlinedIcon className={"cursor-pointer"}/>}
                        tooltipTitle={`Disconnect occurred retrieving cert information from Windows. Reconnection was automatically restarted`}
                        tooltipPlacement={"bottom-start"}
                    />
                }
                return(
                    <div className={"flex flex-nowrap items-center gap-x-2"}>
                        {iconDiv}
                        {params.valueFormatted}
                    </div>
                )
            },
            sortable: true
        },
        { field: "certPublisherNames", headerName: "Cert Publisher Names List", hide: true, sortable: true, initialWidth: 500, suppressColumnsToolPanel: true, lockVisible: true,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],

                filterOptions: ['contains'],
                maxNumConditions: 2,
            }
        },
        { field: "agentVersionFormatted", headerName: "Agent Version", initialWidth: 225,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],

                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                maxNumConditions: 2,
            },
            sortable: true,
            valueFormatter: agentVersionFormatter,
            cellRenderer: function(params){
                let spinnerDiv = ""
                if(params.node.data.agentId === params.valueFormatted ){
                    spinnerDiv = <FontAwesomeIcon
                        className="contain fa-pulse"
                        icon="fa-light fa-spinner"
                        size="lg"
                        name="agentVersionLoading"
                    />
                }else{
                    spinnerDiv = ""
                }
                return(
                    <div id ="fortooltip" className={"flex flex-nowrap items-center justify-start gap-x-2"}>
                        {spinnerDiv}
                        {params.valueFormatted}
                    </div>
                )
            },


        },
        { field: "username", headerName: "Username", initialWidth: 320,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],

                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                maxNumConditions: 2,
            },
            sortable: true
        },
        { field: "userSid", headerName: "User Sid", hide: true, sortable: true, initialWidth: 500, suppressColumnsToolPanel: true, lockVisible: true,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],

                filterOptions: ['contains'],
                maxNumConditions: 2,
            }
        },
        { field: "currentLatestAgentVersion", headerName: "Latest Agent Version", initialWidth: 275,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],

                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                maxNumConditions: 2,
            },
            sortable: true,
        },
        { field: "collected", headerName: "Collected", initialWidth: 280,
            filter: 'agDateColumnFilter',
            filterParams:dateFilterParametersInHeaderSuppressSorting(2),
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        /*{
            //field that is set by us for getting either whitelist name or silent incident/response name(s)
            field: "hidingRule", headerName: "Hiding Rule", width : 430, sortable: false,
            valueFormatter: function(params){
                if(params.node.data.hidingRule){
                    return params.node.data.hidingRule
                }
                if(params.node.data.whitelistAppliedId){
                    //call for whitelist name that is associated with whitelistAppliedId
                    let whitelistId = params.node.data.whitelistAppliedId
                    findBySingleWhitelistAppliedIdReactive(whitelistId).then(function(response){
                        if(response.whitelistName !== null){
                            //params.node.setDataValue("hidingRule", response.whitelistName)
                            params.node.data.hidingRule = response.whitelistName
                            params.api.refreshCells({columns: ["hidingRule"], rowNodes: [params.node], suppressFlash: false, force: false})
                        }
                        else{
                            //params.node.setDataValue("hidingRule", "(The whitelist applied at the time of access was deleted)")
                            params.node.data.hidingRule = "(The tailored behavior applied at the time of access was deleted)"
                            params.api.refreshCells({columns: ["hidingRule"], rowNodes: [params.node], suppressFlash: false, force: false})
                        }
                        /!*if(response.whitelist !== null){
                            params.node.data.tooltipData = response.whitelist
                        }*!/
                    }).catch(function (error) {
                        //params.node.setDataValue("hidingRule", " ")
                        params.node.data.hidingRule = " "
                        params.api.refreshCells({columns: ["hidingRule"], rowNodes: [params.node], suppressFlash: false, force: false})
                    })
                    params.node.data.hidingRule = whitelistId
                }
                else{
                    //else if incident was not whitelisted then check the silent field
                    if(params.node.data.silent){
                        let incidentId = params.node.data.incidentId
                        findSilentIncidentNamesForSingleIncidentIdReactive(incidentId).then(function(silentIncidentsList){
                            let listString = " "
                            let namesSet = new Set();
                            if(silentIncidentsList && silentIncidentsList.length > 0){
                                silentIncidentsList.forEach(silentIncident => {
                                    if(silentIncident.silentIncidentDisplayName){
                                        namesSet.add(silentIncident.silentIncidentDisplayName)
                                    }
                                })
                                //convert set to array and join values together into one string to show in cell
                                listString = Array.from(namesSet).join(' | ')
                                //listString = Array.from(namesSet).join(', ')
                            }
                            //params.node.setDataValue("hidingRule", listString)
                            params.node.data.hidingRule = listString
                            params.api.refreshCells({columns: ["hidingRule"], rowNodes: [params.node], suppressFlash: false, force: false})
                        }).catch(function (error) {
                            //params.node.setDataValue("hidingRule", " ")
                            params.node.data.hidingRule = " "
                            params.api.refreshCells({columns: ["hidingRule"], rowNodes: [params.node], suppressFlash: false, force: false})
                        })
                        params.node.data.hidingRule = incidentId
                    }
                    else{
                        //don't show anything for incidents that aren't silent, but need to set dataValue so the spinner will stop in the cell renderer
                        //params.node.setDataValue("hidingRule", " ")
                        params.node.data.hidingRule = " "
                        params.api.refreshCells({columns: ["hidingRule"], rowNodes: [params.node], suppressFlash: false, force: false})
                    }
                }
                return null
            },
            cellRenderer: function(params){
                let spinnerDiv = ""
                if(params.node.data.hidingRule === null || params.node.data.hidingRule === undefined || params.node.data.hidingRule === params.node.data.whitelistAppliedId ||
                    params.node.data.hidingRule === params.node.data.incidentId){
                    spinnerDiv =
                        <div>
                            <FontAwesomeIcon
                                className="contain fa-pulse mr-1"
                                icon="fa-light fa-spinner"
                                size="lg"
                                name="hidingRuleSpinner"
                            />
                        </div>
                }
                return(
                    <div className={"flex flex-nowrap items-center justify-start gap-x-2"}>
                        {spinnerDiv}
                        {params.node.data.hidingRule}
                    </div>
                )
            },
        },*/
        { field: "accessLength", headerName: "Access Length", initialWidth: 225,
            filter: 'agNumberColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],

                filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
                maxNumConditions: 2,
            },
            sortable: true
        },
        { field: "accessOffset", headerName: "Access Offset", initialWidth: 225,
            filter: 'agNumberColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],

                filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
                maxNumConditions: 2,
            },
            sortable: true
        }
    ])
    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            filterParams: null,
            floatingFilter: true,
            headerClass: "border-0 border-b-0",
            cellClass: "outline:none",
            enableCellChangeFlash: true,
            autoHeight: false,
            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
        }
    )
    const sideBar = useMemo(() => {
        //Inside useMemo to help prevent the sidebar from re-rendering
        return getDefaultAgGridSidebarProps(325)
    }, []);
    const rowSelection = useMemo(() => {
        return {
            mode: 'singleRow',
            enableClickSelection: true,
            checkboxes: false,
            headerCheckbox: false
        };
    }, []);

    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            async function populate() {
                setIsLoading(false);
            }
            await populate();
        })()
        return () => controller?.abort();
    }, []);

    useEffect(() => {
        setChartIsLoading(true)
        //the data returned from this call is for the initial chart and does not include a whitelisted incident count
        chartsApiFunction(false).then(response => {
            setChartIsLoading(false)
            let incidentsPerGroupList = response.incidentsPerGroupList
            if(incidentsPerGroupList){
                let data = []
                for (let i in incidentsPerGroupList){
                    let group = findZenGroupById(incidentsPerGroupList[i].zenGroupId)
                    if(group && group.friendlyName){
                        //found group in session
                        data.push({"zenGroupId":incidentsPerGroupList[i].zenGroupId, "zenGroupName":group.friendlyName,
                            "countWithoutWhitelisted":incidentsPerGroupList[i].countWithoutWhitelisted,
                            "whitelistedIncidentsCount":incidentsPerGroupList[i].whitelistedIncidentsCount
                        })
                    }
                    else{
                        //else did not find group in session
                        data.push({"zenGroupId":incidentsPerGroupList[i].zenGroupId,
                            "countWithoutWhitelisted":incidentsPerGroupList[i].countWithoutWhitelisted,
                            "whitelistedIncidentsCount":incidentsPerGroupList[i].whitelistedIncidentsCount
                        })
                    }
                }
                data.sort((object1, object2) => (object1.zenGroupName?.toLowerCase() > object2.zenGroupName?.toLowerCase()) ? 1 : -1)
                setChartDataWithoutWhitelistedCount(data)
            }
            else{
                setChartDataWithoutWhitelistedCount([])
            }
        }).catch(function (error) {
            setChartIsLoading(false)
        })
    }, [])

    useEffect(() => {
        //the data returned from this call is for when the user toggles to see incident count with whitelisted incidents included
        chartsApiFunction(true).then(response => {
            let incidentsPerGroupList = response.incidentsPerGroupList
            if(incidentsPerGroupList){
                let data = []
                for (let i in incidentsPerGroupList){
                    let group = findZenGroupById(incidentsPerGroupList[i].zenGroupId)
                    if(group && group.friendlyName){
                        //found group in session
                        data.push({"zenGroupId":incidentsPerGroupList[i].zenGroupId, "zenGroupName":group.friendlyName,
                            "countWithoutWhitelisted":incidentsPerGroupList[i].countWithoutWhitelisted,
                            "whitelistedIncidentsCount":incidentsPerGroupList[i].whitelistedIncidentsCount
                        })
                    }
                    else{
                        //else did not find group in session
                        data.push({"zenGroupId":incidentsPerGroupList[i].zenGroupId,
                            "countWithoutWhitelisted":incidentsPerGroupList[i].countWithoutWhitelisted,
                            "whitelistedIncidentsCount":incidentsPerGroupList[i].whitelistedIncidentsCount
                        })
                    }
                }
                data.sort((object1, object2) => (object1.zenGroupName?.toLowerCase() > object2.zenGroupName?.toLowerCase()) ? 1 : -1)
                setChartDataWithWhitelistedCount(data)
                setIncludeWhitelistedToggleDisabled(false)
            }
            else{
                setChartDataWithWhitelistedCount([])
            }
        }).catch(function (error) {
        })
    }, [])

    const onIgnoreModalSubmit = async (data) => {
        if(selectedPaths.length < 1){
            NotificationManager.error("You must select at least one path to submit")
        }
        else{
            setShowIgnoreConfirmModal(true)
        }
    };
    const onSilentIncidentCreateModalSubmit = async (data) => {
        if(selectedPaths.length < 1){
            NotificationManager.error("You must select at least one path to submit")
        }
        else{
            setShowTurnIncidentSilentConfirmModal(true)
        }
    };

    const onWhitelistPath = async () => {
        try {
            if (clickedIconIncidentData && clickedIconIncidentData.zenGroupId && selectedPaths && selectedPaths.length > 0) {
                setIsLoading(true);
                let argumentsToSend = null;
                if(clickedIconIncidentData.programArgumentsEditedString){
                    argumentsToSend = clickedIconIncidentData.programArgumentsEditedString.trim();
                }
                let response = await userAddWhitelistsFromIncidentsPageReactive(selectedPaths, clickedIconIncidentData.zenGroupId, argumentsToSend)
                if(response.whitelistExistedAlreadyCount > 0){
                    if(selectedPaths.length === 1){
                        //user only tried to make 1 whitelist
                        NotificationManager.info(`This tailored behavior existed already and was not created again`);
                        setShowIgnoreConfirmModal(false)
                        setIsLoading(false);
                        return
                    }
                    else{
                        //user selected more than one path to make whitelists for
                        NotificationManager.info(`${response.whitelistExistedAlreadyCount} of the tailored behaviors
                        existed already and 
                        ${response.whitelistExistedAlreadyCount > 1 ? "were" : "was"} not created`);
                        setShowIgnoreConfirmModal(false)
                        setIsLoading(false);
                        return
                    }
                }
                else{
                    NotificationManager.success("Tailored Behavior(s) added successfully");
                }
                reset({})
                setShowIgnoreModal(false)
                setSuggestedPaths([])
                setSelectedPaths([])
                setShowIgnoreConfirmModal(false)
                setIgnoreButtonDisabled(true)
            }
        } catch (error) {
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Error adding tailored behavior(s)");
            }
        }
        setIsLoading(false);
        // setShowIgnoreModal(false)
        // setShowIgnoreConfirmModal(false)
    };

    const onConfirmCreateSilentIncidents =  () => {
        if(clickedIconIncidentData && clickedIconIncidentData.incidentId && selectedPaths && selectedPaths.length > 0){
            let argumentsToSend = null;
            if(clickedIconIncidentData.programArgumentsEditedString){
                argumentsToSend = clickedIconIncidentData.programArgumentsEditedString.trim();
            }
            setIsLoading(true);
            createSilentIncidentsFromIncidentsPageReactive(selectedPaths, clickedIconIncidentData.incidentId, argumentsToSend).then(response => {
                NotificationManager.success("Silent Response(s) successfully created/enabled, you may need to refresh the grid periodically to see updates for any affected accesses within the same group");
                setIsLoading(false)
                reset({})
                setClickedIconIncidentData()
                setShowTurnIncidentSilentModal(false)
                setSuggestedPaths([])
                setSelectedPaths([])
                setShowTurnIncidentSilentConfirmModal(false)
                refreshGrid().then()
            }).catch(function (error) {
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Unexpected error making this request");
                }
                setIsLoading(false)
            })
        }
    }

    return (
        <div className="flex flex-col h-full">
            <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <title>{pageTitle}</title>
                <script src="https://js.stripe.com/v3/"/>
                <link
                    href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap"
                    rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            {/*Incident Details Modal*/}
            <Modal contentLabel="Access Details" isOpen={openResponseHistoryModal}
                   onRequestClose={() => {
                       setActiveIncident(null);
                       setSuggestedPaths([])
                       setSelectedPaths([])
                       setOpenResponseHistoryModal(false);
                       setClickedIconIncidentData()
                   }} shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-6xl max-w-6xl inset-y-10 mx-auto rounded-2xl overflow-auto`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"

            >
                {/*Cert Publisher Names Modal*/}
                <Modal contentLabel="Certificate Publisher Names" isOpen={showCertificateModal}
                       onRequestClose={() => {
                           setShowCertificateModal(false)
                       }} shouldCloseOnOverlayClick={true}
                       className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white max-w-xl inset-y-10 mx-auto rounded-2xl overflow-auto`}
                       overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll">

                    <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Certificate Publisher Names</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    setShowCertificateModal(false)
                                }}
                            />
                        </div>
                        <hr className="h-0.5"/>
                        <div className="">
                            {clickedIconIncidentData && clickedIconIncidentData.certPublisherNames && clickedIconIncidentData.certPublisherNames.length > 0 && clickedIconIncidentData.certPublisherNames.map((certName, i) => {
                                return (
                                    <div key={i} className="mb-3 ml-3 flex flex-row">
                                        {`${certName}`}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Modal>
                <div className="flex flex-col w-full">
                    <div className="flex flex-row justify-between p-8">
                        <h1 className="font-bold text-3xl">Access Details</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                setActiveIncident(null);
                                setSuggestedPaths([])
                                setSelectedPaths([])
                                setOpenResponseHistoryModal(false);
                                setClickedIconIncidentData()
                            }}
                        />
                    </div>
                    <hr className="h-0.5 ml-8 mr-8"/>
                    <div className="flex flex-1 flex-col p-8 w-full">
                        <DetailsGrid
                            headers={[
                                {field: "description", headerName: "Description", initialWidth: 65},
                                {field: "value", headerName: "Value"}
                            ]}
                            data={
                                clickedIconIncidentData
                                    ? processData(clickedIconIncidentData)
                                    : []
                            }
                            rowSelection={rowSelection}
                        />
                    </div>
                    <div className="mx-8 mb-5">
                        <ThemeProvider theme = {buttonTheme}>
                            <Button variant={"contained"}
                                    color={"primary"}
                                    onClick={() => {
                                        setShowCertificateModal(true)
                                    }}
                                    disabled={!clickedIconIncidentData || !clickedIconIncidentData.certPublisherNames || !clickedIconIncidentData.certPublisherNames.length > 0}
                            >
                            View Certificate Publisher Names
                            </Button>
                        </ThemeProvider>
                    </div>
                </div>
            </Modal>
            {/*Ignore Modal*/}
            <Modal contentLabel="Exclude Access"
                   isOpen={showIgnoreModal}
                   onRequestClose={() => {
                       reset({})
                       setShowIgnoreModal(false)
                       setSuggestedPaths([])
                       setSelectedPaths([])
                       setClickedIconIncidentData()
                   }}
                   shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-3xl max-w-3xl inset-y-10 mx-auto rounded-2xl overflow-auto`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                {/*Confirm Ignore Incident Modal*/}
                <ConfirmationModal
                    text="You are about to exclude this access in the future, would you like to continue?"
                    onConfirm={() => onWhitelistPath()}
                    onClose={() => {
                        setShowIgnoreConfirmModal(false)
                    }}
                    opened={showIgnoreConfirmModal}
                />
                {/*Confirm Ignore Incident Modal*/}
                <ConfirmationModal
                    text="Are you sure you want to submit this access for review?"
                    onConfirm={async () => {
                        if (clickedIconIncidentData && clickedIconIncidentData.incidentId) {
                            try {
                                let endpointPathToUse = "/submitBrowserIncidentForReviewReactive"
                                if(identityAccessPage === blockedIdentityAccessPage){
                                    endpointPathToUse = "/submitBrowserIncidentBlockForReviewReactive"
                                }
                                else if(identityAccessPage === hiddenIdentityAccessPage){
                                    endpointPathToUse = "/submitBrowserIncidentHideForReviewReactive"
                                }
                                setIsLoading(true)
                                await submitBrowserIncidentForReviewReactive(clickedIconIncidentData.incidentId, endpointPathToUse)
                                NotificationManager.success("Successfully submitted this access for review")
                                reset({})
                                setShowIgnoreModal(false)
                                setSuggestedPaths([])
                                setSelectedPaths([])
                                setShowIgnoreConfirmModal(false)
                                setIgnoreButtonDisabled(true)
                                setShowSendReviewConfirmModal(false)
                                setClickedIconIncidentData()
                            } catch (error) {
                                if (error.message) {
                                    NotificationManager.error(error.message);
                                } else {
                                    NotificationManager.error("Error submitting this access for review")
                                }
                            }
                            setIsLoading(false)
                        }
                    }}
                    onClose={() => {
                        setShowSendReviewConfirmModal(false)
                    }}
                    opened={showSendReviewConfirmModal}
                />
                <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4"
                      onSubmit={handleSubmit(onIgnoreModalSubmit)}>
                    <div className="flex flex-1 flex-col">
                        {/*Title with exit button*/}
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Exclude</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    reset({})
                                    setShowIgnoreModal(false)
                                    setSuggestedPaths([])
                                    setSelectedPaths([])
                                    setClickedIconIncidentData()
                                }}
                            />
                        </div>
                        <hr className="mt-3 h-0.5"/>
                        {/*Form content*/}
                        <div className="ml-1 mt-5">
                            <div><label>Access
                                Name:</label>{` ${(clickedIconIncidentData && clickedIconIncidentData.incidentDisplayName) ? clickedIconIncidentData.incidentDisplayName : ""}`}
                            </div>
                        </div>
                        <div className="ml-1 mt-5">
                            <div><label>Original Executable
                                Path:</label>{` ${(clickedIconIncidentData && clickedIconIncidentData.path) ? clickedIconIncidentData.path : ""}`}
                            </div>
                        </div>
                        <div className="ml-1 mt-5">
                            <div><label>Original Program
                                Arguments:</label>{` ${(clickedIconIncidentData && clickedIconIncidentData.programArgumentsEditedString) ? clickedIconIncidentData.programArgumentsEditedString : ""}`}
                            </div>
                        </div>
                        <hr className="mt-3 h-0.5"/>
                        <div className="ml-1 mt-5">
                            <div className="flex flex-row">
                            <label>Suggested executable paths. All selected paths will be excluded:</label>
                                {showNoSuggestedPathsFoundIcon &&
                                    <MuiIconWithTooltip
                                        icon={
                                            <FontAwesomeIcon
                                                className="ml-1 object-contain"
                                                icon="fa-light fa-circle-info"
                                                size="lg"
                                            />
                                        }
                                        tooltipTitle={"No suggested executable paths were found, you may submit this access for review if desired"}
                                        tooltipPlacement={"bottom-start"}
                                    />
                                }
                            </div>
                            {suggestedPaths && suggestedPaths.map((path) => {
                                return (
                                    <div key={path} className="ml-1 mt-5 flex flex-row">
                                        <input
                                            className="mr-2 checkbox"
                                            type="checkbox"
                                            onChange={(value) => {
                                                let selectedPathsArray = selectedPaths.slice();
                                                if (selectedPathsArray.includes(path)) {
                                                    let index = selectedPathsArray.indexOf(path);
                                                    selectedPathsArray.splice(index, 1);
                                                    setSelectedPaths(selectedPathsArray);
                                                } else {
                                                    selectedPathsArray.push(path);
                                                    setSelectedPaths(selectedPathsArray);
                                                }
                                            }}
                                        />
                                        <label>{path}</label>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="ml-1 mt-5 flex flex-row flex-wrap items-center gap-x-3 gap-y-3 mb-5">
                            <label>Not seeing what you need?</label>
                            <ThemeProvider theme = {roundButtonTheme}>
                                <Button variant={"contained"}
                                        color={"secondary"}
                                        onClick={() => {
                                            setShowSendReviewConfirmModal(true)
                                        }}
                                        type="button">
                                    Submit Access for Review
                                </Button>
                            </ThemeProvider>
                        </div>
                        <ThemeProvider theme = {buttonTheme}>
                            <Button variant={"contained"}
                                    color={"primary"}
                                    type={"submit"}
                                    disabled={ignoreSubmitButtonDisabled}
                            >
                            Submit
                            </Button>
                        </ThemeProvider>
                    </div>

                </form>
            </Modal>
            {/*Turn Incident to Silent Modal*/}
            <Modal contentLabel="Silent Response"
                   isOpen={showTurnIncidentSilentModal}
                   onRequestClose={() => {
                       reset({})
                       setShowTurnIncidentSilentModal(false)
                       setSuggestedPaths([])
                       setSelectedPaths([])
                       setClickedIconIncidentData()
                   }}
                   shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-3xl max-w-3xl inset-y-10 mx-auto rounded-2xl overflow-auto`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                {/*Confirm Silence Incident Modal*/}
                <ConfirmationModal
                    text="You are about to create/enable a silent response for each selected path and access program arguments (if present), would you like to continue?"
                    onConfirm={() => {
                        onConfirmCreateSilentIncidents()
                    }}
                    onClose={() => {
                        setShowTurnIncidentSilentConfirmModal(false)
                    }}
                    opened={showTurnIncidentSilentConfirmModal}
                />
                <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4"
                      onSubmit={handleSubmit(onSilentIncidentCreateModalSubmit)}>
                    <div className="flex flex-1 flex-col">
                        {/*Title with exit button*/}
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Silence Access</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    reset({})
                                    setShowTurnIncidentSilentModal(false)
                                    setSuggestedPaths([])
                                    setSelectedPaths([])
                                    setClickedIconIncidentData()
                                }}
                            />
                        </div>
                        <hr className="mt-3 h-0.5"/>
                        {/*Form content*/}
                        <div className="ml-1 mt-5">
                            <div><label>Access
                                Name:</label>{` ${(clickedIconIncidentData && clickedIconIncidentData.incidentDisplayName) ? clickedIconIncidentData.incidentDisplayName : ""}`}
                            </div>
                        </div>
                        <div className="ml-1 mt-5">
                            <div><label>Original Executable
                                Path:</label>{` ${(clickedIconIncidentData && clickedIconIncidentData.path) ? clickedIconIncidentData.path : ""}`}
                            </div>
                        </div>
                        <div className="ml-1 mt-5">
                            <div><label>Original Program
                                Arguments:</label>{` ${(clickedIconIncidentData && clickedIconIncidentData.programArgumentsEditedString) ? clickedIconIncidentData.programArgumentsEditedString : ""}`}
                            </div>
                        </div>
                        <hr className="mt-3 h-0.5"/>
                        <div className="ml-1 mt-5">
                            <div className="flex flex-row">
                                <label>Suggested executable paths. All selected paths will be silenced:
                                </label>
                                {showNoSuggestedSilentIncidentPathsFoundIcon &&
                                    <MuiIconWithTooltip
                                        icon={
                                            <FontAwesomeIcon
                                                className="ml-1 object-contain"
                                                icon="fa-light fa-circle-info"
                                                size="lg"
                                            />
                                        }
                                        tooltipTitle={`No suggested executable paths were found. You may click the "Take me to Silent Responses Page" button that will redirect you to the
                                        Silent Responses page. This will also automatically supply the create new Silent Response form with the response's group, path, and
                                        program arguments (if present).`}
                                        tooltipPlacement={"bottom-start"}
                                    />
                                }
                            </div>
                            {suggestedPaths && suggestedPaths.map((path) => {
                                return (
                                    <div key={path} className="ml-1 mt-5 flex flex-row">
                                        <input
                                            className="mr-2 checkbox"
                                            type="checkbox"
                                            onChange={(value) => {
                                                let selectedPathsArray = selectedPaths.slice();
                                                if (selectedPathsArray.includes(path)) {
                                                    let index = selectedPathsArray.indexOf(path);
                                                    selectedPathsArray.splice(index, 1);
                                                    setSelectedPaths(selectedPathsArray);
                                                } else {
                                                    selectedPathsArray.push(path);
                                                    setSelectedPaths(selectedPathsArray);
                                                }
                                            }}
                                        />
                                        <label>{path}</label>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="ml-1 mt-5 flex flex-row flex-wrap items-center gap-x-3 gap-y-3 mb-5">
                            <label>Not seeing what you need?</label>
                            <div className="mt-2">
                                <MuiIconWithTooltip
                                    icon={
                                        <FontAwesomeIcon
                                            className="ml-1 object-contain"
                                            icon="fa-light fa-circle-info"
                                            size="lg"
                                        />
                                    }
                                    tooltipTitle={"Clicking this button will redirect you to the Silent Responses page and automatically supply the create new Silent\n" +
                                        "                                                    Response form with the response's group, path, and program arguments (if present)."}
                                    tooltipPlacement={"bottom-start"}
                                />
                            </div>
                            <Link to={{
                                pathname: "/private/silentResponses"
                            }} state={{
                                showCreateModal: true,
                                path: clickedIconIncidentData ? clickedIconIncidentData.path.trim() : null,
                                zenGroupId: clickedIconIncidentData ? clickedIconIncidentData.zenGroupId : null,
                                programArguments: clickedIconIncidentData ? clickedIconIncidentData.programArgumentsEditedString.trim() : null
                            }} className="mr-2">
                                <ThemeProvider theme = {roundButtonTheme}>
                                    <Button variant={"contained"}
                                            color={"secondary"}>
                                    Take me to Silent Responses Page
                                    </Button>
                                </ThemeProvider>
                            </Link>
                        </div>

                        <ThemeProvider theme = {buttonTheme}>
                            <Button variant={"contained"}
                                    color={"primary"}
                                    type={"submit"}
                                    disabled={silentIncidentTurnOnModalButtonDisabled}
                            >
                            Submit
                            </Button>
                        </ThemeProvider>
                    </div>

                </form>
            </Modal>
            <div className="flex flex-1 flex-row h-full overflow-y-auto">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col flex-nowrap gap-y-3 mt-8 ml-5 mr-10 h-full">
                    {privatePageHeaderHelper(pageTitle)}
                    <hr className=" bg-black h-0.5"/>
                    <div className="flex flex-col gap-y-3">
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={chartToggled}
                                        name="incidentsPerGroupToggle"
                                        onChange={e => {
                                            setChartToggled(e.target.checked)
                                            updateChartVisibilitySettingInSession(chartSessionVariableName, e.target.checked, updateIdentityIncidentChartVisibilityReactive)
                                        }}
                                    />
                                }
                                                  label={chartToggled ? "Showing Accesses Per Group Chart" : "Hiding Accesses Per Group Chart"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`${chartToggled ? 'block' : 'hidden'} flex flex-row items-center`}>
                            {/*      <Toggle
                                        disabled={includeWhitelistedToggleDisabled}
                                        checked={includeWhitelistedToggled}
                                        name="includeWhitelistedIncidentsToggle"
                                        onToggle={e => setIncludeWhitelistedToggled(e.target.checked)}
                                    />*/}
                            {/*                  <label>
                                        {includeWhitelistedToggled ? "Include Excluded Incidents Count" : "Hide Excluded Incidents Count"}
                                    </label>*/}
                        </div>
                    </div>
                    {/*Incidents Chart*/}
                    <div className={`chartContainer ${chartToggled ? `block` : `hidden`} relative`}>
                        <AgChartsReact options={
                            {
                                autoSize: true,
                                data: chartDataWithoutWhitelistedCount,
                                title: {
                                    text: 'Identity Accesses Per Group',
                                    fontSize: 18,
                                },
                                subtitle: {
                                    text: 'Over the last 30 days',
                                    fontSize: 15,
                                    spacing: 15
                                },
                                series: [
                                    {
                                        type: 'bar',
                                        xKey: 'zenGroupId',
                                        yKey: 'countWithoutWhitelisted',
                                        yName: 'Number of Accesses',
                                        fill: '#e76a24',
                                        stroke: '#c2c2c2',
                                        highlightStyle: {
                                            item: {
                                                fill: "#E8E8E8",
                                                stroke: "#181818",
                                                strokeWidth: 1
                                            },
                                        },
                                        tooltip: { //for hovering over a column
                                            renderer: function (params) {
                                                let content = params.datum.zenGroupName + ": " + params.datum[params.yKey]
                                                return {
                                                    title: params.yName,
                                                    content: content
                                                };
                                            },
                                        },
                                    },
                                ],
                                axes: [
                                    {
                                        type: 'category',
                                        position: 'bottom',
                                        keys: ["zenGroupId"],
                                        label: {
                                            rotation: 50,
                                            formatter: function (params) {
                                                //The params include the zenGroupId (params.value) and the index of the label in the chart data array, so we can access the object and format return value
                                                let index = params.index //index of the chart data array
                                                if (chartDataWithoutWhitelistedCount && index !== undefined && index !== null) {
                                                    try {
                                                        let object = chartDataWithoutWhitelistedCount[index]
                                                        if (object) {
                                                            let name = params.value //initialize to zenGroupId (params.value) in case no zenGroupName found
                                                            if (object.zenGroupName) {
                                                                name = object.zenGroupName
                                                            }
                                                            if (object.countWithoutWhitelisted !== null && object.countWithoutWhitelisted !== undefined) {
                                                                return `${name} (${object.countWithoutWhitelisted})`
                                                            } else {
                                                                //should not get to here but default to return name
                                                                return name
                                                            }
                                                        }
                                                    } catch (e) {
                                                    }
                                                }
                                                return params.value //if we get to here then something went wrong, return zenGroupId (params.value)
                                            },
                                            fontSize: 11
                                        },
                                    },
                                    {
                                        type: 'number',
                                        position: 'left',
                                    },
                                ],
                                legend: {
                                    spacing: 40,
                                    position: 'top',
                                    enabled: true
                                },
                            }
                        }/>
                        <BackDropChartLoadingOverlay opened={chartIsLoading}/>
                    </div>
                    <hr className="bg-black h-0.5"/>
                    <div className="flex flex-row justify-between gap-x-1 gap-y-3">
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <GridColumnFilterStateSaving
                                useFilterStateSettingToggled={useFilterStateSettingToggled}
                                setUseFilterStateSettingToggled={setUseFilterStateSettingToggled}
                                toggleUpdateUseFilterState={toggleUpdateUseFilterState}
                                useColumnStateSettingToggled={useColumnStateSettingToggled}
                                setUseColumnStateSettingToggled={setUseColumnStateSettingToggled}
                                toggleUpdateUseColumnState={toggleUpdateUseColumnState}
                                showAllTelemetryDataToggled={showAllTelemetryDataToggled}
                                toggleUpdateShowAllTelemetryData={toggleUpdateShowAllTelemetryData}
                            />
                        </div>
                        <div className={"flex flex-row flex-wrap gap-y-3 gap-x-8 self-end justify-end"}>
                            <ClickToShowColumnOptionsWithToggleButtonGroup
                                columnMode={columnMode} setColumnMode={setColumnMode}
                                gridColumnStateSessionVariableName={gridColumnStateSessionVariableName}
                                gridApi={gridApi}
                                minColumnIds={minColumnIds} medColumnIds={medColumnIds}
                                updateGridColumnModeFunction={updateBrowserDataIncidentsGridColumnModeReactive}/>
                            <ClearRefresh gridApi={gridApi}
                                          refreshGridFunction={refreshGrid}/>
                        </div>
                    </div>
                    <div className="h-full flex flex-col gap-y-5" id="gridRoot">
                        {getGrid()}
                        <Footer/>
                    </div>
                </div>
            </div>
            <NotificationContainer/>
        </div>
    );

    function toggleUpdateUseFilterState(toggleSetting) {
        updateUseFilterStateHelper(toggleSetting, 'browserDataIncidentsGridFilterState', updateBrowserDataIncidentsGridUseFilterStateReactive);
    }

    function toggleUpdateUseColumnState(toggleSetting) {
        updateUseColumnStateHelper(toggleSetting, gridColumnStateSessionVariableName, updateBrowserDataIncidentsGridUseColumnStateReactive);
    }
    function toggleUpdateShowAllTelemetryData(toggleSetting){
        //Make sure gridApi is initialized, else do nothing
        if(gridApi !== null && gridApi !== undefined){
            setShowAllTelemetryDataToggled(toggleSetting)
            onShowAllTelemetryDataChangedHelper(showAllIdentityIncidentsTelemetryVariable, toggleSetting, "browserDataIncidentsGridFilterState", updateShowAllIdentityIncidentsTelemetryReactive)
            let dataSource = getDatasourceForGrid(toggleSetting, gridQueryFunction)
            gridApi.setGridOption("serverSideDatasource", dataSource);
        }
    }

    function initializeWhitelistModalForIconClick(incidentData) {
        setSelectedPaths([])
        setClickedIconIncidentData(incidentData)
        //Temp having submit access for review as only option on browser incident modal for whitelist.
        setIgnoreSubmitButtonDisabled(true)
        setShowNoSuggestedPathsFoundIcon(true)
        /*setIgnoreSubmitButtonDisabled(false)
        setShowNoSuggestedPathsFoundIcon(false)*/
        //load suggested paths and programArgs
        findSuggestedPathsReactive(incidentData?.path).then(response => {
            //Temp having submit access for review as only option on browser incident modal for whitelist.
            setSuggestedPaths([])
            setIgnoreSubmitButtonDisabled(true)
            setShowNoSuggestedPathsFoundIcon(true)
            /*if(response.suggestedPaths && response.suggestedPaths.length > 0){
                setSuggestedPaths(response.suggestedPaths)
            }
            else{
                setSuggestedPaths([])
                setIgnoreSubmitButtonDisabled(true)
                setShowNoSuggestedPathsFoundIcon(true)
            }*/
        }).catch(function (error) {
            setSuggestedPaths([])
            setIgnoreSubmitButtonDisabled(true)
            setShowNoSuggestedPathsFoundIcon(true)
        })
        setShowIgnoreModal(true)
    }

    function initializeDetailsModalForIconClick(incidentData) {
        setClickedIconIncidentData(incidentData)
        setOpenResponseHistoryModal(true)
    }

    function initializeTurnSilentSettingToOffForIconClick(incidentData) {
        setClickedIconIncidentData(incidentData)
        if (incidentData && incidentData.incidentId) {
            userSetIncidentAsNotSilentReactive(incidentData.incidentId).then(response => {
                NotificationManager.success("Successfully queued update, you may need to refresh the grid periodically to see updates for any affected accesses within the same group")
                setClickedIconIncidentData()
                refreshGrid().then()
            }).catch(function (error) {
                if (error.message) {
                    NotificationManager.error(error.message);
                } else {
                    NotificationManager.error("Unexpected error completing this request.");
                }
            })
        }
    }

    function initializeTurnSilentSettingToOnForIconClick(incidentData) {
        //mirroring this program arguments replacement, don't think we need to do this though
        let data = incidentData
        let programArgumentsString = ""
        if (data) {
            programArgumentsString = removePathFromStartOfProgramArgumentsHelper(data.path, data.programArguments)
        }

        data.programArgumentsEditedString = programArgumentsString
        setClickedIconIncidentData(data)
        setShowNoSuggestedSilentIncidentPathsFoundIcon(false)
        setSilentIncidentTurnOnModalButtonDisabled(false)
        setSelectedPaths([])
        findSuggestedSilentIncidentPathsReactive(incidentData.path, incidentData.incidentId).then(suggestedPaths => {
            if (suggestedPaths && suggestedPaths.length > 0) {
                setSuggestedPaths(suggestedPaths)
            } else {
                setSuggestedPaths([])
                setSilentIncidentTurnOnModalButtonDisabled(true)
                setShowNoSuggestedSilentIncidentPathsFoundIcon(true)
            }
        }).catch(function (error) {
            setSuggestedPaths([])
            setSilentIncidentTurnOnModalButtonDisabled(true)
            setShowNoSuggestedSilentIncidentPathsFoundIcon(true)
        })
        setShowTurnIncidentSilentModal(true)
    }

    function getGrid() {
        return (
            <Grid
                onClickRow={(data) => {
                    setActiveIncident(data[0])
                }}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                sideBar={sideBar}
                rowSelection={rowSelection}
                setGridApi={setGridApi}
                columnMode={columnMode}
                setColumnMode={setColumnMode}
                incidentLocation={incidentLocation}
                showAllTelemetryDataToggled={showAllTelemetryDataToggled}
                setShowAllTelemetryDataToggled={setShowAllTelemetryDataToggled}
                gridQueryFunction={gridQueryFunction}
                identityAccessPage={identityAccessPage}
            />
        );
    }

    async function refreshGrid() {
        if (gridApi) {
            await refreshGridZenGroupAndAgentInformation(gridApi, "incidentId", "id", findByIdApiFunction,
                keysToRefresh, "agentId", null, true)
            //gridApi.refreshCells({columns: ["hidingRule"], suppressFlash: true, force: true})
        }
    }

    function applicationFieldValueFormatter(params) {
        //replace '_' with a space
        if (params.value) {
            try {
                return params.value.replace(/_/g, ' ');
            } catch (e) {
            }
        }
        return params.value
    }

    function processTrustStatusValueFormatter(params) {
        if (params && params.value) {
            switch (params.value) {
                case "UNKNOWN":
                    return "Unknown"
                case "TRUE":
                    return "True"
                case "FALSE":
                    return "False"
                default:
                    return "Unknown";
            }
        }
        return "Unknown"
    }

}

//Standardizing datasource for browser incidents grid since the telemetry toggle and initial onGridReady will need to set the datasource
function getDatasourceForGrid(localShowAllTelemetryToggled, gridQueryFunction){
    return {
        getRows: async function (params) {
            try{
                let start = params.request.startRow;
                let end = params.request.endRow;
                let count = end - start;
                let page = Math.floor(start / count)
                let singleConditionFilters = masterFilterHelper(params.request.filterModel, true)
                let multiConditionFilters = serverSideFilterHelperHandleMultiConditionChecks(params.request.filterModel)
                let sortModel = params.request.sortModel;
                let sortModelForRequest = null;
                if(sortModel && sortModel.length > 0){
                    try{ //extra precaution for array access on the sort model so it does not break
                        if(sortModel[0].colId && sortModel[0].sort){
                            sortModelForRequest = sortModel[0]
                        }
                    }
                    catch(error){
                        console.log("Error generating sort request")
                    }
                }
                var data = await gridQueryFunction(count, page, sortModelForRequest, singleConditionFilters, multiConditionFilters, localShowAllTelemetryToggled);
                let incidents = data.incidents
                let finalPage = data.finalPage
                let lastIdx = undefined
                if (finalPage) {
                    lastIdx = (count * page) + incidents.length
                }

                params.api.deselectAll();

                const success = (incidents.length !== 0);
                if (success) {
                    params.api.setGridOption("loading", false)
                    params.success({
                        rowData: incidents, rowCount: lastIdx
                    })
                } else {
                    params.api.showNoRowsOverlay();
                    params.success({
                        rowData: incidents, rowCount: lastIdx
                    }) //to stop loading circle from spinning and make it disappear, nothing in incidents.
                    params.fail();
                }
            }
            catch(error){
                //console.log(error);
                params.api.showNoRowsOverlay();
                params.fail();
            }
        }
    }
}

let saveFilterChanges = true //we don't want to save filters if user clicks link in identity access notification link

class Grid extends Component {
    constructor(props, onClickRow, filterVals, columnDefs) {
        super(props);
    }

    onFirstDataRendered = (params) => {

    };
    onCellValueChanged = (event) => {
        //console.log("cell change old value: "+event.oldValue+" and new value "+event.newValue);
    }
    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        if (params.source !== "api" && params.source !== "gridOptionsChanged") {
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateBrowserDataIncidentsGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateBrowserDataIncidentsGridColumnModeReactive, customColumnModeText)
        } else if (params.source === "api" && params.type === "sortChanged") {
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateBrowserDataIncidentsGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateBrowserDataIncidentsGridColumnModeReactive, customColumnModeText)
        }
    }
    getContextMenuItems = (params) => {
        return [
            "resetColumns",
            "autoSizeAll"
        ];
    };
    cellEditingStoppedFunctionCaller = (event) => {
        let gridApi = event.api
        if(event.column.colId === "incidentDisplayName"){
            if(event.newValue && event.newValue.trim().length > 0){
                if(event.newValue === event.oldValue){
                    event.data.incidentDisplayName = event.oldValue
                    gridApi.refreshCells({suppressFlash: true})
                    return;
                }
                if(!event.data.incidentId || !event.data.zenGroupId){
                    NotificationManager.error("Error updating access name")
                    event.data.incidentDisplayName = event.oldValue
                    gridApi.refreshCells({suppressFlash: true})
                    return;
                }
                let endpointPathToUse = "/changeBrowserDataIncidentNameReactive"
                if(this.props.identityAccessPage === blockedIdentityAccessPage){
                    endpointPathToUse = "/changeBrowserDataIncidentBlockNameReactive"
                }
                else if(this.props.identityAccessPage === hiddenIdentityAccessPage){
                    endpointPathToUse = "/changeBrowserDataIncidentHideNameReactive"
                }
                changeBrowserDataIncidentNameReactive(event.newValue.trim(), event.data.zenGroupId, event.data.incidentId, endpointPathToUse).then(function(response){
                    if(response){
                        NotificationManager.success("Successfully changed this access's name")
                        event.node.setDataValue("incidentDisplayName", event.newValue.trim());
                    }
                }).catch(function(error){
                    if(error.message){
                        NotificationManager.error(error.message)
                    }
                    else{
                        NotificationManager.error("Error changing access name")
                    }
                    event.data.incidentDisplayName = event.oldValue
                    gridApi.refreshCells({suppressFlash: true})
                })
            }
            else{
                event.data.incidentDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true})
            }
        } else if(event.column.colId === "zenGroupDisplayName"){
            handleGroupColumnChangeNameOnly(event)
        } else if(event.column.colId === "agentDisplayName"){
            if(event.newValue && event.newValue.trim().length > 0){
                if(event.newValue === event.oldValue){
                    event.data.agentDisplayName = event.oldValue
                    gridApi.refreshCells({suppressFlash: true})
                    return;
                }
                if(!event.data.agentId){ //check to see if there is an agent associated with the license first.
                    NotificationManager.error("There is no agent associated with this access.")
                    event.data.agentDisplayName = event.oldValue
                    gridApi.refreshCells({suppressFlash: true})
                    return;
                }
                if(!event.data.zenGroupId){
                    NotificationManager.error("There is no zenGroup associated with this agent.")
                    event.data.agentDisplayName = event.oldValue
                    gridApi.refreshCells({suppressFlash: true})
                    return;
                }
                changeAgentNameReactive(event.newValue.trim(), event.data.zenGroupId, event.data.agentId).then(function(response){
                    NotificationManager.success("Successfully changed this agent's name")
                    event.node.setDataValue("agentDisplayName", event.newValue.trim());
                }).catch(function(error){
                    if(error.message){
                        NotificationManager.error(error.message)
                    }
                    else{
                        NotificationManager.error("Error changing agent name")
                    }
                    event.data.agentDisplayName = event.oldValue
                    gridApi.refreshCells({suppressFlash: true})
                })
            }
            else{
                //NotificationManager.error("Agent Names Cannot Be Blank")
                event.data.agentDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true})
            }
        }
    }
    onGridReady = async (gridReadyParams) => {
        // Disable text selection on the page while holding shift or control (to allow grid selections to be done easily without selecting all text)
        ["keyup", "keydown"].forEach((event) => {
            window.addEventListener(event, (e) => {
                document.onselectstart = function () {
                    return !(e.shiftKey || e.ctrlKey);
                }
            });
        });

        let filterForNotification = false
        saveFilterChanges = true //reset to true
        if (this.props.incidentLocation && this.props.incidentLocation.state) {
            let loginURLSearchParams = this.props.incidentLocation.state.loginURLSearchParams
            if (loginURLSearchParams !== null && loginURLSearchParams !== undefined && loginURLSearchParams.trim().length > 0) {
                try {
                    let response = await findNotificationEventForIdentityIncidentAutoFilter(loginURLSearchParams)
                    let zenGroupId = response.zenGroupId
                    let zenGroup = findZenGroupById(zenGroupId)
                    let queryStartDateMillis = response.queryStartDateMillis
                    let queryEndDateMillis = response.queryEndDateMillis
                    if (zenGroupId && queryStartDateMillis && queryEndDateMillis && zenGroup && zenGroup.friendlyName) {
                        let startFilterString = getDateStringForAgGridFilter(queryStartDateMillis)
                        let endFilterString = getDateStringForAgGridFilter(queryEndDateMillis)
                        if (startFilterString && endFilterString) {
                            filterForNotification = true
                            saveFilterChanges = false //don't save filter changes for when we auto filter
                            let filter = {}
                            filter["zenGroupDisplayName"] = {
                                "values": [
                                    zenGroup.friendlyName
                                ],
                                "filterType": "set"
                            }
                            filter["created"] = {
                                "dateFrom": startFilterString,
                                "dateTo": endFilterString,
                                "filterType": "date",
                                "type": "inRange"
                            }
                            //status also needs to be in [AUTOMATICALLY_SUSPENDED, WOULD_SUSPEND] since service only alerts for suspends
                            /*filter["status"] = {
                                "values": [
                                    "AUTOMATICALLY_SUSPENDED", "WOULD_SUSPEND"
                                ],
                                "filterType": "set"
                            }*/
                            gridReadyParams.api.setFilterModel(filter);
                        }
                    }
                } catch (e) {}
            }
        }

        if(!filterForNotification){
            let useFilterState = getUseFilterStateInSession('browserDataIncidentsGridFilterState')
            if(useFilterState){
                onGridReadyHelper(gridReadyParams, "browserDataIncidentsGridFilterState");
            }
        }

        this.gridApi = gridReadyParams.api;
        this.props.setGridApi(gridReadyParams.api);
        //check if we want to apply saved column state

        let columnMode = this.props.columnMode
        //check which initial column mode to apply
        if(columnMode === customColumnModeText){
            onGridReadyHelperForColumnState(gridReadyParams, gridColumnStateSessionVariableName)
        }
        else if(columnMode === minColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, gridReadyParams.api, this.props.setColumnMode, minColumnModeText, minColumnIds, updateBrowserDataIncidentsGridColumnModeReactive)
        }
        else if(columnMode === mediumColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, gridReadyParams.api, this.props.setColumnMode, mediumColumnModeText, medColumnIds, updateBrowserDataIncidentsGridColumnModeReactive)
        }
        //else if columnMode is max then the default column state already shows the max amount of columns no need to update

        let showAllTelemetry = this.props.showAllTelemetryDataToggled
        if(!saveFilterChanges){ //if coming from link, and we are auto filtering for notification, always send true since notification query uses telem only db
            showAllTelemetry = true
            //also set the toggle to false. Don't save the setting though for user since we are manually setting to false. If they toggle again after this, then it will save
            this.props.setShowAllTelemetryDataToggled && this.props.setShowAllTelemetryDataToggled(true)
        }
        let dataSource = getDatasourceForGrid(showAllTelemetry, this.props.gridQueryFunction)
        gridReadyParams.api.setGridOption("serverSideDatasource", dataSource);

        //gridReadyParams.api.sizeColumnsToFit()
    };
    render() {
        return (
            <div className={"w-full h-full"} style={{minHeight: "400px"}}>
                <div id="incidentGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                    <AgGridReact
                        columnMenu={"legacy"}
                        modules={[ServerSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, MultiFilterModule]}
                        defaultColDef={this.props.defaultColDef}
                        columnDefs={this.props.columnDefs}
                        getRowId={(params) => {
                            return params.data.incidentId;
                        }}
                        suppressExcelExport={true}
                        suppressCsvExport={true}
                        suppressMultiSort={true}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        maintainColumnOrder={true} //fixes issue where if you re-order/move column then click anywhere on the grid it reverts this change
                        rowModelType={'serverSide'}
                        onGridReady={this.onGridReady}
                        onCellEditingStopped={this.cellEditingStoppedFunctionCaller}
                        rowSelection={this.props.rowSelection}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        tooltipShowDelay={0}
                        tooltipMouseTrack={true }
                        onFilterChanged={(params)=> {
                            if(saveFilterChanges){
                                onFilterChangedHelper(params, 'browserDataIncidentsGridFilterState', updateBrowserDataIncidentsGridFilterModelReactive);
                            }
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                        getContextMenuItems={this.getContextMenuItems}
                        sideBar={this.props.sideBar}
                    />
                </div>
            </div>
        );
    }
}

class DetailsGrid extends Component {
    constructor(props, onClickRow, filterVals, headers, data) {
        super(props);
    }
    onFirstDataRendered = (params) => {
        params.api.autoSizeAllColumns()
    };

    onGridReady = async (params) => {
        this.gridApi = params.api;
    }


    render() {
        return (
            <div className="mt-2">
                <div
                    id="myGrid"

                    className="ag-theme-alpine rounded-md shadow h-full w-full"
                >
                    <AgGridReact
                        columnMenu={"legacy"}
                        defaultColDef={{
                            resizable: true,
                            filterParams: null,
                            headerClass:"border-0 border-b-0",
                            cellClass:"outline:none",
                            enableCellChangeFlash: true,
                            autoHeight: false,
                            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
                        }}
                        columnDefs={this.props.headers}
                        suppressContextMenu={true}
                        suppressExcelExport={true}
                        suppressCsvExport={true}
                        modules={[ClientSideRowModelModule, MenuModule]}
                        suppressMultiSort={true}
                        domLayout="autoHeight"
                        headerHeight={50}
                        enableBrowserTooltips={true}
                        rowData={this.props.data}
                        rowSelection={this.props.rowSelection}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                    />
                </div>
            </div>
        );
    }
}
