import {useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {getChannelGroupsDropDownContents} from "../utils/zenGroupSessionStorageManager";
import NotificationManager from "react-notifications/lib/NotificationManager";
import Modal from "react-modal";
import {
    MuiAutocompleteForZenGroupsWithoutCreateGroupOption,
    MuiAutocompleteNonGroupOptions, MuiAutocompleteNonGroupOptionsFreeSoloTrueForTextInput, MuiCloseIconButton,
    MuiIconWithTooltip
} from "./muiComponents";
import {Button, FormControlLabel, Switch, ThemeProvider} from "@mui/material";
import {roundButtonTheme, switchTheme} from "../utils/muiStyling";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PhoneInput, {isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber} from "react-phone-number-input";
import {
    annuallyBillingCycleText,
    billingCycleOptions,
    desktopLicensePrice2YearsUpfront, desktopLicensePrice2YearsYearlyBilling,
    desktopLicensePrice3YearsUpfront, desktopLicensePrice3YearsYearlyBilling,
    desktopLicensePriceMonthly,
    desktopLicensePriceYearly,
    monthlyBillingCycleText,
    serverLicensePrice2YearsUpfront,
    serverLicensePrice2YearsYearlyBilling,
    serverLicensePrice3YearsUpfront, serverLicensePrice3YearsYearlyBilling,
    serverLicensePriceMonthly,
    serverLicensePriceYearly,
    threeYearBillingCycleText,
    twoYearBillingCycleText
} from "../pages/private/deals";
import * as EmailValidator from "email-validator";
import {editPartnerDealReactive, newPartnerDealReactive} from "../pages/api/dealsApi";
import {getUsersInGroupListReactive} from "../pages/api/groupsApi";

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const CreateAndEditPartnerDealModal=({isOpen=false, setIsOpen, user, activeDeal, creatingNewDeal=true, setIsLoading, resetGrid, userChannelPartnerGroupSettingsList,
                                                ccUserAllPartnerGroupsList, ccAllUsernamesList}) => {

    const { register, reset, handleSubmit } = useForm();
    const [referralDealEndUserAndDashboardUserAreSame, setReferralDealEndUserAndDashboardUserAreSame] = useState(true);
    const [dealTypeSelected, setDealTypeSelected] = useState(null);
    const [dealBillingCycleSelected, setDealBillingCycleSelected] = useState(null); //default to annually
    const [dealServerLicenseCount, setDealServerLicenseCount] = useState(0);
    const [dealDesktopLicenseCount, setDealDesktopLicenseCount] = useState(0);
    const [dealYears, setDealYears] = useState(1);
    const [billingCycleOptions, setBillingCycleOptions] = useState([annuallyBillingCycleText, monthlyBillingCycleText]); //since default deal years is 1 year, set initial options to monthly or yearly billing
    const [phoneNumber, setPhoneNumber] = useState();
    const [channelGroupForDeal, setChannelGroupForDeal] = useState();
    const [prospectDealTypeOptions, setProspectDealTypeOptions] = useState([]);
    const [dealOwnerUserOptions, setDealOwnerUserOptions] = useState([]);
    const [dealOwnerValue, setDealOwnerValue] = useState(null);
    const [dealCompletedOrRequestedToSign, setDealCompletedOrRequestedToSign] = useState(false); //controls if certain fields are disabled or not

    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            if(isOpen){
                if(!creatingNewDeal){
                    //populate fields for editing deal
                    //Need to check and update dealCompletedOrRequestedToSign to see if we are disabling certain fields to edit
                    setDealCompletedOrRequestedToSign(false)
                    //Prep billing cycle in deal model for frontend wording
                    let billingCycleChangedForFrontendWording = "Annually" //Default
                    if(activeDeal?.billingCycle === "Monthly"){
                        billingCycleChangedForFrontendWording = monthlyBillingCycleText
                    }
                    else if(activeDeal?.billingCycle === "TwoYears"){
                        billingCycleChangedForFrontendWording = twoYearBillingCycleText
                    }
                    else if(activeDeal?.billingCycle === "ThreeYears"){
                        billingCycleChangedForFrontendWording = threeYearBillingCycleText
                    }

                    if(activeDeal?.dealCompleted){
                        setDealCompletedOrRequestedToSign(true)
                    }
                    if(activeDeal?.billingCycle === monthlyBillingCycleText){
                        if(activeDeal?.net30MonthlyPaymentRequestedDate !== null && activeDeal?.net30MonthlyPaymentRequestedDate !== undefined){
                            setDealCompletedOrRequestedToSign(true)
                        }
                    }
                    else{
                        //Else if billing cycle is Annually, TwoYears, or ThreeYears we use the net30YearlyPaymentRequestedDate field to check if requested to sign
                        if(activeDeal?.net30YearlyPaymentRequestedDate !== null && activeDeal?.net30YearlyPaymentRequestedDate !== undefined){
                            setDealCompletedOrRequestedToSign(true)
                        }
                    }
                    let localDealLength = 1
                    if(activeDeal?.dealLength === "TwoYears"){
                        localDealLength = 2
                    }
                    else if(activeDeal?.dealLength === "ThreeYears"){
                        localDealLength = 3
                    }
                    reset({
                        salespersonEmail: activeDeal?.salespersonEmailAddress,
                        serverLicenseCount: activeDeal?.numberOfServerLicensesBought,
                        desktopLicenseCount: activeDeal?.numberOfDesktopLicensesBought,
                        dealYears: localDealLength,
                        associatedProductsAndServices: activeDeal?.associatedProductsAndServices,
                        stripeInvoiceAndQuoteMemo: activeDeal?.stripeInvoiceAndQuoteMemo,
                        totalDealValue: activeDeal?.approximateTotalCashValue,
                        prospectName: activeDeal?.prospectName,
                        prospectEmail: activeDeal?.prospectEmail,
                        prospectPhone: "", //is set below
                        prospectAddress: activeDeal?.prospectAddress,
                        prospectCity: activeDeal?.prospectCity,
                        prospectOrganization: activeDeal?.prospectOrganization,
                        prospectZipCode: activeDeal?.prospectZipCode,
                        prospectDescription: activeDeal?.prospectDescription,
                        userSetFriendlyName: activeDeal?.partnerDealDisplayName,
                        ccDashboardAccountUsername: activeDeal?.ccDashboardAccountUsername
                    })
                    setDealYears(localDealLength)
                    setDealDesktopLicenseCount(activeDeal?.numberOfDesktopLicensesBought)
                    setDealServerLicenseCount(activeDeal?.numberOfServerLicensesBought)
                    setPhoneNumber(activeDeal?.prospectPhone)
                    setChannelGroupForDeal(activeDeal?.zenGroupId)
                    setPossibleProspectDealTypeOptionsForGroup(activeDeal?.zenGroupId)
                    setDealOwnerValue(activeDeal?.createdBy)
                    setDealOwnerUserOptionsForGroup(activeDeal?.zenGroupId)
                    setDealBillingCycleSelected(billingCycleChangedForFrontendWording)
                    handleDealYearsChanged(localDealLength)

                    let activeDealTypeEnumValue = activeDeal?.dealType
                    if(activeDealTypeEnumValue === "Referral_Bill_Partner" || activeDealTypeEnumValue === "Referral_Bill_Customer"){
                        if(activeDeal?.ccDashboardAccountUsername === null || activeDeal?.prospectEmail === activeDeal?.ccDashboardAccountUsername){
                            setReferralDealEndUserAndDashboardUserAreSame(true)
                        }
                        else{
                            setReferralDealEndUserAndDashboardUserAreSame(false)
                        }
                    }
                    else{
                        setReferralDealEndUserAndDashboardUserAreSame(true)
                    }

                    let dealTypeSelectedValueForModal = null
                    //"Prospect is a Referral, Partner Will Bill", "Prospect is a Referral, Cyber Crucible Will Bill", "Prospect is an End User", "Prospect is a Reseller"
                    if(activeDealTypeEnumValue === "Referral_Bill_Partner"){
                        dealTypeSelectedValueForModal = "Prospect is a Referral, Partner Will Bill"
                    }
                    else if(activeDealTypeEnumValue === "Referral_Bill_Customer"){
                        dealTypeSelectedValueForModal = "Prospect is a Referral, Cyber Crucible Will Bill"
                    }
                    else if(activeDealTypeEnumValue === "Channel_Partner"){
                        dealTypeSelectedValueForModal = "Prospect is an End User"
                    }
                    else if(activeDealTypeEnumValue === "Distributor"){
                        dealTypeSelectedValueForModal = "Prospect is a Reseller"
                    }
                    setDealTypeSelected(dealTypeSelectedValueForModal)
                }
                else{
                    //else creating new deal
                    resetDealFormData()
                }
            }
        })()
        return () => controller?.abort();
    }, [isOpen]);

    function resetDealFormData(){
        reset({
            salespersonEmail: user,
            serverLicenseCount: "",
            desktopLicenseCount: "",
            dealYears: 1,
            associatedProductsAndServices: 0,
            stripeInvoiceAndQuoteMemo: "",
            totalDealValue: 0,
            prospectName: "",
            prospectEmail: "",
            prospectPhone: "",
            prospectAddress: "",
            prospectCity: "",
            prospectOrganization: "",
            prospectZipCode: "",
            prospectDescription: "",
            userSetFriendlyName: "",
            ccDashboardAccountUsername: ""
        })
        setDealDesktopLicenseCount(0)
        setDealServerLicenseCount(0)
        setDealYears(1)
        setBillingCycleOptions([annuallyBillingCycleText, monthlyBillingCycleText])
        setPhoneNumber()
        setChannelGroupForDeal()
        setDealTypeSelected(null)
        setDealBillingCycleSelected(null)
        setReferralDealEndUserAndDashboardUserAreSame(true)
        setDealCompletedOrRequestedToSign(false)
        setProspectDealTypeOptions([])
        clearDealOwnerOptionsAndSetCurrentUserToValue()
    }

    function setPossibleProspectDealTypeOptionsForGroup(channelGroupSelected) {
        let groupListToUse = userChannelPartnerGroupSettingsList
        if(user && user.trim().toLowerCase().endsWith("@cybercrucible.com")){
            groupListToUse = ccUserAllPartnerGroupsList
        }
        if(channelGroupSelected === null || channelGroupSelected === undefined){
            setProspectDealTypeOptions([])
            setDealTypeSelected(null)
        }
        else{
            //else check the group settings
            if(groupListToUse === null || groupListToUse === undefined){
                setProspectDealTypeOptions([])
                setDealTypeSelected(null)
            }
            else{
                //else we can look for the group id of channelGroupSelected inside the groupListToUse
                let channelGroupFound = null
                groupListToUse.forEach(channelGroup => {
                    let currentGroupId = channelGroup.id
                    if(channelGroupSelected === currentGroupId){
                        channelGroupFound = channelGroup
                    }
                })
                if(channelGroupFound === null || channelGroupFound === undefined){
                    setProspectDealTypeOptions([])
                    setDealTypeSelected(null)
                }
                else{
                    //else check settings to see which options we can show
                    let validProspectTypeOptions = []
                    let isDistributor = channelGroupFound.isDistributor
                    let isReseller = channelGroupFound.isReseller
                    let isReferralAgent = channelGroupFound.isReferralAgent
                    if(isReferralAgent){
                        validProspectTypeOptions.push("Prospect is a Referral, Partner Will Bill")
                        validProspectTypeOptions.push("Prospect is a Referral, Cyber Crucible Will Bill")
                    }
                    if(isReseller){
                        validProspectTypeOptions.push("Prospect is an End User")
                    }
                    if(isDistributor){
                        validProspectTypeOptions.push("Prospect is a Reseller")
                    }
                    if(dealTypeSelected !== null && dealTypeSelected !== undefined){
                        //need to validate the deal type selected is valid for the validProspectTypeOptions
                        if(!validProspectTypeOptions.includes(dealTypeSelected)){
                            setDealTypeSelected(null)
                        }
                    }
                    setProspectDealTypeOptions(validProspectTypeOptions)
                }
            }
        }
    }

    function setDealOwnerUserOptionsForGroup(channelGroupSelected){
        if(channelGroupSelected === null || channelGroupSelected === undefined){
            clearDealOwnerOptionsAndSetCurrentUserToValue()
        }
        else{
            if(user && user.trim().toLowerCase().endsWith("@cybercrucible.com")){
                //is cc user
                setDealOwnerUserOptions(ccAllUsernamesList)
            }
            else{
                //not cc user
                getUsersInGroupListReactive(channelGroupSelected).then(response => {
                    if(response !== null && response !== undefined){
                        let usernamesList = []
                        response.forEach(responseUser => {
                            if(responseUser.username){
                                if(user?.trim()?.toLowerCase()?.endsWith("@cybercrucible.com")){ //current user is a cc user, push all usernames to list
                                    usernamesList.push(responseUser.username)
                                }
                                else if(!responseUser.username.trim().toLowerCase().endsWith("@cybercrucible.com")){ //user is not cc user, do not push a cc username to list
                                    usernamesList.push(responseUser.username)
                                }
                            }
                        })
                        //should always have current user in the list, check the list here
                        if((user !== null && user !== undefined) && !usernamesList.includes(user)){
                            usernamesList.push(user)
                        }
                        //If there is a current dealOwnerValue selected and the new usernamesList does not contain dealOwnerValue, reset it to current user
                        if((dealOwnerValue !== null && dealOwnerValue !== undefined) && !usernamesList.includes(dealOwnerValue)){
                            if(creatingNewDeal){ //if creating a new deal then reset deal owner value to current user.
                                setDealOwnerValue(user)
                            }
                            //else they are editing a deal, leave dealOwnerValue alone
                        }
                        setDealOwnerUserOptions(usernamesList)
                    }
                    else{
                        clearDealOwnerOptionsAndSetCurrentUserToValue()
                    }
                }).catch(function (error) {
                    clearDealOwnerOptionsAndSetCurrentUserToValue()
                })
            }
        }
    }
    function clearDealOwnerOptionsAndSetCurrentUserToValue(){
        if(user && user.trim().toLowerCase().endsWith("@cybercrucible.com")){
            setDealOwnerUserOptions(ccAllUsernamesList)
            setDealOwnerValue(null)
        }
        else{
            //reset options list to only have the current user and set the deal owner value to be the current user
            if(creatingNewDeal){
                setDealOwnerUserOptions([user])
                setDealOwnerValue(user)
            }
            else{
                //else when editing a deal reset options but leave deal owner value alone
                setDealOwnerUserOptions([])
            }
        }
    }

    function getGroupOptionsList(){
        if(user && user.trim().toLowerCase().endsWith("@cybercrucible.com") && ccUserAllPartnerGroupsList !== null && ccUserAllPartnerGroupsList !== undefined){
            return ccUserAllPartnerGroupsList.map(({ id, organizationName, userSetFriendlyName, friendlyName }) => ({
                value: id,
                label: organizationName || userSetFriendlyName|| friendlyName || id,
            })).sort(function (a, b) {
                return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
            });
        }
        else{
            return getChannelGroupsDropDownContents()
        }

    }

    const submitDeal = async (data) => {
        //verify deal information
        if(!channelGroupForDeal){
            return;
        }
        if(!dealTypeSelected){
            return
        }
        if(!dealBillingCycleSelected){
            return
        }
        if(!data.prospectEmail || !dealOwnerValue){
            return;
        }
        if(data.associatedProductsAndServices === null || data.associatedProductsAndServices === undefined || data.associatedProductsAndServices.toString().trim().length < 1){
            return
        }
        if(data.prospectEmail.trim().length > 0){
            if(!EmailValidator.validate(data.prospectEmail.trim())){
                NotificationManager.error("Please enter a valid prospect email address")
                return;
            }
        }
        else{
            NotificationManager.error("Please enter a valid prospect email address");
            return;
        }
        /*if(data.salespersonEmail.trim().length > 0){
            if(!EmailValidator.validate(data.salespersonEmail.trim())){
                NotificationManager.error("Please enter a valid salesperson email address")
                return;
            }
        }
        else{
            NotificationManager.error("Please enter a valid salesperson email address");
            return;
        }*/
        //check to see if at least one type of license is being bought
        if(data.serverLicenseCount < 1 && data.desktopLicenseCount < 1){
            NotificationManager.error("You must enter at least one desktop or server license")
            return;
        }

        if(isValidPhoneNumber(phoneNumber) && isPossiblePhoneNumber(phoneNumber)){
            //Populate the dealTypeSelected in data object
            data.dealType = dealTypeSelected
            if(data.dealType === "Prospect is an End User"){
                data.dealType = "Channel_Partner" //format into exact enum value for request deal type
            }
            else if(data.dealType === "Prospect is a Reseller"){
                data.dealType = "Distributor" //format into exact enum value for request deal type
            }
            else if(data.dealType === "Prospect is a Referral, Partner Will Bill"){
                data.dealType = "Referral_Bill_Partner" //format into exact enum value for request deal type
            }
            else if(data.dealType === "Prospect is a Referral, Cyber Crucible Will Bill"){
                data.dealType = "Referral_Bill_Customer" //format into exact enum value for request deal type
            }
            //Check ccDashboardAccountUsername for referral deals
            if(data.dealType === "Referral_Bill_Partner" || data.dealType === "Referral_Bill_Customer"){
                if(referralDealEndUserAndDashboardUserAreSame){ //If they are the same then don't send a value for ccDashboardAccountUsername
                    data.ccDashboardAccountUsername = null
                }
                else{
                    //Else we have to check the ccDashboardAccountUsername provided is a valid email address
                    if(data.ccDashboardAccountUsername && data.ccDashboardAccountUsername.trim().length > 0){
                        if(!EmailValidator.validate(data.ccDashboardAccountUsername.trim())){
                            NotificationManager.error("Please enter a valid license recipient user email address")
                            return;
                        }
                        //else is valid and can continue
                        data.ccDashboardAccountUsername = data.ccDashboardAccountUsername.trim().toLowerCase()
                    }
                    else{
                        NotificationManager.error("Please enter a valid license recipient user email address")
                        return;
                    }
                }
            }
            else{
                //Should send null for ccDashboardAccountUsername if deal type is not a referral
                data.ccDashboardAccountUsername = null
            }
            try{
                const phoneNumberParsed = parsePhoneNumber(phoneNumber)
                data.channelPartnerZenGroupId = channelGroupForDeal
                data.phoneCountry = phoneNumberParsed.country
                data.prospectPhone = phoneNumber
                //Prep dealBillingCycleSelected with PartnerDeal model enum
                if(dealBillingCycleSelected === "Monthly"){
                    data.billingCycle = "Monthly"
                }
                else if(dealBillingCycleSelected === "Annually"){
                    data.billingCycle = "Annually"
                }
                else if(dealBillingCycleSelected === "2 Years"){
                    data.billingCycle = "TwoYears"
                }
                else if(dealBillingCycleSelected === "3 Years"){
                    data.billingCycle = "ThreeYears"
                }
                data.prospectEmail = data.prospectEmail.trim().toLowerCase()
                if((user && user.trim().toLowerCase().endsWith("@cybercrucible.com"))){
                    //If user is a cc user they are allowed to make a new user account for the deal owner, make sure deal owner username is a valid email.
                    if(!EmailValidator.validate(dealOwnerValue.trim().toLowerCase())){
                        NotificationManager.error("Please enter a valid email address for the deal owner")
                        return;
                    }
                }
                data.dealOwnerUsername = dealOwnerValue.trim().toLowerCase()
                if(data.serverLicenseCount > 0){
                    data.serverLicensesSubscriptionYears = 1
                }
                if(data.desktopLicenseCount > 0){
                    data.desktopLicensesSubscriptionYears = 1
                }
                if(data.stripeInvoiceAndQuoteMemo){
                    data.stripeInvoiceAndQuoteMemo = data.stripeInvoiceAndQuoteMemo.trim()
                    if(data.stripeInvoiceAndQuoteMemo.length < 1){
                        //if data.stripeInvoiceAndQuoteMemo is just whitespace characters then send null
                        data.stripeInvoiceAndQuoteMemo = null
                    }
                }
                let dealLengthToSend = "OneYear"
                if(data.dealYears === "2" || data.dealYears === 2){
                    dealLengthToSend = "TwoYears"
                }
                else if(data.dealYears === "3" || data.dealYears === 3){
                    dealLengthToSend = "ThreeYears"
                }
                data.dealLength = dealLengthToSend
                setIsLoading && setIsLoading(true)
                if(creatingNewDeal){
                    await newPartnerDealReactive(data);
                    NotificationManager.success(`Successfully registered this deal`);
                }
                else{
                    data.partnerDealId = activeDeal?.dealId
                    await editPartnerDealReactive(data);
                    NotificationManager.success(`Successfully edited this deal`);
                }

                setIsOpen && setIsOpen(false)
                resetGrid && resetGrid()
                resetDealFormData()
            }
            catch(error){
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Unexpected error completing this request");
                }
            }
        }
        else{
            NotificationManager.error("Please enter a valid phone number")
        }
        setIsLoading && setIsLoading(false)
    }

    function handleDealYearsChanged(numDealYearsEntered){
        setDealYears(numDealYearsEntered)
        if (numDealYearsEntered <= 0) {
            //Empty input
            setDealBillingCycleSelected(null)
            setBillingCycleOptions([])
        } else if (numDealYearsEntered === "1" || numDealYearsEntered === 1) {
            if(dealBillingCycleSelected === twoYearBillingCycleText || dealBillingCycleSelected === threeYearBillingCycleText){
                setDealBillingCycleSelected(annuallyBillingCycleText)
            }
            setBillingCycleOptions([annuallyBillingCycleText, monthlyBillingCycleText])
        } else if (numDealYearsEntered === "2" || numDealYearsEntered === 2) {
            if(dealBillingCycleSelected === monthlyBillingCycleText || dealBillingCycleSelected === threeYearBillingCycleText){
                setDealBillingCycleSelected(twoYearBillingCycleText)
            }
            setBillingCycleOptions([twoYearBillingCycleText, annuallyBillingCycleText])
        } else if (numDealYearsEntered === "3" || numDealYearsEntered === 3) {
            if(dealBillingCycleSelected === monthlyBillingCycleText || dealBillingCycleSelected === twoYearBillingCycleText){
                setDealBillingCycleSelected(threeYearBillingCycleText)
            }
            setBillingCycleOptions([threeYearBillingCycleText, annuallyBillingCycleText])
        }
    }

    function getServerLicensesLabelPrice(){
        if(dealYears === "2" || dealYears === 2){
            if(dealBillingCycleSelected === twoYearBillingCycleText){ //If paying up front
                return `$${serverLicensePrice2YearsUpfront} / 2 years`
            }
            else{ //Else not paying up front, they want yearly billing
                return `$${serverLicensePrice2YearsYearlyBilling} / year`
            }
        }
        else if(dealYears === "3" || dealYears === 3){
            if(dealBillingCycleSelected === threeYearBillingCycleText){ //If paying up front
                return `$${serverLicensePrice3YearsUpfront} / 3 years`
            }
            else{ //Else not paying up front, they want yearly billing
                return `$${serverLicensePrice3YearsYearlyBilling} / year`
            }
        }
        else { //else treat as 1 year selected
            if(dealBillingCycleSelected === monthlyBillingCycleText){ //Check if monthly billing selected
                return `$${serverLicensePriceMonthly} / mo`
            }
            else{ //else return yearly billing
                return `$${serverLicensePriceYearly} / year`
            }
        }
    }
    function getDesktopLicensesLabelPrice(){
        if(dealYears === "2" || dealYears === 2){
            if(dealBillingCycleSelected === twoYearBillingCycleText){ //If paying up front
                return `$${desktopLicensePrice2YearsUpfront} / 2 years`

            }
            else{ //Else not paying up front, they want yearly billing
                return `$${desktopLicensePrice2YearsYearlyBilling} / year`
            }
        }
        else if(dealYears === "3" || dealYears === 3){
            if(dealBillingCycleSelected === threeYearBillingCycleText){ //If paying up front
                return `$${desktopLicensePrice3YearsUpfront} / 3 years`
            }
            else{ //Else not paying up front, they want yearly billing
                return `$${desktopLicensePrice3YearsYearlyBilling} / year`
            }
        }
        else { //else treat as 1 year selected
            if(dealBillingCycleSelected === monthlyBillingCycleText){ //Check if monthly billing selected
                return `$${desktopLicensePriceMonthly} / mo`
            }
            else{ //else return yearly billing
                return `$${desktopLicensePriceYearly} / year`
            }
        }
    }
    function getGrossDealValue(){
        if(dealYears === "2" || dealYears === 2){
            if(dealBillingCycleSelected === twoYearBillingCycleText){ //If paying up front
                return formatter.format(((serverLicensePrice2YearsUpfront) * dealServerLicenseCount) + ((desktopLicensePrice2YearsUpfront) * dealDesktopLicenseCount)) + " / 2 Years"
            }
            else{ //Else not paying up front, they want yearly billing
                return formatter.format(((serverLicensePrice2YearsYearlyBilling) * dealServerLicenseCount) + ((desktopLicensePrice2YearsYearlyBilling) * dealDesktopLicenseCount)) + " / Year"
            }
        }
        else if(dealYears === "3" || dealYears === 3){
            if(dealBillingCycleSelected === threeYearBillingCycleText){ //If paying up front
                return formatter.format(((serverLicensePrice3YearsUpfront) * dealServerLicenseCount) + ((desktopLicensePrice3YearsUpfront) * dealDesktopLicenseCount)) + " / 3 Years"
            }
            else{ //Else not paying up front, they want yearly billing
                return formatter.format(((serverLicensePrice3YearsYearlyBilling) * dealServerLicenseCount) + ((desktopLicensePrice3YearsYearlyBilling) * dealDesktopLicenseCount)) + " / Year"
            }
        }
        else { //else treat as 1 year selected
            if(dealBillingCycleSelected === monthlyBillingCycleText){ //Check if monthly billing selected
                return formatter.format((serverLicensePriceMonthly * dealServerLicenseCount) + (desktopLicensePriceMonthly * dealDesktopLicenseCount)) + " / Month"
            }
            else{ //else return yearly billing
                return formatter.format((serverLicensePriceYearly * dealServerLicenseCount) + (desktopLicensePriceYearly * dealDesktopLicenseCount)) + " / Year"
            }
        }
    }

    return (
        <Modal contentLabel="Deal Information" isOpen={isOpen}
               onRequestClose={() => {
                   setIsOpen && setIsOpen(false)
                   resetDealFormData()
               }} shouldCloseOnOverlayClick={false} shouldCloseOnEsc={true}
               className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-4xl max-w-4xl min-w-4xl inset-y-10 mx-auto rounded-2xl`}
               overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
        >
            <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(submitDeal)}>
                <div className="flex flex-1 flex-col gap-y-5 ml-1">
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">{creatingNewDeal ? "New Deal Information" : "Edit Deal Information"}</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                setIsOpen && setIsOpen(false)
                                resetDealFormData()
                            }}
                        />
                    </div>
                    <hr className="h-0.5"/>
                    <div className="flex flex-row flex-wrap justify-between gap-x-2 gap-y-2">
                        <div className={"w-full xl:w-[46%] lg:w-w-[46%]"}>
                            <label>Select Channel Partner Group</label>
                            <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                                disabled={dealCompletedOrRequestedToSign}
                                zenGroupDropdownOptionsList={getGroupOptionsList()}
                                value={channelGroupForDeal}
                                onChange={(event, value) => {
                                    setChannelGroupForDeal(value?.value)
                                    setPossibleProspectDealTypeOptionsForGroup(value?.value)
                                    setDealOwnerUserOptionsForGroup(value?.value)
                                }}
                            />
                        </div>
                        <div className={"w-full xl:w-[46%] lg:w-w-[46%]"}>
                            <label>Select Prospect Deal Type</label>
                            <MuiAutocompleteNonGroupOptions
                                disabled={dealCompletedOrRequestedToSign}
                                options={prospectDealTypeOptions}
                                value={dealTypeSelected}
                                onChange={(event, value) => {
                                    setDealTypeSelected(value)
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row flex-wrap justify-between gap-x-2 gap-y-2">
                        <div className={"w-full xl:w-[46%] lg:w-w-[46%]"}>
                            {((user && user.trim().toLowerCase().endsWith("@cybercrucible.com") ? (
                                <div>
                                    <div className="flex flex-row mb-0 gap-x-2">
                                        <label>Deal Owner</label>
                                        <MuiIconWithTooltip
                                            icon={
                                                <FontAwesomeIcon
                                                    className="object-contain cursor-pointer"
                                                    icon="fa-light fa-circle-info"
                                                    size="lg"
                                                />
                                            }
                                            tooltipTitle={"If the user is not a member of this partner group already, they will be added to it. If no user account exists for this email address, one will be created " +
                                                "for them and they will be automatically added to the partner group."}
                                            tooltipPlacement={"bottom-start"}
                                        />
                                    </div>
                                    <MuiAutocompleteNonGroupOptionsFreeSoloTrueForTextInput
                                        label={"Select Deal Owner User"}
                                        options={dealOwnerUserOptions}
                                        value={dealOwnerValue}
                                        onInputChange={(event, value) => {
                                            setDealOwnerValue(value)
                                        }}
                                    />
                                </div>
                            ) : (
                                <div>
                                    <label>Deal Owner</label>
                                    <MuiAutocompleteNonGroupOptions
                                        disabled={dealCompletedOrRequestedToSign}
                                        options={dealOwnerUserOptions}
                                        value={dealOwnerValue}
                                        onChange={(event, value) => {
                                            setDealOwnerValue(value)
                                        }}
                                    />
                                </div>
                            )))}
                        </div>
                        <div className={"w-full xl:w-[46%] lg:w-w-[46%]"}>
                            <label>Deal Name</label>
                            <input
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                                type="text"
                                name="userSetFriendlyName"
                                {...register("userSetFriendlyName")}
                                placeholder="Optional"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row flex-wrap justify-evenly items-center gap-y-5 gap-x-5">
                        <div className={`flex flex-col xl:items-center gap-y-2`}>
                            <div className="flex flex-row items-center gap-x-3 flex-wrap">
                                <div className={"flex flex-col justify-center items-center"}>
                                    <label>Server Licenses</label>
                                    <label>{getServerLicensesLabelPrice()}</label>
                                </div>
                                <input
                                    autoComplete="off"
                                    name="serverLicenseCount"
                                    disabled={dealCompletedOrRequestedToSign}
                                    type="number"
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/\D/g, '');
                                        setDealServerLicenseCount(e.target.value)
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                        }
                                    }}
                                    {...register("serverLicenseCount")}
                                    className="text-center w-24 focus:outline-none h-10 p-1 mt-2 rounded-lg border border-black border-opacity-25 border-solid"
                                />
                            </div>
                        </div>
                        <div className={`flex flex-col xl:items-center gap-y-2`}>
                            <div className="flex flex-row items-center gap-x-3 flex-wrap">
                                <div className={"flex flex-col justify-center items-center"}>
                                    <label>Desktop Licenses</label>
                                    <label>{getDesktopLicensesLabelPrice()}</label>
                                </div>
                                <input
                                    autoComplete="off"
                                    name="desktopLicenseCount"
                                    type="number"
                                    disabled={dealCompletedOrRequestedToSign}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/\D/g, '');
                                        setDealDesktopLicenseCount(e.target.value)
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                        }
                                    }}
                                    {...register("desktopLicenseCount")}
                                    className="text-center w-24 focus:outline-none h-10 p-1 mt-2 rounded-lg border border-black border-opacity-25 border-solid"
                                />
                            </div>
                        </div>
                        <div className={`flex flex-col xl:items-center gap-y-2`}>
                            <div className="flex flex-row items-center gap-x-3 flex-wrap">
                                <div className={"flex flex-col justify-center items-center"}>
                                    <label>Deal Length</label>
                                    <label>(Years)</label>
                                </div>
                                <input
                                    autoComplete="off"
                                    name="dealYears" {...register("dealYears")} type="number"
                                    disabled={dealCompletedOrRequestedToSign}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/\D/g, '');
                                        if (e.target.value > 3) {
                                            e.target.value = 3 //If num greater than 3 is entered, replace with 3 since thats the max year deal we allow
                                        }
                                        handleDealYearsChanged(e.target.value)
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                        }
                                    }}
                                    required
                                    min={1} max={3}
                                    className="text-center w-24 focus:outline-none h-10 p-1 mt-2 rounded-lg border border-black border-opacity-25 border-solid"
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className={`flex flex-row gap-x-1 items-center ${(dealTypeSelected === "Prospect is a Referral, Partner Will Bill" || dealTypeSelected === "Prospect is a Referral, Cyber Crucible Will Bill") ? "block" : "hidden"}`}>
                        <MuiIconWithTooltip
                            icon={<FontAwesomeIcon className="mt-1 object-contain" icon="fa-light fa-circle-info"
                                                   size="lg"/>}
                            tooltipTitle={`For referral deals, you are able to specify the Cyber Crucible dashboard user account these licenses should belong to after the deal is completed. If no email address is 
                                                provided, the prospect email address will be used. If no account exists for the given email address, the account will be 
                                                automatically created after the quote is signed and the licenses are populated.`}
                            tooltipPlacement={"bottom-start"}
                        />
                        <ThemeProvider theme={switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={referralDealEndUserAndDashboardUserAreSame}
                                    name="dashboardEmailToggle"
                                    onChange={e => setReferralDealEndUserAndDashboardUserAreSame(e.target.checked)}
                                />
                            }
                                              label={referralDealEndUserAndDashboardUserAreSame ? "End User Email and License Recipient are the Same" : "End User Email and License Recipient are Not the Same"}/>
                        </ThemeProvider>
                    </div>
                    <div
                        className={`${((dealTypeSelected === "Prospect is a Referral, Partner Will Bill" || dealTypeSelected === "Prospect is a Referral, Cyber Crucible Will Bill") && !referralDealEndUserAndDashboardUserAreSame) ? "block" : "hidden"}`}>
                        <div className="mx-5">
                            <label>License Recipient User Email Address</label>
                            <input
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                                type="text"
                                required={(dealTypeSelected === "Prospect is a Referral, Partner Will Bill" || dealTypeSelected === "Prospect is a Referral, Cyber Crucible Will Bill") && !referralDealEndUserAndDashboardUserAreSame}
                                name="ccDashboardAccountUsername"
                                {...register("ccDashboardAccountUsername")}
                                placeholder="Enter Email Address"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                    </div>

                    <div className="flex flex-row flex-wrap justify-between gap-x-2 gap-y-2">
                        <div className={"w-full xl:w-[46%] lg:w-w-[46%]"}>
                            <label>Billing Cycle</label>
                            <MuiAutocompleteNonGroupOptions
                                disabled={dealCompletedOrRequestedToSign}
                                options={billingCycleOptions}
                                value={dealBillingCycleSelected}
                                onChange={(event, value) => {
                                    setDealBillingCycleSelected(value)
                                }}
                            />
                        </div>
                        <div className="w-full xl:w-[46%] lg:w-w-[46%]">
                            <label>Gross Deal Value per Billing Cycle</label>
                            <input
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                                type="text"
                                name="totalDealValue"
                                value={getGrossDealValue()}
                                {...register("totalDealValue")}
                                disabled
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                    </div>
                    <div className="flex flex-row flex-wrap justify-between gap-y-5">
                        <div className="w-full xl:w-[46%] lg:w-w-[46%]">
                            <label>Prospect Organization</label>
                            <input
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                                type="text"
                                name="prospectOrganization"
                                {...register("prospectOrganization")}
                                required
                                disabled={dealCompletedOrRequestedToSign}
                                placeholder="Required"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="w-full xl:w-[46%] lg:w-w-[46%]">
                            <label>Prospect Point of Contact</label>
                            <input
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                                type="text"
                                name="prospectName"
                                {...register("prospectName")}
                                required
                                placeholder="Required"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                    </div>
                    <div className="flex flex-row flex-wrap justify-between gap-y-5">
                        <div className="w-full xl:w-[46%] lg:w-w-[46%]">
                            <label>Prospect Email</label>
                            <input
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                                type="text"
                                required
                                name="prospectEmail"
                                {...register("prospectEmail")}
                                disabled={dealCompletedOrRequestedToSign}
                                placeholder="Required"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="w-full xl:w-[46%] lg:w-w-[46%]">
                            <label>Prospect Phone</label>
                            <PhoneInput
                                required
                                name="prospectPhone"
                                maxLength="16"
                                className="mt-3 w-full bg-white rounded border border-gray-300 focus:border-theme text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                defaultCountry="US"
                                placeholder="Enter phone number"
                                value={phoneNumber}
                                onChange={setPhoneNumber}
                            />
                        </div>
                    </div>
                    <div className="">
                        <label>Prospect Address</label>
                        <input
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                            type="text"
                            name="prospectAddress"
                            {...register("prospectAddress")}
                            required
                            placeholder="Required"
                            className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                        />
                    </div>
                    <div className="flex flex-row flex-wrap justify-between gap-y-5">
                        <div className="w-full xl:w-[46%] lg:w-w-[46%]">
                            <label>City</label>
                            <input
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                                type="text"
                                name="prospectCity"
                                {...register("prospectCity")}
                                required
                                placeholder="Required"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="w-full xl:w-[46%] lg:w-w-[46%]">
                            <label>Zip Code</label>
                            <input
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                                type="text"
                                name="prospectZipCode"
                                {...register("prospectZipCode")}
                                required
                                placeholder="Required"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                    </div>

                    <div className="">
                        <label>Description</label>
                        <input
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                            type="text"
                            disabled={dealCompletedOrRequestedToSign}
                            name="prospectDescription"
                            {...register("prospectDescription")}
                            required
                            placeholder="Required"
                            className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                        />
                    </div>
                    <div className="flex flex-row flex-wrap justify-between gap-y-5">
                        <div className="w-full xl:w-[46%] lg:w-w-[46%]">
                            <label>Associated Products and Services</label>
                            <textarea
                                name="associatedProductsAndServices"
                                disabled={dealCompletedOrRequestedToSign}
                                {...register("associatedProductsAndServices")}
                                className={"w-full p-2 mt-3 rounded-lg border border-black border-opacity-25 border-solid"}
                                rows={5}
                                defaultValue={0}/>
                        </div>
                        <div className="w-full xl:w-[46%] lg:w-w-[46%]">
                            <label>Custom Memo to Show on Quote and Invoice</label>
                            <textarea
                                name="stripeInvoiceAndQuoteMemo"
                                disabled={dealCompletedOrRequestedToSign}
                                {...register("stripeInvoiceAndQuoteMemo")}
                                className={"w-full p-2 mt-3 rounded-lg border border-black border-opacity-25 border-solid"}
                                rows={5}
                                defaultValue={""}/>
                        </div>
                    </div>
                    <div className={"flex flex-row flex-wrap justify-between gap-y-3 gap-x-3"}>
                        <ThemeProvider theme={roundButtonTheme}>
                            <Button variant={"contained"} className={"flex-1"}
                                    color={"primary"}
                                    type={"submit"}
                                    style={{minWidth: "200px"}}>
                                {creatingNewDeal ? "Register Deal" : "Edit Deal Information"}
                            </Button>
                        </ThemeProvider>
                    </div>
                </div>
            </form>
        </Modal>
    )
}