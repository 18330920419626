import React, {Component, useEffect, useMemo, useState} from "react";
import {AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import HandshakeIcon from '@mui/icons-material/Handshake';
import SidebarMenu from "../../components/sideBarComponent";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import CustomNameCellEditor, {
    CustomDoubleCellEditor,
    CustomGroupInvoiceDaysUntilDueCellEditor,
    CustomGroupMaxDaysUntilPOCTrialLicensesCanExpireCellEditor,
    editNameIconOnlyCellRenderer
} from "../../utils/customCellEditor";
import DTPicker, {dateFilterParametersInHeaderClientSideGrid} from "../../utils/DTPicker";
import {
    loadDataWithSSEAndStartChangeStreamListener,
    standardHandleInsertEvent,
    standardHandlePopulateGrid,
    standardHandleUpdateAndReplaceEvent
} from "../../utils/sseAndChangeStreamHelper";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {useForm} from "react-hook-form";
import {ExcelExportModule} from "@ag-grid-enterprise/excel-export";
import {standardExcelExportHelper, standardExcelExportObjectInContextMenu} from "../../utils/excelExportHelper";
import {MasterDetailModule} from "@ag-grid-enterprise/master-detail";
import {
    getColumnModeInSession,
    getDefaultAgGridSidebarProps,
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper, onGridReadyHelper,
    onGridReadyHelperForColumnState,
    updateColumnModeInSessionHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import {
    ClickToShowColumnOptionsWithToggleButtonGroup,
    customColumnModeText,
    mediumColumnModeText,
    minColumnModeText,
    standardApplyMinimumOrMediumColumnMode
} from "../../components/clickToShowButtons";
import {defaultClientSideTextFilterParams} from "../../utils/filterHelper";
import {
    ccApprovePartnerInMotionReactive,
    ccPartnerInMotionClosedStatusReactive,
    ccRejectPartnerInMotionReactive,
    ccRenewPartnerInMotionReactive, changePartnerInMotionNameReactive,
    updatePartnersInMotionGridColumnModeReactive,
    updatePartnersInMotionGridColumnStateReactive,
    updatePartnersInMotionGridFilterModelReactive,
    updatePartnersInMotionGridUseColumnStateReactive,
    updatePartnersInMotionGridUseFilterStateReactive
} from "../api/partnersInMotionApi";
import {dateValueFormatter} from "../../utils/gridDateFormatter";
import {
    MuiPartnerInMotionStatusLogoIconWithMenu,
    MuiIconButtonWithTooltip,
    MuiIconButtonWithTooltipAndBox, MuiCloseIconButton, MuiPartnerInMotionClosedStatusLogoIconWithMenu
} from "../../components/muiComponents";
import {getItemFromStorageWithoutDecrypting} from "../../utils/storageHelper";
import {
    ccGetPartnerGroupsReactive,
    getPartnerGroupSettingsReactive
} from "../api/groupsApi";
import {useLocation} from "react-router-dom";
import {ccRetrieveUsernamesReactive} from "../api/notificationsApi";
import {CreateAndEditPartnerInMotionModal} from "../../components/createAndEditPartnerInMotionModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {dealPendingText, dealRenewText, dealApprovedText, dealRejectedText, isDealExpiredHelper} from "./deals";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {findNotificationEventForSinglePartnerInMotionAutoFilter} from "../api/notificationEventApi";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Modal from "react-modal";
import {Button, TextField, ThemeProvider} from "@mui/material";
import {buttonTheme} from "../../utils/muiStyling";
import {
    launchLinkedInCompanyScraperForPartnerInMotionReactive
} from "../api/ccOnlyLinkedInCompanyProfilesApi";
let gridFilterStateSessionVariableName = "partnersInMotionGridFilterState"
let gridColumnStateSessionVariableName = "partnersInMotionGridColumnState"
let minColumnIds = ["cyberCrucibleApproved", "zenGroupDisplayName", "prospectOrganization", "linkedInCompanyUrl", "submitted"]
let medColumnIds = ["cyberCrucibleApproved", "zenGroupDisplayName", "partnerInMotionDisplayName", "prospectOrganization", "linkedInCompanyUrl", "prospectEmail", "submitted", "expirationDate", "createdByUsername"]

export default function PartnersInMotion() {
    const { register, handleSubmit, reset } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState();
    const [partnerInMotionModalIsOpen, setPartnerInMotionModalIsOpen] = useState(false);
    const [partnerInMotionModalIsForNewPartner, setPartnerInMotionModalIsForNewPartner] = useState(true);
    const [ccAllUsernamesList, setCCAllUsernamesList] = useState([]);
    const [ccUserAllPartnerGroupsList, setCCUserAllPartnerGroupsList] = useState([]);
    const [userChannelPartnerGroupSettingsList, setUserChannelPartnerGroupSettingsList] = useState([]);
    const [isCyberCrucibleUser, setIsCyberCrucibleUser] = useState(getItemFromStorageWithoutDecrypting("username")?.endsWith("@cybercrucible.com"));
    const [activePartnerInMotion, setActivePartnerInMotion] = useState();
    const partnerInMotionLocation = useLocation();
    const [phantomBusterModalIsOpen, setPhantomBusterModalIsOpen] = useState(false);
    const [linkedInSessionCookie, setLinkedInSessionCookie] = useState("");
    const [linkedInCompanyURL, setLinkedInCompanyURL] = useState("");
    const [partnerInMotionIdForSelectedLinkedInCompanyURL, setPartnerInMotionIdForSelectedLinkedInCompanyURL] = useState(null);
    const [modalOrgName, setModalOrgName] = useState("");
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession(gridFilterStateSessionVariableName));
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    const [columnMode, setColumnMode] = useState(getColumnModeInSession(gridColumnStateSessionVariableName));
    const [gridApi, setGridApi] = useState(null);
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(200); //200ms to start for initial data pull, then turns to 5000ms
    const [columnDefs, setColumnDefs] = useState([
        { field: "id", hide: true, suppressColumnsToolPanel: true, lockVisible: true,
            //This col is only added in so we can filter by id easily for auto filters for notifications, its not visible on grid
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                maxNumConditions: 1,
            },
        },
        { field: "cyberCrucibleApproved", headerName: "Approval", initialWidth: 175,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: [dealPendingText, dealApprovedText, dealRejectedText, dealRenewText],
                suppressSorting: false,
                convertValuesToStrings: true
            },
            valueGetter: (params) => {
                let isExpired = isDealExpiredHelper(params.node.data.expirationDate)
                let status = params.node.data.status
                if(status === dealPendingText){
                    return dealPendingText
                }
                else if(status === dealRejectedText){
                    return dealRejectedText
                }
                else if(status === dealApprovedText){
                    //check if expired
                    if(isExpired){
                        return dealRenewText
                    }
                    else{
                        return dealApprovedText
                    }
                }
                else{
                    //else status was null so default to Pending
                    return dealPendingText
                }
            },
            cellRenderer: function(params) {
                let isExpired = isDealExpiredHelper(params.node.data.expirationDate)
                let status = params.node.data.status
                let iconDiv = ""
                let editPartnerInMotionIconDiv =
                    <MuiIconButtonWithTooltip
                        icon={
                            <FontAwesomeIcon
                                className="object-contain"
                                icon="fa-duotone fa-pen-to-square"
                                size="sm"
                            />
                        }
                        onClick={() => {
                            setActivePartnerInMotion(params.node.data)
                            setPartnerInMotionModalIsForNewPartner(false)
                            setPartnerInMotionModalIsOpen(true)
                        }}
                        tooltipTitle={"Click to edit this partner in motion"}
                        tooltipPlacement={"bottom-start"}
                    />


                if(status === dealRejectedText){
                    //Partner in Motion has been rejected, show a rejected icon with an icon for cc users to approve the rejected partner
                    iconDiv =
                        <div className={"flex flex-row flex-nowrap gap-x-2 items-center"}>
                            <MuiPartnerInMotionStatusLogoIconWithMenu
                                partnerInMotionId={params.node.data.id} fontAwesomeIcon={"fa-solid fa-xmark"} isCyberCrucibleUser={isCyberCrucibleUser} iconColor={"red"}
                                tooltipTitle={`${isCyberCrucibleUser ? "This partner in motion has been rejected. Click to change partner in motion status. Note that changing the status will send an alert to users in this group" : "This partner has been rejected by Cyber Crucible"}`}
                                tooltipPlacement={"bottom-start"}
                                onApprovePartnerInMotionClick={() => {approvePartnerInMotionClickedHelper(params)}}
                                hideRenewOption={true} hideRejectOption={true} hideSubscriptionOption={true}
                            />
                        </div>
                }
                else if(status === dealApprovedText){
                    //Partner in Motion has been approved, check if it is expired and needs renewed
                    if(isExpired){
                        //Partner in Motion is expired, needs renewal
                        iconDiv =
                            <div className={"flex flex-row flex-nowrap gap-x-2 items-center"}>
                                <MuiPartnerInMotionStatusLogoIconWithMenu
                                    partnerInMotionId={params.node.data.id} isCyberCrucibleUser={isCyberCrucibleUser} fontAwesomeIcon={"fa-duotone fa-clock-rotate-left"}
                                    tooltipTitle={`${isCyberCrucibleUser ? "This partner in motion has been approved but needs renewed. Click to change partner in motion status. Note that changing the status will send an alert to users in this group" : "This partner is currently expired and awaiting renewal from Cyber Crucible"}`}
                                    tooltipPlacement={"bottom-start"}
                                    onRenewPartnerInMotionClick={() => {renewPartnerInMotionClickedHelper(params)}}
                                    onRejectPartnerInMotionClick={() => {rejectPartnerInMotionClickedHelper(params)}}
                                    hideRenewOption={false} hideApproveOption={true} hideRejectOption={false} hideSubscriptionOption={true}
                                />
                            </div>
                    }
                    else{
                        //Partner in motion is approved and not expired, so we can show the payment icons
                        iconDiv =
                            <div className={"flex flex-row flex-nowrap gap-x-2 items-center"}>
                                <MuiPartnerInMotionStatusLogoIconWithMenu
                                    partnerInMotionId={params.node.data.id} fontAwesomeIcon={"fa-solid fa-check"} iconColor={"green"} isCyberCrucibleUser={isCyberCrucibleUser}
                                    tooltipTitle={`${isCyberCrucibleUser ? "This partner in motion has been approved. Click to change partner in motion status. Note that changing the status will send an alert to users in this group" : "This partner has been approved by Cyber Crucible"}`}
                                    tooltipPlacement={"bottom-start"}
                                    onRejectPartnerInMotionClick={() => {rejectPartnerInMotionClickedHelper(params)}}
                                    params={params}
                                    hideRenewOption={true} hideApproveOption={true} hideRejectOption={false} hideSubscriptionOption={false}
                                />
                            </div>
                    }
                }
                else{
                    //Partner in Motion is not rejected or approved, so value is Pending or null. Show pending icon
                    iconDiv =
                        <div className={"flex flex-row flex-nowrap gap-x-2 items-center"}>
                            <MuiPartnerInMotionStatusLogoIconWithMenu
                                partnerInMotionId={params.node.data.id} fontAwesomeIcon={"fa-duotone fa-calendar-clock"} isCyberCrucibleUser={isCyberCrucibleUser}
                                tooltipTitle={`${isCyberCrucibleUser ? "This partner in motion is awaiting approval. Click to change partner in motion status. Note that changing the status will send an alert to users in this group" : "This partner is awaiting review from Cyber Crucible"}`}
                                tooltipPlacement={"bottom-start"}
                                onApprovePartnerInMotionClick={() => {approvePartnerInMotionClickedHelper(params)}}
                                onRejectPartnerInMotionClick={() => {rejectPartnerInMotionClickedHelper(params)}}
                                hideRenewOption={true} hideSubscriptionOption={true}
                            />
                        </div>
                }

                return (
                    <div className={"flex flex-nowrap justify-start gap-x-2 items-center"}>
                        {editPartnerInMotionIconDiv}
                        {iconDiv}
                    </div>
                )
            },
            pinned: "left",
            lockPinned: true,
            lockVisible: true,
            sortable: true
        },
        { field: "closedStatus", headerName: "Closed", initialWidth: 175,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ["Won", "Lost", "Pending"],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            valueGetter: (params) => {
                if(params.node.data.closedStatus === "Won"){
                    return "Won"
                }
                else if(params.node.data.closedStatus === "Lost"){
                    return "Lost"
                }
                else{
                    //else it is pending
                    return "Pending"
                }
            },
            cellRenderer: function(params) {
                let ccIconDiv = ""
                if(isCyberCrucibleUser){
                    ccIconDiv =
                        <MuiPartnerInMotionClosedStatusLogoIconWithMenu
                            partnerInMotionId={params.node.data.id} isCyberCrucibleUser={isCyberCrucibleUser}
                            tooltipTitle={(params && params.node.data.status === dealApprovedText) ? "Mark this partner in motion as closed won or closed lost" : "This partner in motion needs approved before marking the closed status"}
                            onClosedWonClick={() => {
                                setIsLoading(true)
                                ccPartnerInMotionClosedStatusReactive(params.node.data.id, true).then(response => {
                                    setIsLoading(false)
                                    NotificationManager.success("Successfully updated this partner in motion");
                                    params.node.data.closedStatus = "Won"
                                    params.node.setDataValue("closedStatus", "Won")
                                }).catch(error => {
                                    setIsLoading(false)
                                    if(error.message){
                                        NotificationManager.error(error.message);
                                    }
                                    else{
                                        NotificationManager.error("Unexpected error completing this request");
                                    }
                                })
                            }}
                            onClosedLostClick={() => {
                                setIsLoading(true)
                                ccPartnerInMotionClosedStatusReactive(params.node.data.id, false).then(response => {
                                    setIsLoading(false)
                                    NotificationManager.success("Successfully updated this partner in motion");
                                    params.node.data.closedStatus = "Lost"
                                    params.node.setDataValue("closedStatus", "Lost")
                                }).catch(error => {
                                    setIsLoading(false)
                                    if(error.message){
                                        NotificationManager.error(error.message);
                                    }
                                    else{
                                        NotificationManager.error("Unexpected error completing this request");
                                    }
                                })
                            }}
                            partnerInMotionApproved={params && params.node.data.status === dealApprovedText}
                        />
                }

                return (
                    <div className={"flex flex-nowrap justify-start gap-x-2 items-center"}>
                        {ccIconDiv}
                        {params.value}
                    </div>
                )
            },
            pinned: "left",
            lockPinned: true,
            lockVisible: true,
            sortable: true
        },
        {
            field: "zenGroupDisplayName", initialWidth: 330, headerName: "Distributor Group", filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                suppressSorting: false
            },
            sortable: true,
        },
        { field: "partnerInMotionDisplayName", headerName: "Partner Friendly Name", initialWidth: 430,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true,
            editable: true,
            cellEditor: "customNameCellEditor",
            cellRenderer: function (params) {
                return editNameIconOnlyCellRenderer(params, "Click to Edit this Partner in Motion's Friendly Name", "partnerInMotionDisplayName")
            },
        },
        { field: "prospectOrganization", headerName: "Prospect Org", initialWidth: 325,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
        { field: "linkedInCompanyUrl", headerName: "LinkedIn Company Url", initialWidth: 450,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true,
            cellRenderer: function (params) {
                let tooltipDiv = ""
                let ccOnlyRunPBAgentDiv = ""
                if(params.value){
                    tooltipDiv = <MuiIconButtonWithTooltip
                        icon={<FontAwesomeIcon className="object-contain" icon="fa-duotone fa-up-right-from-square" size="xs"/>}
                        onClick={() => {
                            window.open(params.value, "_blank", "noreferrer")
                        }}
                        tooltipTitle={`Click to Open in New Tab`}
                        tooltipPlacement={"bottom-start"}
                    />
                    if(isCyberCrucibleUser){
                        ccOnlyRunPBAgentDiv = <MuiIconButtonWithTooltip
                            icon={<LinkedInIcon className={"cursor-pointer"}/>}
                            onClick={() => {
                                resetPhantomBusterModal()
                                setModalOrgName(params.node.data.prospectOrganization)
                                setLinkedInCompanyURL(params.node.data.linkedInCompanyUrl)
                                setPartnerInMotionIdForSelectedLinkedInCompanyURL(params.node.data.id)
                                setPhantomBusterModalIsOpen(true)
                            }}
                            enterDelayMillis={1000}
                            tooltipTitle={<div>Click to run the LinkedIn Company Scraper for this partner in motion to populate the company size</div>}
                            tooltipPlacement={"bottom-start"}
                        />
                    }
                }
                return (
                    <div className={`flex flex-row items-center gap-x-1`}>
                        {ccOnlyRunPBAgentDiv}
                        {tooltipDiv}
                        {params.value}
                    </div>
                )
            },
        },
        {
            field: "companySize", initialWidth: 230, headerName: "Company Size",
            filter: 'agSetColumnFilter', filterParams: {buttons: ["reset", "apply", "cancel"],},
            sortable: true
        },
        { field: "submitted", headerName: "Submitted", initialWidth: 325,
            filter: 'agDateColumnFilter',
            sortable: true,
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            valueFormatter: dateValueFormatter
        },
        { field: "expirationDate", headerName: "Expiration Date", initialWidth: 325,
            filter: 'agDateColumnFilter',
            sortable: true,
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            valueFormatter: dateValueFormatter
        },
        { field: "closedDate", headerName: "Completed Date", initialWidth: 325,
            filter: 'agDateColumnFilter',
            sortable: true,
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            valueFormatter: dateValueFormatter
        },
        { field: "prospectName", headerName: "Prospect Point of Contact",
            initialWidth: 315,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
        { field: "prospectEmail", headerName: "Prospect Email",
            initialWidth: 325,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
        { field: "prospectPhone", headerName: "Prospect Phone",
            initialWidth: 250,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
        { field: "prospectZipCode", headerName: "Prospect Zipcode",
            initialWidth: 250,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
        { field: "prospectCity", headerName: "Prospect City",
            initialWidth: 250,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
        { field: "prospectAddress", headerName: "Prospect Address",
            initialWidth: 325,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
        { field: "createdByUsername", headerName: "Created By",
            initialWidth: 325,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true
        }
    ])
    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            filterParams: null,
            headerClass:"border-0 border-b-0",
            cellClass:"outline:none",
            enableCellChangeFlash:true,
            floatingFilter:true,
            autoHeight:false,
            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
        }
    )

    const sideBar = useMemo(() => {
        //Inside useMemo to help prevent the sidebar from re-rendering
        return getDefaultAgGridSidebarProps(375)
    }, []);
    const rowSelection = useMemo(() => {
        return {
            mode: 'singleRow',
            enableClickSelection: true,
            checkboxes: false,
            headerCheckbox: false
        };
    }, []);

    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            let username = getItemFromStorageWithoutDecrypting("username")
            setUser(username)
            if(username && username.trim().toLowerCase().endsWith("@cybercrucible.com")){
                ccGetPartnerGroupsReactive().then(response => {
                    setCCUserAllPartnerGroupsList(response)
                }).catch(error => {
                    setCCUserAllPartnerGroupsList([])
                })
                ccRetrieveUsernamesReactive().then(response => {
                    setCCAllUsernamesList(response)
                }).catch(function (error) {
                    setCCAllUsernamesList([])
                })

            }
        })()
        return () => controller?.abort();
    }, []);

    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            getPartnerGroupSettingsReactive().then(response => {
                if(response === null || response === undefined){
                    setUserChannelPartnerGroupSettingsList([])
                }
                else{
                    response.sort((object1, object2) => (object1.friendlyName?.toLowerCase() > object2.friendlyName?.toLowerCase()) ? 1 : -1)
                    //Format for dropdown
                    let isDistributorGroups = []
                    response.forEach(group => {
                        if(group.isDistributor){
                            let objToPush = {}
                            objToPush.value = group.id
                            objToPush.label = group.friendlyName || group.id
                            isDistributorGroups.push(objToPush)
                        }
                    })
                    setUserChannelPartnerGroupSettingsList(isDistributorGroups)
                }

            }).catch(error => {
                setUserChannelPartnerGroupSettingsList([])
            })
        })()
        return () => controller?.abort();
    }, []);

    function approvePartnerInMotionClickedHelper(params){
        ccApprovePartnerInMotionReactive(params.node.data.id).then(response => {
            NotificationManager.success("Successfully approved this partner in motion");
            params.node.data.status = dealApprovedText
            params.node.setDataValue("cyberCrucibleApproved", dealApprovedText)
        }).catch(error => {
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Unexpected error completing this request");
            }
        })
    }

    function rejectPartnerInMotionClickedHelper(params){
        ccRejectPartnerInMotionReactive(params.node.data.id).then(response => {
            NotificationManager.success("Successfully rejected this partner in motion");
            params.node.data.status = dealRejectedText
            params.node.setDataValue("cyberCrucibleApproved", dealRejectedText)
        }).catch(error => {
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Unexpected error completing this request");
            }
        })
    }

    function renewPartnerInMotionClickedHelper(params){
        ccRenewPartnerInMotionReactive(params.node.data.id).then(response => {
            NotificationManager.success("Successfully renewed this partner in motion");
            params.node.data.status = dealApprovedText
            params.node.setDataValue("cyberCrucibleApproved", dealApprovedText)
        }).catch(error => {
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Unexpected error completing this request");
            }
        })
    }

    function resetPhantomBusterModal(){
        setPhantomBusterModalIsOpen(false)
        setLinkedInSessionCookie("")
        setLinkedInCompanyURL("")
        setPartnerInMotionIdForSelectedLinkedInCompanyURL(null)
        setModalOrgName("")
    }

    function submitPhantomBusterModal(){
        if(partnerInMotionIdForSelectedLinkedInCompanyURL === null || partnerInMotionIdForSelectedLinkedInCompanyURL === undefined || partnerInMotionIdForSelectedLinkedInCompanyURL.trim().length <= 0){
            NotificationManager.info(`Unexpected error making request`);
            return
        }
        let cookieToSend = null
        if(linkedInSessionCookie !== null && linkedInSessionCookie !== undefined && linkedInSessionCookie.trim().length > 0){
            cookieToSend = linkedInSessionCookie
        }
        setIsLoading(true)
        launchLinkedInCompanyScraperForPartnerInMotionReactive(partnerInMotionIdForSelectedLinkedInCompanyURL, cookieToSend).then(function(response){
            setIsLoading(false)
            NotificationManager.success("Successfully sent request");
            resetPhantomBusterModal()
        }).catch(function(error){
            setIsLoading(false)
            if(error.message){
                NotificationManager.error(error.message)
            }
            else{
                NotificationManager.error("Unexpected error making request")
            }
        })

    }

    return (
        <div className="flex flex-col h-full">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Partners in Motion</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <CreateAndEditPartnerInMotionModal
                isOpen={partnerInMotionModalIsOpen} setIsOpen={setPartnerInMotionModalIsOpen} user={user} activePartnerInMotion={activePartnerInMotion} creatingNewPartnerInMotion={partnerInMotionModalIsForNewPartner}
                setIsLoading={setIsLoading} resetGrid={resetGrid} userDistributorGroupsDropdownList={userChannelPartnerGroupSettingsList}
                ccUserAllPartnerGroupsList={ccUserAllPartnerGroupsList} ccAllUsernamesList={ccAllUsernamesList}
            />
            <Modal contentLabel="Run Phantom Buster LinkedIn Company Scraper"
                   isOpen={phantomBusterModalIsOpen}
                   onRequestClose={() => {
                       resetPhantomBusterModal()
                   }}
                   shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-3xl max-w-3xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                    {/*Title with exit button*/}
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">Run Phantom Buster LinkedIn Company Scraper for Company Size</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                resetPhantomBusterModal()
                            }}
                        />
                    </div>
                    <hr className="h-0.5"/>
                    {/*Form content*/}
                    <div className="flex flex-col gap-y-5">
                        <label><label className="font-bold">Prospect Organization Name: </label>{modalOrgName}</label>
                        <label className={"break-all"}><label className="font-bold">LinkedIn Company URL: </label>{linkedInCompanyURL}</label>
                    </div>
                    <hr className="h-0.5"/>
                    <div className="flex flex-col gap-y-5">
                        <label>We save the LinkedIn session cookie in your user object and re-use it to run the Phantom Buster Agent, but the
                            cookie may expire. If a new cookie is needed, enter it below</label>
                    </div>
                    <TextField
                        size={"small"}
                        id="outlined-controlled"
                        label="Optional - LinkedIn Session Cookie"
                        value={linkedInSessionCookie}
                        onChange={(event) => {
                            setLinkedInSessionCookie(event.target.value);
                        }}
                    />
                    <div className="flex flex-col">
                        <ThemeProvider theme={buttonTheme}>
                            <Button type={"submit"} color={"primary"} variant={"contained"}
                                    onClick={() => {
                                        submitPhantomBusterModal()
                                    }}
                            >
                                Submit
                            </Button>
                        </ThemeProvider>
                    </div>
                </div>
            </Modal>
            <div className="flex flex-1 flex-row h-full overflow-y-auto">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col flex-nowrap gap-y-3 mt-8 ml-5 mr-10 h-full">
                    {privatePageHeaderHelper("Partners in Motion")}
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row justify-between gap-x-1 gap-y-3">
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <GridColumnFilterStateSaving
                                useFilterStateSettingToggled = {useFilterStateSettingToggled}
                                setUseFilterStateSettingToggled = {setUseFilterStateSettingToggled}
                                toggleUpdateUseFilterState = {toggleUpdateUseFilterState}
                                useColumnStateSettingToggled = {useColumnStateSettingToggled}
                                setUseColumnStateSettingToggled = {setUseColumnStateSettingToggled}
                                toggleUpdateUseColumnState = {toggleUpdateUseColumnState}/>
                            <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                                <MuiIconButtonWithTooltipAndBox
                                    icon={<HandshakeIcon className={"cursor-pointer"}/>} tooltipTitle={"Add a Partner in Motion"}
                                    tooltipPlacement={"top"}
                                    onClick={() => {
                                        setPartnerInMotionModalIsForNewPartner(true)
                                        setPartnerInMotionModalIsOpen(true)
                                    }}
                                />
                            </div>
                        </div>
                        <div className={"flex flex-row flex-wrap gap-y-3 gap-x-8 self-end justify-end"}>
                            <ClickToShowColumnOptionsWithToggleButtonGroup
                                columnMode={columnMode} setColumnMode={setColumnMode} gridColumnStateSessionVariableName={gridColumnStateSessionVariableName} gridApi={gridApi}
                                disableMediumOption={false} disableMinOption={false} updateGridColumnModeFunction={updatePartnersInMotionGridColumnModeReactive}
                                minColumnIds={minColumnIds} medColumnIds={medColumnIds}
                            />
                            <ClearRefresh gridApi = {gridApi} showRefreshIcon={false} showExcelExportIcon={true} sseDataPullActive={sseDataPullActive}
                                          excelExportFunction = {excelExport}/>
                        </div>
                    </div>
                    <div className="h-full flex flex-col gap-y-5" id="gridRoot">
                        <Grid
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            sideBar={sideBar}
                            rowSelection={rowSelection}
                            setGridApi={setGridApi}
                            sseDataPullActive={sseDataPullActive}
                            setSSEDataPullActive={setSSEDataPullActive}
                            asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                            setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                            excelExport={excelExport}
                            setIsLoading={setIsLoading}
                            columnMode={columnMode}
                            setColumnMode={setColumnMode}
                            partnerInMotionLocation={partnerInMotionLocation}
                        />
                        <Footer />
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );

    function excelExport(){
        standardExcelExportHelper(gridApi, sseDataPullActive, "partnersInMotionGridExport")
    }

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, gridFilterStateSessionVariableName, updatePartnersInMotionGridUseFilterStateReactive);
    }
    function toggleUpdateUseColumnState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, gridColumnStateSessionVariableName, updatePartnersInMotionGridUseColumnStateReactive);
    }

    function resetGrid(){
        setActivePartnerInMotion(null)
    }
}

let saveFilterChanges = true //If user clicks link from a partner in motion notification/alert email then we don't want to save filter changes because we will be auto filtering

class Grid extends Component {
    rowData = []
    updateTransactionsToApply = []
    abortController = new AbortController()

    constructor(props, onClickRow, filterVals) {
        super(props);
    }

    onCellEditingStopped = (event) => {
        let gridApi = event.api
        if(event.column.colId === "partnerInMotionDisplayName"){
            if(event.newValue && event.newValue.trim().length > 0){
                if(event.newValue === event.oldValue){
                    event.data.partnerInMotionDisplayName = event.oldValue
                    gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
                    return;
                }
                if(!event.data.id){
                    NotificationManager.error("Error updating name")
                    event.data.partnerInMotionDisplayName = event.oldValue
                    gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
                    return;
                }
                changePartnerInMotionNameReactive(event.newValue.trim(), event.data.zenGroupId, event.data.id).then(function(response){
                    NotificationManager.success("Successfully changed the name")
                    event.node.setDataValue("partnerInMotionDisplayName", event.newValue.trim());
                }).catch(function(error){
                    if(error.message){
                        NotificationManager.error(error.message)
                    }
                    else{
                        NotificationManager.error("Error updating name")
                    }
                    event.data.partnerInMotionDisplayName = event.oldValue
                    gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
                })
            }
            else{
                event.data.partnerInMotionDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
            }
        }

    }


    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved

        if(params.source !== "api" && params.source !== "gridOptionsChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updatePartnersInMotionGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updatePartnersInMotionGridColumnModeReactive, customColumnModeText)
        }
        else if(params.source === "api" && params.type === "sortChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updatePartnersInMotionGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updatePartnersInMotionGridColumnModeReactive, customColumnModeText)
        }
    }

    componentWillUnmount(){
        this.abortController.abort()
    }

    applyFilterStateAfterSSE(gridReadyParams){
        /*
            Wait to apply saved grid filters until initial grid is done loading with data because asyncTransactionWaitMillis is set to 200ms initially so every 200ms rows will be added to the grid.
            We need to wait until the rows are added so that the group filters will have all the values because we are leaving it up to ag grid to populate the set filters for these columns so
            we can easily populate group filters for cc users who won't be in the group most likely since we return all possible partners in motion
        */
        let msToSleep = 300
        const timer = setTimeout(() => {
            onGridReadyHelper(gridReadyParams, gridFilterStateSessionVariableName);
        }, msToSleep)
        return () => clearTimeout(timer)
    }


    populateGrid = async (rowData) => {
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    updateGridForChangeStream = async (changeStreamData) => {
        let operationType = changeStreamData.operationType
        let objectBody = changeStreamData.body
        if(operationType === "UPDATE" || operationType === "REPLACE"){
            standardHandleUpdateAndReplaceEvent(objectBody, this.gridApi, this.props.sseDataPullActive, this.updateTransactionsToApply)
        }
        else if (operationType === "INSERT"){
            standardHandleInsertEvent(objectBody, this.gridApi, this.props.sseDataPullActive)
        }
    }

    getRowId = (params) => {
        return params.data.id
    }

    getContextMenuItems = (params) => {
        let excelExport = this.props.excelExport //don't have access to this.props below in the action function so define it here
        return [
            standardExcelExportObjectInContextMenu(excelExport),
            "resetColumns",
            "autoSizeAll"
        ];
    };

    onGridReady = async (gridReadyParams) => {
        this.gridApi = gridReadyParams.api;
        this.props.setGridApi(gridReadyParams.api);

        // Disable text selection on the page while holding shift or control (to allow grid selections to be done easily without selecting all text)
        ["keyup","keydown"].forEach((event) => {
            window.addEventListener(event, (e) => {
                document.onselectstart = function() {
                    return !(e.shiftKey || e.ctrlKey);
                }
            });
        });

        //Only max/custom are allowed for this grid
        let columnMode = this.props.columnMode
        //check which initial column mode to apply

        if(columnMode === customColumnModeText){
            onGridReadyHelperForColumnState(gridReadyParams, gridColumnStateSessionVariableName)
        }
        else if(columnMode === minColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, gridReadyParams.api, this.props.setColumnMode, minColumnModeText, minColumnIds, updatePartnersInMotionGridColumnModeReactive)
        }
        else if(columnMode === mediumColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, gridReadyParams.api, this.props.setColumnMode, mediumColumnModeText, medColumnIds, updatePartnersInMotionGridColumnModeReactive)
        }
        //else if columnMode is max then the default column state already shows the max amount of columns no need to update
        let filterForNotification = false
        saveFilterChanges = true //reset to true
        if(this.props.partnerInMotionLocation && this.props.partnerInMotionLocation.state){
            //check for "filterSinglePartnerInMotionIdURLSearchParams" state
            let filterSinglePartnerInMotionIdURLSearchParams = this.props.partnerInMotionLocation.state.filterSinglePartnerInMotionIdURLSearchParams
            if(filterSinglePartnerInMotionIdURLSearchParams !== null && filterSinglePartnerInMotionIdURLSearchParams !== undefined && filterSinglePartnerInMotionIdURLSearchParams.trim().length > 0){
                try{
                    let response = await findNotificationEventForSinglePartnerInMotionAutoFilter(filterSinglePartnerInMotionIdURLSearchParams)
                    let zenGroupId = response.zenGroupId
                    let partnerInMotionId = response.partnerInMotionId
                    if(zenGroupId && partnerInMotionId){
                        filterForNotification = true
                        saveFilterChanges = false //don't save filter changes for when we auto filter
                        //just filter by id
                        let locationFilterModel = {"id": {filterType: "text", type: "equals", filter: partnerInMotionId}}
                        gridReadyParams.api.setFilterModel(locationFilterModel)
                    }
                } catch (e) {}
            }
        }
        //Don't pass applyFilterStateAfterSSE if we are auto filtering for single partner in motion id
        let applyFilterStateAfterSSEToPass = null
        if(saveFilterChanges){
            applyFilterStateAfterSSEToPass = this.applyFilterStateAfterSSE
        }

        await loadDataWithSSEAndStartChangeStreamListener("/getPartnersInMotionReactive", "/sse/listenToPartnerInMotionEvent",
            this.populateGrid, this.updateGridForChangeStream, gridReadyParams, this.props.setSSEDataPullActive, this.props.setAsyncTransactionWaitMillis, this.updateTransactionsToApply,
            this.abortController, null, applyFilterStateAfterSSEToPass)
    };

    render() {
        return (
            <div className={"w-full h-full"} style={{minHeight: "400px"}}>
                <div id="myGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                    <AgGridReact
                        columnMenu={"legacy"}
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, ExcelExportModule, MasterDetailModule]}
                        defaultColDef={this.props.defaultColDef}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor, customDoubleCellEditor: CustomDoubleCellEditor, customGroupInvoiceDaysUntilDueCellEditor: CustomGroupInvoiceDaysUntilDueCellEditor,
                            customGroupMaxDaysUntilPOCTrialLicensesCanExpireCellEditor: CustomGroupMaxDaysUntilPOCTrialLicensesCanExpireCellEditor}}
                        multiSortKey={"ctrl"}
                        columnDefs={this.props.columnDefs}
                        rowData={this.rowData}
                        onGridReady={this.onGridReady}
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        suppressModelUpdateAfterUpdateTransaction={true}
                        getRowId={this.getRowId}
                        onCellEditingStopped={this.onCellEditingStopped}
                        rowSelection={this.props.rowSelection}
                        onSelectionChanged={() => {}}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        maintainColumnOrder={true} //fixes issue where if you re-order/move column then click anywhere on the grid it reverts this change
                        getContextMenuItems={this.getContextMenuItems}
                        onFilterChanged={(params)=> {
                            if(saveFilterChanges){
                                onFilterChangedHelper(params, gridFilterStateSessionVariableName, updatePartnersInMotionGridFilterModelReactive);
                            }
                        }}
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                        sideBar={this.props.sideBar}
                    />
                </div>
            </div>
        );
    }
}
