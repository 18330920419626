import React, {Component, useEffect, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import NotificationManager from "react-notifications/lib/NotificationManager";
import LockIcon from '@mui/icons-material/Lock';
import {
    addGroupProxyConfigReactive,
    addToGroupReactive,
    changeGroupCollectAgentTelemetrySettingReactive,
    changeZenGroupAssignedDistributionGroupIdReactive,
    changeZenGroupGroupNameReactive,
    changeZenGroupOrganizationNameReactive,
    createUserFromGMReactive,
    deleteSSLCertFromGroupReactive,
    editGroupAutoUpdateAgentsSettingReactive,
    editGroupCanaryExtensionModeReactive,
    editGroupLatestAgentVersionApprovedSettingReactive,
    editGroupPasswordExpirationPolicyReactive,
    editGroupProxyConfigReactive,
    editGroupSSLPinningModeReactive,
    editGroupWhcpReactive,
    getInstallScriptClientAuthReactive,
    getUsersInGroupListReactive,
    removeFromGroupReactive,
    removeGroupProxyConfigReactive,
    resendUserInvitationReactive,
    singleRemoveZenGroupAssignedDistributionGroupIdReactive,
    updateGroupAgentInactiveDaysReactive,
    updateGroupAutomaticallyHideAgentsReactive,
    updateGroupDmzModeReactive, updateGroupEnableIncidentAutoDeleteReactive,
    updateGroupEnableSystemTrayReactive,
    updateGroupRunAgentsInSafeModeReactive,
    updateGroupsAssignedDistributionGroupIdReactive,
    updateGroupsGridColumnModeReactive,
    updateGroupsGridColumnStateReactive,
    updateGroupsGridFilterModelReactive,
    updateGroupsGridUseColumnStateReactive,
    updateGroupsGridUseFilterStateReactive,
    updateGroupToNotUseProxyServerReactive,
    updateShowGroupInstallerConfigIconToggledReactive,
    uploadSSLCertReactive
} from "../api/groupsApi";
import {AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {RichSelectModule} from "@ag-grid-enterprise/rich-select";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {ExcelExportModule} from "@ag-grid-enterprise/excel-export";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Modal from "react-modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import {ConfirmationModal} from "../../components/confirmationModal";
import SidebarMenu from "../../components/sideBarComponent";
import * as EmailValidator from "email-validator";
import {useLocation} from "react-router-dom";
import {
    assignedDistributionGroupCellRendererForGroupsPage,
    assignedDistributionGroupValueFormatterForGroupsPage,
    distributionGroupEnableToggled,
    zenGroupFilterParamsValues
} from "../../utils/zenGroupDisplayNameGridHelper";
import {
    getChartVisibilitySettingInSession,
    getColumnModeInSession, getDefaultAgGridSidebarProps,
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnState,
    showGroupInstallerConfigIconToggledSessionVariable,
    updateChartVisibilitySettingInSession,
    updateColumnModeInSessionHelper,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import {
    getDistributionGroups,
    getDistributionZenGroupFriendlyNames,
    getZenGroupSessionStorageOrDefault
} from "../../utils/zenGroupSessionStorageManager";
import {dateValueFormatter} from "../../utils/gridDateFormatter";
import CustomNameCellEditor, {
    CustomMuiAutocompleteGroupCellEditor,
    editNameIconOnlyCellRenderer,
    getEditIconComponent
} from "../../utils/customCellEditor";
import {Link} from "react-router-dom";
import {
    decryptAndGetSessionVariable,
    encryptAndStoreSessionVariable,
    getItemFromStorageWithoutDecrypting
} from "../../utils/storageHelper";
import {getGroupAndUserInfoReactive} from "../api/rolesApi";
import {checkPermission} from "../../utils/permissionCheckHelper";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {distinctAgentVersionsReactive} from "../api/agentsApi";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {standardExcelExportHelper, standardExcelExportObjectInContextMenu} from "../../utils/excelExportHelper";
import {buttonTheme, cellButtonTheme, switchTheme} from "../../utils/muiStyling";
import {Box, Button, FormControlLabel, IconButton, Switch, ThemeProvider, Tooltip} from "@mui/material";
import {
    MuiAutocompleteForZenGroupsWithoutCreateGroupOption,
    MuiCloseIconButton,
    MuiIconButtonWithTooltip,
    MuiIconButtonWithTooltipAndBox,
    MuiIconWithTooltip,
    MuiTextWithTooltip
} from "../../components/muiComponents";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import EditNoteIcon from '@mui/icons-material/EditNote';
import {
    ClickToShowColumnOptionsWithToggleButtonGroup,
    customColumnModeText,
    mediumColumnModeText,
    minColumnModeText,
    standardApplyMinimumOrMediumColumnMode
} from "../../components/clickToShowButtons";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from '@mui/icons-material/Send';
import {CreateGroupModal} from "../../components/createGroupModal";
import {
    loadDataWithSSEAndStartChangeStreamListener,
    standardHandleInsertEvent,
    standardHandlePopulateGrid,
    standardHandleUpdateAndReplaceEvent
} from "../../utils/sseAndChangeStreamHelper";
import {defaultClientSideTextFilterParams} from "../../utils/filterHelper";
import {ccGenerateMonthlyReportForCustomerReactive} from "../api/notificationsApi";
import {deleteSingleSalesNotificationReactive} from "../api/salesNotificationsApi";
import {checkPermissionAndDeleteRowNodeFromGrid} from "../../utils/clientSideDataSessionStorageHelper";

let gridColumnStateSessionVariableName = "groupsGridColumnState"
let doNotUpdateKeywordForApprovedVersion = "Do Not Update"

//no mandatory yet
export const sslPinningModeOptions = [
    {
        value: "OFF",
        label: "Off",
    },
    {
        value: "ATTEMPT",
        label: "Attempt",
    },
]
export const passwordPolicyFilterAndEditableOptionsMap = [
    {
        value: 90,
        label: "90 Days",
    },
    {
        value: 180,
        label: "180 Days",
    },
    {
        value: 365,
        label: "365 Days",
    },
    {
        value: null,
        label: "Never",
    },
]


export const canaryExtensionModeFilterValuesList = ["Minimum", "Normal", "Maximum", "Custom"]
export const canaryExtensionModeEditableOptionsList = ["Minimum", "Normal", "Maximum"]
export const passwordPolicyLabelList = ["90 Days", "180 Days", "365 Days", "Never"]
export const inactiveAgentDaysOffText = "Off"
export const inactiveAgentDays30Text = "After 30 Days Inactive"
export const inactiveAgentDays60Text = "After 60 Days Inactive"
export const inactiveAgentDays90Text = "After 90 Days Inactive"
export const inactiveAgentDaysOptions = [inactiveAgentDaysOffText, inactiveAgentDays30Text, inactiveAgentDays60Text, inactiveAgentDays90Text]
export const sslPinningModeOptionsLabelList = ["Off", "Attempt"]
export const dmzModeTooltipText = "DMZ Mode is for environments where online certificate checking is unavailable. Enabling DMZ Mode will embed CA roots locally on agents in this group and disable OSCP"
let minColumnIds = ["friendlyName", "channelPartner", "distributionGroup", "assignedDistributionGroupName", "passwordExpirationPolicyInDays", "agentInactiveDaysSetting", "enableIncidentAutoDelete"]
let medColumnIds = ["friendlyName", "channelPartner", "distributionGroup", "assignedDistributionGroupName", "autoUpdate", "latestAgentVersionApproved","passwordExpirationPolicyInDays", "agentInactiveDaysSetting", "enableIncidentAutoDelete"]
export default function Groups() {
    const { register, reset, handleSubmit } = useForm();
    const { register: registerProxyConfig, handleSubmit: handleSubmitProxyConfig, reset: resetProxyConfig } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    //const [zenGroups, setZenGroups] = useState([]);
    const [users, setUsers] = useState([]);
    const [usersGridApi, setUsersGridApi] = useState();
    const [enableUsersGridButtons, setEnableUsersGridButtons] = useState(false);
    const [zenGroup, setZenGroup] = useState();
    const [showClientAuthModal, setShowClientAuthModal] = useState(false);
    const [clientAuthEncoded, setClientAuthEncoded] = useState();
    // eslint-disable-next-line no-unused-vars
    const [showManageGroupModal, setShowManageGroupModal] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [distributionGroups, setDistributionGroups] = useState(getDistributionGroups(true));
    const [showReassignGroupsModal, setShowReassignGroupsModal] = useState(false);
    const [distributionGroupsForReassignModal, setDistributionGroupsForReassignModal] = useState([]);
    const [reassignGroupsModalGroupSelected, setReassignGroupsModalGroupSelected] = useState();
    const [groupsToReassign, setGroupsToReassign] = useState([]);
    const [selectedGroupsToReassign, setSelectedGroupsToReassign] = useState([]);
    const [zenGroupIdToUpdateDistributionGroup, setZenGroupIdToUpdateDistributionGroup] = useState();
    const [gridApi, setGridApi] = useState();
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession("groupsGridFilterState"));
    const [showClientAuthIconToggled, setShowClientAuthIconToggled] = useState(getChartVisibilitySettingInSession(showGroupInstallerConfigIconToggledSessionVariable));
    const zenGroupIdLocation = useLocation();
    const [showSSLModal, setShowSSLModal] = useState(false);
    const [selectedGroupForSSLCertModal, setSelectedGroupForSSLCertModal] = useState();
    const [selectedGroupForBulkEditGroupModal, setSelectedGroupForBulkEditGroupModal] = useState();
    const [sslCertGridRowData, setSSLCertGridRowData] = useState([]);
    const [sslGridApi, setSSLGridApi] = useState(null);
    const [enableSSLGridButtons, setEnableSSLGridButtons] = useState(false);
    const [proxyConfigModalIsOpen, setProxyConfigModalIsOpen] = useState(false);
    const [showAddProxyForm, setShowAddProxyForm] = useState(false);
    const [groupForProxyConfigModal, setGroupForProxyConfigModal] = useState(null);
    const [proxyGridRowData, setProxyGridRowData] = useState([]);
    const [proxyConfigGridApi, setProxyConfigGridApi] = useState(null);
    const [editAutoDeleteModalIsOpen, setEditAutoDeleteModalIsOpen] = useState(false);
    const [confirmEditAutoDeleteModalIsOpen, setConfirmEditAutoDeleteModalIsOpen] = useState(false);
    const [groupForEditAutoDelete, setGroupForEditAutoDelete] = useState(null);
    const [newAutoDeleteSettingForModal, setNewAutoDeleteSettingForModal] = useState(false);
    const [enableProxyConfigGridButtons, setEnableProxyConfigGridButtons] = useState(false);
    const [enableButtons, setEnableButtons] = useState(false);
    const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
    const [loggedInUsername, setLoggedInUsername] = useState("");
    const [groupModalIsForNewGroup, setGroupModalIsForNewGroup] = useState(true);
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(200);
    const [columnMode, setColumnMode] = useState(getColumnModeInSession(gridColumnStateSessionVariableName));
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    const [distinctAgentVersionsList, setDistinctAgentVersionsList] = useState(JSON.parse(decryptAndGetSessionVariable("distinctAgentVersionsList")));
    // eslint-disable-next-line no-unused-vars
    const [columnDefs, setColumnDefs] = useState([
        { field: "friendlyName", headerName: "Group", initialWidth: 400,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: zenGroupFilterParamsValues,
                refreshValuesOnOpen: true,
                suppressSorting: true,
            },
            sortable: true,
            editable: true,
            cellEditor: "customNameCellEditor",
            cellRenderer: function (params) {
                let isADistributionGroup = params.node.data.distributionGroup
                //Since the columnDefs are in a hook, if we use the showClientAuthIconToggled hook here the value won't have the update value if the toggle changes so we need to get the session variable for this.
                let showClientAuthIcon = getChartVisibilitySettingInSession(showGroupInstallerConfigIconToggledSessionVariable)
                return (
                    <div id ="groupFriendlyNameCell" className={"flex flex-nowrap items-center justify-start gap-x-1"}>
                        {showClientAuthIcon &&
                            <MuiIconButtonWithTooltip
                                icon={
                                    <FontAwesomeIcon
                                        className="object-contain"
                                        icon="fa-solid fa-circle-info"
                                        size="xs"
                                        color={"#949494"}
                                    />
                                }
                                onClick={() => {
                                    //open modal for group id and auth information
                                    setClientAuthEncoded() //reset before showing modal
                                    setZenGroup(params.node.data)
                                    setShowClientAuthModal(true)
                                    getInstallScriptClientAuthReactive(params.node.data.zenGroupId).then(response => {
                                        setClientAuthEncoded(response.clientAuth)
                                    }).catch(function(error){
                                        if(error.message){
                                            NotificationManager.error(error.message);
                                        }
                                        else{
                                            NotificationManager.error(`Unexpected error retrieving client auth`);
                                        }
                                    })
                                }}
                                tooltipTitle={"Click to view the client auth config for this group used in the agent installer script"}
                                tooltipPlacement={"bottom-start"}
                            />
                        }
                        <div className='mb-1'>
                            <MuiIconButtonWithTooltip
                                icon={
                                    <FontAwesomeIcon
                                        className="object-contain"
                                        icon="fa-duotone fa-user-gear"
                                        size="xs"
                                    />
                                }
                                onClick={() => {
                                    setUsersGridApi(null)
                                    setEnableUsersGridButtons(false)
                                    setShowManageGroupModal(true)
                                    if(params.node.data && params.node.data.zenGroupId){
                                        setZenGroup(params.node.data) //just in case we should setZenGroup here too
                                        getUsersInGroupListReactive(params.node.data.zenGroupId).then(usersList => {
                                            if(usersList){
                                                setUsers(usersList)
                                            }
                                            else{
                                                setUsers([])
                                            }
                                        }).catch(function(error){
                                            if(error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error("Error retrieving the list of users in this group");
                                            }
                                            setUsers([])
                                        })
                                    }
                                    else{
                                        setUsers([]);
                                    }
                                }}
                                tooltipTitle={"Click to manage this group"}
                                tooltipPlacement={"bottom-start"}
                            />
                        </div>
                        {getEditIconComponent(params, "Click to Edit this Group's Name", "friendlyName")}
                        { isADistributionGroup &&
                            <div className='mb-3'>
                                <MuiIconWithTooltip
                                    icon={
                                        <FontAwesomeIcon
                                            className="object-contain"
                                            icon="fa-duotone fa-warehouse"
                                            size="lg"
                                        />
                                    }
                                    tooltipTitle={"This group is a Distribution Group. It can automatically supply licenses to linked child groups as necessary."}
                                    tooltipPlacement={"bottom-start"}
                                />
                            </div>
                        }
                        <MuiTextWithTooltip
                            text={params.value}
                            tooltipTitle={"Double click to change group name"}
                            tooltipPlacement={"right"}
                        >
                        </MuiTextWithTooltip>
                    </div>
                )
            },
        },
        { field: "organizationName", headerName: "Organization Name", sortable: true,
            initialWidth: 325,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            editable: true,
            cellEditor: "customNameCellEditor",
            cellRenderer: function (params) {
                return editNameIconOnlyCellRenderer(params, "Click to Edit this Group's Organization Name", "organizationName", true)
            }
        },
        { field: "channelPartner", headerName: "Channel Partner",
            initialWidth: 290,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Channel Partner', 'Direct Customer'],
                suppressSorting: false,
                convertValuesToStrings: true
            },
            valueGetter: (params) => { //need valueGetter or else true/false will show for this column in excel export because only valueGetters are used to get values for export
                if(params.node.data.channelPartner){
                    return "Channel Partner";
                }else{
                    return "Direct Customer";
                }
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                                <Switch
                                    checked={params.node.data.channelPartner}
                                    name={`cellToggleChannelPartner${params.node.data.zenGroupId}`}
                                    disabled={true}
                                    className={"cursor-pointer"}
                                />
                            </ThemeProvider>
                            {/*{params.node.data.hiddenFromUI === true ? "Hidden" : "Visible"}*/}
                        </div>
                    )
                },
            sortable: true
        },
        { field: "channelPartnerCustomer", headerName: "Channel Partner Customer",
            initialWidth: 325,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Channel Partner Customer', 'Direct Customer'],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            valueGetter: (params) => {
                if(params.node.data.channelPartnerCustomer){
                    return "Channel Partner Customer";
                }else{
                    return "Direct Customer";
                }
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <Switch
                                    checked={params.node.data.channelPartnerCustomer}
                                    disabled={true}
                                    className={"cursor-pointer"}
                                    name={`cellToggleChannelPartnerCustomer${params.node.data.zenGroupId}`}
                                />
                            </ThemeProvider>
                        </div>
                    )
                },
            sortable: true
        },
        { field: "distributionGroup", headerName: "Distribution Group",
            initialWidth: 300,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Distributor', 'Consumer'],
                suppressSorting: false,
                convertValuesToStrings: true
            },
            sortable: true,
            cellRenderer: function (params) {
                return (
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme = {switchTheme}>
                            <Switch
                                checked={params.node.data.distributionGroup}
                                name={`cellToggleDistributionGroup${params.node.data.zenGroupId}`}
                                onChange={(changeEvent) =>{
                                    setSelectedGroupsToReassign([])
                                    //params.node.setDataValue("distributionGroup",!params.node.data.distributionGroup);
                                    let oldValue = params.node.data.distributionGroup
                                    let newValue = !params.node.data.distributionGroup
                                    distributionGroupEnableToggled(params,oldValue, newValue,
                                        setZenGroupIdToUpdateDistributionGroup, setDistributionGroupsForReassignModal,
                                        setGroupsToReassign,setShowReassignGroupsModal).then(() =>{})
                                }}
                            />
                        </ThemeProvider>
                    </div>
                )
            },
            valueGetter: (params) => {
                if(params.node.data.distributionGroup){
                    return "Distributor";
                }else{
                    return "Consumer";
                }
            },
        },
        { field: "sslPinningMode", headerName: "SSL Pinning Mode",
            initialWidth: 325,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: sslPinningModeOptions.map(
                    ({ value, label}) => label
                ),
                suppressSorting: false,
                convertValuesToStrings: true
            },
            cellRenderer: function (params) {
                let valueToShow = convertSSLPinningModeToFormattedValue(params.node.data.sslPinningMode)
                return (
                    <button id ="sslCell" className={"flex flex-nowrap items-center justify-between gap-x-2"}>
                        <MuiIconButtonWithTooltip
                            icon={
                                <FontAwesomeIcon
                                    className="object-contain"
                                    icon="fa-duotone fa-user-gear"
                                    size="xs"
                                />
                            }
                            onClick={() => {
                                reset({sslFile: null})
                                setSSLGridApi(null)
                                setEnableSSLGridButtons(false)
                                setShowSSLModal(true)
                                setSelectedGroupForSSLCertModal(params.node.data)
                                if(params.node.data.sslPublicKeys){
                                    setSSLCertGridRowData(params.node.data.sslPublicKeys)
                                }
                                else{
                                    setSSLCertGridRowData([])
                                }
                            }}
                            tooltipTitle={"Click to manage SSL Certs"}
                            tooltipPlacement={"bottom-start"}
                        />
                        <MuiTextWithTooltip
                            text={valueToShow}
                            tooltipTitle={"Double click to edit"}
                            tooltipPlacement={"right"}
                        >
                        </MuiTextWithTooltip>
                    </button>
                )
            },
            valueGetter: function (params) {
                return convertSSLPinningModeToFormattedValue(params.node.data.sslPinningMode)
            },
            sortable: true,
            cellEditor: "agSelectCellEditor",
            editable: true,
            cellEditorParams: function(params) {
                return { cellHeight: 50, values: sslPinningModeOptions.map(({ value, label}) => label)}
            }
        },
        { field: "assignedDistributionGroupName", headerName: "Source Distribution Group",
            initialWidth: 360,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: function(params){
                    params.success(getDistributionZenGroupFriendlyNames())
                },
                suppressSorting: true,
            },
            sortable: true,
            cellEditorPopup: false,
            cellEditor: "customMuiAutocompleteGroupCellEditor",
            cellEditorParams: (params) =>{
                return {sourceDistributionGroupEditor: true, values: distributionGroups.map(({id, friendlyName}) => friendlyName || id)};
            },
            editable: true,
            valueGetter: assignedDistributionGroupValueFormatterForGroupsPage,
            cellRenderer: assignedDistributionGroupCellRendererForGroupsPage,
        },
        { field: "assignedDistributionGroupId", headerName: "Remove Assigned Distribution Group",
            initialWidth: 400,
            sortable: true,
            cellRenderer:
                function (params) {
                    if(params.node.data.assignedDistributionGroupId){
                        return (
                            <ThemeProvider theme = {cellButtonTheme}>
                                <Button variant={"contained"}
                                        color={"primary"}
                                        onClick={() => {
                                            if(params.node.data.zenGroupId){
                                                setIsLoading(true)
                                                singleRemoveZenGroupAssignedDistributionGroupIdReactive(params.node.data.zenGroupId).then(response => {
                                                    setIsLoading(false)
                                                    NotificationManager.success("Successfully updated this group")
                                                    params.node.data.assignedDistributionGroupId = null
                                                    params.node.data.assignedDistributionGroupName = null
                                                    //need to refresh these two cells in row due to cells not updating with new null value
                                                    params.api.refreshCells({columns: ["assignedDistributionGroupName", "assignedDistributionGroupId"], suppressFlash: true, rowNodes: [params.node]})
                                                }).catch(function(error){
                                                    setIsLoading(false)
                                                    if(error.message){
                                                        NotificationManager.error(error.message);
                                                    }
                                                    else{
                                                        NotificationManager.error("Error updating this group")
                                                    }
                                                    //reset to old value
                                                    params.data.assignedDistributionGroupId = params.value
                                                })
                                            }
                                        }}
                                >Remove Association
                                </Button>
                            </ThemeProvider>
                        )
                    }
                    return null
                }
        },
        { field: "autoUpdate", headerName: "Always Install/Update Agents in Group with Latest Version",
            initialWidth: 520,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ["On", "Off"],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            sortable: true,
            valueGetter: (params) => {
                if(params.node.data.autoUpdate){
                    return "On"
                }
                else{
                    return "Off"
                }
            },
            tooltipValueGetter: (params) => {
                return "This setting controls if agents in this group should always update/install with the latest agent version regardless" +
                    " of the latest agent version approved in this group"
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <Switch
                                    checked={params.node.data.autoUpdate}
                                    name={`cellToggleAutoUpdate${params.node.data.zenGroupId}`}
                                    onChange={(changeEvent) => {
                                        if(params.node.data.zenGroupId){
                                            editGroupAutoUpdateAgentsSettingReactive(params.node.data.zenGroupId, !params.node.data.autoUpdate).then(response => {
                                                NotificationManager.success(`Successfully updated this setting`);
                                                //let change stream handle changing these fields
                                                //params.node.setDataValue("autoUpdate", !params.node.data.autoUpdate);
                                            }).catch(function(error){
                                                if(error.message){
                                                    NotificationManager.error(error.message);
                                                }
                                                else{
                                                    NotificationManager.error(`Unexpected error making this request`);
                                                }
                                            })
                                        }
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    )
                }
        },
        { field: "latestAgentVersionApproved", headerName: "Latest Agent Version Approved for Updates/Installs", sortable: true,
            initialWidth: 500,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: false,
                suppressSorting: true, //we want values to be in descending order which we do before storing in session, so suppress ag grid sorting this list in asc order to show most recent versions first
                values: function(params){
                    try{
                        //should not use the distinctAgentVersionsList hook here to provide filter values because after testing the filter does not get the updated list when using the
                        // setDistinctAgentVersionsList in the useEffect above, so just do some processing here to provide the filter values
                        let agentVersionsList = JSON.parse(decryptAndGetSessionVariable("distinctAgentVersionsList"))
                        if(agentVersionsList === null){
                            distinctAgentVersionsReactive().then(data => {
                                //store list in descending order by version number
                                data?.sort()?.reverse()
                                data.unshift(doNotUpdateKeywordForApprovedVersion)
                                params.success(data)
                                encryptAndStoreSessionVariable("distinctAgentVersionsList", JSON.stringify(data))
                            }).catch(error => {})
                        }
                        params.success(agentVersionsList)
                    }catch (e) {
                        params.success([])
                    }
                },
            },
            tooltipValueGetter: (params) => {
                return "If the always update/install agents with the latest version setting is turned off, this setting controls what version agents in this group" +
                    " are allowed to update to and install with. This setting will not downgrade agents."
            },
            editable: function(params){
                return params.node.data.autoUpdate === false;
            },
            valueGetter: function (params) {
                if(params.node.data.autoUpdate === true && distinctAgentVersionsList?.length >= 2 ){
                    try{
                        return distinctAgentVersionsList[1]
                    } catch (e) {
                        //default to value in row data if error accessing list
                        return params.node.data.latestAgentVersionApproved
                    }
                }
                else{
                    return params.node.data.latestAgentVersionApproved
                }
            },
            cellEditor: "agSelectCellEditor",
            cellEditorParams: function (params) {
                return { cellHeight: 50, values: distinctAgentVersionsList}
            },
        },
        { field: "collectAgentTelemetry", headerName: "Collect Agent Telemetry",
            initialWidth: 325,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Collect', 'Do Not Collect'],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            valueGetter: (params) => {
                if(params.node.data.collectAgentTelemetry){
                    return "Collect"
                }
                else{
                    return "Do Not Collect"
                }
            },
            sortable: true,
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <Switch
                                    checked={params.node.data.collectAgentTelemetry}
                                    name={`cellToggle${params.node.data.zenGroupId}`}
                                    onChange={(changeEvent) => {
                                        if(!params.node.data.zenGroupId){
                                            NotificationManager.error(`Unexpected error making this request`);
                                            //reset toggle
                                            params.node.setDataValue("collectAgentTelemetry", params.node.data.collectAgentTelemetry);
                                            return
                                        }
                                        changeGroupCollectAgentTelemetrySettingReactive(params.node.data.zenGroupId, !params.node.data.collectAgentTelemetry).then(response => {
                                            NotificationManager.success(`Successfully updated this setting`);
                                            //manually change the toggle on success
                                            params.node.setDataValue("collectAgentTelemetry", !params.node.data.collectAgentTelemetry);
                                        }).catch(function(error){
                                            if(error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error(`Unexpected error making this request`);
                                            }
                                            //reset toggle
                                            params.node.setDataValue("collectAgentTelemetry", params.node.data.collectAgentTelemetry);
                                        })
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    )
                }
        },
        { field: "passwordExpirationPolicyInDays", headerName: "User Password Expiration Policy",
            initialWidth: 350,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: passwordPolicyFilterAndEditableOptionsMap.map(
                    ({ value, label}) => label
                ),
                suppressSorting: true,
                convertValuesToStrings: true
            },
            comparator: function(valueA, valueB, nodeA, nodeB, isDescending){
                let sortValueA = getPasswordExpirationValueForCellSort(nodeA.data.passwordExpirationPolicyInDays)
                let sortValueB = getPasswordExpirationValueForCellSort(nodeB.data.passwordExpirationPolicyInDays)

                //if the passwordExpirationPolicyInDays is null that means never, so assign sortValueA and sortValueB a large value to use so that sort will return as expected on grid for never
                if(sortValueA === null || sortValueA === undefined){
                    sortValueA = 7300 //20 years in days, does not matter what value just needs to be greater than the highest allowable number of days for this field
                }
                if(sortValueB === null || sortValueB === undefined){
                    sortValueB = 7300 //20 years in days, does not matter what value just needs to be greater than the highest allowable number of days for this field
                }
                return (sortValueA === sortValueB) ? 0 : (sortValueA > sortValueB) ? 1 : -1;
            },
            valueGetter: (params) => {
                return getPasswordExpirationToDisplayInCell(params.node.data.passwordExpirationPolicyInDays)
            },
            cellEditor: "agRichSelectCellEditor",
            editable: true,
            cellEditorPopup:true,
            cellEditorParams: function (params) {
                return { cellHeight: 50, values: passwordPolicyFilterAndEditableOptionsMap.map(({ value, label}) => label)}
            },
            sortable: true,
        },
        { field: "canaryExtensionMode", headerName: "Canary Extension Mode",
            initialWidth: 350,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: canaryExtensionModeFilterValuesList,
                suppressSorting: false,
                convertValuesToStrings: true
            },
            valueGetter: (params) => {
                return getCanaryExtensionModeForCell(params.node.data.canaryExtensionConfigs)
            },
            cellEditor: "agSelectCellEditor",
            editable: true,
            cellEditorParams: function (params) {
                return { cellHeight: 50, values: canaryExtensionModeEditableOptionsList}
            },
            sortable: true
        },
        { field: "agentInactiveDaysSetting", headerName: "Auto-Release Inactive Agent Licenses Policy",
            initialWidth: 415,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: inactiveAgentDaysOptions,
                suppressSorting: true,
                convertValuesToStrings: true
            },
            valueGetter: (params) => {
                return getAgentInactiveDaysForCell(params.node.data.agentInactiveDaysSetting)
            },
            cellEditor: "agSelectCellEditor",
            editable: true,
            cellEditorParams: function (params) {
                return { cellHeight: 50, values: inactiveAgentDaysOptions}
            },
            sortable: true
        },
        { field: "hideAgentsAutomatically", headerName: "Automatically Hide Agents",
            initialWidth: 325,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Hide', 'Do Not Hide'],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            valueGetter: (params) => {
                if(params.node.data.hideAgentsAutomatically){
                    return "Hide"
                }
                else{
                    return "Do Not Hide"
                }
            },
            sortable: true,
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <MuiIconButtonWithTooltip
                                icon={
                                    <FontAwesomeIcon
                                        className="object-contain"
                                        icon="fa-light fa-circle-info"
                                        size="xs"
                                    />
                                }
                                tooltipTitle={"This setting is for automatically hiding agents in this group when a license is released from an agent, an agent is marked as inactive, or an agent completes an uninstall"}
                                tooltipPlacement={"bottom-start"}
                            />
                            <ThemeProvider theme = {switchTheme}>
                                <Switch
                                    checked={params.node.data.hideAgentsAutomatically}
                                    name={`cellToggle${params.node.data.zenGroupId}`}
                                    onChange={(changeEvent) => {
                                        if(!params.node.data.zenGroupId){
                                            NotificationManager.error(`Unexpected error making this request`);
                                            //reset toggle
                                            params.node.setDataValue("hideAgentsAutomatically", params.node.data.hideAgentsAutomatically);
                                            return
                                        }
                                        updateGroupAutomaticallyHideAgentsReactive(params.node.data.zenGroupId, !params.node.data.hideAgentsAutomatically).then(response => {
                                            NotificationManager.success(`Successfully updated this setting`);
                                            //manually change the toggle on success
                                            params.node.setDataValue("hideAgentsAutomatically", !params.node.data.hideAgentsAutomatically);
                                        }).catch(function(error){
                                            if(error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error(`Unexpected error making this request`);
                                            }
                                            //reset toggle
                                            params.node.setDataValue("hideAgentsAutomatically", params.node.data.hideAgentsAutomatically);
                                        })
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    )
                }
        },
        { field: "runAgentsInSafeMode", headerName: "Run Agents In Safe Mode",
            initialWidth: 300,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Run', 'Do Not Run'],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            valueGetter: (params) => {
                if(params.node.data.runAgentsInSafeMode === "RUN"){
                    return "Run"
                }
                else{
                    return "Do Not Run"
                }
            },
            sortable: true,
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <Switch
                                    checked={params.node.data.runAgentsInSafeMode === "RUN"}
                                    name={`cellToggleRunAgentsInSafeMode${params.node.data.zenGroupId}`}
                                    onChange={(changeEvent) => {
                                        if(!params.node.data.zenGroupId){
                                            NotificationManager.error(`Unexpected error making this request`);
                                            //reset toggle
                                            params.node.setDataValue("runAgentsInSafeMode", params.node.data.runAgentsInSafeMode);
                                            return
                                        }
                                        let toggledCurrently = params.node.data.runAgentsInSafeMode === "RUN"
                                        let newSetting = "RUN"
                                        if(toggledCurrently) {
                                            newSetting = "DONT_RUN"
                                        }
                                        updateGroupRunAgentsInSafeModeReactive(params.node.data.zenGroupId, !toggledCurrently).then(response => {
                                            NotificationManager.success(`Successfully updated this setting`);
                                            //manually change the toggle on success
                                            params.node.setDataValue("runAgentsInSafeMode", newSetting);
                                        }).catch(function(error){
                                            if(error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error(`Unexpected error making this request`);
                                            }
                                        })
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    )
                }
        },
        { field: "enableSystemTray", headerName: "Show Agent in System Tray",
            initialWidth: 325,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Show', 'Hide'],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            valueGetter: (params) => {
                if(params.node.data.enableSystemTray){
                    return "Show"
                }
                else{
                    return "Hide"
                }
            },
            sortable: true,
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <MuiIconButtonWithTooltip
                                icon={
                                    <FontAwesomeIcon
                                        className="object-contain"
                                        icon="fa-light fa-circle-info"
                                        size="xs"
                                    />
                                }
                                tooltipTitle={"This setting is for showing the agent icon in the system tray for agents in this group. Note that only agents on version 4.4.8.2 and higher have this capability"}
                                tooltipPlacement={"bottom-start"}
                            />
                            <ThemeProvider theme = {switchTheme}>
                                <Switch
                                    checked={params.node.data.enableSystemTray}
                                    name={`cellToggleSystemTray${params.node.data.zenGroupId}`}
                                    onChange={(changeEvent) => {
                                        if(!params.node.data.zenGroupId){
                                            NotificationManager.error(`Unexpected error making this request`);
                                            //reset toggle
                                            params.node.setDataValue("enableSystemTray", params.node.data.enableSystemTray);
                                            return
                                        }
                                        updateGroupEnableSystemTrayReactive(params.node.data.zenGroupId, !params.node.data.enableSystemTray).then(response => {
                                            NotificationManager.success(`Successfully updated this setting`);
                                            //manually change the toggle on success
                                            params.node.setDataValue("enableSystemTray", !params.node.data.enableSystemTray);
                                        }).catch(function(error){
                                            if(error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error(`Unexpected error making this request`);
                                            }
                                            //reset toggle
                                            params.node.setDataValue("enableSystemTray", params.node.data.enableSystemTray);
                                        })
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    )
                }
        },
        { field: "proxyServerConfig", headerName: "Proxy Server Config", sortable: true,
            initialWidth: 375,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            valueGetter: (params) => {
                if(params.node.data.proxyServerConfig !== null && params.node.data.proxyServerConfig !== undefined){
                    return params.node.data.proxyServerConfig.url
                }
                else{
                    return "Do Not Use Proxy"
                }
            },
            cellRenderer: function (params) {
                return (
                    <div className={`flex flex-row items-center gap-x-1`}>
                        <MuiIconButtonWithTooltip
                            icon={<FontAwesomeIcon className="object-contain" icon="fa-duotone fa-user-gear" size="xs"/>}
                            onClick={() => {
                                //open manage proxy configs for group modal
                                setEnableProxyConfigGridButtons(false)
                                setShowAddProxyForm(false)
                                setProxyConfigGridApi(null)
                                setProxyGridRowData()
                                setProxyConfigModalIsOpen(true)
                                setGroupForProxyConfigModal(params.node.data)
                                if(params.node.data.savedProxyServerConfigs){
                                    setProxyGridRowData(params.node.data.savedProxyServerConfigs)
                                }
                                else{
                                    setProxyGridRowData([])
                                }
                            }}
                            tooltipTitle={"Click to manage proxy configurations for agents in this group and change the current group setting"}
                            tooltipPlacement={"bottom-start"}
                        />
                        {params.value}
                    </div>
                )
            },
        },
        { field: "dmzMode", headerName: "DMZ Mode Enabled",
            initialWidth: 235,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ["Enabled", "Disabled"],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            valueGetter: (params) => {
                if(params.node.data.dmzMode){
                    return "Enabled"
                }
                else{
                    return "Disabled"
                }
            },
            sortable: true,
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <MuiIconButtonWithTooltip
                                icon={
                                    <FontAwesomeIcon
                                        className="object-contain"
                                        icon="fa-light fa-circle-info"
                                        size="xs"
                                    />
                                }
                                tooltipTitle={dmzModeTooltipText}
                                tooltipPlacement={"bottom-start"}
                            />
                            <ThemeProvider theme = {switchTheme}>
                                <Switch
                                    checked={params.node.data.dmzMode}
                                    name={`cellToggle${params.node.data.zenGroupId}`}
                                    onChange={(changeEvent) => {
                                        if(!params.node.data.zenGroupId){
                                            NotificationManager.error(`Unexpected error making this request`);
                                            //reset toggle
                                            params.node.setDataValue("dmzMode", params.node.data.dmzMode);
                                            return
                                        }
                                        updateGroupDmzModeReactive(params.node.data.zenGroupId, !params.node.data.dmzMode).then(response => {
                                            NotificationManager.success(`Successfully updated this setting`);
                                            //manually change the toggle on success
                                            params.node.setDataValue("dmzMode", !params.node.data.dmzMode);
                                        }).catch(function(error){
                                            if(error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error(`Unexpected error making this request`);
                                            }
                                            //reset toggle
                                            params.node.setDataValue("dmzMode", params.node.data.dmzMode);
                                        })
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    )
                }
        },
        { field: "enableIncidentAutoDelete", headerName: "Auto-Delete Extortion Response Program",
            initialWidth: 400,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ["Enabled", "Disabled"],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            valueGetter: (params) => {
                if(params.node.data.enableIncidentAutoDelete){
                    return "Enabled"
                }
                else{
                    return "Disabled"
                }
            },
            sortable: true,
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <div className={`flex flex-row items-center gap-x-1`}>
                                <MuiIconButtonWithTooltip
                                    icon={
                                        <FontAwesomeIcon
                                            className="object-contain"
                                            icon={params.node.data.enableIncidentAutoDelete ? "fa-solid fa-triangle-exclamation" : "fa-light fa-circle-info"}
                                            color={params.node.data.enableIncidentAutoDelete ? "#FFD43B" : ""}
                                            size={params.node.data.enableIncidentAutoDelete ? "1x" : "xs"}
                                        />
                                    }
                                    tooltipTitle={params.node.data.enableIncidentAutoDelete ? "WARNING: This setting is ENABLED which means agents in this group on version 4.4.8.3 and higher" +
                                        " WILL DELETE the causing program of any associated extortion responses to the agent. Use this setting carefully" : "This setting is not enabled. When this setting is enabled, agents " +
                                        "in this group on version 4.4.8.3 and higher WILL DELETE the causing program of any associated extortion " +
                                        "responses to the agent. Use this setting carefully"}
                                    tooltipPlacement={"bottom-start"}
                                />
                                <MuiIconButtonWithTooltip
                                    icon={<FontAwesomeIcon className="object-contain" icon="fa-duotone fa-pen-to-square" size="xs"/>}
                                    onClick={() => {
                                        resetEditAutoDeleteModal()
                                        setGroupForEditAutoDelete(params.node.data)
                                        setNewAutoDeleteSettingForModal(params.node.data.enableIncidentAutoDelete)
                                        setEditAutoDeleteModalIsOpen(true)
                                    }}
                                    tooltipTitle={"Click to edit"}
                                    tooltipPlacement={"bottom-start"}
                                />
                            </div>
                            <ThemeProvider theme = {switchTheme}>
                                <Switch checked={params.node.data.enableIncidentAutoDelete} name={`cellToggleAutoDelete${params.node.data.zenGroupId}`} disabled={true} />
                            </ThemeProvider>
                        </div>
                    )
                }
        }
    ])
    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            filterParams: null,
            floatingFilter: true,
            headerClass: "border-0 border-b-0",
            cellClass: "outline:none",
            enableCellChangeFlash: true,
            autoHeight: false,
            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
        }
    )

    const sideBar = useMemo(() => {
        //Inside useMemo to help prevent the sidebar from re-rendering
        return getDefaultAgGridSidebarProps(400)
    }, []);
    const rowSelection = useMemo(() => {
        return {
            mode: 'singleRow',
            enableClickSelection: true,
            checkboxes: false,
            headerCheckbox: false
        };
    }, []);
    useEffect(()  => {
        function openManageGroupModal () {
            let zenGroupClickId = null
            if(zenGroupIdLocation && zenGroupIdLocation.state){
                zenGroupClickId = zenGroupIdLocation.state.zenGroupClickId
            }
            if (zenGroupClickId  !== null && zenGroupClickId !== undefined){
                let zenGroupList = getZenGroupSessionStorageOrDefault()
                let zenGroupMatched = null
                for (const currentZenGroup of zenGroupList) {
                    if (currentZenGroup.id === zenGroupClickId) {
                        zenGroupMatched = currentZenGroup
                        //the groupManagementListReactive endpoint used to supply this page's grid data has the key of zenGroupId for the id, and the groups from getZenGroupSessionStorageOrDefault
                        // have "id" for the key. So to keep standard on this page and the zenGroup hook, make a "zenGroupId" key/value in the zenGroupMatched variable so nothing breaks
                        zenGroupMatched["zenGroupId"] = zenGroupMatched.id
                        break
                    }
                }
                //if zenGroupMatched is not null, then proceed
                if(zenGroupMatched){
                    setZenGroup(zenGroupMatched)
                    setUsersGridApi(null)
                    setEnableUsersGridButtons(false)
                    setShowManageGroupModal(true)
                    getUsersInGroupListReactive(zenGroupClickId).then(usersList => {
                        if(usersList){
                            setUsers(usersList)
                        }
                        else{
                            setUsers([])
                        }
                    }).catch(error => {
                        if(error.message){
                            NotificationManager.error(error.message);
                        }
                        else{
                            NotificationManager.error("Error retrieving the list of users in this group");
                        }
                        setUsers([])
                    })
                }
            }else {
                // do nothing since the click is not from an any icon
            }
        }
        openManageGroupModal();
    },[zenGroupIdLocation])

    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            let currentUsername = getItemFromStorageWithoutDecrypting("username")
            if(currentUsername !== null && currentUsername !== undefined){
                setLoggedInUsername(currentUsername)
            }

        })()
        return () => controller?.abort();
    }, []);

    useEffect(() => {
        //only runs if distinctAgentVersionsList is null from JSON.parse(decryptAndGetSessionVariable("distinctAgentVersionsList"))
        let controller = new AbortController();
        (async () => {
            if(distinctAgentVersionsList === null){
                try{
                    let sessionAgentVersionsList = JSON.parse(decryptAndGetSessionVariable("distinctAgentVersionsList"))
                    if(sessionAgentVersionsList === null){
                        distinctAgentVersionsReactive().then(data => {
                            //store list in descending order by version number
                            data?.sort()?.reverse()
                            data.unshift(doNotUpdateKeywordForApprovedVersion)
                            setDistinctAgentVersionsList(data)
                            columnDefs?.forEach((col) => {
                                if(col && col.field === "latestAgentVersionApproved"){
                                    col.cellEditorParams = {values: data}
                                    //have to reset the valueGetter with the data list of distinct agent versions since col defs are in hook they won't get the updated hook from setDistinctAgentVersionsList
                                    col.valueGetter = function(params) {
                                        if(params.node.data.autoUpdate === true && data?.length >= 2 ){
                                            try{
                                                return data[1]
                                            } catch (e) {
                                                //default to value in row data if error accessing list
                                                return params.node.data.latestAgentVersionApproved
                                            }
                                        }
                                        else{
                                            return params.node.data.latestAgentVersionApproved
                                        }
                                    }
                                }
                            })
                            encryptAndStoreSessionVariable("distinctAgentVersionsList", JSON.stringify(data))
                        }).catch(error => {})
                    }
                    else{
                        setDistinctAgentVersionsList(sessionAgentVersionsList)
                        columnDefs?.forEach((col) => {
                            if(col && col.field === "latestAgentVersionApproved"){
                                col.cellEditorParams = {values: sessionAgentVersionsList}
                                //have to reset the valueGetter with the sessionAgentVersionsList list of distinct agent versions since col defs are in hook they won't get the updated hook from setDistinctAgentVersionsList
                                col.valueGetter = function(params) {
                                    if(params.node.data.autoUpdate === true && sessionAgentVersionsList?.length >= 2 ){
                                        try{
                                            return sessionAgentVersionsList[1]
                                        } catch (e) {
                                            //default to value in row data if error accessing list
                                            return params.node.data.latestAgentVersionApproved
                                        }
                                    }
                                    else{
                                        return params.node.data.latestAgentVersionApproved
                                    }
                                }
                            }
                        })
                    }
                } catch (e) {}
            }
        })()
        return () => controller?.abort();
    }, [])
    const resetFormFields = () => {
        reset({
            newUser:""
        })
    }

    const onUnexistingUser = async (newUser) => {
        try {
            if (newUser && zenGroup && zenGroup.zenGroupId) {
                setIsLoading(true);
                const response = await createUserFromGMReactive(newUser.trim(), zenGroup.zenGroupId);
                if(response.userExistsAlready === false){
                    //success
                    await resetUsersData()
                    setEnableUsersGridButtons(false)
                    NotificationManager.success("Successfully created this user");
                    resetFormFields()
                    //need to update the roles grid roleGridData sessionStorage variable for the new user created then added to the group
                    getGroupAndUserInfoReactive().then(response => {
                        encryptAndStoreSessionVariable("roleGridData", JSON.stringify(response))
                    }).catch(error => {})
                }
                else if(response.userExistsAlready === true){
                    //user exists already
                    NotificationManager.error("Error, this user exists already");
                }
            }
        } catch (error) {
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Error creating this user");
            }
        }
        setIsLoading(false);
    };

    const onAddUser = async (data) => {
        try {
            if(data && data.newUser){
                let newUser = data.newUser
                if (newUser && newUser.trim().length > 0 && zenGroup && zenGroup.zenGroupId) {
                    let email = newUser.trim();
                    if(!EmailValidator.validate(email)){
                        NotificationManager.error("Please enter a valid email address")
                        return;
                    }
                    setIsLoading(true);
                    const response = await addToGroupReactive(email, zenGroup.zenGroupId);
                    if(response.userToAddExists === true){
                        await resetUsersData()
                        NotificationManager.success(`Successfully added ${email.toLowerCase()} to this group`);
                        resetFormFields()
                        setEnableUsersGridButtons(false)
                        //need to update the roles grid roleGridData session storage variable for new user added to group
                        getGroupAndUserInfoReactive().then(response => {
                            encryptAndStoreSessionVariable("roleGridData", JSON.stringify(response))
                        }).catch(error => {})
                    }
                    else if(response.userToAddExists === false){
                        await onUnexistingUser(newUser)
                    }
                }
            }
        } catch (error) {
            if (error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Error adding user to group");
            }
        }
        setIsLoading(false);
    };

    const onRemoveUser = async () => {
        try {
            let user
            if(usersGridApi && usersGridApi.getSelectedRows() && usersGridApi.getSelectedRows().length > 0) {
                user = usersGridApi.getSelectedRows()[0]
            }
            if (user) {
                if(user.username && loggedInUsername && user.username.toLowerCase() === loggedInUsername.toLowerCase()){
                    NotificationManager.info(`You cannot remove yourself from a group`);
                    return
                }
                setIsLoading(true);
                await removeFromGroupReactive(
                    user.username,
                    zenGroup.zenGroupId
                );
                await resetUsersData()
                setEnableUsersGridButtons(false)
                NotificationManager.success(`Successfully removed ${user.username} from this group`);
                //need to update the roles grid roleGridData session storage variable for user removed from group
                getGroupAndUserInfoReactive().then(response => {
                    encryptAndStoreSessionVariable("roleGridData", JSON.stringify(response))
                }).catch(error => {})
            }
        } catch (error) {
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Error removing user from this group");
            }
        }
        setIsLoading(false);
    };

    const resetUsersData = async () => {
        if(zenGroup && zenGroup.zenGroupId){
            try{
                let usersList = await getUsersInGroupListReactive(zenGroup.zenGroupId)
                if(usersList){
                    setUsers(usersList)
                }
                else{
                    setUsers([])
                }
            }
            catch (error){
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Error retrieving the list of users in this group");
                }
                console.log(error)
                setUsers([])
            }
        }
        else{
            setUsers([]);
        }
    }

    const onUploadSSLSubmit = (data) => {
        if(data && data.sslFile && data.sslFile.length > 0 && selectedGroupForSSLCertModal && selectedGroupForSSLCertModal.zenGroupId){
            setIsLoading(true)
            uploadSSLCertReactive(data.sslFile[0], selectedGroupForSSLCertModal.zenGroupId).then(response => {
                NotificationManager.success("Successfully uploaded this cert")
                reset({sslFile: null})
                setSSLCertGridRowData(response.data.sslPublicKeys)
                selectedGroupForSSLCertModal.sslPublicKeys = response.data.sslPublicKeys
                setSelectedGroupForSSLCertModal(selectedGroupForSSLCertModal)
                setEnableSSLGridButtons(false)
                setIsLoading(false)
            }).catch(error => {
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Error uploading this certificate")
                }
                setIsLoading(false)
            })
        }
    };

    const onNewProxyConfigSubmit = (data) => {
        if(data && data.proxyUrl && data.proxyUrl.trim().length > 0 && data.proxyUser && data.proxyUser.trim().length > 0 && data.proxyPassword && data.proxyPassword.trim().length > 0){
            setIsLoading(true)
            addGroupProxyConfigReactive(groupForProxyConfigModal.zenGroupId, data.proxyUrl.trim(), data.proxyUser.trim(), data.proxyPassword).then(response => {
                setIsLoading(false)
                NotificationManager.success("Successfully added new configuration")
                let url = data.proxyUrl.trim()
                let username = data.proxyUser.trim()
                let password = data.proxyPassword
                let newConfigObj = {url: url, username: username, password: password}
                resetProxyConfig({proxyUrl: "", proxyUser: "", proxyPassword: ""})
                //Add new config to grid
                setEnableProxyConfigGridButtons(false)
                //check if grid already does not have the config, if it does not then add it to manage proxy config grid
                let containsConfigAlready = false
                if(proxyGridRowData){
                    proxyGridRowData.forEach(proxyConfig => {
                        if(proxyConfig.url === url && proxyConfig.username === username && proxyConfig.password === password){
                            containsConfigAlready = true
                        }
                    })
                }
                if(!containsConfigAlready){
                    setProxyGridRowData([...proxyGridRowData, newConfigObj])
                }
            }).catch(error => {
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Unexpected error making this request")
                }
                setIsLoading(false)
            })

        }
    }

    function getPasswordExpirationValueForCellSort(passwordExpirationPolicyInDays){
        if(passwordExpirationPolicyInDays){
            //need to check for the number and the strings in passwordPolicyFilterAndEditableOptions because these are the values from the cell editor
            switch(passwordExpirationPolicyInDays) {
                case 90: case "90 Days":
                    return 90
                case 180: case "180 Days":
                    return 180
                case 365: case "365 Days":
                    return 365
                case null: case 'Never':
                    return null
                default:
                    return null;
            }
        }
        else{
            return null;
        }
    }

    function resetProxyConfigModal(){
        setProxyConfigModalIsOpen(false)
        setProxyConfigGridApi(null)
        setEnableProxyConfigGridButtons(false)
        setGroupForProxyConfigModal(null)
        setShowAddProxyForm(false)
        setProxyGridRowData([])
        resetProxyConfig({proxyUrl: "", proxyUser: "", proxyPassword: ""})
    }

    function resetEditAutoDeleteModal(){
        setEditAutoDeleteModalIsOpen(false)
        setConfirmEditAutoDeleteModalIsOpen(false)
        setGroupForEditAutoDelete(null)
        setNewAutoDeleteSettingForModal(false)
    }

    const _ = require('lodash');
    const canaryMinModeSortedList = _.sortBy(canaryMinModeList, ["ext", "parentChild", "folderless"])
    const canaryNormalModeSortedList = _.sortBy(canaryNormalModeList, ["ext", "parentChild", "folderless"])
    const canaryMaxModeSortedList = _.sortBy(canaryMaxModeList, ["ext", "parentChild", "folderless"])


    return (
        <div className="flex flex-col h-full">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Groups</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            {/*Create New Group Modal*/}
            <CreateGroupModal
                isOpen={showCreateGroupModal} setIsOpen={setShowCreateGroupModal} setIsLoading={setIsLoading} resetGrid={resetGrid} creatingNewGroup={groupModalIsForNewGroup} activeGroup={selectedGroupForBulkEditGroupModal}
                setZenGroupIdToUpdateDistributionGroup={setZenGroupIdToUpdateDistributionGroup} setDistributionGroupsForReassignModal={setDistributionGroupsForReassignModal} setGroupsToReassign={setGroupsToReassign}
                setShowReassignGroupsModal={setShowReassignGroupsModal}
            />
            {/*Upload ssl modal*/}
            <Modal contentLabel="Manage SSL Cert for Group"
                   isOpen={showSSLModal}
                   onRequestClose={() => {
                       setShowSSLModal(false)
                       reset({sslFile: null})
                       setSelectedGroupForSSLCertModal(null)
                       setSSLGridApi(null)
                       setEnableSSLGridButtons(false)
                       setSSLCertGridRowData([])
                   }}
                   shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-7xl max-w-7xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className={"flex flex-1 flex-col gap-y-3"}>
                    <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(onUploadSSLSubmit)}>
                        <div className="flex flex-1 flex-col gap-y-3">
                            {/*Title with exit button*/}
                            <div className="flex flex-row justify-between">
                                <h1 className="font-bold text-3xl">Manage SSL Certificates</h1>
                                <MuiCloseIconButton
                                    onClick={() => {
                                        setShowSSLModal(false)
                                        reset({sslFile: null})
                                        setSelectedGroupForSSLCertModal(null)
                                        setSSLGridApi(null)
                                        setEnableSSLGridButtons(false)
                                        setSSLCertGridRowData([])
                                    }}
                                />
                            </div>
                            <hr className="mt-3 h-0.5" />
                            {/*Form content*/}
                            <div className="ml-1 mt-5">
                                <label className="font-bold text-2xl">Current Group: {selectedGroupForSSLCertModal && selectedGroupForSSLCertModal.friendlyName}</label>
                            </div>
                            <div className="ml-1 mt-5 flex flex-col gap-y-3 w-min">
                                <label>Upload SSL Cert File:</label>
                                <input className={'ml-2 w-min'} type="file" name="sslFile"
                                       {...register("sslFile")}
                                       required={true}
                                       accept=".crt,.pem,.der"
                                />
                            </div>
                            <div className="ml-1 mt-3">
                                <ThemeProvider theme = {buttonTheme}>
                                    <Button variant={"contained"}
                                            color={"primary"}
                                            type={"submit"}
                                            startIcon={<FontAwesomeIcon
                                                className="object-contain text-black mr-2"
                                                icon="fa-light fa-upload"
                                                size="1x"
                                            /> }>
                                    Upload SSL Cert
                                    </Button>
                                </ThemeProvider>
                            </div>
                        </div>
                    </form>
                    <hr className="h-0.5 ml-10 mr-4" />
                    <div className="flex flex-col w-full mb-5 gap-y-3">
                        <label className="font-bold text-2xl ml-10">Existing SSL Certs for Group</label>
                        <div className="flex flex-row justify-between flex-wrap gap-x-0 gap-y-3 ml-11">
                            <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                                <MuiIconButtonWithTooltipAndBox
                                    icon={<DeleteIcon className={"cursor-pointer"}/>}
                                    tooltipTitle={"Delete Cert"}
                                    tooltipPlacement={"top"}
                                    disabled={!enableSSLGridButtons}
                                    onClick={() => {
                                        let sslCertGridSelectedRow
                                        if(sslGridApi && sslGridApi.getSelectedRows() && sslGridApi.getSelectedRows().length > 0) {
                                            sslCertGridSelectedRow = sslGridApi.getSelectedRows()[0]
                                        }
                                        if(sslCertGridSelectedRow && sslCertGridSelectedRow.sha256Base64 && selectedGroupForSSLCertModal && selectedGroupForSSLCertModal.zenGroupId){
                                            if(checkPermission(selectedGroupForSSLCertModal.zenGroupId, "groupManager", "deleteSSLCert")) {
                                                if(sslCertGridSelectedRow.userRemovable) {
                                                    setIsLoading(true)
                                                    deleteSSLCertFromGroupReactive(selectedGroupForSSLCertModal.zenGroupId, sslCertGridSelectedRow.sha256Base64).then(result => {

                                                        NotificationManager.success("Successfully deleted this certificate")

                                                        if (selectedGroupForSSLCertModal.sslPublicKeys && selectedGroupForSSLCertModal.sslPublicKeys.includes(sslCertGridSelectedRow)) {
                                                            selectedGroupForSSLCertModal.sslPublicKeys = selectedGroupForSSLCertModal.sslPublicKeys.filter(function (value, index, arr) {
                                                                return value.sha256Base64 !== sslCertGridSelectedRow.sha256Base64;
                                                            })
                                                        }
                                                        setSSLCertGridRowData(selectedGroupForSSLCertModal.sslPublicKeys)
                                                        setSelectedGroupForSSLCertModal(selectedGroupForSSLCertModal)
                                                        setEnableSSLGridButtons(false)
                                                        setIsLoading(false)
                                                    }).catch(function (error) {
                                                        if (error.message) {
                                                            NotificationManager.error(error.message);
                                                        } else {
                                                            NotificationManager.error("Error deleting this certificate from group");
                                                        }
                                                        setIsLoading(false)
                                                    })
                                                }
                                                else{
                                                    NotificationManager.error("This certificate cannot be deleted");
                                                }
                                            }
                                            else{
                                                NotificationManager.error("You do not have permission to delete this certificate from this group");
                                            }
                                        }
                                    }}
                                >
                                </MuiIconButtonWithTooltipAndBox>
                            </div>
                        </div>
                        <div className="m-10 mt-2">
                            <div id="sslCertsGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                                <div className="ag-theme-alpine" style={{height: 400}}>
                                    <AgGridReact
                                        columnMenu={"legacy"}
                                        defaultColDef={{
                                            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
                                        }}
                                        columnDefs={[
                                            {
                                                sortable:true,
                                                resizable:true,
                                                field:"uploaderUsername",
                                                filter:'agTextColumnFilter',
                                                filterParams: defaultClientSideTextFilterParams,
                                                initialWidth: 300
                                            },
                                            {
                                                sortable:true,
                                                resizable:true,
                                                field:"domainName",
                                                filter:'agTextColumnFilter',
                                                filterParams: defaultClientSideTextFilterParams,
                                                initialWidth: 400
                                            },
                                            {
                                                sortable:true,
                                                resizable:true,
                                                field:"created",
                                                filter:'agDateColumnFilter',
                                                filterParams:{
                                                    suppressSorting: true,
                                                    buttons: ["reset", "apply"],

                                                    filterOptions: ['greaterThan', 'lessThan'],
                                                    maxNumConditions: 1,
                                                },
                                                valueFormatter:dateValueFormatter,
                                                initialWidth: 350
                                            },
                                            {
                                                sortable:true,
                                                resizable:true,
                                                field:"expired",
                                                filter:'agDateColumnFilter',
                                                filterParams:{
                                                    suppressSorting: true,
                                                    buttons: ["reset", "apply"],

                                                    filterOptions: ['greaterThan', 'lessThan'],
                                                    maxNumConditions: 1,
                                                },
                                                valueFormatter:dateValueFormatter,
                                                initialWidth: 350
                                            },
                                            {
                                                sortable:true,
                                                resizable:true,
                                                field:"userUploadedDate",
                                                filter:'agDateColumnFilter',
                                                filterParams:{
                                                    suppressSorting: true,
                                                    buttons: ["reset", "apply"],

                                                    filterOptions: ['greaterThan', 'lessThan'],
                                                    maxNumConditions: 1,
                                                },
                                                valueFormatter:dateValueFormatter,
                                                initialWidth: 350
                                            }
                                        ]}
                                        suppressContextMenu={true}
                                        suppressExcelExport={true}
                                        maintainColumnOrder={true} //fixes issue where if you re-order/move column then click anywhere on the grid it reverts this change
                                        suppressCsvExport={true}
                                        onGridReady={(params) => {
                                            setSSLGridApi(params.api)
                                        }}
                                        modules={[ClientSideRowModelModule,RichSelectModule,SetFilterModule,MenuModule,ColumnsToolPanelModule]}
                                        rowSelection={rowSelection}
                                        onFilterChanged={(params) => {
                                            params.api.deselectAll();
                                        }}
                                        suppressMultiSort={true}
                                        onSelectionChanged={(params) => {
                                            let selectedRows = params.api.getSelectedRows();
                                            if(selectedRows && selectedRows.length > 0){
                                                setEnableSSLGridButtons(true)
                                            }
                                            else{
                                                setEnableSSLGridButtons(false)
                                            }
                                        }}
                                        enableCellTextSelection={true}
                                        ensureDomOrder={true}
                                        rowData={sslCertGridRowData}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            {/*Manage Group Modal*/}
            <Modal contentLabel="Manage Group" isOpen={showManageGroupModal}
                   onRequestClose={() => {
                       setShowManageGroupModal(false)
                       setUsersGridApi(null)
                       setEnableUsersGridButtons(false)
                       setUsers([])
                       resetFormFields()
                   }} shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white max-w-3xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className="flex flex-col w-full">
                    <div className="flex flex-row justify-between p-8">
                        <h1 className="font-bold text-3xl">{`Manage Group${(zenGroup && zenGroup.friendlyName) ? `: ${zenGroup.friendlyName}` : ""}`}</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                setShowManageGroupModal(false)
                                setUsersGridApi(null)
                                setEnableUsersGridButtons(false)
                                setUsers([])
                                resetFormFields()
                            }}
                        />
                    </div>
                    <hr className="h-0.5 ml-8 mr-8" />
                    <form className="flex flex-1 flex-col p-8 max-w-md" onSubmit={handleSubmit(onAddUser)}>
                        <div className="flex flex-1 flex-col">
                            <div className="ml-1">
                                <label>Add user</label>
                                <input
                                    onKeyPress={(e) => {
                                        if(e.key === 'Enter'){
                                            e.preventDefault();
                                        }}}
                                    type="text"
                                    required
                                    name="newUser"
                                    {...register("newUser")}
                                    className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                                />
                            </div>
                            <div className="ml-1 mb-0 mt-3">
                                <ThemeProvider theme = {buttonTheme}>
                                    <Button variant={"contained"}
                                            type={"submit"}
                                            color={"primary"} >
                                            Add user
                                    </Button>
                                </ThemeProvider>
                            </div>
                        </div>
                    </form>
                    <hr className="ml-10 mr-10 mb-3 bg-black h-0.5" />
                    <div className="flex flex-row justify-between flex-wrap gap-x-0 gap-y-3  ml-10 mr-10 mb-2">
                        <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                            <MuiIconButtonWithTooltipAndBox
                                icon={<DeleteIcon className={"cursor-pointer"}/>} tooltipTitle={"Remove user from group"}
                                tooltipPlacement={"top"}
                                disabled={!enableUsersGridButtons}
                                onClick={() => {
                                    onRemoveUser().then()
                                }}/>
                            <Tooltip title={<div className={"text-sm"}>Assign user roles, clicking this button will redirect you to the roles page and automatically filter the data by user and group where you can easily manage their roles</div>}
                                     slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                     placement={"top"} followCursor={false} enterDelay={750} arrow>
                                <Box sx={{boxShadow: 7, borderRadius: '28px'}}>
                                    <Link
                                        style={{pointerEvents: enableUsersGridButtons ? '' : 'none'}}
                                        to={{pathname:"/private/roles"}} state={{zenGroupDisplayName: zenGroup && zenGroup.friendlyName,
                                        user: ((usersGridApi && usersGridApi.getSelectedRows() && usersGridApi.getSelectedRows().length > 0) ? usersGridApi.getSelectedRows()[0] : null )}}>
                                        <IconButton
                                            sx={{
                                                "&:hover": {
                                                    backgroundColor: "white",
                                                    cursor: "pointer"
                                                },
                                                color: "#505050",
                                                background: "#F3f3f3",
                                                "&:disabled": {
                                                    backgroundColor: "#Fbfbfb",
                                                    cursor: "default",
                                                    color: "#C1c1c1"
                                                },
                                                borderRadius: "28px"
                                            }}
                                            disabled={!enableUsersGridButtons}
                                            className={"self-center object-contain"}
                                        >
                                            <LockIcon className={"cursor-pointer"}/>
                                        </IconButton>
                                    </Link>
                                </Box>
                            </Tooltip>
                            <MuiIconButtonWithTooltipAndBox
                                icon={<SendIcon className={"cursor-pointer"}/>} tooltipTitle={"Resend Invitation to User"}
                                tooltipPlacement={"top"}
                                disabled={!enableUsersGridButtons}
                                onClick={() => {
                                    let user
                                    if(usersGridApi && usersGridApi.getSelectedRows() && usersGridApi.getSelectedRows().length > 0) {
                                        user = usersGridApi.getSelectedRows()[0]
                                    }
                                    if(user && user.username && zenGroup && zenGroup.zenGroupId){
                                        let username = user.username.toLowerCase()
                                        if(loggedInUsername && username === loggedInUsername.toLowerCase()){
                                            NotificationManager.info(`You cannot resend an invitation to your account`);
                                            return
                                        }
                                        setIsLoading(true)
                                        //else make api call
                                        resendUserInvitationReactive(username, zenGroup.zenGroupId).then(response => {
                                            NotificationManager.success(`Successfully resent invitation to user ${username}`);
                                            setIsLoading(false)
                                        }).catch(error => {
                                            if(error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error("Error resending invitation to user")
                                            }
                                            setIsLoading(false)
                                        })
                                    }
                                }}/>
                        </div>
                    </div>
                    <UsersGrid
                        headers={[
                            { field: "username", headerName: "Users in group",
                                filter: 'agTextColumnFilter',
                                filterParams: defaultClientSideTextFilterParams,
                                sortable: true,
                                valueFormatter: function (params) {
                                    if(loggedInUsername){
                                        if (params.data.username === loggedInUsername) {
                                            //for the current logged-in user, add a (me) to end of string
                                            return params.data.username + " (me)"
                                        }
                                    }
                                    return params.data.username
                                },
                            },
                        ]}
                        rowSelection={rowSelection}
                        data={users}
                        setEnableUsersGridButtons={setEnableUsersGridButtons}
                        setUsersGridApi={setUsersGridApi}
                    />
                </div>

            </Modal>
            <Modal contentLabel="Client Auth" isOpen={showClientAuthModal}
                   onRequestClose={() => {
                       setShowClientAuthModal(false)
                       setClientAuthEncoded()
                   }} shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white max-w-2xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-3">
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">{`Installer Script Client Auth Configuration`}</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                setShowClientAuthModal(false)
                                setClientAuthEncoded()
                            }}
                        />
                    </div>
                    <hr className="mt-3 h-0.5" />
                    {/*<label className="ml-1 mt-2">
                        The Group Id and Client Auth values are used in the agent installer script
                    </label>*/}
                    <div className="break-all">
                        <label>Group Name: </label>{zenGroup?.friendlyName}
                    </div>
                    <div className="break-all">
                        <label>Group Id: </label>{zenGroup?.zenGroupId}
                    </div>
                    <div className="break-all">
                        <label>Client Auth: </label>{clientAuthEncoded}
                    </div>
                </div>

            </Modal>
            {/*Re-assign groups modal*/}
            <Modal contentLabel="Reassign Groups" isOpen={showReassignGroupsModal}
                   onRequestClose={() => {
                       if(showCreateGroupModal && !groupModalIsForNewGroup){
                           //if edit group modal is open, we don't want to reset everything because we still want the edit group modal to be open so user can finish editing the group after re-assigning distr groups
                           resetReassignGroupModal()
                       }
                       else{
                           //else edit modal is not open, reset like normal
                           resetGrid()
                       }
                   }} shouldCloseOnOverlayClick={false}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white xl:w-xl lg:w-xl md:w-xl sm:w-xl xs:w-11/12 max-w-xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                    {/*Title with exit button*/}
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">Reassign Groups</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                if(showCreateGroupModal && !groupModalIsForNewGroup){
                                    //if edit group modal is open, we don't want to reset everything because we still want the edit group modal to be open so user can finish editing the group after re-assigning distr groups
                                    resetReassignGroupModal()
                                }
                                else{
                                    //else edit modal is not open, reset like normal
                                    resetGrid()
                                }
                            }}
                        />
                    </div>
                    <hr className="mt-3 h-0.5" />
                    {/*Form content*/}
                    <div className="text-xl font-bold ml-1">
                        This form will repeat itself until all groups are reassigned, you do not have to reassign all groups to the same group.
                    </div>
                    <div className="ml-1">
                        <label>Select which distribution group you would like reassign the groups selected below to</label>
                        <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                            zenGroupDropdownOptionsList={distributionGroupsForReassignModal.map(({ id, friendlyName }) => ({
                                value: id,
                                label: friendlyName || id,
                            }))}
                            value={reassignGroupsModalGroupSelected}
                            onChange={( event, value ) => {
                                setReassignGroupsModalGroupSelected(value?.value)
                            }}
                        />
                    </div>
                    {groupsToReassign && groupsToReassign.map((group) => {
                        return (
                            <div key={group.zenGroupId} className="ml-1 flex flex-row items-center">
                                <input
                                    className="mr-2 checkbox"
                                    type="checkbox"
                                    checked={selectedGroupsToReassign.includes(group.zenGroupId)}
                                    onChange={(value) => {
                                        let selectedGroupsArray = selectedGroupsToReassign.slice();
                                        if(selectedGroupsArray.includes(group.zenGroupId)){
                                            let index = selectedGroupsArray.indexOf(group.zenGroupId);
                                            selectedGroupsArray.splice(index, 1);
                                            setSelectedGroupsToReassign(selectedGroupsArray);
                                        }
                                        else{
                                            selectedGroupsArray.push(group.zenGroupId);
                                            setSelectedGroupsToReassign(selectedGroupsArray);
                                        }
                                    }}

                                />
                                <label>{group.friendlyName}</label>
                            </div>
                        );
                    })}
                    <ThemeProvider theme = {buttonTheme}>
                        <Button variant={"contained"}
                                color={"primary"}
                              onClick={handleReassignSelectedGroups}
                        >
                        Reassign Groups
                        </Button>
                    </ThemeProvider>
                </div>
            </Modal>
            <Modal contentLabel="Manage Proxy Server Configuration for Group"
                   isOpen={proxyConfigModalIsOpen}
                   onRequestClose={() => {
                       resetProxyConfigModal()
                   }}
                   shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-4xl max-w-4xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className={"flex flex-1 flex-col gap-y-5 w-full p-8"}>
                    <div className="flex flex-1 flex-col gap-y-3">
                        {/*Title with exit button*/}
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Manage Proxy Server Configuration</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    resetProxyConfigModal()
                                }}
                            />
                        </div>
                        <hr className="mt-3 h-0.5"/>
                        <div className="">
                            <label className="font-bold">Current
                                Group: </label>{groupForProxyConfigModal && groupForProxyConfigModal.friendlyName}
                        </div>
                        {groupForProxyConfigModal && groupForProxyConfigModal.proxyServerConfig ? (
                            <div className={"flex flex-col gap-y-3"}>
                                <div className="">
                                    <label className="font-bold">Current Proxy Config
                                        URL: </label>{groupForProxyConfigModal.proxyServerConfig.url}
                                </div>
                                <div className="">
                                    <label className="font-bold">Current Proxy Config
                                        Username: </label>{groupForProxyConfigModal.proxyServerConfig.username}
                                </div>
                                <div className={"flex flex-row gap-x-2 items-center flex-wrap gap-y-2"}>
                                    <label className="font-bold">Update Group to Not Use Proxy: </label>
                                    <MuiIconButtonWithTooltip
                                        icon={
                                            <FontAwesomeIcon
                                                className="object-contain"
                                                icon="fa-duotone fa-square-xmark"
                                                size="sm"
                                            />
                                        }
                                        tooltipTitle={"Click to change this group's setting for agents in this group to not use a proxy server"}
                                        tooltipPlacement={"right-end"}
                                        onClick={(event) => {
                                            if(groupForProxyConfigModal && groupForProxyConfigModal.zenGroupId){
                                                setIsLoading(true)
                                                updateGroupToNotUseProxyServerReactive(groupForProxyConfigModal.zenGroupId).then(response => {
                                                    setIsLoading(false)
                                                    NotificationManager.success("Successfully updated configuration")
                                                    //edit selected group's proxyServerConfig and update on modal too
                                                    groupForProxyConfigModal.proxyServerConfig = null
                                                    setGroupForProxyConfigModal(groupForProxyConfigModal)
                                                }).catch(error => {
                                                    if(error.message){
                                                        NotificationManager.error(error.message);
                                                    }
                                                    else{
                                                        NotificationManager.error("Unexpected error making this request")
                                                    }
                                                    setIsLoading(false)
                                                })
                                            }

                                        }}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="">
                                <label className="font-bold">Current Proxy Config: </label>Do Not Use Proxy
                            </div>
                        )}
                        <div className={`flex flex-row items-center ml-1`}>
                            <ThemeProvider theme={switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={showAddProxyForm}
                                        name="toggle3"
                                        onChange={e => setShowAddProxyForm(e.target.checked)}
                                    />
                                }
                                                  label={showAddProxyForm ? "Show Add New Proxy Configuration Form" : "Hide Add New Proxy Configuration Form"}/>
                            </ThemeProvider>
                        </div>
                        <div
                            className={`flex flex-row gap-x-1 gap-y-2 items-center flex-wrap ${showAddProxyForm ? "block" : "hidden"}`}>
                            <label className="font-bold">Add New Proxy Configuration for Group:</label>
                            <MuiIconWithTooltip
                                icon={<FontAwesomeIcon className="mt-1 ml-1 object-contain"
                                                       icon="fa-light fa-circle-info" size="lg"/>}
                                tooltipTitle={"The saved proxy configurations will appear as options when editing the group's current proxy server configuration on the grid below"}
                                tooltipPlacement={"right-end"}
                            />
                        </div>
                        {/*Form for adding new proxy config to group*/}
                        <form className={`flex flex-col gap-y-2 mx-2 ${showAddProxyForm ? "block" : "hidden"}`}
                              onSubmit={handleSubmitProxyConfig(onNewProxyConfigSubmit)}>
                            <div className={`flex flex-col gap-y-2`}>
                                <label>Proxy Server URL</label>
                                <input
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                        }
                                    }}
                                    type="text" required name="proxyUrl" {...registerProxyConfig("proxyUrl")}
                                    placeholder="Required"
                                    className="focus:outline-none h-9 p-2 rounded-lg border border-black border-opacity-25 border-solid"
                                />
                            </div>
                            <div className={"flex flex-row flex-wrap justify-between gap-x-2 gap-y-3"}>
                                <div
                                    className={`flex flex-col gap-y-3 w-full xl:w-[46%] lg:w-[46%] md:w-[46%] sm:w-[46%]`}>
                                    <label>Proxy Server User</label>
                                    <input
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                            }
                                        }}
                                        type="text" required name="proxyUser" {...registerProxyConfig("proxyUser")}
                                        placeholder="Required"
                                        className="focus:outline-none h-9 p-2 rounded-lg border border-black border-opacity-25 border-solid"
                                    />
                                </div>
                                <div
                                    className={`flex flex-col gap-y-3 w-full xl:w-[46%] lg:w-[46%] md:w-[46%] sm:w-[46%]`}>
                                    <label>Proxy Server Password</label>
                                    <input
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                            }
                                        }}
                                        type="text" required
                                        name="proxyPassword" {...registerProxyConfig("proxyPassword")}
                                        placeholder="Required"
                                        className="focus:outline-none h-9 p-2 rounded-lg border border-black border-opacity-25 border-solid"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-row flex-wrap gap-x-5 gap-y-3 mt-3 mb-3">
                                <ThemeProvider theme={buttonTheme}>
                                    <Button
                                        variant={"contained"} color={"secondary"} type={"button"}
                                        onClick={() => {
                                            resetProxyConfig({proxyUrl: "", proxyUser: "", proxyPassword: ""})
                                        }}
                                    > Clear
                                    </Button>
                                    <Button
                                        variant={"contained"}
                                        color={"primary"} type={"submit"}
                                    > Submit
                                    </Button>
                                </ThemeProvider>
                            </div>
                        </form>
                    </div>
                    <hr className="h-0.5"/>
                    <div className="flex flex-col w-full gap-y-3">
                        <label className="font-bold">Edit Proxy Configuration for Group</label>
                        <div className="flex flex-row justify-between flex-wrap gap-x-0 gap-y-3">
                            <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                                <MuiIconButtonWithTooltipAndBox
                                    icon={
                                        <IconButton
                                            disabled={!enableProxyConfigGridButtons} sx={{width: 25, height: 25}} className={`self-center object-contain`} disableRipple={true}
                                        >
                                            <FontAwesomeIcon className={`object-contain`} icon={"fa-duotone fa-pen-to-square"} size="sm" color={`${!enableProxyConfigGridButtons ? "#C1c1c1" : "black"}`} />
                                        </IconButton>
                                    }
                                    tooltipTitle={"Update the group's current proxy server configuration with the selected configuration"}
                                    tooltipPlacement={"top"}
                                    disabled={!enableProxyConfigGridButtons}
                                    onClick={() => {
                                        //Update group to set this selected row as the new group setting
                                        if(proxyConfigGridApi && proxyConfigGridApi.getSelectedRows() && proxyConfigGridApi.getSelectedRows().length > 0 && groupForProxyConfigModal && groupForProxyConfigModal.zenGroupId) {
                                            let selectedRow = proxyConfigGridApi.getSelectedRows()[0]
                                            if(selectedRow){
                                                setIsLoading(true)
                                                let url = selectedRow.url
                                                let username = selectedRow.username
                                                editGroupProxyConfigReactive(groupForProxyConfigModal.zenGroupId, url, username).then(response => {
                                                    setIsLoading(false)
                                                    NotificationManager.success("Successfully updated configuration")
                                                    setEnableProxyConfigGridButtons(false)
                                                    groupForProxyConfigModal.proxyServerConfig = selectedRow
                                                    setGroupForProxyConfigModal(groupForProxyConfigModal)
                                                }).catch(error => {
                                                    if(error.message){
                                                        NotificationManager.error(error.message);
                                                    }
                                                    else{
                                                        NotificationManager.error("Unexpected error making this request")
                                                    }
                                                    setIsLoading(false)
                                                })
                                            }
                                        }
                                    }}
                                />
                                <MuiIconButtonWithTooltipAndBox
                                    icon={<DeleteIcon className={"cursor-pointer"}/>}
                                    tooltipTitle={"Remove selected proxy configurations from group. Note that if any removed configurations are set as the current setting for the group, the " +
                                        "current setting will be changed to not use a proxy"}
                                    tooltipPlacement={"top"}
                                    disabled={!enableProxyConfigGridButtons}
                                    onClick={() => {
                                        //Remove selected proxy config from group
                                        if(proxyConfigGridApi && proxyConfigGridApi.getSelectedRows() && proxyConfigGridApi.getSelectedRows().length > 0 && groupForProxyConfigModal && groupForProxyConfigModal.zenGroupId) {
                                            let selectedRow = proxyConfigGridApi.getSelectedRows()[0]
                                            if(selectedRow){
                                                setIsLoading(true)
                                                let url = selectedRow.url
                                                let username = selectedRow.username
                                                removeGroupProxyConfigReactive(groupForProxyConfigModal.zenGroupId, url, username).then(response => {
                                                    setIsLoading(false)
                                                    NotificationManager.success("Successfully removed configuration")
                                                    setEnableProxyConfigGridButtons(false)
                                                    if(proxyGridRowData){
                                                        let newRowData = []
                                                        //remove proxy config from grid row data
                                                        proxyGridRowData.forEach(config => {
                                                            if(!(config.url === url && config.username === username)){
                                                                newRowData.push(config)
                                                            }
                                                        })
                                                        setProxyGridRowData(newRowData)
                                                    }
                                                }).catch(error => {
                                                    if(error.message){
                                                        NotificationManager.error(error.message);
                                                    }
                                                    else{
                                                        NotificationManager.error("Unexpected error making this request")
                                                    }
                                                    setIsLoading(false)
                                                })
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="mt-2">
                            <div id="proxyConfigGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                                <div className="ag-theme-alpine" style={{height: 300}}>
                                    <AgGridReact
                                        columnMenu={"legacy"}
                                        columnDefs={[
                                            {
                                                sortable: true, resizable: true, field: "url", initialWidth: 200, filter: 'agTextColumnFilter',
                                                filterParams: defaultClientSideTextFilterParams,
                                            },
                                            {
                                                sortable: true, resizable: true, field: "username", initialWidth: 200, filter: 'agTextColumnFilter',
                                                filterParams: defaultClientSideTextFilterParams,
                                            },
                                            {
                                                sortable: true, resizable: true, field: "password", initialWidth: 200,
                                                valueFormatter: function(params){
                                                    //Rest should not return pass but always display this in cell
                                                    return "****"
                                                },
                                            }
                                        ]}
                                        suppressContextMenu={true}
                                        suppressExcelExport={true}
                                        maintainColumnOrder={true} //fixes issue where if you re-order/move column then click anywhere on the grid it reverts this change
                                        suppressCsvExport={true}
                                        onGridReady={(params) => {
                                            setProxyConfigGridApi(params.api)
                                        }}
                                        onFirstDataRendered={(params) => {
                                            params.api.autoSizeAllColumns()
                                        }}
                                        modules={[ClientSideRowModelModule, RichSelectModule, SetFilterModule, MenuModule, ColumnsToolPanelModule]}
                                        rowSelection={rowSelection}
                                        onFilterChanged={(params) => {
                                            params.api.deselectAll();
                                        }}
                                        suppressMultiSort={true}
                                        onSelectionChanged={(params) => {
                                            let selectedRows = params.api.getSelectedRows();
                                            if(selectedRows && selectedRows.length > 0){
                                                setEnableProxyConfigGridButtons(true)
                                            }
                                            else{
                                                setEnableProxyConfigGridButtons(false)
                                            }
                                        }}
                                        enableCellTextSelection={true}
                                        ensureDomOrder={true}
                                        rowData={proxyGridRowData}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal contentLabel="Edit Auto-Delete Extortion Response EXEs"
                   isOpen={editAutoDeleteModalIsOpen}
                   onRequestClose={() => {
                       resetEditAutoDeleteModal()
                   }}
                   shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-3xl max-w-3xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <ConfirmationModal
                    text={`You are about to change the auto-delete extortion response executables setting for this group, would you like the continue?`}
                    onConfirm={() => {
                        if(groupForEditAutoDelete && groupForEditAutoDelete.zenGroupId){
                            setIsLoading(true)
                            updateGroupEnableIncidentAutoDeleteReactive(groupForEditAutoDelete.zenGroupId, newAutoDeleteSettingForModal).then(result => {
                                setIsLoading(false)
                                NotificationManager.success("Successfully updated this group")
                                resetEditAutoDeleteModal()
                            }).catch(error => {
                                setIsLoading(false)
                                if(error.message){
                                    NotificationManager.error(error.message)
                                }
                                else{
                                    NotificationManager.error("Unexpected error updating this group")
                                }
                            })
                        }
                    }}
                    onClose={() => {
                        setConfirmEditAutoDeleteModalIsOpen(false);
                    }}
                    opened={confirmEditAutoDeleteModalIsOpen}
                />
                <div className={"flex flex-1 flex-col gap-y-5 w-full p-8"}>
                    <div className="flex flex-1 flex-col gap-y-3">
                        {/*Title with exit button*/}
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Edit Auto-Delete Extortion Response Exectuables</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    resetEditAutoDeleteModal()
                                }}
                            />
                        </div>
                        <hr className="mt-3 h-0.5"/>
                        <div className="">
                            <label><label className={"font-bold"}>WARNING:</label> Enabling this setting will cause agents in this group on version 4.4.8.3 and
                                higher to DELETE the program path
                                executable of any associated extortion responses to the related agent. Use this setting
                                carefully</label>
                        </div>
                        <hr className="mt-3 h-0.5"/>
                        <div className="">
                            <label className="font-bold">Current
                                Group: </label>{groupForEditAutoDelete && groupForEditAutoDelete.friendlyName}
                        </div>
                        <div className={`flex flex-row items-center gap-x-2`}>
                            <label className="font-bold">Current Setting: </label>
                            <ThemeProvider theme={switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={groupForEditAutoDelete && groupForEditAutoDelete.enableIncidentAutoDelete}
                                        name="autoDeleteToggle"
                                        className={"opacity-50"}
                                    />
                                } label={groupForEditAutoDelete && groupForEditAutoDelete.enableIncidentAutoDelete ? "Enabled" : "Disabled"}/>
                            </ThemeProvider>
                        </div>
                        <hr className="mt-3 h-0.5"/>
                        <div className={`flex flex-row items-center gap-x-2`}>
                            <label className="font-bold">New Auto-Delete Setting for Group:</label>
                            <ThemeProvider theme={switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={newAutoDeleteSettingForModal}
                                        onChange={e => setNewAutoDeleteSettingForModal(e.target.checked)}
                                        name="editAutoDeleteToggle"
                                    />
                                } label={newAutoDeleteSettingForModal ? "Enabled" : "Disabled"}
                                />
                            </ThemeProvider>
                        </div>
                        <div>
                            <ThemeProvider theme = {buttonTheme}>
                                <Button variant={"contained"} className={""} color={"primary"} type={"submit"}
                                        //disabled={groupForEditAutoDelete && newAutoDeleteSettingForModal === groupForEditAutoDelete.enableIncidentAutoDelete}
                                        onClick={() => {
                                            setConfirmEditAutoDeleteModalIsOpen(true)
                                        }}>
                                    Submit
                                </Button>
                            </ThemeProvider>
                        </div>
                    </div>
                    <hr className="h-0.5"/>
                </div>
            </Modal>
            <div className="flex flex-1 flex-row h-full overflow-y-auto">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col mr-10 ml-5 mt-8 flex-nowrap gap-y-3 h-full">
                    {privatePageHeaderHelper("Groups")}
                    <hr className="bg-black h-0.5"/>
                    {/*Create New Group*/}
                    <div className="flex flex-row justify-between gap-x-1 gap-y-3">
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <div className={"self-end flex flex-col gap-y-0"}>
                                <GridColumnFilterStateSaving
                                    useFilterStateSettingToggled={useFilterStateSettingToggled}
                                    setUseFilterStateSettingToggled={setUseFilterStateSettingToggled}
                                    toggleUpdateUseFilterState = {toggleUpdateUseFilterState}
                                    useColumnStateSettingToggled = {useColumnStateSettingToggled}
                                    setUseColumnStateSettingToggled = {setUseColumnStateSettingToggled}
                                    toggleUpdateUseColumnState = {toggleUpdateUseColumnState}/>
                                <div className={`flex flex-row items-center mt-0 mb-0`}>
                                    <ThemeProvider theme = {switchTheme}>
                                        <FormControlLabel control={
                                            <Switch
                                                checked={showClientAuthIconToggled}
                                                name="showGroupClientAuthIconToggle"
                                                color={"primary"}
                                                onChange={toggleSetting => {
                                                    setShowClientAuthIconToggled(toggleSetting.target.checked)
                                                    updateChartVisibilitySettingInSession(showGroupInstallerConfigIconToggledSessionVariable, toggleSetting.target.checked, updateShowGroupInstallerConfigIconToggledReactive)
                                                    //We have to refresh the cell renderer for the Group column to show/hide the icon
                                                    gridApi && gridApi.refreshCells({force: true, columns: ["friendlyName"], suppressFlash: true})
                                                }}
                                            />
                                        } label={
                                            <div className={"flex flex-row gap-x-1 gap-y-2 items-center flex-wrap"}>
                                                {showClientAuthIconToggled ? "Show Installer Script Config Icon" : "Do Not Show Installer Script Config Icon"}
                                                <MuiIconButtonWithTooltip
                                                    icon={
                                                        <FontAwesomeIcon
                                                            className="object-contain"
                                                            icon="fa-solid fa-circle-info"
                                                            size="xs"
                                                            color={"#949494"}
                                                        />
                                                    }
                                                    tooltipTitle={`${showClientAuthIconToggled ? "Clicking this icon in the Group column below will show the group id and client auth for the group used in the agent installer script" :
                                                        "When this icon is shown in the Group column below, clicking it will show the group id and client auth for the group used in the agent installer script"}`}
                                                    tooltipPlacement={"bottom-start"}
                                                />
                                            </div>
                                        } />
                                    </ThemeProvider>
                                </div>
                            </div>
                            <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                                <MuiIconButtonWithTooltipAndBox
                                    icon={<PersonAddAlt1Icon className={"cursor-pointer"}/>} tooltipTitle={"Create New Group"}
                                    tooltipPlacement={"top"}
                                    onClick={() => {
                                        setGroupModalIsForNewGroup(true)
                                        setShowCreateGroupModal(true)
                                    }}
                                />
                                <MuiIconButtonWithTooltipAndBox
                                    icon={<EditNoteIcon className={"cursor-pointer"}/>} tooltipTitle={"Click to edit/view the selected group's settings"}
                                    disabled={!enableButtons}
                                    tooltipPlacement={"top"}
                                    onClick={() => {
                                        if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length > 0){
                                            let groupToEdit = gridApi.getSelectedNodes()[0]
                                            if(!groupToEdit){
                                                NotificationManager.error("Error, no rows are selected")
                                                return
                                            }
                                            if(!groupToEdit.data || !groupToEdit.data.zenGroupId){
                                                return
                                            }
                                            setSelectedGroupForBulkEditGroupModal(groupToEdit.data)
                                            setGroupModalIsForNewGroup(false)
                                            setShowCreateGroupModal(true)
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className={"flex flex-row flex-wrap gap-y-3 gap-x-8 self-end justify-end"}>
                            <ClickToShowColumnOptionsWithToggleButtonGroup
                                columnMode={columnMode} setColumnMode={setColumnMode} gridColumnStateSessionVariableName={gridColumnStateSessionVariableName} gridApi={gridApi}
                                minColumnIds={minColumnIds} medColumnIds={medColumnIds} updateGridColumnModeFunction={updateGroupsGridColumnModeReactive} />
                            <ClearRefresh gridApi = {gridApi}
                                          showRefreshIcon={false} sseDataPullActive={sseDataPullActive}
                                          refreshGridFunction = {resetGrid} showExcelExportIcon={true} excelExportFunction={excelExport} />
                        </div>

                    </div>
                    <div className="h-full flex flex-col gap-y-5" id="gridRoot">
                        {getGrid()}
                        <Footer />
                    </div>
                </div>
            </div>

            <NotificationContainer />
        </div>
    );
    function getCanaryExtensionModeForCell(groupCanaryExtensionConfigsList){
        if(groupCanaryExtensionConfigsList === null || groupCanaryExtensionConfigsList === undefined){
            return "Custom"
        }
        //Check for Min,Normal,Max,Custom. Sort the groupCanaryExtensionConfigsList with order of sort being "ext", "parentChild", "folderless", then compare lists
        try{
            let sortedGroupList = _.sortBy(groupCanaryExtensionConfigsList, ["ext", "parentChild", "folderless"])
            if(_.isEqual(sortedGroupList, canaryMinModeSortedList)){
                return "Minimum"
            }
            if(_.isEqual(sortedGroupList, canaryNormalModeSortedList)){
                return "Normal"
            }
            if(_.isEqual(sortedGroupList, canaryMaxModeSortedList)){
                return "Maximum"
            }
        } catch (e) {}
        //else return custom
        return "Custom"
    }

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, 'groupsGridFilterState', updateGroupsGridUseFilterStateReactive);
    }

    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, gridColumnStateSessionVariableName, updateGroupsGridUseColumnStateReactive);
    }

    function resetReassignGroupModal(){
        setShowReassignGroupsModal(false)
        setDistributionGroupsForReassignModal([])
        setReassignGroupsModalGroupSelected()
        setGroupsToReassign([])
        setSelectedGroupsToReassign([])
        setZenGroupIdToUpdateDistributionGroup()
    }

    async function handleReassignSelectedGroups(){
        if(zenGroupIdToUpdateDistributionGroup && reassignGroupsModalGroupSelected && selectedGroupsToReassign && selectedGroupsToReassign.length > 0){
            setIsLoading(true)
            try{
                let response = null
                if(reassignGroupsModalGroupSelected === "none"){ //custom added option to allow user to remove selected groups assignedDistributionGroupId field
                    response = await updateGroupsAssignedDistributionGroupIdReactive(null, selectedGroupsToReassign, zenGroupIdToUpdateDistributionGroup)
                }
                else{
                    response = await updateGroupsAssignedDistributionGroupIdReactive(reassignGroupsModalGroupSelected, selectedGroupsToReassign, zenGroupIdToUpdateDistributionGroup)
                }
                if(!response.needToReassignGroups){
                    NotificationManager.success("Successfully updated all groups that needed to be reassigned a distribution group")
                    //make sure all necessary fields are reset
                    if(showCreateGroupModal && !groupModalIsForNewGroup){
                        //if edit group modal is open, we don't want to reset everything because we still want the edit group modal to be open so user can finish editing the group after re-assigning distr groups
                        resetReassignGroupModal()
                    }
                    else{
                        //else edit modal is not open, reset like normal
                        resetGrid()
                    }
                }
                else{
                    //user needs to reassign existing groups to have a different assignedDistributionGroupId
                    if(response.zenGroupsThatNeedReassigned){
                        let distributionGroupsForReassign = getDistributionGroups(true)
                        if(distributionGroupsForReassign){
                            //good to go, we need to remove the value.data.zenGroupId group from the distributionGroupsForReassign list
                            let distributionGroupsList = []
                            distributionGroupsForReassign.forEach(distributionGroup => {
                                if(distributionGroup.id && distributionGroup.id !== zenGroupIdToUpdateDistributionGroup){
                                    distributionGroupsList.push(distributionGroup)
                                }
                            })
                            let noGroupOption = {"id":"none", "friendlyName":"Do Not Reassign a Value"}
                            distributionGroupsList.push(noGroupOption)
                            setDistributionGroupsForReassignModal(distributionGroupsList)
                            setGroupsToReassign(response.zenGroupsThatNeedReassigned)
                            setSelectedGroupsToReassign([])
                        }
                        else{
                            NotificationManager.error("Unexpected error updating groups");
                        }
                    }
                    else{
                        NotificationManager.error("Unexpected error updating groups");
                    }

                }
            }
            catch (error){
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error(`Unexpected error updating groups`);
                }
            }
            setIsLoading(false)
        }

    }

    function getGrid(){
        return (
            <Grid
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                sideBar={sideBar}
                rowSelection={rowSelection}
                setGridApi={setGridApi}
                excelExport={excelExport}
                columnMode={columnMode}
                setColumnMode={setColumnMode}
                setIsLoading={setIsLoading}
                sseDataPullActive={sseDataPullActive}
                setSSEDataPullActive={setSSEDataPullActive}
                asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                setEnableButtons={setEnableButtons}
            />
        );
    }

    function resetGrid(){
        //gridApi && gridApi.onFilterChanged()
        gridApi && gridApi.deselectAll();
        setIsLoading(false)
        setUsers([])
        setUsersGridApi(null)
        setEnableUsersGridButtons(false)
        setZenGroup(null)
        setShowManageGroupModal(false)
        resetReassignGroupModal()
        setSelectedGroupForSSLCertModal()
        setSSLCertGridRowData([])
        setSSLGridApi(null)
        setEnableSSLGridButtons(false)
        setShowCreateGroupModal(false)

        //TODO: should reset zenGroups and distributionGroups with new data
        resetFormFields()

    }

    function excelExport(){
        standardExcelExportHelper(gridApi, sseDataPullActive, "groupsGridExport", ["assignedDistributionGroupId"])
    }

    function convertSSLPinningModeToFormattedValue(value){
        let returnVal = value
        sslPinningModeOptions.forEach(element => {
            if(element.value === value){
                returnVal = element.label
            }
        })
        return returnVal
    }
}

export function getPasswordExpirationToDisplayInCell(passwordExpirationPolicyInDays){
    if(passwordExpirationPolicyInDays){
        //need to check for the number and the strings in passwordPolicyFilterAndEditableOptions because these are the values from the cell editor
        switch(passwordExpirationPolicyInDays) {
            case 90: case "90 Days":
                return '90 Days'
            case 180: case "180 Days":
                return '180 Days'
            case 365: case "365 Days":
                return '365 Days'
            case 'Never':
                return 'Never'
            default:
                return null;
        }
    }
    else{
        return 'Never'
    }
}
export function getAgentInactiveDaysForCell(agentInactiveDaysSetting){
    let agentInactiveDaysValueToReturn = inactiveAgentDaysOffText
    if(agentInactiveDaysSetting === "Thirty"){
        agentInactiveDaysValueToReturn = inactiveAgentDays30Text
    }
    else if(agentInactiveDaysSetting === "Sixty"){
        agentInactiveDaysValueToReturn = inactiveAgentDays60Text
    }
    else if(agentInactiveDaysSetting === "Ninety"){
        agentInactiveDaysValueToReturn = inactiveAgentDays90Text
    }

    return agentInactiveDaysValueToReturn
}

class Grid extends Component {
    rowData = []
    updateTransactionsToApply = []
    abortController = new AbortController()
    constructor(props, filterVals) {
        super(props);
    }

    componentWillUnmount(){
        this.abortController.abort()
    }

    onFirstDataRendered = (params) => {
        //params.api.sizeColumnsToFit();
    };
    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        if(params.source !== "api" && params.source !== "gridOptionsChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateGroupsGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateGroupsGridColumnModeReactive, customColumnModeText)
        }
        else if(params.source === "api" && params.type === "sortChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateGroupsGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateGroupsGridColumnModeReactive, customColumnModeText)
        }
    }

    getContextMenuItems = (params) => {
        let excelExport = this.props.excelExport //don't have access to this.props below in the action function so define it here
        return [
            standardExcelExportObjectInContextMenu(excelExport),
            "resetColumns",
            "autoSizeAll"
        ];
    };

    updateGridForChangeStream = async (changeStreamData) => {
        let operationType = changeStreamData.operationType
        let objectBody = changeStreamData.body
        objectBody["zenGroupId"] = objectBody["id"]
        objectBody["friendlyName"] = objectBody["zenGroupDisplayName"]
        let managedUpdateSettings = objectBody["managedUpdateSettings"]
        if(managedUpdateSettings){
            objectBody["autoUpdate"] = managedUpdateSettings.autoUpdate
            objectBody["latestAgentVersionApproved"] = managedUpdateSettings.latestAgentVersionApproved
        }

        if(operationType === "UPDATE" || operationType === "REPLACE"){
            /*
                Check if we have an existing value for assignedDistributionGroupName before applying update so we can avoid making an api call in assignedDistributionGroupValueFormatterForGroupsPage.
                Need to check some conditions if we can re-use the value though.
             */
            if(!this.gridApi.destroyCalled){
                let updateAssignedDistributionGroupId = objectBody["assignedDistributionGroupId"]
                if(updateAssignedDistributionGroupId !== null && updateAssignedDistributionGroupId !== undefined){
                    //Now we can check if assignedDistributionGroupId is the same or changed from existing rowNode
                    let groupRowNode = this.gridApi.getRowNode(objectBody["zenGroupId"])
                    if(groupRowNode && groupRowNode.data){
                        //found row node. If they are the same then re-use, else let assignedDistributionGroupValueFormatterForGroupsPage get the value
                        if(updateAssignedDistributionGroupId === groupRowNode.data.assignedDistributionGroupId && groupRowNode.data.assignedDistributionGroupName){
                            objectBody["assignedDistributionGroupName"] = groupRowNode.data.assignedDistributionGroupName
                        }
                    }
                }
                else{
                    //else assignedDistributionGroupId is null in update coming in, prep it to have
                    objectBody["assignedDistributionGroupName"] = "(Double Click to Assign)"
                }
                standardHandleUpdateAndReplaceEvent(objectBody, this.gridApi, this.props.sseDataPullActive, this.updateTransactionsToApply)
            }
        }
        else if (operationType === "INSERT"){
            standardHandleInsertEvent(objectBody, this.gridApi, this.props.sseDataPullActive)
        }
    }

    populateGrid = async (rowData) => {
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    onGridReady = async (params) => {
        this.gridApi = params.api;
        this.props.setGridApi(params.api);
        // Disable text selection on the page while holding shift or control (to allow grid selections to be done easily without selecting all text)
        ["keyup","keydown"].forEach((event) => {
            window.addEventListener(event, (e) => {
                document.onselectstart = function() {
                    return !(e.shiftKey || e.ctrlKey);
                }
            });
        });

        let columnMode = this.props.columnMode
        //check which initial column mode to apply
        if(columnMode === customColumnModeText){
            onGridReadyHelperForColumnState(params, gridColumnStateSessionVariableName)
        }
        else if(columnMode === minColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, params.api, this.props.setColumnMode, minColumnModeText, minColumnIds, updateGroupsGridColumnModeReactive)
        }
        else if(columnMode === mediumColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, params.api, this.props.setColumnMode, mediumColumnModeText, medColumnIds, updateGroupsGridColumnModeReactive)
        }
        //else if columnMode is max then the default column state already shows the max amount of columns no need to update

        onGridReadyHelper(params, "groupsGridFilterState");
        await loadDataWithSSEAndStartChangeStreamListener("/groupManagementListReactive", "/sse/listenToZenGroupEvent",
            this.populateGrid, this.updateGridForChangeStream, params, this.props.setSSEDataPullActive, this.props.setAsyncTransactionWaitMillis, this.updateTransactionsToApply,
            this.abortController)
        //params.api.sizeColumnsToFit()
    };

    getRowId = (params) => {
        return params.data.zenGroupId
    }

    onCellEditingStopped = (event) => {
        let gridApi = event.api
        if(event.column.colId === "canaryExtensionMode"){
            let zenGroupId = event.data.zenGroupId
            let newValue = event.newValue
            if(zenGroupId && newValue && newValue !== "Custom" && canaryExtensionModeEditableOptionsList.includes(newValue)){
                if(event.newValue === event.oldValue){
                    event.data.canaryExtensionMode = event.oldValue
                    gridApi.refreshCells({columns: ["canaryExtensionMode"], suppressFlash: true, rowNodes: [event.node]})
                    return;
                }
                editGroupCanaryExtensionModeReactive(zenGroupId, newValue).then(result => {
                    NotificationManager.success("Successfully updated this group")
                    let newList = []
                    if(newValue === "Minimum"){
                        newList = canaryMinModeList
                    }
                    else if(newValue === "Normal"){
                        newList = canaryNormalModeList
                    }
                    else if(newValue === "Maximum"){
                        newList = canaryMaxModeList
                    }
                    event.data.canaryExtensionConfigs = newList
                    gridApi.refreshCells({columns: ["canaryExtensionMode"], suppressFlash: true, rowNodes: [event.node]})
                }).catch(error => {
                    if(error.message){
                        NotificationManager.error(error.message)
                    }
                    else{
                        NotificationManager.error("Unexpected error updating this group")
                    }
                    event.data.canaryExtensionMode = event.oldValue
                    gridApi.refreshCells({columns: ["canaryExtensionMode"], suppressFlash: true, rowNodes: [event.node]})
                })
            }
            else{
                event.data.canaryExtensionMode = event.oldValue
                gridApi.refreshCells({columns: ["canaryExtensionMode"], suppressFlash: true, rowNodes: [event.node]})
            }
        }
        else if(event.column.colId === "friendlyName"){
            let newName = event.newValue;
            if(newName && newName.trim().length > 0){
                if(event.newValue === event.oldValue){
                    event.data.friendlyName = event.oldValue
                    event.api.refreshCells({columns: ["friendlyName"], suppressFlash: true, rowNodes: [event.node]})
                    return;
                }
                if(!event.data.zenGroupId){
                    event.data.friendlyName = event.oldValue
                    event.api.refreshCells({columns: ["friendlyName"], suppressFlash: true, rowNodes: [event.node]})
                    return;
                }
                changeZenGroupGroupNameReactive(newName.trim(), event.data.zenGroupId).then(response => {
                    NotificationManager.success("Successfully changed this group's name")
                }).catch(error => {
                    if(error.message){
                        NotificationManager.error(error.message);
                    }
                    else{
                        NotificationManager.error("Error changing group name")
                    }
                    event.data.friendlyName = event.oldValue
                    event.api.refreshCells({columns: ["friendlyName"], suppressFlash: true, rowNodes: [event.node]})
                })
            }
            else{
                event.data.friendlyName = event.oldValue
                event.api.refreshCells({columns: ["friendlyName"], suppressFlash: true, rowNodes: [event.node]})
            }
        }
        else if (event.column.colId === "organizationName"){
            let newName = event.newValue;
            if(newName && newName.trim().length > 0){
                if(event.newValue === event.oldValue){
                    event.data.organizationName = event.oldValue
                    event.api.refreshCells({columns: ["organizationName"], suppressFlash: true, rowNodes: [event.node]})
                    return;
                }
                if(!event.data.zenGroupId){
                    event.data.organizationName = event.oldValue
                    event.api.refreshCells({columns: ["organizationName"], suppressFlash: true, rowNodes: [event.node]})
                    return;
                }
                changeZenGroupOrganizationNameReactive(newName.trim(), event.data.zenGroupId).then(response => {
                    NotificationManager.success("Successfully changed this group's organization name")

                }).catch(error => {
                    if(error.message){
                        NotificationManager.error(error.message);
                    }
                    else{
                        NotificationManager.error("Error changing group's organization name")
                    }
                    event.data.organizationName = event.oldValue
                    event.api.refreshCells({columns: ["organizationName"], suppressFlash: true, rowNodes: [event.node]})
                })
            }
            else{
                event.data.organizationName = event.oldValue
                event.api.refreshCells({columns: ["organizationName"], suppressFlash: true, rowNodes: [event.node]})
            }
        }
        else if(event.column.colId === "sslPinningMode"){
            let newValue = event.newValue
            let oldValue = event.oldValue
            if(newValue){
                if(newValue === oldValue){
                    event.data.sslPinningMode = oldValue
                    event.api.refreshCells({columns: ["sslPinningMode"], suppressFlash: true, rowNodes: [event.node]})
                    return;
                }
                let zenGroupId = event.data.zenGroupId
                if(!zenGroupId){
                    event.data.sslPinningMode = oldValue
                    event.api.refreshCells({columns: ["sslPinningMode"], suppressFlash: true, rowNodes: [event.node]})
                    return;
                }
                let modeToSend = sslPinningModeOptions.find(({value, label}) => label === newValue)
                editGroupSSLPinningModeReactive(zenGroupId, modeToSend.value).then(response => {
                    NotificationManager.success("Successfully updated this group")

                }).catch(error => {
                    if(error.message){
                        NotificationManager.error(error.message)
                    }
                    else{
                        NotificationManager.error("Unexpected error updating this group")
                    }
                    event.data.sslPinningMode = oldValue
                    event.api.refreshCells({columns: ["sslPinningMode"], suppressFlash: true, rowNodes: [event.node]})
                })
            }
            else{
                event.data.sslPinningMode = oldValue
                event.api.refreshCells({columns: ["sslPinningMode"], suppressFlash: true, rowNodes: [event.node]})
            }
        }
        else if(event.column.colId === "assignedDistributionGroupName"){
            let zenGroupId = event.data.zenGroupId
            let newGroup = event.newValue
            if(zenGroupId && newGroup){
                if(event.newValue === event.oldValue){
                    event.data.assignedDistributionGroupName = event.oldValue
                    event.api.refreshCells({columns: ["assignedDistributionGroupName"], suppressFlash: true, rowNodes: [event.node]})
                    return;
                }
                if(newGroup === "(Double Click to Assign)"){
                    event.data.assignedDistributionGroupName = event.oldValue
                    event.api.refreshCells({columns: ["assignedDistributionGroupName"], suppressFlash: true, rowNodes: [event.node]})
                    return;
                }
                const newAssignedDistributionGroupId = getDistributionGroups().find(
                    (zg) => zg.friendlyName === newGroup || zg.id === newGroup
                );
                if(!newAssignedDistributionGroupId){
                    event.data.assignedDistributionGroupName = event.oldValue
                    event.api.refreshCells({columns: ["assignedDistributionGroupName"], suppressFlash: true, rowNodes: [event.node]})
                    return;
                }
                if(event.data.assignedDistributionGroupId === newAssignedDistributionGroupId.id){
                    //Check if the same assignedDistributionGroupId is selected
                    event.data.assignedDistributionGroupName = event.oldValue
                    event.api.refreshCells({columns: ["assignedDistributionGroupName"], suppressFlash: true, rowNodes: [event.node]})
                    return;
                }
                if(zenGroupId === newAssignedDistributionGroupId.id){
                    NotificationManager.error("You may not set a group's assigned distribution group to itself.")
                    event.data.assignedDistributionGroupName = event.oldValue
                    event.api.refreshCells({columns: ["assignedDistributionGroupName"], suppressFlash: true, rowNodes: [event.node]})
                    return;
                }
                this.props.setIsLoading && this.props.setIsLoading(true)
                changeZenGroupAssignedDistributionGroupIdReactive(zenGroupId, newAssignedDistributionGroupId.id).then(response => {
                    this.props.setIsLoading && this.props.setIsLoading(false)
                    NotificationManager.success("Successfully updated this group")
                    event.node.data.assignedDistributionGroupId = newAssignedDistributionGroupId.id
                    event.node.data.assignedDistributionGroupName = newGroup
                    //new value does not display on cell without calling the refresh cells, this will only refresh this
                    // row's assignedDistributionGroupName/assignedDistributionGroupId cells
                    event.api.refreshCells({columns: ["assignedDistributionGroupName", "assignedDistributionGroupId"], suppressFlash: true, rowNodes: [event.node]})
                }).catch(error => {
                    this.props.setIsLoading && this.props.setIsLoading(false)
                    if(error.message){
                        NotificationManager.error(error.message)
                    }
                    else{
                        NotificationManager.error("Unexpected error updating this group")
                    }
                    event.data.assignedDistributionGroupName = event.oldValue
                    event.api.refreshCells({columns: ["assignedDistributionGroupName"], suppressFlash: true, rowNodes: [event.node]})
                })
            }
            else{
                event.data.assignedDistributionGroupName = event.oldValue
                event.api.refreshCells({columns: ["assignedDistributionGroupName"], suppressFlash: true, rowNodes: [event.node]})
            }
        }
        else if(event.column.colId === "latestAgentVersionApproved"){
            let zenGroupId = event.data.zenGroupId
            let newValue = event.newValue
            let oldValue = event.oldValue
            if(zenGroupId && newValue && newValue !== oldValue){
                editGroupLatestAgentVersionApprovedSettingReactive(zenGroupId, newValue).then(response => {
                    NotificationManager.success("Successfully updated this group")

                }).catch((error) => {
                    if(error.message){
                        NotificationManager.error(error.message)
                    }
                    else{
                        NotificationManager.error("Unexpected error updating this group")
                    }
                    event.data.latestAgentVersionApproved = oldValue
                    event.api.refreshCells({columns: ["latestAgentVersionApproved"],suppressFlash: true, rowNodes: [event.node]})
                })
            }
            else{
                event.data.latestAgentVersionApproved = oldValue
                event.api.refreshCells({columns: ["latestAgentVersionApproved"], suppressFlash: true, rowNodes: [event.node]})
            }
        }
        else if(event.column.colId === "passwordExpirationPolicyInDays"){
            let zenGroupId = event.data.zenGroupId
            let newValue = event.newValue
            if(zenGroupId && newValue){
                if(event.newValue === event.oldValue){
                    event.data.passwordExpirationPolicyInDays = event.oldValue
                    event.api.refreshCells({columns: ["passwordExpirationPolicyInDays"], suppressFlash: true, rowNodes: [event.node]})
                    return;
                }
                let valueToSend = passwordPolicyFilterAndEditableOptionsMap.find(({value, label}) => label === newValue)
                editGroupPasswordExpirationPolicyReactive(zenGroupId, valueToSend.value).then(result => {
                    NotificationManager.success("Successfully updated this group")

                }).catch(error => {
                    if(error.message){
                        NotificationManager.error(error.message)
                    }
                    else{
                        NotificationManager.error("Unexpected error updating this group")
                    }
                    event.data.passwordExpirationPolicyInDays = event.oldValue
                    event.api.refreshCells({columns: ["passwordExpirationPolicyInDays"], suppressFlash: true, rowNodes: [event.node]})
                })
            }
            else{
                event.data.passwordExpirationPolicyInDays = event.oldValue
                event.api.refreshCells({columns: ["passwordExpirationPolicyInDays"], suppressFlash: true, rowNodes: [event.node]})
            }
        }
        else if(event.column.colId === "agentInactiveDaysSetting"){
            let zenGroupId = event.data.zenGroupId
            let newValue = event.newValue
            if(zenGroupId && newValue){
                if(event.newValue === event.oldValue){
                    event.data.agentInactiveDaysSetting = event.oldValue
                    event.api.refreshCells({columns: ["agentInactiveDaysSetting"], suppressFlash: true, rowNodes: [event.node]})
                    return;
                }

                let agentInactiveDaysToSend = "Off"
                if(newValue === inactiveAgentDays30Text){agentInactiveDaysToSend = "Thirty"}
                else if(newValue === inactiveAgentDays60Text){agentInactiveDaysToSend = "Sixty"}
                else if(newValue === inactiveAgentDays90Text){agentInactiveDaysToSend = "Ninety"}
                updateGroupAgentInactiveDaysReactive(zenGroupId, agentInactiveDaysToSend).then(result => {
                    NotificationManager.success("Successfully updated this group")

                }).catch(error => {
                    if(error.message){
                        NotificationManager.error(error.message)
                    }
                    else{
                        NotificationManager.error("Unexpected error updating this group")
                    }
                    event.data.agentInactiveDaysSetting = event.oldValue
                    event.api.refreshCells({columns: ["agentInactiveDaysSetting"], suppressFlash: true, rowNodes: [event.node]})
                })
            }
            else{
                event.data.agentInactiveDaysSetting = event.oldValue
                event.api.refreshCells({columns: ["agentInactiveDaysSetting"], suppressFlash: true, rowNodes: [event.node]})
            }
        }
    }

    render() {
        return (
            <div className={"w-full h-full"} style={{minHeight: "400px"}}>
                <div id="myGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                    <AgGridReact
                        columnMenu={"legacy"}
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, RichSelectModule, ExcelExportModule]}
                        defaultColDef={this.props.defaultColDef}
                        tooltipInteraction={true} //allows tooltips supplied by ag grid to remain open when hovered over by mouse
                        columnDefs={this.props.columnDefs}
                        components={{customNameCellEditor: CustomNameCellEditor, customMuiAutocompleteGroupCellEditor: CustomMuiAutocompleteGroupCellEditor}}
                        multiSortKey={"ctrl"}
                        rowData={this.rowData}
                        getRowId={this.getRowId}
                        onGridReady={this.onGridReady}
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        suppressModelUpdateAfterUpdateTransaction={true}
                        onCellEditingStopped={this.onCellEditingStopped}
                        rowSelection={this.props.rowSelection}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            if(selectedRows && selectedRows.length > 0){
                                //checks if the setEnableButtons method is null or not
                                this.props.setEnableButtons && this.props.setEnableButtons(true);
                            }
                            else{
                                this.props.setEnableButtons && this.props.setEnableButtons(false);
                            }
                        }}
                        enableCellTextSelection={true}
                        maintainColumnOrder={true} //fixes issue where if you re-order/move column then click anywhere on the grid it reverts this change
                        ensureDomOrder={true}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        onFilterChanged={(params)=> {
                            onFilterChangedHelper(params, 'groupsGridFilterState', updateGroupsGridFilterModelReactive);
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                        getContextMenuItems={this.getContextMenuItems}
                        sideBar={this.props.sideBar}
                    />
                </div>
            </div>
        );
    }
}

class UsersGrid extends Component {
    constructor(props, onClickRow, filterVals, headers, data) {
        super(props);
    }
    onFirstDataRendered = (params) => {
        params.api.sizeColumnsToFit();
    };

    onGridReady = async (params) => {
        this.gridApi = params.api;
        params.api.sizeColumnsToFit()
        this.props.setUsersGridApi && this.props.setUsersGridApi(params.api);
    }


    render() {
        return (
            <div className="m-10 mt-2">
                <div
                    id="myGrid"

                    className="ag-theme-alpine rounded-md shadow h-full w-full"
                >
                    <AgGridReact
                        columnMenu={"legacy"}
                        defaultColDef={{
                            resizable: true,
                            filterParams: null,
                            floatingFilter: false,
                            headerClass: "border-0 border-b-0",
                            cellClass: "outline:none",
                            enableCellChangeFlash: true,
                            autoHeight: false,
                            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
                        }}
                        columnDefs={this.props.headers}
                        suppressContextMenu={true}
                        suppressExcelExport={true}
                        suppressCsvExport={true}
                        modules={[ClientSideRowModelModule,MenuModule, ColumnsToolPanelModule, SetFilterModule,RichSelectModule]}
                        domLayout="autoHeight"
                        rowData={this.props.data}
                        rowSelection={this.props.rowSelection}
                        onGridReady={this.onGridReady}
                        onFilterChanged={(params) => {
                            params.api.deselectAll();
                        }}
                        suppressMultiSort={true}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            if(selectedRows && selectedRows.length > 0){
                                //checks if the setEnableButtons method is null or not
                                this.props.setEnableUsersGridButtons && this.props.setEnableUsersGridButtons(true);
                            }
                            else{
                                this.props.setEnableUsersGridButtons && this.props.setEnableUsersGridButtons(false);
                            }
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                    />
                </div>
            </div>
        );
    }
}

export const canaryMinModeList = [{ext: "123", parentChild: true, folderless: false}]
export const canaryNormalModeList = [{ext: "123", parentChild: true, folderless: false}, {ext: "txt", parentChild: true, folderless: false}]
export const canaryMaxModeList = [
    {ext: "txt", parentChild: true, folderless: false},
    {ext: "123", parentChild: true, folderless: true},
    {ext: "jpg", parentChild: true, folderless: true},
    {ext: "abc0", parentChild: true, folderless: false},
    {ext: "abc1", parentChild: true, folderless: false},
    {ext: "abc2", parentChild: true, folderless: false},
    {ext: "abc3", parentChild: true, folderless: false},
    {ext: "abc4", parentChild: true, folderless: false},
    {ext: "abc5", parentChild: true, folderless: false},
    {ext: "abc6", parentChild: true, folderless: false},
    {ext: "abc7", parentChild: true, folderless: false},
    {ext: "abc8", parentChild: true, folderless: false},
    {ext: "abc9", parentChild: true, folderless: false},
    {ext: "abc10", parentChild: true, folderless: false},
    {ext: "abc11", parentChild: true, folderless: false},
    {ext: "abc12", parentChild: true, folderless: false},
    {ext: "abc13", parentChild: true, folderless: false},
    {ext: "abc14", parentChild: true, folderless: false},
    {ext: "abc15", parentChild: true, folderless: false},
    {ext: "abc16", parentChild: true, folderless: false},
    {ext: "abc17", parentChild: true, folderless: false},
    {ext: "abc18", parentChild: true, folderless: false},
    {ext: "abc19", parentChild: true, folderless: false},
    {ext: "abc20", parentChild: true, folderless: false},
    {ext: "abc21", parentChild: true, folderless: false},
    {ext: "abc22", parentChild: true, folderless: false},
    {ext: "abc23", parentChild: true, folderless: false},
    {ext: "abc24", parentChild: true, folderless: false},
    {ext: "abc25", parentChild: true, folderless: false},
    {ext: "abc26", parentChild: true, folderless: false},
    {ext: "abc27", parentChild: true, folderless: false},
    {ext: "abc28", parentChild: true, folderless: false},
    {ext: "abc29", parentChild: true, folderless: false},
    {ext: "abc30", parentChild: true, folderless: false},
    {ext: "abc31", parentChild: true, folderless: false},
    {ext: "abc32", parentChild: true, folderless: false},
    {ext: "abc33", parentChild: true, folderless: false},
    {ext: "abc34", parentChild: true, folderless: false},
    {ext: "abc35", parentChild: true, folderless: false},
    {ext: "abc36", parentChild: true, folderless: false},
    {ext: "abc37", parentChild: true, folderless: false},
    {ext: "abc38", parentChild: true, folderless: false},
    {ext: "abc39", parentChild: true, folderless: false},
    {ext: "abc40", parentChild: true, folderless: false},
    {ext: "abc41", parentChild: true, folderless: false},
    {ext: "abc42", parentChild: true, folderless: false},
    {ext: "abc43", parentChild: true, folderless: false},
    {ext: "abc44", parentChild: true, folderless: false},
    {ext: "abc45", parentChild: true, folderless: false},
    {ext: "abc46", parentChild: true, folderless: false},
    {ext: "abc47", parentChild: true, folderless: false},
    {ext: "abc48", parentChild: true, folderless: false},
    {ext: "abc49", parentChild: true, folderless: false}
]
