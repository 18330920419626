import axios from "../../utils/axiosInstance";


export const launchLinkedInCompanyScraperPhantomBusterReactive = async (linkedInCompanyURLs, sessionCookie, userAgentFromSessionCookie) => {
    return await axios.post("/launchLinkedInCompanyScraperPhantomBusterReactive", {
        linkedInCompanyURLs: linkedInCompanyURLs,
        sessionCookie: sessionCookie,
        userAgentFromSessionCookie: userAgentFromSessionCookie,
    })
};
export const launchLinkedInCompanyScraperForPartnerOrgLeadsReactive = async (partnerOrganizationLeadIds, sessionCookie, userAgentFromSessionCookie) => {
    return await axios.post("/launchLinkedInCompanyScraperForPartnerOrgLeadsReactive", {
        partnerOrganizationLeadIds: partnerOrganizationLeadIds,
        sessionCookie: sessionCookie,
        userAgentFromSessionCookie: userAgentFromSessionCookie,
    })
};
export const launchLinkedInCompanyScraperForPartnerInMotionReactive = async (partnerInMotionId, sessionCookie, userAgentFromSessionCookie) => {
    return await axios.post("/launchLinkedInCompanyScraperForPartnerInMotionReactive", {
        partnerInMotionId: partnerInMotionId,
        sessionCookie: sessionCookie,
        userAgentFromSessionCookie: userAgentFromSessionCookie,
    })
};
export const launchSalesNavigatorEmployeeExportPhantomBusterReactive = async (linkedInCompanyURLs, sessionCookie, userAgentFromSessionCookie) => {
    return await axios.post("/launchSalesNavigatorEmployeeExportPhantomBusterReactive", {
        linkedInCompanyURLs: linkedInCompanyURLs,
        sessionCookie: sessionCookie,
        userAgentFromSessionCookie: userAgentFromSessionCookie,
    })
};
export const launchLinkedInSalesNavigatorEmployeeExportForCompanyProfilesReactive = async (companyProfileIds, sessionCookie, userAgentFromSessionCookie) => {
    return await axios.post("/launchLinkedInSalesNavigatorEmployeeExportForCompanyProfilesReactive", {
        companyProfileIds: companyProfileIds,
        sessionCookie: sessionCookie,
        userAgentFromSessionCookie: userAgentFromSessionCookie,
    })
};
