import React, {Component, useEffect, useMemo, useState} from "react";
import {AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ServerSideRowModelModule} from "@ag-grid-enterprise/server-side-row-model";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import SidebarMenu from "../../components/sideBarComponent";
import ClearIcon from '@mui/icons-material/Clear';
import EditOffIcon from '@mui/icons-material/EditOff';
import {
    defaultZenGroupColumnInitWithOptions,
    defaultZenGroupColumnInitWithOptionsWithValueGetter
} from "../../utils/zenGroupDisplayNameGridHelper";
import {defaultAgentNameColumnInitWithOptions} from "../../utils/agentNameGridHelper";
import {dateValueFormatter} from "../../utils/gridDateFormatter";
import {base64DecodedValueFormatter} from "../../utils/gridBase64ValueFormatter";
import {agentDisplayNameAndZenGroupDisplayNameOnlyCellEditingStopped,} from "../../utils/gridCellEditing";
import {agentVersionFormatter} from "../../utils/agentVersionFormatter";
import {
    ccProcessCreationRelatedModuleLoadsExistReactive,
    findByProcessCreationIdListReactive,
    processCreationListReactive, updateProcessCreationsGridColumnModeReactive,
    updateProcessCreationsGridColumnStateReactive,
    updateProcessCreationsGridFilterModelReactive,
    updateProcessCreationsGridUseColumnStateReactive,
    updateProcessCreationsGridUseFilterStateReactive, updateShowAllCreationsTelemetryReactive
} from "../api/processCreationsApi";
import {refreshGridZenGroupAndAgentInformation} from "../../utils/refreshGridHelper";
import {masterFilterHelper, serverSideFilterHelperHandleMultiConditionChecks} from "../../utils/filterHelper";
import {
    getColumnModeInSession,
    getDefaultAgGridSidebarProps,
    getTelemetryDataVisibilitySettingInSession,
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnState,
    onShowAllTelemetryDataChangedHelper,
    showAllCreationsTelemetryVariable,
    updateColumnModeInSessionHelper,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import CustomNameCellEditor from "../../utils/customCellEditor";
import DTPicker, {dateFilterParametersInHeaderSuppressSorting} from "../../utils/DTPicker";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {Link, useLocation} from "react-router-dom";
import {findZenGroupById} from "../../utils/zenGroupSessionStorageManager";
import {
    ClickToShowColumnOptionsWithToggleButtonGroup,
    customColumnModeText, mediumColumnModeText,
    minColumnModeText, standardApplyMinimumOrMediumColumnMode
} from "../../components/clickToShowButtons";
import {
    decryptAndGetSessionVariable,
    encryptAndStoreSessionVariable,
    getItemFromStorageWithoutDecrypting
} from "../../utils/storageHelper";
import {distinctAgentVersionsReactive} from "../api/agentsApi";
import {MuiIconWithTooltip} from "../../components/muiComponents";
import {IconButton} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";

let gridColumnStateSessionVariableName = "processCreationsGridColumnState"
let minColumnIds = ["zenGroupDisplayName", "agentDisplayName", "parentPath", "childPath", "timestamp"]
let medColumnIds = ["zenGroupDisplayName", "agentDisplayName", "parentPath", "parentArgs", "childPath", "childArgs", "timestamp"]
let globalShowAllTelemetryDataToggled = getTelemetryDataVisibilitySettingInSession(showAllCreationsTelemetryVariable, "processCreationsGridFilterState") //so we can send accurate value to ccProcessCreationRelatedModuleLoadsExistReactive since in cell renderer it does not have access to see changes to the hook
export default function ProcessCreations() {
    const [isLoading, setIsLoading] = useState(false);
    const [gridApi, setGridApi] = useState();
    const processCreationLocation = useLocation();
    const [isCyberCrucibleUser, setIsCyberCrucibleUser] = useState(getItemFromStorageWithoutDecrypting("username")?.endsWith("@cybercrucible.com"));
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession("processCreationsGridFilterState"));
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    const [showAllTelemetryDataToggled, setShowAllTelemetryDataToggled] = useState(getTelemetryDataVisibilitySettingInSession(showAllCreationsTelemetryVariable, "processCreationsGridFilterState"));
    const [columnMode, setColumnMode] = useState(getColumnModeInSession(gridColumnStateSessionVariableName));
    // eslint-disable-next-line no-unused-vars
    const [columnDefs, setColumnDefs] = useState([
        defaultZenGroupColumnInitWithOptionsWithValueGetter(false, true, true),
        defaultAgentNameColumnInitWithOptions(false, 5),
        {
            field: "moduleLoadAnalysis", headerName: "Related Module Load Error", width: 300,
            initialHide: true, suppressColumnsToolPanel: true, lockVisible: true, //default to hiding this column, useEffect below checks and updates if we should let this column be visible
            sortable: false,
            valueFormatter: function (params) {
                if (params.node.data.moduleLoadAnalysis) {
                    return params.node.data.moduleLoadAnalysis
                }
                if(!isCyberCrucibleUser){
                    params.node.setDataValue("moduleLoadAnalysis", " ")
                    return " "
                }
                let id = params.node.data.processCreationId
                if(id === null || id === undefined){
                    params.node.setDataValue("moduleLoadAnalysis", " ")
                    return " "
                }
                ccProcessCreationRelatedModuleLoadsExistReactive(id, globalShowAllTelemetryDataToggled).then(response => {
                    params.node.data.mismatchModuleLoadExists = response.mismatchModuleLoadExists
                    params.node.data.unsignedModuleLoadExists = response.unsignedModuleLoadExists
                    //Set to blank so we don't query again
                    params.node.setDataValue("moduleLoadAnalysis", " ") //lets cell know not to call checkBrowserIncidentHasRelatedProcessInjectionsAndCreationsReactive again
                }).catch(function (error) {
                    params.node.setDataValue("moduleLoadAnalysis", " ") //lets cell know not to call checkBrowserIncidentHasRelatedProcessInjectionsAndCreationsReactive again
                })
                params.node.data.moduleLoadAnalysis = params.node.data.processCreationId
                return params.node.data.processCreationId
            },
            cellRenderer: function (params) {
                if(!isCyberCrucibleUser){
                    return ""
                }
                let spinnerDiv = ""
                let mismatchIconDiv = ""
                let unsignedIconDiv = ""
                let id = params.node.data.processCreationId
                if (id !== null && id !== undefined) {
                    if (params.node.data.moduleLoadAnalysis === params.node.data.processCreationId) {
                        spinnerDiv = <FontAwesomeIcon
                            className="contain fa-pulse mr-1"
                            icon="fa-light fa-spinner"
                            size="lg"
                            name="relatedModulesLoading"
                        />
                    } else {
                        spinnerDiv = ""
                    }
                    //Links
                    if (params.node.data.mismatchModuleLoadExists === true) {
                        mismatchIconDiv =
                            <div className={"mb-1"}>
                                <MuiIconWithTooltip
                                    icon={
                                        <IconButton sx={{width: 25, height: 25}}
                                                    className={`self-center object-contain mb-1`}>
                                            <ClearIcon className={"cursor-pointer mb-1"}/>
                                        </IconButton>
                                    }
                                    tooltipTitle={"Mismatch, the authenticode hash of the file does not match the signed hash inside the signature"}
                                    tooltipPlacement={"bottom-start"}
                                />
                            </div>

                    }
                    if (params.node.data.unsignedModuleLoadExists === true) {
                        unsignedIconDiv =
                            <div className={"mb-1"}>
                                <MuiIconWithTooltip
                                    icon={
                                        <IconButton sx={{width: 25, height: 25}}
                                                    className={`self-center object-contain mb-1`}>
                                            <EditOffIcon className={"cursor-pointer mb-1"}/>
                                        </IconButton>
                                    }
                                    tooltipTitle={"DLL was unsigned"}
                                    tooltipPlacement={"bottom-start"}
                                />
                            </div>
                    }
                }

                return (
                    <div className={"flex flex-nowrap items-center justify-start gap-x-0"}>
                        {spinnerDiv}
                        {unsignedIconDiv}
                        {mismatchIconDiv}
                        {params.node.data.moduleLoadAnalysis}
                    </div>
                )
            }
        },
        { field: "parentPath", headerName: "Parent Path", initialWidth: 500,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                maxNumConditions: 2,
            },
            sortable: true
        },
        { field: "parentArgs", headerName: "Parent Arguments", initialWidth: 500,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                filterOptions: ['contains', 'notContains', 'startsWith'],
                maxNumConditions: 2,
            },
            sortable: true
        },
        { field: "childPath", headerName: "Child Path", initialWidth: 500,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                maxNumConditions: 2,
            },
            sortable: true
        },
        { field: "childArgs", headerName: "Child Arguments", initialWidth: 500,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                filterOptions: ['contains', 'notContains', 'startsWith'],
                maxNumConditions: 2,
            },
            sortable: true
        },
        { field: "parentPid", headerName: "Parent Pid", initialWidth: 200,
            filter: 'agNumberColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
                maxNumConditions: 2,
            },
            sortable: true
        },
        { field: "childPid", headerName: "Child Pid", initialWidth: 200,
            filter: 'agNumberColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
                maxNumConditions: 2,
            },
            sortable: true
        },
        { field: "timestamp", headerName: "Created", initialWidth: 280,
            filter: 'agDateColumnFilter',
            filterParams:dateFilterParametersInHeaderSuppressSorting(2),
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        { field: "dateCollected", headerName: "Collected", initialWidth: 280,
            filter: 'agDateColumnFilter',
            filterParams:dateFilterParametersInHeaderSuppressSorting(2),
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        { field: "childUniqueProcessIdentifier", headerName: "Child Unique Process Identifier", initialWidth: 280,
            filter: 'agTextColumnFilter',
            hide: true,
            suppressColumnsToolPanel: true, lockVisible: true
        },
        { field: "childUsername", headerName: "Username", initialWidth: 320,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],

                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                maxNumConditions: 2,
            },
            sortable: true
        },
        { field: "agentVersionFormatted", headerName: "Agent Version", initialWidth: 280,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                maxNumConditions: 2,
            },
            sortable: true,
            valueFormatter:agentVersionFormatter,
            cellRenderer: function(params){
                let spinnerDiv = ""
                if(params.node.data.agentId === params.valueFormatted ){
                    spinnerDiv = <FontAwesomeIcon
                        className="contain fa-pulse"
                        icon="fa-light fa-spinner"
                        size="lg"
                        name="agentVersionLoading"
                    />
                }else{
                    spinnerDiv = ""
                }
                return(
                    <div id ="fortooltip" className={"flex flex-nowrap items-center justify-start gap-x-2"}>
                        {spinnerDiv}
                        {params.valueFormatted}
                    </div>
                )
            },
        },
    ])
    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            filterParams: null,
            floatingFilter: true,
            headerClass: "border-0 border-b-0",
            cellClass: "outline:none",
            enableCellChangeFlash: true,
            autoHeight: false,
            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
        }
    )
    const sideBar = useMemo(() => {
        //Inside useMemo to help prevent the sidebar from re-rendering
        return getDefaultAgGridSidebarProps()
    }, []);
    const rowSelection = useMemo(() => {
        return {
            mode: 'singleRow',
            enableClickSelection: true,
            checkboxes: false,
            headerCheckbox: false
        };
    }, []);

    //Update the globalShowAllTelemetryDataToggled when showAllTelemetryDataToggled changes so Module Loads col valueFormatter can send proper value
    useEffect(() =>{
        let controller = new AbortController();
        (async () => {
            globalShowAllTelemetryDataToggled = showAllTelemetryDataToggled
        })()
        return () => controller?.abort();
    }, [showAllTelemetryDataToggled]);

    //Check whether to show moduleLoadAnalysis column or not based on if cc user, only cc users should see this
    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            if(isCyberCrucibleUser){
                columnDefs?.forEach((col) => {
                    if(col && col.field === "moduleLoadAnalysis"){
                        col.suppressColumnsToolPanel = false //reset to be visible in panel
                        col.lockVisible = false //reset lockVisible to false
                        col.initialHide = false //reset initialHide to false
                        //don't touch the hide field in case user is in custom col mode and had it hidden before
                    }
                })
            }
            else{
                //else always hide
                columnDefs?.forEach((col) => {
                    if(col && col.field === "moduleLoadAnalysis"){
                        col.hide = true
                    }
                })
            }
        }) ()
        return () => controller?.abort();
    }, [])
    return (
        <div className="flex flex-col h-full">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Process Creations</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <div className="flex flex-1 flex-row h-full overflow-y-auto">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col ml-5 mr-10 mt-8 flex-nowrap gap-y-2 h-full">
                    {privatePageHeaderHelper("Process Creations")}
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row justify-between gap-x-1 gap-y-3">
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <GridColumnFilterStateSaving
                                useFilterStateSettingToggled = {useFilterStateSettingToggled}
                                setUseFilterStateSettingToggled = {setUseFilterStateSettingToggled}
                                toggleUpdateUseFilterState = {toggleUpdateUseFilterState}
                                useColumnStateSettingToggled = {useColumnStateSettingToggled}
                                setUseColumnStateSettingToggled = {setUseColumnStateSettingToggled}
                                toggleUpdateUseColumnState = {toggleUpdateUseColumnState}
                                showAllTelemetryDataToggled={showAllTelemetryDataToggled}
                                toggleUpdateShowAllTelemetryData={toggleUpdateShowAllTelemetryData}
                            />
                        </div>
                        <div className={"flex flex-row flex-wrap gap-y-3 gap-x-8 self-end justify-end"}>
                            <ClickToShowColumnOptionsWithToggleButtonGroup
                                columnMode={columnMode} setColumnMode={setColumnMode} gridColumnStateSessionVariableName={gridColumnStateSessionVariableName} gridApi={gridApi}
                                minColumnIds={minColumnIds} medColumnIds={medColumnIds} updateGridColumnModeFunction={updateProcessCreationsGridColumnModeReactive} />
                            <ClearRefresh gridApi = {gridApi} refreshGridFunction = {refreshGrid}/>
                        </div>
                    </div>
                    <div className="h-full flex flex-col gap-y-5" id="gridRoot">
                        {getGrid()}
                        <Footer />
                    </div>
                </div>
            </div>

            <NotificationContainer />
        </div>
    );

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, 'processCreationsGridFilterState', updateProcessCreationsGridUseFilterStateReactive);
    }
    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, gridColumnStateSessionVariableName, updateProcessCreationsGridUseColumnStateReactive);
    }
    function toggleUpdateShowAllTelemetryData(toggleSetting){
        //Make sure gridApi is initialized, else do nothing
        if(gridApi !== null && gridApi !== undefined){
            setShowAllTelemetryDataToggled(toggleSetting)
            onShowAllTelemetryDataChangedHelper(showAllCreationsTelemetryVariable, toggleSetting, "processCreationsGridFilterState", updateShowAllCreationsTelemetryReactive)
            let dataSource = getDatasourceForGrid(toggleSetting)
            gridApi.setGridOption("serverSideDatasource", dataSource);
        }
    }

    function getGrid(){
        return (
            <Grid
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                sideBar={sideBar}
                rowSelection={rowSelection}
                setGridApi={setGridApi}
                processCreationLocation={processCreationLocation}
                columnMode={columnMode}
                setColumnMode={setColumnMode}
                showAllTelemetryDataToggled={showAllTelemetryDataToggled}
                setShowAllTelemetryDataToggled={setShowAllTelemetryDataToggled}
            />
        );
    }

    async function refreshGrid(){
        await refreshGridZenGroupAndAgentInformation(gridApi, "processCreationId", "processCreationId", findByProcessCreationIdListReactive, null, "agentId",null)
    }
    /*
    function resetGrid(){
        //ReactDOM.unmountComponentAtNode(document.getElementById("gridRoot"))
        //ReactDOM.render(getGrid(), document.getElementById('gridRoot'));
    }

     */
}
//Standardizing datasource for creations grid since the telemetry toggle and initial onGridReady will need to set the datasource
function getDatasourceForGrid(localShowAllTelemetryToggled){
    return {
        getRows: async function (params) {
            try{
                let start = params.request.startRow;
                let end = params.request.endRow;
                let singleConditionFilters = masterFilterHelper(params.request.filterModel, true)
                let multiConditionFilters = serverSideFilterHelperHandleMultiConditionChecks(params.request.filterModel)
                let sortModel = params.request.sortModel;
                let sortModelForRequest = null;
                if(sortModel && sortModel.length > 0){
                    try{ //extra precaution for array access on the sort model so it does not break
                        if(sortModel[0].colId && sortModel[0].sort){
                            sortModelForRequest = sortModel[0]
                        }
                    }
                    catch(error){
                        console.log("Error generating sort request")
                    }
                }
                let count = end - start;
                let page = Math.floor(start / count)
                let objects = await processCreationListReactive(count, page, singleConditionFilters, multiConditionFilters, sortModelForRequest, localShowAllTelemetryToggled);
                let finalPage = true
                //This logic mimics how we would check in the rest api for what to send for the finalPage in the response. Same logic is being used here so we can just return a flux from the
                // endpoint instead of Mono with a Response object
                if(objects && objects.length === count){
                    finalPage = false
                }
                let lastIdx = undefined
                if (finalPage) {
                    lastIdx = (count * page) + objects.length
                }
                params.api.deselectAll();
                const success = (objects.length !== 0);
                if (success) {
                    params.api.setGridOption("loading", false)
                    params.success({
                        rowData: objects, rowCount: lastIdx
                    })
                } else {
                    params.success({
                        rowData: objects, rowCount: lastIdx
                    }) //to stop loading circle from spinning and make it disappear, nothing in objects.
                    params.fail();
                }

            }
            catch(error){
                //console.log(error);
                params.api.showNoRowsOverlay();
                params.fail();
            }
        }
    }
}


let saveFilterChanges = true //used to help let code below know to save filters or not, when this is false that means we were redirected from incidents page and don't want to save filters since we will be auto filtering
class Grid extends Component {
    constructor(props, onClickRow, filterVals, setGridApi) {
        super(props);
    }
    onFirstDataRendered = (params) => {

    };
    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        if(params.source !== "api" && params.source !== "gridOptionsChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateProcessCreationsGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateProcessCreationsGridColumnModeReactive, customColumnModeText)
        }
        else if(params.source === "api" && params.type === "sortChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateProcessCreationsGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateProcessCreationsGridColumnModeReactive, customColumnModeText)
        }
    }
    getContextMenuItems = (params) => {
        return [
            "resetColumns",
            "autoSizeAll"
        ];
    };
    onGridReady = async (params) => {
        // Disable text selection on the page while holding shift or control (to allow grid selections to be done easily without selecting all text)
        ["keyup","keydown"].forEach((event) => {
            window.addEventListener(event, (e) => {
                document.onselectstart = function() {
                    return !(e.shiftKey || e.ctrlKey);
                }
            });
        });

        this.gridApi = params.api;
        this.props.setGridApi(params.api);
        //first check if we are coming from a different page where the user clicked a crosslink, this filter takes precedence over any other saved/default filter
        if(this.props.processCreationLocation && this.props.processCreationLocation.state && this.props.processCreationLocation.state.zenGroupIdClicked
            && this.props.processCreationLocation.state.uniqueProcessIdentifier && this.props.processCreationLocation.state.childPath){
            let zenGroupId = this.props.processCreationLocation.state.zenGroupIdClicked
            let childUniqueProcessIdentifier = this.props.processCreationLocation.state.uniqueProcessIdentifier
            let locationFilterModel = {"childUniqueProcessIdentifier": {
                    filterType: "text",
                    type: "equals",
                    filter: JSON.stringify(childUniqueProcessIdentifier) //need to convert to string for ag grid filters
                }}
            let zenGroup = findZenGroupById(zenGroupId)
            if(zenGroup && zenGroup.friendlyName){
                locationFilterModel["zenGroupDisplayName"] = {filterType: "set", values: [zenGroup.friendlyName]}
            }
            let childPath = this.props.processCreationLocation.state.childPath
            if(childPath){
                locationFilterModel["childPath"] = {
                    filterType: "text",
                    type: "equals",
                    filter: childPath.toLowerCase() //need to send lowercase string since process creations are inserted with the paths converted to lowercase
                }
            }

            //we don't want to save filter changes for the user if we are coming from a page where they clicked the agent link
            saveFilterChanges = false
            params.api.setFilterModel(locationFilterModel)
            //scroll to top of page or else it is very likely the user will be at the bottom of the grid and see no data (since they should only see one row) when being redirected
            window.scroll({behavior: "smooth", top: 0, left: 0})
            //remove from state so if they hit back tab then forward tab filter is not applied again
            window.history.replaceState(this.props.processCreationLocation.state, '')
        } else {
            saveFilterChanges = true
            onGridReadyHelper(params, "processCreationsGridFilterState");
        }
        let columnMode = this.props.columnMode
        //check which initial column mode to apply
        if(columnMode === customColumnModeText){
            onGridReadyHelperForColumnState(params, gridColumnStateSessionVariableName)
        }
        else if(columnMode === minColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, params.api, this.props.setColumnMode, minColumnModeText, minColumnIds, updateProcessCreationsGridColumnModeReactive)
        }
        else if(columnMode === mediumColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, params.api, this.props.setColumnMode, mediumColumnModeText, medColumnIds, updateProcessCreationsGridColumnModeReactive)
        }
        //else if columnMode is max then the default column state already shows the max amount of columns no need to update

        let showAllTelemetry = this.props.showAllTelemetryDataToggled
        if(!saveFilterChanges){ //if coming from root cause, always send false so query uses creations collection in prod db that does not age off
            showAllTelemetry = false
            //If coming from browser incidents page for root cause, we need show all telemetry true
            if(this.props.processCreationLocation && this.props.processCreationLocation.state && this.props.processCreationLocation.state.browserAccessRootCause){
                showAllTelemetry = true
            }
            //also set the toggle to false. Don't save the setting though for user since we are manually setting to false. If they toggle again after this, then it will save
            this.props.setShowAllTelemetryDataToggled && this.props.setShowAllTelemetryDataToggled(showAllTelemetry)
        }
        let dataSource = getDatasourceForGrid(showAllTelemetry)
        params.api.setGridOption("serverSideDatasource", dataSource);

        //params.api.sizeColumnsToFit()
    };
    render() {
        return (
            <div className={"w-full h-full"} style={{minHeight: "400px"}}>
                <div id="myGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                    <AgGridReact
                        columnMenu={"legacy"}
                        modules={[ServerSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule]}
                        defaultColDef={this.props.defaultColDef}
                        columnDefs={this.props.columnDefs}
                        getRowId={(params) => {
                            return params.data.processCreationId;
                        }}
                        suppressExcelExport={true}
                        suppressCsvExport={true}
                        suppressMultiSort={true}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        rowModelType={'serverSide'}
                        onGridReady={this.onGridReady}
                        onCellEditingStopped={agentDisplayNameAndZenGroupDisplayNameOnlyCellEditingStopped}
                        rowSelection={this.props.rowSelection}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            this.props.onClickRow && this.props.onClickRow(selectedRows);
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        maintainColumnOrder={true} //fixes issue where if you re-order/move column then click anywhere on the grid it reverts this change
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        onFilterChanged={(params)=> {
                            if(saveFilterChanges){
                                onFilterChangedHelper(params, 'processCreationsGridFilterState', updateProcessCreationsGridFilterModelReactive);
                            }
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                        getContextMenuItems={this.getContextMenuItems}
                        sideBar={this.props.sideBar}
                    />
                </div>
            </div>
        );
    }
}
