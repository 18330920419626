import React, {Component, createRef} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    MuiAutocompleteForZenGroupsWithoutCreateGroupOption,
    MuiIconButtonWithTooltip
} from "../components/muiComponents";
import {getDistributionGroups, getZenGroupDropDownContents} from "./zenGroupSessionStorageManager";
import {
    maxDaysUntilInvoiceDue, maxDaysUntilTrialLicenseExpiration,
    minDaysForTrialLicenseExpiration,
    minDaysUntilInvoiceDue
} from "../pages/private/ccOnlyPartnerGroupManagement";


//NOTE: You need to register this Component with each AgGridReact's components in order to use it
export default class CustomNameCellEditor extends Component {
    constructor(props) {
        super(props);

        this.inputRef = createRef();
        this.state = {
            value: props.value
        };
    }

    //focus on the input element of the cell
    afterGuiAttached(){
        if (this.inputRef && this.inputRef.current) this.inputRef.current.focus();
    }

    //Gets called once before cell editing starts
    isCancelBeforeStart() {
        //returning true would cancel the cell editing.
        return false;
    }

    // Gets called once when editing is finished.
    isCancelAfterEnd() {
        //returning true means the result of the edit is ignored and changed back to original value
        //The applyButtonPressed is just to make sure that the only way the update will trigger is if they click the Apply button.
        // This probably is unnecessary since we are calling stopPropagation() on the enter key down
        return !this.state.applyButtonPressed
    }

    render() {
        return (
            <div
                className="flex flex-row gap-x-1 align-start w-full justify-between p-1"
            >
                <input ref={this.inputRef}
                       value={this.props.value}
                       onChange={event => this.props.onValueChange(event.target.value)}
                       onKeyDownCapture={(event) => {
                           if(event.key && event.key.toLowerCase() === "enter"){
                               this.setState({applyButtonPressed: true}, () => {
                                   this.props.api.stopEditing()
                               })
                           }
                       }}
                       className="w-full px-1 focus:outline-none rounded h-full mt-1 mb-1"
                />
                <button
                    className="focus:outline-none px-1 bg-success text-white rounded whitespace-normal md:whitespace-no-wrap outline-none"
                    onClick={() => {
                        this.setState({applyButtonPressed: true}, () => {
                            this.props.api.stopEditing()
                        })
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" fill="currentColor"
                         className="bi bi-check2" viewBox="0 0 16 16">
                        <path
                            d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                    </svg>
                </button>
                <button
                    className="focus:outline-none px-1 bg-danger text-white rounded whitespace-normal md:whitespace-no-wrap outline-none"
                    onClick={() => {
                        this.setState({applyButtonPressed: true}, () => {
                            this.props.api.stopEditing(true)
                        })
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" fill="currentColor"
                         className="bi bi-x-lg" viewBox="0 0 16 16">
                        <path
                            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                </button>
            </div>
        );
    }
}

export function editNameIconOnlyCellRenderer(params, titleText, colKey, alwaysShowIconDiv=false){
    let iconDiv = ""
    if(params.value){
        iconDiv = getEditIconComponent(params, titleText, colKey)
    }
    else if(alwaysShowIconDiv){
        //if alwaysShowIconDiv is passed a true value, then we want to always show the icon div even if there is not a value, so this would return a cell with the icon div but no value after,
        // instead of not showing the div and just a blank cell
        iconDiv = getEditIconComponent(params, titleText, colKey)
    }

    return(
        <div className={"flex flex-nowrap justify-start gap-x-2 items-center"}>
            {iconDiv}
            <div className="flex">
                {params.value}
            </div>
        </div>
    )
}

export function getEditIconComponent(params, titleText, colKey){
    return (
        <MuiIconButtonWithTooltip
            icon={
                <FontAwesomeIcon
                    className="object-contain"
                    icon="fa-duotone fa-pen-to-square"
                    size="xs"
                />
            }
            onClick={() => {
                //manually start editing the cell
                if(params.api && params.node && colKey){
                    params.api.startEditingCell({
                        rowIndex: params.node.rowIndex,
                        colKey: colKey,
                        key: "editNameIconClicked"
                    })
                }
                //The key: "editNameIconClicked" was intended for use of distinguishing which cell editor to use for default group col
                // since we now allow them to be able to change a group name and object's group in one cell, otherwise it is ignored for other columns that use this function
            }}
            tooltipTitle={titleText}
            tooltipPlacement={"bottom-start"}
        />
    )
}

export class CustomMuiAutocompleteGroupCellEditor extends Component {
    constructor(props) {
        super(props);
        this.inputRef = createRef();
        //Check if the Source Distribution Column is the one that is editing, as the logic for zenGroupDropdownOptionsList and state zenGroupId is different than for regular group editing
        if(props.sourceDistributionGroupEditor === true){
            this.zenGroupDropdownOptionsList = getDistributionGroups(true).map(({id,friendlyName}) => ({
                    value: id,
                    label: friendlyName || id,
                }))
            this.state = {
                zenGroupId: props.data.assignedDistributionGroupId,
                value: props.value
            };
        }
        else{
            this.zenGroupDropdownOptionsList = getZenGroupDropDownContents()
            this.state = {
                zenGroupId: props.data.zenGroupId,
                value: props.value
            };
        }
    }

    //focus on the input element of the cell
    afterGuiAttached(){
        if (this.inputRef && this.inputRef.current) this.inputRef.current.focus();
    }

    //Gets called once before cell editing starts
    isCancelBeforeStart() {
        //returning true would cancel the cell editing.
        return false;
    }

    // Gets called once when editing is finished.
    isCancelAfterEnd() {
        //returning true means the result of the edit is ignored and changed back to original value
        //The applyButtonPressed is just to make sure that the only way the update will trigger is if they click the Apply button.
        // This probably is unnecessary since we are calling stopPropagation() on the enter key down
        if(this.state.value){
            return false
        }
        else{
            return true
        }
    }

    render() {
        return (
            <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                zenGroupDropdownOptionsList={this.zenGroupDropdownOptionsList}
                value={this.state.zenGroupId}
                disablePortal={false}
                fullWidth={true}
                label={""}
                className={""}
                onChange={( event, newValue ) => {
                    if(newValue && newValue.value && newValue.label){
                        this.setState({
                            value: newValue.label,
                            zenGroupId: newValue.value
                        }, () => {
                            this.props.api.stopEditing()
                        })
                        this.props.onValueChange(newValue.label)
                    }
                }}
                sx={{
                    "& .MuiOutlinedInput-root": {
                        borderRadius: "0",
                        padding: "0"
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        border: "none"
                    }
                }}
            />
        );
    }
}

export class CustomDoubleCellEditor extends Component {
    constructor(props) {
        super(props);

        this.inputRef = createRef();
        this.state = {
            value: props.value
        };
    }

    //focus on the input element of the cell
    afterGuiAttached(){
        if (this.inputRef && this.inputRef.current) this.inputRef.current.focus();
    }

    //Gets called once before cell editing starts
    isCancelBeforeStart() {
        //returning true would cancel the cell editing.
        return false;
    }

    // Gets called once when editing is finished.
    isCancelAfterEnd() {
        //returning true means the result of the edit is ignored and changed back to original value
        //The applyButtonPressed is just to make sure that the only way the update will trigger is if they click the Apply button.
        // This probably is unnecessary since we are calling stopPropagation() on the enter key down
        return !this.state.applyButtonPressed
    }

    render() {
        return (
            <div
                className="flex flex-row gap-x-1 align-start w-full justify-between p-1"
            >
                <input ref={this.inputRef}
                       value={this.props.value}
                       min={0} max={100} step={0.01}
                       type="number" name="discountEditor"
                       onChange={event => this.props.onValueChange(event.target.value)}
                       onKeyDownCapture={(event) => {
                           if(event.key && event.key.toLowerCase() === "enter"){
                               this.setState({applyButtonPressed: true}, () => {
                                   this.props.api.stopEditing()
                               })
                           }
                           else if (event.key === "e" || event.key === "-" || event.key === "+") { //don't let these chars be included for input
                               event.preventDefault();
                           }
                       }}
                       className="w-full px-1 focus:outline-none rounded h-full mt-1 mb-1"
                />
                <button
                    className="focus:outline-none px-1 bg-success text-white rounded whitespace-normal md:whitespace-no-wrap outline-none"
                    onClick={() => {
                        this.setState({applyButtonPressed: true}, () => {
                            this.props.api.stopEditing()
                        })
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" fill="currentColor"
                         className="bi bi-check2" viewBox="0 0 16 16">
                        <path
                            d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                    </svg>
                </button>
                <button
                    className="focus:outline-none px-1 bg-danger text-white rounded whitespace-normal md:whitespace-no-wrap outline-none"
                    onClick={() => {
                        this.setState({applyButtonPressed: true}, () => {
                            this.props.api.stopEditing(true)
                        })
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" fill="currentColor"
                         className="bi bi-x-lg" viewBox="0 0 16 16">
                        <path
                            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                </button>
            </div>
        );
    }
}

export class CustomGroupInvoiceDaysUntilDueCellEditor extends Component {
    constructor(props) {
        super(props);

        this.inputRef = createRef();
        this.state = {
            value: props.value
        };
    }

    //focus on the input element of the cell
    afterGuiAttached(){
        if (this.inputRef && this.inputRef.current) this.inputRef.current.focus();
    }

    //Gets called once before cell editing starts
    isCancelBeforeStart() {
        //returning true would cancel the cell editing.
        return false;
    }

    // Gets called once when editing is finished.
    isCancelAfterEnd() {
        //returning true means the result of the edit is ignored and changed back to original value
        //The applyButtonPressed is just to make sure that the only way the update will trigger is if they click the Apply button.
        // This probably is unnecessary since we are calling stopPropagation() on the enter key down
        return !this.state.applyButtonPressed
    }

    render() {
        return (
            <div
                className="flex flex-row gap-x-1 align-start w-full justify-between p-1"
            >
                <input ref={this.inputRef}
                       value={this.props.value}
                       min={minDaysUntilInvoiceDue} max={maxDaysUntilInvoiceDue} step={1}
                       type="number" name="invoiceDaysUntilDueEditor"
                       onChange={event => this.props.onValueChange(event.target.value)}
                       onKeyDownCapture={(event) => {
                           if(event.key && event.key.toLowerCase() === "enter"){
                               this.setState({applyButtonPressed: true}, () => {
                                   this.props.api.stopEditing()
                               })
                           }
                           else if (event.key === "e" || event.key === "-" || event.key === "+" || event.key === ".") { //don't let these chars be included for input
                               event.preventDefault();
                           }
                       }}
                       className="w-full px-1 focus:outline-none rounded h-full mt-1 mb-1"
                />
                <button
                    className="focus:outline-none px-1 bg-success text-white rounded whitespace-normal md:whitespace-no-wrap outline-none"
                    onClick={() => {
                        this.setState({applyButtonPressed: true}, () => {
                            this.props.api.stopEditing()
                        })
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" fill="currentColor"
                         className="bi bi-check2" viewBox="0 0 16 16">
                        <path
                            d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                    </svg>
                </button>
                <button
                    className="focus:outline-none px-1 bg-danger text-white rounded whitespace-normal md:whitespace-no-wrap outline-none"
                    onClick={() => {
                        this.setState({applyButtonPressed: true}, () => {
                            this.props.api.stopEditing(true)
                        })
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" fill="currentColor"
                         className="bi bi-x-lg" viewBox="0 0 16 16">
                        <path
                            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                </button>
            </div>
        );
    }
}

export class CustomGroupMaxDaysUntilPOCTrialLicensesCanExpireCellEditor extends Component {
    constructor(props) {
        super(props);

        this.inputRef = createRef();
        this.state = {
            value: props.value
        };
    }

    //focus on the input element of the cell
    afterGuiAttached(){
        if (this.inputRef && this.inputRef.current) this.inputRef.current.focus();
    }

    //Gets called once before cell editing starts
    isCancelBeforeStart() {
        //returning true would cancel the cell editing.
        return false;
    }

    // Gets called once when editing is finished.
    isCancelAfterEnd() {
        //returning true means the result of the edit is ignored and changed back to original value
        //The applyButtonPressed is just to make sure that the only way the update will trigger is if they click the Apply button.
        // This probably is unnecessary since we are calling stopPropagation() on the enter key down
        return !this.state.applyButtonPressed
    }

    render() {
        return (
            <div
                className="flex flex-row gap-x-1 align-start w-full justify-between p-1"
            >
                <input ref={this.inputRef}
                       value={this.props.value}
                       min={minDaysForTrialLicenseExpiration} max={maxDaysUntilTrialLicenseExpiration} step={1}
                       type="number" name="partnerMaxDaysForPOCTrialLicensesToExpireEditor"
                       onChange={event => this.props.onValueChange(event.target.value)}
                       onKeyDownCapture={(event) => {
                           if(event.key && event.key.toLowerCase() === "enter"){
                               this.setState({applyButtonPressed: true}, () => {
                                   this.props.api.stopEditing()
                               })
                           }
                           else if (event.key === "e" || event.key === "-" || event.key === "+" || event.key === ".") { //don't let these chars be included for input
                               event.preventDefault();
                           }
                       }}
                       className="w-full px-1 focus:outline-none rounded h-full mt-1 mb-1"
                />
                <button
                    className="focus:outline-none px-1 bg-success text-white rounded whitespace-normal md:whitespace-no-wrap outline-none"
                    onClick={() => {
                        this.setState({applyButtonPressed: true}, () => {
                            this.props.api.stopEditing()
                        })
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" fill="currentColor"
                         className="bi bi-check2" viewBox="0 0 16 16">
                        <path
                            d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                    </svg>
                </button>
                <button
                    className="focus:outline-none px-1 bg-danger text-white rounded whitespace-normal md:whitespace-no-wrap outline-none"
                    onClick={() => {
                        this.setState({applyButtonPressed: true}, () => {
                            this.props.api.stopEditing(true)
                        })
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" fill="currentColor"
                         className="bi bi-x-lg" viewBox="0 0 16 16">
                        <path
                            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                </button>
            </div>
        );
    }
}